import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const health: AnswerPathTreeNode = {
  path: 'health',
  children: [
    {
      path: 'medicalConsultationsInfo',
      children: [
        {
          path: 'diagnosedTreatedOrConsultedForSymptoms',
          nodeId: NodeIds.healthDiagnosedTreatedOrConsultedForSymptoms,
        },
        { path: 'diagnosedTreatedOrConsultedDetails', nodeId: NodeIds.healthDiagnosedTreatedOrConsultedDetails },
        { path: 'consultedPhysicianRecommendedForAge', nodeId: NodeIds.healthConsultedPhysicianRecommendedForAge },
        {
          path: 'consultedPhysicianRecommendedForAgeResults',
          nodeId: NodeIds.healthConsultedPhysicianRecommendedForAgeResults,
        },
        {
          path: 'consultedPhysicianRecommendedForAgeDetails',
          nodeId: NodeIds.healthConsultedPhysicianRecommendedForAgeDetails,
        },
        { path: 'hadMedicalTestsLast5Years', nodeId: NodeIds.healthMedicalTestsLast5Years },
        { path: 'details', nodeId: NodeIds.healthMedicalTestsLast5YearsDetails },
        {
          path: 'hadConsultedForAbnormalProblemsLast5Years',
          nodeId: 'health-consulted-for-abnormal-problems-last-5-years',
        },
        { path: 'healthAbnormalProblemsLast5YearsDetails', nodeId: 'health-abnormal-problems-last-5-years-details' },
        { path: 'hadHospitalizedInLast5Years', nodeId: 'health-hospitalized-in-last-5-years' },
        { path: 'hospitalizedLast5YearsDetails', nodeId: 'health-hospitalized-last-5-years-details' },
      ],
    },
    { path: 'confidenceInWeightMeasurement', nodeId: NodeIds.healthConfidenceInWeightMeasurement },
    {
      path: 'covid19',
      children: [{ path: 'diagnosedOrContactPastMonth', nodeId: 'health-covid-19-diagnosed-or-contact' }],
    },
    { path: 'fullTimeCare', children: [{ path: 'needed', nodeId: NodeIds.healthFullTimeCareNeeded }] },
    {
      path: 'disability',
      children: [
        { path: 'hasDisabilitySymptoms', nodeId: NodeIds.healthHasDisabilitySymptoms },
        { path: 'infirmOrInWheelchair', nodeId: NodeIds.healthInfirmOrInWheelchair },
      ],
    },
    {
      path: 'pergnancyChildbirthInfo',
      children: [
        { path: 'isPregnant', nodeId: NodeIds.healthIsPregnant },
        { path: 'pregnancyDueDate', nodeId: NodeIds.healthPregnancyDueDate },
        { path: 'hadPregnancyComplications', nodeId: NodeIds.healthPregnancyComplications },
        { path: 'pregnancyComplicationsDetails', nodeId: NodeIds.healthPregnancyComplicationsDetails },
        { path: 'pregnancyDeliveryDate', nodeId: NodeIds.healthPregnancyDeliveryDate },
        { path: 'hasPregnancyComplications', nodeId: NodeIds.healthHasPregnancyComplications },
        { path: 'pregnancyComplicationDetails', nodeId: NodeIds.healthPregnancyComplicationDetails },
        { path: 'pregnancyComplicationHistory', nodeId: NodeIds.healthPregnancyComplicationHistory },
        { path: 'pregnancyMedProviderName', nodeId: NodeIds.healthPregnancyMedProviderName },
        { path: 'pregnancyMedProviderAddress', nodeId: NodeIds.healthPregnancyMedProviderAddress },
        { path: 'pregnancyDiagnosis', nodeId: NodeIds.healthPregnancyDiagnosis },
      ],
    },
    {
      path: 'medicationInfo',
      children: [
        { path: 'medication', nodeId: NodeIds.healthMedication },
        { path: 'medicationDetails', nodeId: NodeIds.healthMedicationDetails },
      ],
    },
    {
      path: 'telephoneInterviewInfo',
      children: [
        { path: 'preselection', nodeId: NodeIds.healthPhoneInterviewPreselection },
        { path: 'day', nodeId: NodeIds.healthPhoneInterviewDay },
        { path: 'time', nodeId: NodeIds.healthPhoneInterviewTime },
        { path: 'number', nodeId: NodeIds.healthPhoneInterviewNumber },
        { path: 'day2', nodeId: 'health-phone-interview-day-2' },
        { path: 'time2', nodeId: 'health-phone-interview-time-2' },
        { path: 'number2', nodeId: 'health-phone-interview-number-2' },
      ],
    },
    {
      path: 'temporaryInsuranceAgreementInfo',
      children: [
        { path: 'agreement', nodeId: NodeIds.temporaryInsuranceAgreement },
        { path: 'legal', nodeId: NodeIds.temporaryInsuranceAgreementLegal },
        { path: 'age', nodeId: NodeIds.temporaryInsuranceAgreementAge },
        {
          path: 'pastApplications',
          nodeId: NodeIds.temporaryInsuranceAgreementPastApplications,
        },
        {
          path: 'cardiovascular',
          nodeId: NodeIds.temporaryInsuranceAgreementCardiovascular,
        },
        { path: 'hospital', nodeId: NodeIds.temporaryInsuranceAgreementHospital },
        { path: 'drugAlcohol', nodeId: NodeIds.temporaryInsuranceAgreementDrugAlcohol },
      ],
    },
    {
      path: 'stomachDisorder',
      children: [
        { path: 'hasGastrointestinalDisease', nodeId: NodeIds.healthHasGastrointestinalDisease },
        { path: 'gastrointestinalDiseaseChoices', nodeId: NodeIds.healthGastrointestinalDiseaseChoices },
        { path: 'stomachDisorderDiagnosisDate', nodeId: NodeIds.healthStomachDisorderDiagnosisDate },
        { path: 'stomachDisorderMedications', nodeId: NodeIds.healthStomachDisorderMedications },
        { path: 'stomachDisorderAdditionalTreatment', nodeId: NodeIds.healthStomachDisorderAdditionalTreatment },
        { path: 'stomachDisorderStatus', nodeId: NodeIds.healthStomachDisorderStatus },
        { path: 'stomachDisorderDateOfFullRecovery', nodeId: NodeIds.healthStomachDisorderDateOfFullRecovery },
        { path: 'stomachDisorderLimitationDetails', nodeId: NodeIds.healthStomachDisorderLimitationDetails },
        {
          path: 'stomachDisorderCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthStomachDisorderCurrentlyExperienceSymptoms,
        },
        { path: 'stomachDisorderDateOfLastSymptom', nodeId: NodeIds.healthStomachDisorderDateOfLastSymptom },
        { path: 'stomachDisorderDateOfLastOccurrence', nodeId: NodeIds.healthStomachDisorderDateOfLastOccurrence },
        { path: 'stomachDisorderNumberOfOccurrences', nodeId: NodeIds.healthStomachDisorderNumberOfOccurrences },
        {
          path: 'stomachDisorderCausePhysicalLimitations',
          nodeId: NodeIds.healthStomachDisorderCausePhysicalLimitations,
        },
        { path: 'stomachDisorderLimitationDetails2', nodeId: 'health-stomach-disorder-limitation-details-2' },
        { path: 'stomachDisorderMedProv', nodeId: NodeIds.healthStomachDisorderMedProv },
        { path: 'stomachDisorderMedProvName', nodeId: NodeIds.healthStomachDisorderMedProvName },
        { path: 'stomachDisorderMedProvAddress', nodeId: NodeIds.healthStomachDisorderMedProvAddress },
        { path: 'stomachDisorderMedProvPhone', nodeId: NodeIds.healthStomachDisorderMedProvPhone },
        { path: 'stomachDisorderHospitalVisit', nodeId: NodeIds.healthStomachDisorderHospitalVisit },
        { path: 'stomachDisorderHospitalVisitDate', nodeId: NodeIds.healthStomachDisorderHospitalVisitDate },
        { path: 'stomachDisorderHospitalName', nodeId: NodeIds.healthStomachDisorderHospitalName },
        { path: 'stomachDisorderHospitalAddress', nodeId: NodeIds.healthStomachDisorderHospitalAddress },
      ],
    },
    {
      path: 'earDisease',
      children: [
        { path: 'earDiseaseDiagnosisDate', nodeId: NodeIds.healthEarDiseaseDiagnosisDate },
        { path: 'earDiseaseMedications', nodeId: NodeIds.healthEarDiseaseMedications },
        { path: 'earDiseaseAdditionalTreatment', nodeId: NodeIds.healthEarDiseaseAdditionalTreatment },
        { path: 'earDiseaseStatus', nodeId: NodeIds.healthEarDiseaseStatus },
        { path: 'earDiseaseDateOfFullRecovery', nodeId: NodeIds.healthEarDiseaseDateOfFullRecovery },
        { path: 'earDiseaseLimitationDetails', nodeId: NodeIds.healthEarDiseaseLimitationDetails },
        { path: 'earDiseaseCurrentlyExperienceSymptoms', nodeId: NodeIds.healthEarDiseaseCurrentlyExperienceSymptoms },
        { path: 'earDiseaseDateOfLastSymptom', nodeId: NodeIds.healthEarDiseaseDateOfLastSymptom },
        { path: 'earDiseaseDateOfLastOccurrence', nodeId: NodeIds.healthEarDiseaseDateOfLastOccurrence },
        { path: 'earDiseaseNumberOfOccurrences', nodeId: NodeIds.healthEarDiseaseNumberOfOccurrences },
        { path: 'earDiseaseCausePhysicalLimitations', nodeId: NodeIds.healthEarDiseaseCausePhysicalLimitations },
        { path: 'earDiseaseLimitationDetails2', nodeId: 'health-ear-disease-limitation-details-2' },
        { path: 'earDiseaseMedProv', nodeId: NodeIds.healthEarDiseaseMedProv },
        { path: 'earDiseaseMedProvName', nodeId: NodeIds.healthEarDiseaseMedProvName },
        { path: 'earDiseaseMedProvAddress', nodeId: NodeIds.healthEarDiseaseMedProvAddress },
        { path: 'earDiseaseMedProvPhone', nodeId: NodeIds.healthEarDiseaseMedProvPhone },
        { path: 'earDiseaseHospitalVisit', nodeId: NodeIds.healthEarDiseaseHospitalVisit },
        { path: 'earDiseaseHospitalVisitDate', nodeId: NodeIds.healthEarDiseaseHospitalVisitDate },
        { path: 'earDiseaseHospitalName', nodeId: NodeIds.healthEarDiseaseHospitalName },
        { path: 'earDiseaseHospitalAddress', nodeId: NodeIds.healthEarDiseaseHospitalAddress },
      ],
    },
    {
      path: 'dizziness',
      children: [
        { path: 'dizzinessDiagnosisDate', nodeId: NodeIds.healthDizzinessDiagnosisDate },
        { path: 'dizzinessMedications', nodeId: NodeIds.healthDizzinessMedications },
        { path: 'dizzinessAdditionalTreatment', nodeId: NodeIds.healthDizzinessAdditionalTreatment },
        { path: 'dizzinessStatus', nodeId: NodeIds.healthDizzinessStatus },
        { path: 'dizzinessDateOfFullRecovery', nodeId: NodeIds.healthDizzinessDateOfFullRecovery },
        { path: 'dizzinessLimitationDetails', nodeId: NodeIds.healthDizzinessLimitationDetails },
        { path: 'dizzinessCurrentlyExperienceSymptoms', nodeId: NodeIds.healthDizzinessCurrentlyExperienceSymptoms },
        { path: 'dizzinessDateOfLastSymptom', nodeId: NodeIds.healthDizzinessDateOfLastSymptom },
        { path: 'dizzinessDateOfLastOccurrence', nodeId: NodeIds.healthDizzinessDateOfLastOccurrence },
        { path: 'dizzinessNumberOfOccurrences', nodeId: NodeIds.healthDizzinessNumberOfOccurrences },
        { path: 'dizzinessCausePhysicalLimitations', nodeId: NodeIds.healthDizzinessCausePhysicalLimitations },
        { path: 'dizzinessLimitationDetails2', nodeId: 'health-dizziness-limitation-details-2' },
        { path: 'dizzinessMedProv', nodeId: NodeIds.healthDizzinessMedProv },
        { path: 'dizzinessMedProvName', nodeId: NodeIds.healthDizzinessMedProvName },
        { path: 'dizzinessMedProvAddress', nodeId: NodeIds.healthDizzinessMedProvAddress },
        { path: 'dizzinessMedProvPhone', nodeId: NodeIds.healthDizzinessMedProvPhone },
        { path: 'dizzinessHospitalVisit', nodeId: NodeIds.healthDizzinessHospitalVisit },
        { path: 'dizzinessHospitalVisitDate', nodeId: NodeIds.healthDizzinessHospitalVisitDate },
        { path: 'dizzinessHospitalName', nodeId: NodeIds.healthDizzinessHospitalName },
        { path: 'dizzinessHospitalAddress', nodeId: NodeIds.healthDizzinessHospitalAddress },
      ],
    },
    {
      path: 'epilepsy',
      children: [
        { path: 'hasNervousSystemIssuesSymptoms', nodeId: NodeIds.healthHasNervousSystemIssuesSymptoms },
        { path: 'hasNervousSystemIssuesChoices', nodeId: NodeIds.healthHasNervousSystemIssuesChoices },
        { path: 'epilepsyFirstDate', nodeId: NodeIds.healthEpilepsyFirstDate },
        { path: 'epilepsyOccur', nodeId: NodeIds.healthEpilepsyOccur },
        { path: 'epilepsyLastDate', nodeId: NodeIds.healthEpilepsyLastDate },
        { path: 'epilepsyType', nodeId: NodeIds.healthEpilepsyType },
        { path: 'epilepsyOther', nodeId: NodeIds.healthEpilepsyOther },
        { path: 'epilepsyDiagnosisDate', nodeId: NodeIds.healthEpilepsyDiagnosisDate },
        { path: 'epilepsyMedications', nodeId: NodeIds.healthEpilepsyMedications },
        { path: 'epilepsyAdditionalTreatment', nodeId: NodeIds.healthEpilepsyAdditionalTreatment },
        { path: 'epilepsyStatus', nodeId: NodeIds.healthEpilepsyStatus },
        { path: 'epilepsyDateOfFullRecovery', nodeId: NodeIds.healthEpilepsyDateOfFullRecovery },
        { path: 'epilepsyLimitationDetails', nodeId: NodeIds.healthEpilepsyLimitationDetails },
        { path: 'epilepsyCurrentlyExperienceSymptoms', nodeId: NodeIds.healthEpilepsyCurrentlyExperienceSymptoms },
        { path: 'epilepsyDateOfLastSymptom', nodeId: NodeIds.healthEpilepsyDateOfLastSymptom },
        { path: 'epilepsyDateOfLastOccurrence', nodeId: NodeIds.healthEpilepsyDateOfLastOccurrence },
        { path: 'epilepsyNumberOfOccurrences', nodeId: NodeIds.healthEpilepsyNumberOfOccurrences },
        { path: 'epilepsyCausePhysicalLimitations', nodeId: NodeIds.healthEpilepsyCausePhysicalLimitations },
        { path: 'epilepsyLimitationDetails2', nodeId: 'health-epilepsy-limitation-details-2' },
        { path: 'epilepsyMedProv', nodeId: NodeIds.healthEpilepsyMedProv },
        { path: 'epilepsyMedProvName', nodeId: NodeIds.healthEpilepsyMedProvName },
        { path: 'epilepsyMedProvAddress', nodeId: NodeIds.healthEpilepsyMedProvAddress },
        { path: 'epilepsyMedProvPhone', nodeId: NodeIds.healthEpilepsyMedProvPhone },
        { path: 'epilepsyMedicationNumber', nodeId: NodeIds.healthEpilepsyMedicationNumber },
        { path: 'epilepsyMedicationName', nodeId: NodeIds.healthEpilepsyMedicationName },
        { path: 'epilepsyHospitalVisit', nodeId: NodeIds.healthEpilepsyHospitalVisit },
        { path: 'epilepsyHospitalVisitDate', nodeId: NodeIds.healthEpilepsyHospitalVisitDate },
        { path: 'epilepsyHospitalName', nodeId: NodeIds.healthEpilepsyHospitalName },
        { path: 'epilepsyHospitalAddress', nodeId: NodeIds.healthEpilepsyHospitalAddress },
        { path: 'epilepsyEmergencyRoomVisit', nodeId: NodeIds.healthEpilepsyEmergencyRoomVisit },
        { path: 'epilepsyEmergencyRoomDate', nodeId: NodeIds.healthEpilepsyEmergencyRoomDate },
        { path: 'epilepsyEmergencyRoomName', nodeId: NodeIds.healthEpilepsyEmergencyRoomName },
        { path: 'epilepsyEmergencyRoomAddress', nodeId: NodeIds.healthEpilepsyEmergencyRoomAddress },
      ],
    },
    {
      path: 'injuryMuscles',
      children: [
        { path: 'injuryMusclesDiagnosisDate', nodeId: NodeIds.healthInjuryMusclesDiagnosisDate },
        { path: 'injuryMusclesMedications', nodeId: NodeIds.healthInjuryMusclesMedications },
        { path: 'injuryMusclesAdditionalTreatment', nodeId: NodeIds.healthInjuryMusclesAdditionalTreatment },
        { path: 'injuryMusclesStatus', nodeId: NodeIds.healthInjuryMusclesStatus },
        { path: 'injuryMusclesDateOfFullRecovery', nodeId: NodeIds.healthInjuryMusclesDateOfFullRecovery },
        { path: 'injuryMusclesLimitationDetails', nodeId: NodeIds.healthInjuryMusclesLimitationDetails },
        {
          path: 'injuryMusclesCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthInjuryMusclesCurrentlyExperienceSymptoms,
        },
        { path: 'injuryMusclesDateOfLastSymptom', nodeId: NodeIds.healthInjuryMusclesDateOfLastSymptom },
        { path: 'injuryMusclesDateOfLastOccurrence', nodeId: NodeIds.healthInjuryMusclesDateOfLastOccurrence },
        { path: 'injuryMusclesNumberOfOccurrences', nodeId: NodeIds.healthInjuryMusclesNumberOfOccurrences },
        { path: 'injuryMusclesCausePhysicalLimitations', nodeId: NodeIds.healthInjuryMusclesCausePhysicalLimitations },
        { path: 'injuryMusclesLimitationDetails2', nodeId: 'health-injury-muscles-limitation-details-2' },
        { path: 'injuryMusclesMedProv', nodeId: NodeIds.healthInjuryMusclesMedProv },
        { path: 'injuryMusclesMedProvName', nodeId: NodeIds.healthInjuryMusclesMedProvName },
        { path: 'injuryMusclesMedProvAddress', nodeId: NodeIds.healthInjuryMusclesMedProvAddress },
        { path: 'injuryMusclesMedProvPhone', nodeId: NodeIds.healthInjuryMusclesMedProvPhone },
        { path: 'injuryMusclesHospitalVisit', nodeId: NodeIds.healthInjuryMusclesHospitalVisit },
        { path: 'injuryMusclesHospitalVisitDate', nodeId: NodeIds.healthInjuryMusclesHospitalVisitDate },
        { path: 'injuryMusclesHospitalName', nodeId: NodeIds.healthInjuryMusclesHospitalName },
        { path: 'injuryMusclesHospitalAddress', nodeId: NodeIds.healthInjuryMusclesHospitalAddress },
      ],
    },
    {
      path: 'injuryBones',
      children: [
        { path: 'injuryBonesDiagnosisDate', nodeId: NodeIds.healthInjuryBonesDiagnosisDate },
        { path: 'injuryBonesMedications', nodeId: NodeIds.healthInjuryBonesMedications },
        { path: 'injuryBonesAdditionalTreatment', nodeId: NodeIds.healthInjuryBonesAdditionalTreatment },
        { path: 'injuryBonesStatus', nodeId: NodeIds.healthInjuryBonesStatus },
        { path: 'injuryBonesDateOfFullRecovery', nodeId: NodeIds.healthInjuryBonesDateOfFullRecovery },
        { path: 'injuryBonesLimitationDetails', nodeId: NodeIds.healthInjuryBonesLimitationDetails },
        {
          path: 'injuryBonesCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthInjuryBonesCurrentlyExperienceSymptoms,
        },
        { path: 'injuryBonesDateOfLastSymptom', nodeId: NodeIds.healthInjuryBonesDateOfLastSymptom },
        { path: 'injuryBonesDateOfLastOccurrence', nodeId: NodeIds.healthInjuryBonesDateOfLastOccurrence },
        { path: 'injuryBonesNumberOfOccurrences', nodeId: NodeIds.healthInjuryBonesNumberOfOccurrences },
        { path: 'injuryBonesCausePhysicalLimitations', nodeId: NodeIds.healthInjuryBonesCausePhysicalLimitations },
        { path: 'injuryBonesLimitationDetails2', nodeId: 'health-injury-bones-limitation-details-2' },
        { path: 'injuryBonesMedProv', nodeId: NodeIds.healthInjuryBonesMedProv },
        { path: 'injuryBonesMedProvName', nodeId: NodeIds.healthInjuryBonesMedProvName },
        { path: 'injuryBonesMedProvAddress', nodeId: NodeIds.healthInjuryBonesMedProvAddress },
        { path: 'injuryBonesMedProvPhone', nodeId: NodeIds.healthInjuryBonesMedProvPhone },
        { path: 'injuryBonesHospitalVisit', nodeId: NodeIds.healthInjuryBonesHospitalVisit },
        { path: 'injuryBonesHospitalVisitDate', nodeId: NodeIds.healthInjuryBonesHospitalVisitDate },
        { path: 'injuryBonesHospitalName', nodeId: NodeIds.healthInjuryBonesHospitalName },
        { path: 'injuryBonesHospitalAddress', nodeId: NodeIds.healthInjuryBonesHospitalAddress },
      ],
    },
    {
      path: 'connectiveTissueDisease',
      children: [
        { path: 'connectiveTissueDiseaseDiagnosisDate', nodeId: NodeIds.healthConnectiveTissueDiseaseDiagnosisDate },
        { path: 'connectiveTissueDiseaseMedications', nodeId: NodeIds.healthConnectiveTissueDiseaseMedications },
        {
          path: 'connectiveTissueDiseaseAdditionalTreatment',
          nodeId: NodeIds.healthConnectiveTissueDiseaseAdditionalTreatment,
        },
        { path: 'connectiveTissueDiseaseStatus', nodeId: NodeIds.healthConnectiveTissueDiseaseStatus },
        {
          path: 'connectiveTissueDiseaseDateOfFullRecovery',
          nodeId: NodeIds.healthConnectiveTissueDiseaseDateOfFullRecovery,
        },
        {
          path: 'connectiveTissueDiseaseLimitationDetails',
          nodeId: NodeIds.healthConnectiveTissueDiseaseLimitationDetails,
        },
        {
          path: 'connectiveTissueDiseaseCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthConnectiveTissueDiseaseCurrentlyExperienceSymptoms,
        },
        {
          path: 'connectiveTissueDiseaseDateOfLastSymptom',
          nodeId: NodeIds.healthConnectiveTissueDiseaseDateOfLastSymptom,
        },
        {
          path: 'connectiveTissueDiseaseDateOfLastOccurrence',
          nodeId: NodeIds.healthConnectiveTissueDiseaseDateOfLastOccurrence,
        },
        {
          path: 'connectiveTissueDiseaseNumberOfOccurrences',
          nodeId: NodeIds.healthConnectiveTissueDiseaseNumberOfOccurrences,
        },
        {
          path: 'connectiveTissueDiseaseCausePhysicalLimitations',
          nodeId: NodeIds.healthConnectiveTissueDiseaseCausePhysicalLimitations,
        },
        {
          path: 'connectiveTissueDiseaseLimitationDetails2',
          nodeId: 'health-connective-tissue-disease-limitation-details-2',
        },
        { path: 'connectiveTissueDiseaseMedProv', nodeId: NodeIds.healthConnectiveTissueDiseaseMedProv },
        { path: 'connectiveTissueDiseaseMedProvName', nodeId: NodeIds.healthConnectiveTissueDiseaseMedProvName },
        { path: 'connectiveTissueDiseaseMedProvAddress', nodeId: NodeIds.healthConnectiveTissueDiseaseMedProvAddress },
        { path: 'connectiveTissueDiseaseMedProvPhone', nodeId: NodeIds.healthConnectiveTissueDiseaseMedProvPhone },
        { path: 'connectiveTissueDiseaseHospitalVisit', nodeId: NodeIds.healthConnectiveTissueDiseaseHospitalVisit },
        {
          path: 'connectiveTissueDiseaseHospitalVisitDate',
          nodeId: NodeIds.healthConnectiveTissueDiseaseHospitalVisitDate,
        },
        { path: 'connectiveTissueDiseaseHospitalName', nodeId: NodeIds.healthConnectiveTissueDiseaseHospitalName },
        {
          path: 'connectiveTissueDiseaseHospitalAddress',
          nodeId: NodeIds.healthConnectiveTissueDiseaseHospitalAddress,
        },
      ],
    },
    {
      path: 'lupus',
      children: [
        { path: 'lupusDiagnosisDate', nodeId: NodeIds.healthLupusDiagnosisDate },
        { path: 'lupusMedications', nodeId: NodeIds.healthLupusMedications },
        { path: 'lupusAdditionalTreatment', nodeId: NodeIds.healthLupusAdditionalTreatment },
        { path: 'lupusStatus', nodeId: NodeIds.healthLupusStatus },
        { path: 'lupusDateOfFullRecovery', nodeId: NodeIds.healthLupusDateOfFullRecovery },
        { path: 'lupusLimitationDetails', nodeId: NodeIds.healthLupusLimitationDetails },
        { path: 'lupusCurrentlyExperienceSymptoms', nodeId: NodeIds.healthLupusCurrentlyExperienceSymptoms },
        { path: 'lupusDateOfLastSymptom', nodeId: NodeIds.healthLupusDateOfLastSymptom },
        { path: 'lupusDateOfLastOccurrence', nodeId: NodeIds.healthLupusDateOfLastOccurrence },
        { path: 'lupusNumberOfOccurrences', nodeId: NodeIds.healthLupusNumberOfOccurrences },
        { path: 'lupusCausePhysicalLimitations', nodeId: NodeIds.healthLupusCausePhysicalLimitations },
        { path: 'lupusLimitationDetails2', nodeId: 'health-lupus-limitation-details-2' },
        { path: 'lupusMedProv', nodeId: NodeIds.healthLupusMedProv },
        { path: 'lupusMedProvName', nodeId: NodeIds.healthLupusMedProvName },
        { path: 'lupusMedProvAddress', nodeId: NodeIds.healthLupusMedProvAddress },
        { path: 'lupusMedProvPhone', nodeId: NodeIds.healthLupusMedProvPhone },
        { path: 'lupusHospitalVisit', nodeId: NodeIds.healthLupusHospitalVisit },
        { path: 'lupusHospitalVisitDate', nodeId: NodeIds.healthLupusHospitalVisitDate },
        { path: 'lupusHospitalName', nodeId: NodeIds.healthLupusHospitalName },
        { path: 'lupusHospitalAddress', nodeId: NodeIds.healthLupusHospitalAddress },
      ],
    },
    {
      path: 'autoImmune',
      children: [
        { path: 'autoImmuneDiagnosis', nodeId: NodeIds.healthAutoImmuneDiagnosis },
        { path: 'autoImmuneChoices', nodeId: NodeIds.healthAutoImmuneChoices },
        { path: 'autoImmuneDiagnosisDate', nodeId: NodeIds.healthAutoImmuneDiagnosisDate },
        { path: 'autoImmuneMedications', nodeId: NodeIds.healthAutoImmuneMedications },
        { path: 'autoImmuneAdditionalTreatment', nodeId: NodeIds.healthAutoImmuneAdditionalTreatment },
        { path: 'autoImmuneStatus', nodeId: NodeIds.healthAutoImmuneStatus },
        { path: 'autoImmuneDateOfFullRecovery', nodeId: NodeIds.healthAutoImmuneDateOfFullRecovery },
        { path: 'autoImmuneLimitationDetails', nodeId: NodeIds.healthAutoImmuneLimitationDetails },
        { path: 'autoImmuneCurrentlyExperienceSymptoms', nodeId: NodeIds.healthAutoImmuneCurrentlyExperienceSymptoms },
        { path: 'autoImmuneDateOfLastSymptom', nodeId: NodeIds.healthAutoImmuneDateOfLastSymptom },
        { path: 'autoImmuneDateOfLastOccurrence', nodeId: NodeIds.healthAutoImmuneDateOfLastOccurrence },
        { path: 'autoImmuneNumberOfOccurrences', nodeId: NodeIds.healthAutoImmuneNumberOfOccurrences },
        { path: 'autoImmuneCausePhysicalLimitations', nodeId: NodeIds.healthAutoImmuneCausePhysicalLimitations },
        { path: 'autoImmuneLimitationDetails2', nodeId: 'health-auto-immune-limitation-details-2' },
        { path: 'autoImmuneMedProv', nodeId: NodeIds.healthAutoImmuneMedProv },
        { path: 'autoImmuneMedProvName', nodeId: NodeIds.healthAutoImmuneMedProvName },
        { path: 'autoImmuneMedProvAddress', nodeId: NodeIds.healthAutoImmuneMedProvAddress },
        { path: 'autoImmuneMedProvPhone', nodeId: NodeIds.healthAutoImmuneMedProvPhone },
        { path: 'autoImmuneHospitalVisit', nodeId: NodeIds.healthAutoImmuneHospitalVisit },
        { path: 'autoImmuneHospitalVisitDate', nodeId: NodeIds.healthAutoImmuneHospitalVisitDate },
        { path: 'autoImmuneHospitalName', nodeId: NodeIds.healthAutoImmuneHospitalName },
        { path: 'autoImmuneHospitalAddress', nodeId: NodeIds.healthAutoImmuneHospitalAddress },
      ],
    },
    { path: 'aidsRisk', children: [{ path: 'aidsRisk', nodeId: NodeIds.healthAidsRisk }] },
    {
      path: 'otherBloodGlandularDisorder',
      children: [
        {
          path: 'otherBloodGlandularDisorderDiagnosisDate',
          nodeId: NodeIds.healthOtherBloodGlandularDisorderDiagnosisDate,
        },
        {
          path: 'otherBloodGlandularDisorderMedications',
          nodeId: NodeIds.healthOtherBloodGlandularDisorderMedications,
        },
        {
          path: 'otherBloodGlandularDisorderAdditionalTreatment',
          nodeId: NodeIds.healthOtherBloodGlandularDisorderAdditionalTreatment,
        },
        { path: 'otherBloodGlandularDisorderStatus', nodeId: NodeIds.healthOtherBloodGlandularDisorderStatus },
        {
          path: 'otherBloodGlandularDisorderDateOfFullRecovery',
          nodeId: NodeIds.healthOtherBloodGlandularDisorderDateOfFullRecovery,
        },
        {
          path: 'otherBloodGlandularDisorderLimitationDetails',
          nodeId: NodeIds.healthOtherBloodGlandularDisorderLimitationDetails,
        },
        {
          path: 'otherBloodGlandularDisorderCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthOtherBloodGlandularDisorderCurrentlyExperienceSymptoms,
        },
        {
          path: 'otherBloodGlandularDisorderDateOfLastSymptom',
          nodeId: NodeIds.healthOtherBloodGlandularDisorderDateOfLastSymptom,
        },
        {
          path: 'otherBloodGlandularDisorderDateOfLastOccurrence',
          nodeId: NodeIds.healthOtherBloodGlandularDisorderDateOfLastOccurrence,
        },
        {
          path: 'otherBloodGlandularDisorderNumberOfOccurrences',
          nodeId: NodeIds.healthOtherBloodGlandularDisorderNumberOfOccurrences,
        },
        {
          path: 'otherBloodGlandularDisorderCausePhysicalLimitations',
          nodeId: NodeIds.healthOtherBloodGlandularDisorderCausePhysicalLimitations,
        },
        {
          path: 'otherBloodGlandularDisorderLimitationDetails2',
          nodeId: 'health-other-blood-glandular-disorder-limitation-details-2',
        },
        { path: 'otherBloodGlandularDisorderMedProv', nodeId: NodeIds.healthOtherBloodGlandularDisorderMedProv },
        {
          path: 'otherBloodGlandularDisorderMedProvName',
          nodeId: NodeIds.healthOtherBloodGlandularDisorderMedProvName,
        },
        {
          path: 'otherBloodGlandularDisorderMedProvAddress',
          nodeId: NodeIds.healthOtherBloodGlandularDisorderMedProvAddress,
        },
        {
          path: 'otherBloodGlandularDisorderMedProvPhone',
          nodeId: NodeIds.healthOtherBloodGlandularDisorderMedProvPhone,
        },
        {
          path: 'otherBloodGlandularDisorderHospitalVisit',
          nodeId: NodeIds.healthOtherBloodGlandularDisorderHospitalVisit,
        },
        {
          path: 'otherBloodGlandularDisorderHospitalVisitDate',
          nodeId: NodeIds.healthOtherBloodGlandularDisorderHospitalVisitDate,
        },
        {
          path: 'otherBloodGlandularDisorderHospitalName',
          nodeId: NodeIds.healthOtherBloodGlandularDisorderHospitalName,
        },
        {
          path: 'otherBloodGlandularDisorderHospitalAddress',
          nodeId: NodeIds.healthOtherBloodGlandularDisorderHospitalAddress,
        },
      ],
    },
    {
      path: 'growthLumpNotEvaluated',
      children: [
        { path: 'growthLumpNotEvaluatedDiagnosisDate', nodeId: NodeIds.healthGrowthLumpNotEvaluatedDiagnosisDate },
        { path: 'growthLumpNotEvaluatedMedications', nodeId: NodeIds.healthGrowthLumpNotEvaluatedMedications },
        {
          path: 'growthLumpNotEvaluatedAdditionalTreatment',
          nodeId: NodeIds.healthGrowthLumpNotEvaluatedAdditionalTreatment,
        },
        { path: 'growthLumpNotEvaluatedStatus', nodeId: NodeIds.healthGrowthLumpNotEvaluatedStatus },
        {
          path: 'growthLumpNotEvaluatedDateOfFullRecovery',
          nodeId: NodeIds.healthGrowthLumpNotEvaluatedDateOfFullRecovery,
        },
        {
          path: 'growthLumpNotEvaluatedLimitationDetails',
          nodeId: NodeIds.healthGrowthLumpNotEvaluatedLimitationDetails,
        },
        {
          path: 'growthLumpNotEvaluatedCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthGrowthLumpNotEvaluatedCurrentlyExperienceSymptoms,
        },
        {
          path: 'growthLumpNotEvaluatedDateOfLastSymptom',
          nodeId: NodeIds.healthGrowthLumpNotEvaluatedDateOfLastSymptom,
        },
        {
          path: 'growthLumpNotEvaluatedDateOfLastOccurrence',
          nodeId: NodeIds.healthGrowthLumpNotEvaluatedDateOfLastOccurrence,
        },
        {
          path: 'growthLumpNotEvaluatedNumberOfOccurrences',
          nodeId: NodeIds.healthGrowthLumpNotEvaluatedNumberOfOccurrences,
        },
        {
          path: 'growthLumpNotEvaluatedCausePhysicalLimitations',
          nodeId: NodeIds.healthGrowthLumpNotEvaluatedCausePhysicalLimitations,
        },
        {
          path: 'growthLumpNotEvaluatedLimitationDetails2',
          nodeId: 'health-growth-lump-not-evaluated-limitation-details-2',
        },
        { path: 'growthLumpNotEvaluatedMedProv', nodeId: NodeIds.healthGrowthLumpNotEvaluatedMedProv },
        { path: 'growthLumpNotEvaluatedMedProvName', nodeId: NodeIds.healthGrowthLumpNotEvaluatedMedProvName },
        { path: 'growthLumpNotEvaluatedMedProvAddress', nodeId: NodeIds.healthGrowthLumpNotEvaluatedMedProvAddress },
        { path: 'growthLumpNotEvaluatedMedProvPhone', nodeId: NodeIds.healthGrowthLumpNotEvaluatedMedProvPhone },
        { path: 'growthLumpNotEvaluatedHospitalVisit', nodeId: NodeIds.healthGrowthLumpNotEvaluatedHospitalVisit },
        {
          path: 'growthLumpNotEvaluatedHospitalVisitDate',
          nodeId: NodeIds.healthGrowthLumpNotEvaluatedHospitalVisitDate,
        },
        { path: 'growthLumpNotEvaluatedHospitalName', nodeId: NodeIds.healthGrowthLumpNotEvaluatedHospitalName },
        { path: 'growthLumpNotEvaluatedHospitalAddress', nodeId: NodeIds.healthGrowthLumpNotEvaluatedHospitalAddress },
      ],
    },
    {
      path: 'malignantMelanoma',
      children: [
        { path: 'malignantMelanomaDiagnosisDate', nodeId: NodeIds.healthMalignantMelanomaDiagnosisDate },
        { path: 'malignantMelanomaMedications', nodeId: NodeIds.healthMalignantMelanomaMedications },
        { path: 'malignantMelanomaAdditionalTreatment', nodeId: NodeIds.healthMalignantMelanomaAdditionalTreatment },
        { path: 'malignantMelanomaStatus', nodeId: NodeIds.healthMalignantMelanomaStatus },
        { path: 'malignantMelanomaDateOfFullRecovery', nodeId: NodeIds.healthMalignantMelanomaDateOfFullRecovery },
        { path: 'malignantMelanomaLimitationDetails', nodeId: NodeIds.healthMalignantMelanomaLimitationDetails },
        {
          path: 'malignantMelanomaCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthMalignantMelanomaCurrentlyExperienceSymptoms,
        },
        { path: 'malignantMelanomaDateOfLastSymptom', nodeId: NodeIds.healthMalignantMelanomaDateOfLastSymptom },
        { path: 'malignantMelanomaDateOfLastOccurrence', nodeId: NodeIds.healthMalignantMelanomaDateOfLastOccurrence },
        { path: 'malignantMelanomaNumberOfOccurrences', nodeId: NodeIds.healthMalignantMelanomaNumberOfOccurrences },
        {
          path: 'malignantMelanomaCausePhysicalLimitations',
          nodeId: NodeIds.healthMalignantMelanomaCausePhysicalLimitations,
        },
        { path: 'malignantMelanomaLimitationDetails2', nodeId: 'health-malignant-melanoma-limitation-details-2' },
        { path: 'malignantMelanomaMedProv', nodeId: NodeIds.healthMalignantMelanomaMedProv },
        { path: 'malignantMelanomaMedProvName', nodeId: NodeIds.healthMalignantMelanomaMedProvName },
        { path: 'malignantMelanomaMedProvAddress', nodeId: NodeIds.healthMalignantMelanomaMedProvAddress },
        { path: 'malignantMelanomaMedProvPhone', nodeId: NodeIds.healthMalignantMelanomaMedProvPhone },
        { path: 'malignantMelanomaHospitalVisit', nodeId: NodeIds.healthMalignantMelanomaHospitalVisit },
        { path: 'malignantMelanomaHospitalVisitDate', nodeId: NodeIds.healthMalignantMelanomaHospitalVisitDate },
        { path: 'malignantMelanomaHospitalName', nodeId: NodeIds.healthMalignantMelanomaHospitalName },
        { path: 'malignantMelanomaHospitalAddress', nodeId: NodeIds.healthMalignantMelanomaHospitalAddress },
      ],
    },
    {
      path: 'dysplasticNevi',
      children: [
        { path: 'dysplasticNeviDiagnosisDate', nodeId: NodeIds.healthDysplasticNeviDiagnosisDate },
        { path: 'dysplasticNeviMedications', nodeId: NodeIds.healthDysplasticNeviMedications },
        { path: 'dysplasticNeviAdditionalTreatment', nodeId: NodeIds.healthDysplasticNeviAdditionalTreatment },
        { path: 'dysplasticNeviStatus', nodeId: NodeIds.healthDysplasticNeviStatus },
        { path: 'dysplasticNeviDateOfFullRecovery', nodeId: NodeIds.healthDysplasticNeviDateOfFullRecovery },
        { path: 'dysplasticNeviLimitationDetails', nodeId: NodeIds.healthDysplasticNeviLimitationDetails },
        {
          path: 'dysplasticNeviCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthDysplasticNeviCurrentlyExperienceSymptoms,
        },
        { path: 'dysplasticNeviDateOfLastSymptom', nodeId: NodeIds.healthDysplasticNeviDateOfLastSymptom },
        { path: 'dysplasticNeviDateOfLastOccurrence', nodeId: NodeIds.healthDysplasticNeviDateOfLastOccurrence },
        { path: 'dysplasticNeviNumberOfOccurrences', nodeId: NodeIds.healthDysplasticNeviNumberOfOccurrences },
        {
          path: 'dysplasticNeviCausePhysicalLimitations',
          nodeId: NodeIds.healthDysplasticNeviCausePhysicalLimitations,
        },
        { path: 'dysplasticNeviLimitationDetails2', nodeId: 'health-dysplastic-nevi-limitation-details-2' },
        { path: 'dysplasticNeviMedProv', nodeId: NodeIds.healthDysplasticNeviMedProv },
        { path: 'dysplasticNeviMedProvName', nodeId: NodeIds.healthDysplasticNeviMedProvName },
        { path: 'dysplasticNeviMedProvAddress', nodeId: NodeIds.healthDysplasticNeviMedProvAddress },
        { path: 'dysplasticNeviMedProvPhone', nodeId: NodeIds.healthDysplasticNeviMedProvPhone },
        { path: 'dysplasticNeviHospitalVisit', nodeId: NodeIds.healthDysplasticNeviHospitalVisit },
        { path: 'dysplasticNeviHospitalVisitDate', nodeId: NodeIds.healthDysplasticNeviHospitalVisitDate },
        { path: 'dysplasticNeviHospitalName', nodeId: NodeIds.healthDysplasticNeviHospitalName },
        { path: 'dysplasticNeviHospitalAddress', nodeId: NodeIds.healthDysplasticNeviHospitalAddress },
      ],
    },
    {
      path: 'abnormalMoles',
      children: [
        { path: 'abnormalMolesLesionsDiagnosisDate', nodeId: NodeIds.healthAbnormalMolesLesionsDiagnosisDate },
        { path: 'abnormalMolesLesionsMedications', nodeId: NodeIds.healthAbnormalMolesLesionsMedications },
        {
          path: 'abnormalMolesLesionsAdditionalTreatment',
          nodeId: NodeIds.healthAbnormalMolesLesionsAdditionalTreatment,
        },
        { path: 'abnormalMolesLesionsStatus', nodeId: NodeIds.healthAbnormalMolesLesionsStatus },
        {
          path: 'abnormalMolesLesionsDateOfFullRecovery',
          nodeId: NodeIds.healthAbnormalMolesLesionsDateOfFullRecovery,
        },
        { path: 'abnormalMolesLesionsLimitationDetails', nodeId: NodeIds.healthAbnormalMolesLesionsLimitationDetails },
        {
          path: 'abnormalMolesLesionsCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthAbnormalMolesLesionsCurrentlyExperienceSymptoms,
        },
        { path: 'abnormalMolesLesionsDateOfLastSymptom', nodeId: NodeIds.healthAbnormalMolesLesionsDateOfLastSymptom },
        {
          path: 'abnormalMolesLesionsDateOfLastOccurrence',
          nodeId: NodeIds.healthAbnormalMolesLesionsDateOfLastOccurrence,
        },
        {
          path: 'abnormalMolesLesionsNumberOfOccurrences',
          nodeId: NodeIds.healthAbnormalMolesLesionsNumberOfOccurrences,
        },
        {
          path: 'abnormalMolesLesionsCausePhysicalLimitations',
          nodeId: NodeIds.healthAbnormalMolesLesionsCausePhysicalLimitations,
        },
        {
          path: 'abnormalMolesLesionsLimitationDetails2',
          nodeId: 'health-abnormal-moles-lesions-limitation-details-2',
        },
        { path: 'abnormalMolesLesionsMedProv', nodeId: NodeIds.healthAbnormalMolesLesionsMedProv },
        { path: 'abnormalMolesLesionsMedProvName', nodeId: NodeIds.healthAbnormalMolesLesionsMedProvName },
        { path: 'abnormalMolesLesionsMedProvAddress', nodeId: NodeIds.healthAbnormalMolesLesionsMedProvAddress },
        { path: 'abnormalMolesLesionsMedProvPhone', nodeId: NodeIds.healthAbnormalMolesLesionsMedProvPhone },
        { path: 'abnormalMolesLesionsHospitalVisit', nodeId: NodeIds.healthAbnormalMolesLesionsHospitalVisit },
        { path: 'abnormalMolesLesionsHospitalVisitDate', nodeId: NodeIds.healthAbnormalMolesLesionsHospitalVisitDate },
        { path: 'abnormalMolesLesionsHospitalName', nodeId: NodeIds.healthAbnormalMolesLesionsHospitalName },
        { path: 'abnormalMolesLesionsHospitalAddress', nodeId: NodeIds.healthAbnormalMolesLesionsHospitalAddress },
      ],
    },
    {
      path: 'basalSquamousCellCarcinoma',
      children: [
        {
          path: 'basalSquamousCellCarcinomaDiagnosisDate',
          nodeId: NodeIds.healthBasalSquamousCellCarcinomaDiagnosisDate,
        },
        { path: 'basalSquamousCellCarcinomaMedications', nodeId: NodeIds.healthBasalSquamousCellCarcinomaMedications },
        {
          path: 'basalSquamousCellCarcinomaAdditionalTreatment',
          nodeId: NodeIds.healthBasalSquamousCellCarcinomaAdditionalTreatment,
        },
        { path: 'basalSquamousCellCarcinomaStatus', nodeId: NodeIds.healthBasalSquamousCellCarcinomaStatus },
        {
          path: 'basalSquamousCellCarcinomaDateOfFullRecovery',
          nodeId: NodeIds.healthBasalSquamousCellCarcinomaDateOfFullRecovery,
        },
        {
          path: 'basalSquamousCellCarcinomaLimitationDetails',
          nodeId: NodeIds.healthBasalSquamousCellCarcinomaLimitationDetails,
        },
        {
          path: 'basalSquamousCellCarcinomaCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthBasalSquamousCellCarcinomaCurrentlyExperienceSymptoms,
        },
        {
          path: 'basalSquamousCellCarcinomaDateOfLastSymptom',
          nodeId: NodeIds.healthBasalSquamousCellCarcinomaDateOfLastSymptom,
        },
        {
          path: 'basalSquamousCellCarcinomaDateOfLastOccurrence',
          nodeId: NodeIds.healthBasalSquamousCellCarcinomaDateOfLastOccurrence,
        },
        {
          path: 'basalSquamousCellCarcinomaNumberOfOccurrences',
          nodeId: NodeIds.healthBasalSquamousCellCarcinomaNumberOfOccurrences,
        },
        {
          path: 'basalSquamousCellCarcinomaCausePhysicalLimitations',
          nodeId: NodeIds.healthBasalSquamousCellCarcinomaCausePhysicalLimitations,
        },
        {
          path: 'basalSquamousCellCarcinomaLimitationDetails2',
          nodeId: 'health-basal-squamous-cell-carcinoma-limitation-details-2',
        },
        { path: 'basalSquamousCellCarcinomaMedProv', nodeId: NodeIds.healthBasalSquamousCellCarcinomaMedProv },
        { path: 'basalSquamousCellCarcinomaMedProvName', nodeId: NodeIds.healthBasalSquamousCellCarcinomaMedProvName },
        {
          path: 'basalSquamousCellCarcinomaMedProvAddress',
          nodeId: NodeIds.healthBasalSquamousCellCarcinomaMedProvAddress,
        },
        {
          path: 'basalSquamousCellCarcinomaMedProvPhone',
          nodeId: NodeIds.healthBasalSquamousCellCarcinomaMedProvPhone,
        },
        {
          path: 'basalSquamousCellCarcinomaHospitalVisit',
          nodeId: NodeIds.healthBasalSquamousCellCarcinomaHospitalVisit,
        },
        {
          path: 'basalSquamousCellCarcinomaHospitalVisitDate',
          nodeId: NodeIds.healthBasalSquamousCellCarcinomaHospitalVisitDate,
        },
        {
          path: 'basalSquamousCellCarcinomaHospitalName',
          nodeId: NodeIds.healthBasalSquamousCellCarcinomaHospitalName,
        },
        {
          path: 'basalSquamousCellCarcinomaHospitalAddress',
          nodeId: NodeIds.healthBasalSquamousCellCarcinomaHospitalAddress,
        },
      ],
    },
    {
      path: 'polyps2Diagnosis',
      children: [
        { path: 'polyps2DiagnosisDate', nodeId: 'health-polyps-2-diagnosis-date' },
        { path: 'polyps2Medications', nodeId: 'health-polyps-2-medications' },
        { path: 'polyps2AdditionalTreatment', nodeId: 'health-polyps-2-additional-treatment' },
        { path: 'polyps2Status', nodeId: 'health-polyps-2-status' },
        { path: 'polyps2DateOfFullRecovery', nodeId: 'health-polyps-2-date-of-full-recovery' },
        { path: 'polyps2LimitationDetails', nodeId: 'health-polyps-2-limitation-details' },
        { path: 'polyps2CurrentlyExperienceSymptoms', nodeId: 'health-polyps-2-currently-experience-symptoms' },
        { path: 'polyps2DateOfLastSymptom', nodeId: 'health-polyps-2-date-of-last-symptom' },
        { path: 'polyps2DateOfLastOccurrence', nodeId: 'health-polyps-2-date-of-last-occurrence' },
        { path: 'polyps2NumberOfOccurrences', nodeId: 'health-polyps-2-number-of-occurrences' },
        { path: 'polyps2CausePhysicalLimitations', nodeId: 'health-polyps-2-cause-physical-limitations' },
        { path: 'polyps2LimitationDetails2', nodeId: 'health-polyps-2-limitation-details-2' },
        { path: 'polyps2MedProv', nodeId: 'health-polyps-2-med-prov' },
        { path: 'polyps2MedProvName', nodeId: 'health-polyps-2-med-prov-name' },
        { path: 'polyps2MedProvAddress', nodeId: 'health-polyps-2-med-prov-address' },
        { path: 'polyps2MedProvPhone', nodeId: 'health-polyps-2-med-prov-phone' },
        { path: 'polyps2HospitalVisit', nodeId: 'health-polyps-2-hospital-visit' },
        { path: 'polyps2HospitalVisitDate', nodeId: 'health-polyps-2-hospital-visit-date' },
        { path: 'polyps2HospitalName', nodeId: 'health-polyps-2-hospital-name' },
        { path: 'polyps2HospitalAddress', nodeId: 'health-polyps-2-hospital-address' },
      ],
    },
    {
      path: 'cancer',
      children: [
        { path: 'otherCancerType', nodeId: NodeIds.healthOtherCancerType },
        { path: 'otherCancerDiagnosisDate', nodeId: NodeIds.healthOtherCancerDiagnosisDate },
        { path: 'otherCancerStage', nodeId: NodeIds.healthOtherCancerStage },
        { path: 'otherCancerMedications', nodeId: NodeIds.healthOtherCancerMedications },
        { path: 'otherCancerTreatment', nodeId: NodeIds.healthOtherCancerTreatment },
        { path: 'otherCancerTreatmentDate', nodeId: NodeIds.healthOtherCancerTreatmentDate },
        { path: 'otherCancerAdditionalTreatment', nodeId: NodeIds.healthOtherCancerAdditionalTreatment },
        { path: 'otherCancerStatus', nodeId: NodeIds.healthOtherCancerStatus },
        { path: 'otherCancerFullRecoveryDate', nodeId: NodeIds.healthOtherCancerFullRecoveryDate },
        { path: 'otherCancerLimitationDetails', nodeId: NodeIds.healthOtherCancerLimitationDetails },
        { path: 'otherCancerCurrentSymptoms', nodeId: NodeIds.healthOtherCancerCurrentSymptoms },
        { path: 'otherCancerLastSymptomDate', nodeId: NodeIds.healthOtherCancerLastSymptomDate },
        { path: 'otherCancerLastOccurrenceDate', nodeId: NodeIds.healthOtherCancerLastOccurrenceDate },
        { path: 'otherCancerNumberOfOccurrences', nodeId: NodeIds.healthOtherCancerNumberOfOccurrences },
        { path: 'otherCancerPhysicalLimitations', nodeId: NodeIds.healthOtherCancerPhysicalLimitations },
        { path: 'otherCancerPhysicalLimitationsDetails', nodeId: NodeIds.healthOtherCancerPhysicalLimitationsDetails },
        { path: 'otherCancerMedProvider', nodeId: NodeIds.healthOtherCancerMedProvider },
        { path: 'otherCancerMedProviderName', nodeId: NodeIds.healthOtherCancerMedProviderName },
        { path: 'otherCancerMedProviderAddress', nodeId: NodeIds.healthOtherCancerMedProviderAddress },
        { path: 'otherCancerMedProviderPhone', nodeId: NodeIds.healthOtherCancerMedProviderPhone },
        { path: 'otherCancerHospitalVisit', nodeId: NodeIds.healthOtherCancerHospitalVisit },
        { path: 'otherCancerHospitalVisitDate', nodeId: NodeIds.healthOtherCancerHospitalVisitDate },
        { path: 'otherCancerHospitalName', nodeId: NodeIds.healthOtherCancerHospitalName },
        { path: 'otherCancerHospitalAddress', nodeId: NodeIds.healthOtherCancerHospitalAddress },
      ],
    },
    {
      path: 'tumor',
      children: [
        { path: 'tumorDiagnosisDate', nodeId: NodeIds.healthTumorDiagnosisDate },
        { path: 'tumorMedications', nodeId: NodeIds.healthTumorMedications },
        { path: 'tumorAdditionalTreatment', nodeId: NodeIds.healthTumorAdditionalTreatment },
        { path: 'tumorStatus', nodeId: NodeIds.healthTumorStatus },
        { path: 'tumorDateOfFullRecovery', nodeId: NodeIds.healthTumorDateOfFullRecovery },
        { path: 'tumorLimitationDetails', nodeId: NodeIds.healthTumorLimitationDetails },
        { path: 'tumorCurrentlyExperienceSymptoms', nodeId: NodeIds.healthTumorCurrentlyExperienceSymptoms },
        { path: 'tumorDateOfLastSymptom', nodeId: NodeIds.healthTumorDateOfLastSymptom },
        { path: 'tumorDateOfLastOccurrence', nodeId: NodeIds.healthTumorDateOfLastOccurrence },
        { path: 'tumorNumberOfOccurrences', nodeId: NodeIds.healthTumorNumberOfOccurrences },
        { path: 'tumorCausePhysicalLimitations', nodeId: NodeIds.healthTumorCausePhysicalLimitations },
        { path: 'tumorLimitationDetails2', nodeId: 'health-tumor-limitation-details-2' },
        { path: 'tumorMedProv', nodeId: NodeIds.healthTumorMedProv },
        { path: 'tumorMedProvName', nodeId: NodeIds.healthTumorMedProvName },
        { path: 'tumorMedProvAddress', nodeId: NodeIds.healthTumorMedProvAddress },
        { path: 'tumorMedProvPhone', nodeId: NodeIds.healthTumorMedProvPhone },
        { path: 'tumorHospitalVisit', nodeId: NodeIds.healthTumorHospitalVisit },
        { path: 'tumorHospitalVisitDate', nodeId: NodeIds.healthTumorHospitalVisitDate },
        { path: 'tumorHospitalName', nodeId: NodeIds.healthTumorHospitalName },
        { path: 'tumorHospitalAddress', nodeId: NodeIds.healthTumorHospitalAddress },
      ],
    },
    {
      path: 'circulatorySystemOtherDisorder',
      children: [
        {
          path: 'circulatorySystemOtherDisorderDiagnosisDate',
          nodeId: NodeIds.healthCirculatorySystemOtherDisorderDiagnosisDate,
        },
        {
          path: 'circulatorySystemOtherDisorderMedications',
          nodeId: NodeIds.healthCirculatorySystemOtherDisorderMedications,
        },
        {
          path: 'circulatorySystemOtherDisorderAdditionalTreatment',
          nodeId: NodeIds.healthCirculatorySystemOtherDisorderAdditionalTreatment,
        },
        { path: 'circulatorySystemOtherDisorderStatus', nodeId: NodeIds.healthCirculatorySystemOtherDisorderStatus },
        {
          path: 'circulatorySystemOtherDisorderDateOfFullRecovery',
          nodeId: NodeIds.healthCirculatorySystemOtherDisorderDateOfFullRecovery,
        },
        {
          path: 'circulatorySystemOtherDisorderLimitationDetails',
          nodeId: NodeIds.healthCirculatorySystemOtherDisorderLimitationDetails,
        },
        {
          path: 'circulatorySystemOtherDisorderCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthCirculatorySystemOtherDisorderCurrentlyExperienceSymptoms,
        },
        {
          path: 'circulatorySystemOtherDisorderDateOfLastSymptom',
          nodeId: NodeIds.healthCirculatorySystemOtherDisorderDateOfLastSymptom,
        },
        {
          path: 'circulatorySystemOtherDisorderDateOfLastOccurrence',
          nodeId: NodeIds.healthCirculatorySystemOtherDisorderDateOfLastOccurrence,
        },
        {
          path: 'circulatorySystemOtherDisorderNumberOfOccurrences',
          nodeId: NodeIds.healthCirculatorySystemOtherDisorderNumberOfOccurrences,
        },
        {
          path: 'circulatorySystemOtherDisorderCausePhysicalLimitations',
          nodeId: NodeIds.healthCirculatorySystemOtherDisorderCausePhysicalLimitations,
        },
        {
          path: 'circulatorySystemOtherDisorderLimitationDetails2',
          nodeId: 'health-circulatory-system-other-disorder-limitation-details-2',
        },
        { path: 'circulatorySystemOtherDisorderMedProv', nodeId: NodeIds.healthCirculatorySystemOtherDisorderMedProv },
        {
          path: 'circulatorySystemOtherDisorderMedProvName',
          nodeId: NodeIds.healthCirculatorySystemOtherDisorderMedProvName,
        },
        {
          path: 'circulatorySystemOtherDisorderMedProvAddress',
          nodeId: NodeIds.healthCirculatorySystemOtherDisorderMedProvAddress,
        },
        {
          path: 'circulatorySystemOtherDisorderMedProvPhone',
          nodeId: NodeIds.healthCirculatorySystemOtherDisorderMedProvPhone,
        },
        {
          path: 'circulatorySystemOtherDisorderHospitalVisit',
          nodeId: NodeIds.healthCirculatorySystemOtherDisorderHospitalVisit,
        },
        {
          path: 'circulatorySystemOtherDisorderHospitalVisitDate',
          nodeId: NodeIds.healthCirculatorySystemOtherDisorderHospitalVisitDate,
        },
        {
          path: 'circulatorySystemOtherDisorderHospitalName',
          nodeId: NodeIds.healthCirculatorySystemOtherDisorderHospitalName,
        },
        {
          path: 'circulatorySystemOtherDisorderHospitalAddress',
          nodeId: NodeIds.healthCirculatorySystemOtherDisorderHospitalAddress,
        },
      ],
    },
    {
      path: 'peripheralVascularDisease',
      children: [
        {
          path: 'peripheralVascularDiseaseDiagnosisDate',
          nodeId: NodeIds.healthPeripheralVascularDiseaseDiagnosisDate,
        },
        { path: 'peripheralVascularDiseaseMedications', nodeId: NodeIds.healthPeripheralVascularDiseaseMedications },
        {
          path: 'peripheralVascularDiseaseAdditionalTreatment',
          nodeId: NodeIds.healthPeripheralVascularDiseaseAdditionalTreatment,
        },
        { path: 'peripheralVascularDiseaseStatus', nodeId: NodeIds.healthPeripheralVascularDiseaseStatus },
        {
          path: 'peripheralVascularDiseaseDateOfFullRecovery',
          nodeId: NodeIds.healthPeripheralVascularDiseaseDateOfFullRecovery,
        },
        {
          path: 'peripheralVascularDiseaseLimitationDetails',
          nodeId: NodeIds.healthPeripheralVascularDiseaseLimitationDetails,
        },
        {
          path: 'peripheralVascularDiseaseCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthPeripheralVascularDiseaseCurrentlyExperienceSymptoms,
        },
        {
          path: 'peripheralVascularDiseaseDateOfLastSymptom',
          nodeId: NodeIds.healthPeripheralVascularDiseaseDateOfLastSymptom,
        },
        {
          path: 'peripheralVascularDiseaseDateOfLastOccurrence',
          nodeId: NodeIds.healthPeripheralVascularDiseaseDateOfLastOccurrence,
        },
        {
          path: 'peripheralVascularDiseaseNumberOfOccurrences',
          nodeId: NodeIds.healthPeripheralVascularDiseaseNumberOfOccurrences,
        },
        {
          path: 'peripheralVascularDiseaseCausePhysicalLimitations',
          nodeId: NodeIds.healthPeripheralVascularDiseaseCausePhysicalLimitations,
        },
        {
          path: 'peripheralVascularDiseaseLimitationDetails2',
          nodeId: 'health-peripheral-vascular-disease-limitation-details-2',
        },
        { path: 'peripheralVascularDiseaseMedProv', nodeId: NodeIds.healthPeripheralVascularDiseaseMedProv },
        { path: 'peripheralVascularDiseaseMedProvName', nodeId: NodeIds.healthPeripheralVascularDiseaseMedProvName },
        {
          path: 'peripheralVascularDiseaseMedProvAddress',
          nodeId: NodeIds.healthPeripheralVascularDiseaseMedProvAddress,
        },
        { path: 'peripheralVascularDiseaseMedProvPhone', nodeId: NodeIds.healthPeripheralVascularDiseaseMedProvPhone },
        {
          path: 'peripheralVascularDiseaseHospitalVisit',
          nodeId: NodeIds.healthPeripheralVascularDiseaseHospitalVisit,
        },
        {
          path: 'peripheralVascularDiseaseHospitalVisitDate',
          nodeId: NodeIds.healthPeripheralVascularDiseaseHospitalVisitDate,
        },
        { path: 'peripheralVascularDiseaseHospitalName', nodeId: NodeIds.healthPeripheralVascularDiseaseHospitalName },
        {
          path: 'peripheralVascularDiseaseHospitalAddress',
          nodeId: NodeIds.healthPeripheralVascularDiseaseHospitalAddress,
        },
      ],
    },
    {
      path: 'abnormalEkg',
      children: [
        { path: 'abnormalEkgDiagnosisDate', nodeId: NodeIds.healthAbnormalEkgDiagnosisDate },
        { path: 'abnormalEkgMedications', nodeId: NodeIds.healthAbnormalEkgMedications },
        { path: 'abnormalEkgAdditionalTreatment', nodeId: NodeIds.healthAbnormalEkgAdditionalTreatment },
        { path: 'abnormalEkgStatus', nodeId: NodeIds.healthAbnormalEkgStatus },
        { path: 'abnormalEkgDateOfFullRecovery', nodeId: NodeIds.healthAbnormalEkgDateOfFullRecovery },
        { path: 'abnormalEkgLimitationDetails', nodeId: NodeIds.healthAbnormalEkgLimitationDetails },
        {
          path: 'abnormalEkgCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthAbnormalEkgCurrentlyExperienceSymptoms,
        },
        { path: 'abnormalEkgDateOfLastSymptom', nodeId: NodeIds.healthAbnormalEkgDateOfLastSymptom },
        { path: 'abnormalEkgDateOfLastOccurrence', nodeId: NodeIds.healthAbnormalEkgDateOfLastOccurrence },
        { path: 'abnormalEkgNumberOfOccurrences', nodeId: NodeIds.healthAbnormalEkgNumberOfOccurrences },
        { path: 'abnormalEkgCausePhysicalLimitations', nodeId: NodeIds.healthAbnormalEkgCausePhysicalLimitations },
        { path: 'abnormalEkgLimitationDetails2', nodeId: 'health-abnormal-ekg-limitation-details-2' },
        { path: 'abnormalEkgMedProv', nodeId: NodeIds.healthAbnormalEkgMedProv },
        { path: 'abnormalEkgMedProvName', nodeId: NodeIds.healthAbnormalEkgMedProvName },
        { path: 'abnormalEkgMedProvAddress', nodeId: NodeIds.healthAbnormalEkgMedProvAddress },
        { path: 'abnormalEkgMedProvPhone', nodeId: NodeIds.healthAbnormalEkgMedProvPhone },
        { path: 'abnormalEkgHospitalVisit', nodeId: NodeIds.healthAbnormalEkgHospitalVisit },
        { path: 'abnormalEkgHospitalVisitDate', nodeId: NodeIds.healthAbnormalEkgHospitalVisitDate },
        { path: 'abnormalEkgHospitalName', nodeId: NodeIds.healthAbnormalEkgHospitalName },
        { path: 'abnormalEkgHospitalAddress', nodeId: NodeIds.healthAbnormalEkgHospitalAddress },
      ],
    },
    {
      path: 'coronaryArteryDisease',
      children: [
        { path: 'coronaryArteryDiseaseDiagnosisDate', nodeId: NodeIds.healthCoronaryArteryDiseaseDiagnosisDate },
        { path: 'coronaryArteryDiseaseMedications', nodeId: NodeIds.healthCoronaryArteryDiseaseMedications },
        {
          path: 'coronaryArteryDiseaseAdditionalTreatment',
          nodeId: NodeIds.healthCoronaryArteryDiseaseAdditionalTreatment,
        },
        { path: 'coronaryArteryDiseaseStatus', nodeId: NodeIds.healthCoronaryArteryDiseaseStatus },
        {
          path: 'coronaryArteryDiseaseDateOfFullRecovery',
          nodeId: NodeIds.healthCoronaryArteryDiseaseDateOfFullRecovery,
        },
        {
          path: 'coronaryArteryDiseaseLimitationDetails',
          nodeId: NodeIds.healthCoronaryArteryDiseaseLimitationDetails,
        },
        {
          path: 'coronaryArteryDiseaseCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthCoronaryArteryDiseaseCurrentlyExperienceSymptoms,
        },
        {
          path: 'coronaryArteryDiseaseDateOfLastSymptom',
          nodeId: NodeIds.healthCoronaryArteryDiseaseDateOfLastSymptom,
        },
        {
          path: 'coronaryArteryDiseaseDateOfLastOccurrence',
          nodeId: NodeIds.healthCoronaryArteryDiseaseDateOfLastOccurrence,
        },
        {
          path: 'coronaryArteryDiseaseNumberOfOccurrences',
          nodeId: NodeIds.healthCoronaryArteryDiseaseNumberOfOccurrences,
        },
        {
          path: 'coronaryArteryDiseaseCausePhysicalLimitations',
          nodeId: NodeIds.healthCoronaryArteryDiseaseCausePhysicalLimitations,
        },
        {
          path: 'coronaryArteryDiseaseLimitationDetails2',
          nodeId: 'health-coronary-artery-disease-limitation-details-2',
        },
        { path: 'coronaryArteryDiseaseMedProv', nodeId: NodeIds.healthCoronaryArteryDiseaseMedProv },
        { path: 'coronaryArteryDiseaseMedProvName', nodeId: NodeIds.healthCoronaryArteryDiseaseMedProvName },
        { path: 'coronaryArteryDiseaseMedProvAddress', nodeId: NodeIds.healthCoronaryArteryDiseaseMedProvAddress },
        { path: 'coronaryArteryDiseaseMedProvPhone', nodeId: NodeIds.healthCoronaryArteryDiseaseMedProvPhone },
        { path: 'coronaryArteryDiseaseHospitalVisit', nodeId: NodeIds.healthCoronaryArteryDiseaseHospitalVisit },
        {
          path: 'coronaryArteryDiseaseHospitalVisitDate',
          nodeId: NodeIds.healthCoronaryArteryDiseaseHospitalVisitDate,
        },
        { path: 'coronaryArteryDiseaseHospitalName', nodeId: NodeIds.healthCoronaryArteryDiseaseHospitalName },
        { path: 'coronaryArteryDiseaseHospitalAddress', nodeId: NodeIds.healthCoronaryArteryDiseaseHospitalAddress },
      ],
    },
    {
      path: 'strokeTia',
      children: [
        { path: 'strokeTiaEpisodes', nodeId: NodeIds.healthStrokeTiaEpisodes },
        { path: 'strokeTiaDateOne', nodeId: NodeIds.healthStrokeTiaDateOne },
        { path: 'strokeTiaDateTwo', nodeId: NodeIds.healthStrokeTiaDateTwo },
        { path: 'strokeTiaDateThree', nodeId: NodeIds.healthStrokeTiaDateThree },
        { path: 'strokeTiaDateFour', nodeId: NodeIds.healthStrokeTiaDateFour },
        { path: 'strokeTiaDateFive', nodeId: NodeIds.healthStrokeTiaDateFive },
        { path: 'strokeTiaDateSix', nodeId: NodeIds.healthStrokeTiaDateSix },
        { path: 'strokeTiaDateSeven', nodeId: NodeIds.healthStrokeTiaDateSeven },
        { path: 'strokeTiaDateEight', nodeId: NodeIds.healthStrokeTiaDateEight },
        { path: 'strokeTiaDateNine', nodeId: NodeIds.healthStrokeTiaDateNine },
        { path: 'strokeTiaDateTen', nodeId: NodeIds.healthStrokeTiaDateTen },
        { path: 'strokeTiaDiagnosisDate', nodeId: NodeIds.healthStrokeTiaDiagnosisDate },
        { path: 'strokeTiaMedications', nodeId: NodeIds.healthStrokeTiaMedications },
        { path: 'strokeTiaAdditionalTreatment', nodeId: NodeIds.healthStrokeTiaAdditionalTreatment },
        { path: 'strokeTiaStatus', nodeId: NodeIds.healthStrokeTiaStatus },
        { path: 'strokeTiaDateOfFullRecovery', nodeId: NodeIds.healthStrokeTiaDateOfFullRecovery },
        { path: 'strokeTiaLimitationDetails', nodeId: NodeIds.healthStrokeTiaLimitationDetails },
        { path: 'strokeTiaCurrentlyExperienceSymptoms', nodeId: NodeIds.healthStrokeTiaCurrentlyExperienceSymptoms },
        { path: 'strokeTiaDateOfLastSymptom', nodeId: NodeIds.healthStrokeTiaDateOfLastSymptom },
        { path: 'strokeTiaDateOfLastOccurrence', nodeId: NodeIds.healthStrokeTiaDateOfLastOccurrence },
        { path: 'strokeTiaNumberOfOccurrences', nodeId: NodeIds.healthStrokeTiaNumberOfOccurrences },
        { path: 'strokeTiaCausePhysicalLimitations', nodeId: NodeIds.healthStrokeTiaCausePhysicalLimitations },
        { path: 'strokeTiaLimitationDetails2', nodeId: 'health-stroke-tia-limitation-details-2' },
        { path: 'strokeTiaMedProv', nodeId: NodeIds.healthStrokeTiaMedProv },
        { path: 'strokeTiaMedProvName', nodeId: NodeIds.healthStrokeTiaMedProvName },
        { path: 'strokeTiaMedProvAddress', nodeId: NodeIds.healthStrokeTiaMedProvAddress },
        { path: 'strokeTiaMedProvPhone', nodeId: NodeIds.healthStrokeTiaMedProvPhone },
        { path: 'strokeTiaHospitalVisit', nodeId: NodeIds.healthStrokeTiaHospitalVisit },
        { path: 'strokeTiaHospitalVisitDate', nodeId: NodeIds.healthStrokeTiaHospitalVisitDate },
        { path: 'strokeTiaHospitalName', nodeId: NodeIds.healthStrokeTiaHospitalName },
        { path: 'strokeTiaHospitalAddress', nodeId: NodeIds.healthStrokeTiaHospitalAddress },
      ],
    },
    {
      path: 'elevatedCholesterol',
      children: [
        { path: 'totalCholesterol', nodeId: NodeIds.healthCirculatorySystemTotalCholesterol },
        {
          path: 'totalCholesterolReducedInPastTwelveMonths',
          nodeId: NodeIds.healthTotalCholesterolReducedInPastTwelveMonths,
        },
        { path: 'currentCholesterol', nodeId: NodeIds.healthCirculatorySystemCurrentCholesterol },
        { path: 'cholesterolMedications', nodeId: NodeIds.healthCirculatorySystemCholesterolMedications },
        { path: 'cholesterolHospitalVisit', nodeId: NodeIds.healthCirculatorySystemCholesterolHospitalVisit },
        { path: 'cholesterolHospitalName', nodeId: NodeIds.healthCirculatorySystemCholesterolHospitalName },
        { path: 'cholesterolHospitalAddress', nodeId: NodeIds.healthCirculatorySystemCholesterolHospitalAddress },
        { path: 'cholesterolHospitalVisitDate', nodeId: NodeIds.healthCirculatorySystemCholesterolHospitalVisitDate },
      ],
    },
    {
      path: 'abnormalBloodPressure',
      children: [
        { path: 'bloodPressureDiagnosisDate', nodeId: NodeIds.healthAbnormalBloodPressureDiagnosisDate },
        { path: 'bloodPressureLastSystolicReading', nodeId: NodeIds.healthBloodPressureLastSystolicReading },
        { path: 'bloodPressureLastDiastolicReading', nodeId: NodeIds.healthBloodPressureLastDiastolicReading },
        {
          path: 'bloodPressureAbnormalReadingLast03Years',
          nodeId: 'health-blood-pressure-abnormal-reading-last-03-years',
        },
        { path: 'bloodPressureMedicationCount', nodeId: NodeIds.healthBloodPressureMedicationCount },
        { path: 'bloodPressureMedicationList', nodeId: NodeIds.healthBloodPressureMedicationList },
        { path: 'bloodPressureControlled', nodeId: NodeIds.healthBloodPressureControlled },
        { path: 'bloodPressureHospitalVisit', nodeId: NodeIds.healthBloodPressureHospitalVisit },
        { path: 'bloodPressureHospitalName ', nodeId: NodeIds.healthBloodPressureHospitalName },
        { path: 'bloodPressureHospitalAddress', nodeId: NodeIds.healthBloodPressureHospitalAddress },
        { path: 'bloodPressureHospitalizationDate', nodeId: NodeIds.healthBloodPressureHospitalizationDate },
      ],
    },
    {
      path: 'menstruationDisorder',
      children: [
        { path: 'menstruationDisorderDiagnosisDate', nodeId: NodeIds.healthMenstruationDisorderDiagnosisDate },
        { path: 'menstruationDisorderMedications', nodeId: NodeIds.healthMenstruationDisorderMedications },
        {
          path: 'menstruationDisorderAdditionalTreatment',
          nodeId: NodeIds.healthMenstruationDisorderAdditionalTreatment,
        },
        { path: 'menstruationDisorderStatus', nodeId: NodeIds.healthMenstruationDisorderStatus },
        {
          path: 'menstruationDisorderDateOfFullRecovery',
          nodeId: NodeIds.healthMenstruationDisorderDateOfFullRecovery,
        },
        { path: 'menstruationDisorderLimitationDetails', nodeId: NodeIds.healthMenstruationDisorderLimitationDetails },
        {
          path: 'menstruationDisorderCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthMenstruationDisorderCurrentlyExperienceSymptoms,
        },
        { path: 'menstruationDisorderDateOfLastSymptom', nodeId: NodeIds.healthMenstruationDisorderDateOfLastSymptom },
        {
          path: 'menstruationDisorderDateOfLastOccurrence',
          nodeId: NodeIds.healthMenstruationDisorderDateOfLastOccurrence,
        },
        {
          path: 'menstruationDisorderNumberOfOccurrences',
          nodeId: NodeIds.healthMenstruationDisorderNumberOfOccurrences,
        },
        {
          path: 'menstruationDisorderCausePhysicalLimitations',
          nodeId: NodeIds.healthMenstruationDisorderCausePhysicalLimitations,
        },
        { path: 'menstruationDisorderLimitationDetails2', nodeId: 'health-menstruation-disorder-limitation-details-2' },
        { path: 'menstruationDisorderMedProv', nodeId: NodeIds.healthMenstruationDisorderMedProv },
        { path: 'menstruationDisorderMedProvName', nodeId: NodeIds.healthMenstruationDisorderMedProvName },
        { path: 'menstruationDisorderMedProvAddress', nodeId: NodeIds.healthMenstruationDisorderMedProvAddress },
        { path: 'menstruationDisorderMedProvPhone', nodeId: NodeIds.healthMenstruationDisorderMedProvPhone },
        { path: 'menstruationDisorderHospitalVisit', nodeId: NodeIds.healthMenstruationDisorderHospitalVisit },
        { path: 'menstruationDisorderHospitalVisitDate', nodeId: NodeIds.healthMenstruationDisorderHospitalVisitDate },
        { path: 'menstruationDisorderHospitalName', nodeId: NodeIds.healthMenstruationDisorderHospitalName },
        { path: 'menstruationDisorderHospitalAddress', nodeId: NodeIds.healthMenstruationDisorderHospitalAddress },
      ],
    },
    {
      path: 'breastDisorder',
      children: [
        { path: 'breastDisorderDiagnosisDate', nodeId: NodeIds.healthBreastDisorderDiagnosisDate },
        { path: 'breastDisorderMedications', nodeId: NodeIds.healthBreastDisorderMedications },
        { path: 'breastDisorderAdditionalTreatment', nodeId: NodeIds.healthBreastDisorderAdditionalTreatment },
        { path: 'breastDisorderStatus', nodeId: NodeIds.healthBreastDisorderStatus },
        { path: 'breastDisorderDateOfFullRecovery', nodeId: NodeIds.healthBreastDisorderDateOfFullRecovery },
        { path: 'breastDisorderLimitationDetails', nodeId: NodeIds.healthBreastDisorderLimitationDetails },
        {
          path: 'breastDisorderCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthBreastDisorderCurrentlyExperienceSymptoms,
        },
        { path: 'breastDisorderDateOfLastSymptom', nodeId: NodeIds.healthBreastDisorderDateOfLastSymptom },
        { path: 'breastDisorderDateOfLastOccurrence', nodeId: NodeIds.healthBreastDisorderDateOfLastOccurrence },
        { path: 'breastDisorderNumberOfOccurrences', nodeId: NodeIds.healthBreastDisorderNumberOfOccurrences },
        {
          path: 'breastDisorderCausePhysicalLimitations',
          nodeId: NodeIds.healthBreastDisorderCausePhysicalLimitations,
        },
        { path: 'breastDisorderLimitationDetails2', nodeId: 'health-breast-disorder-limitation-details-2' },
        { path: 'breastDisorderMedProv', nodeId: NodeIds.healthBreastDisorderMedProv },
        { path: 'breastDisorderMedProvName', nodeId: NodeIds.healthBreastDisorderMedProvName },
        { path: 'breastDisorderMedProvAddress', nodeId: NodeIds.healthBreastDisorderMedProvAddress },
        { path: 'breastDisorderMedProvPhone', nodeId: NodeIds.healthBreastDisorderMedProvPhone },
        { path: 'breastDisorderHospitalVisit', nodeId: NodeIds.healthBreastDisorderHospitalVisit },
        { path: 'breastDisorderHospitalVisitDate', nodeId: NodeIds.healthBreastDisorderHospitalVisitDate },
        { path: 'breastDisorderHospitalName', nodeId: NodeIds.healthBreastDisorderHospitalName },
        { path: 'breastDisorderHospitalAddress', nodeId: NodeIds.healthBreastDisorderHospitalAddress },
      ],
    },
    {
      path: 'prostateDisorder',
      children: [
        { path: 'prostateDisorderDiagnosisDate', nodeId: NodeIds.healthProstateDisorderDiagnosisDate },
        { path: 'prostateDisorderMedications', nodeId: NodeIds.healthProstateDisorderMedications },
        { path: 'prostateDisorderAdditionalTreatment', nodeId: NodeIds.healthProstateDisorderAdditionalTreatment },
        { path: 'prostateDisorderStatus', nodeId: NodeIds.healthProstateDisorderStatus },
        { path: 'prostateDisorderDateOfFullRecovery', nodeId: NodeIds.healthProstateDisorderDateOfFullRecovery },
        { path: 'prostateDisorderLimitationDetails', nodeId: NodeIds.healthProstateDisorderLimitationDetails },
        {
          path: 'prostateDisorderCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthProstateDisorderCurrentlyExperienceSymptoms,
        },
        { path: 'prostateDisorderDateOfLastSymptom', nodeId: NodeIds.healthProstateDisorderDateOfLastSymptom },
        { path: 'prostateDisorderDateOfLastOccurrence', nodeId: NodeIds.healthProstateDisorderDateOfLastOccurrence },
        { path: 'prostateDisorderNumberOfOccurrences', nodeId: NodeIds.healthProstateDisorderNumberOfOccurrences },
        {
          path: 'prostateDisorderCausePhysicalLimitations',
          nodeId: NodeIds.healthProstateDisorderCausePhysicalLimitations,
        },
        { path: 'prostateDisorderLimitationDetails2', nodeId: 'health-prostate-disorder-limitation-details-2' },
        { path: 'prostateDisorderMedProvider', nodeId: NodeIds.healthProstateDisorderMedProvider },
        { path: 'prostateDisorderMedProviderName', nodeId: NodeIds.healthProstateDisorderMedProviderName },
        { path: 'prostateDisorderMedProviderAddress', nodeId: NodeIds.healthProstateDisorderMedProviderAddress },
        { path: 'prostateDisorderMedProviderPhone', nodeId: NodeIds.healthProstateDisorderMedProviderPhone },
        { path: 'prostateDisorderHospitalVisit', nodeId: NodeIds.healthProstateDisorderHospitalVisit },
        { path: 'prostateDisorderHospitalVisitDate', nodeId: NodeIds.healthProstateDisorderHospitalVisitDate },
        { path: 'prostateDisorderHospitalName', nodeId: NodeIds.healthProstateDisorderHospitalName },
        { path: 'prostateDisorderHospitalAddress', nodeId: NodeIds.healthProstateDisorderHospitalAddress },
      ],
    },
    {
      path: 'reproductiveOrgansDisorder',
      children: [
        { path: 'hasReproductiveOrgansDisorder', nodeId: NodeIds.healthHasReproductiveOrgansDisorder },
        { path: 'reproductiveOrgansDisorderChoices', nodeId: NodeIds.healthReproductiveOrgansDisorderChoices },
        {
          path: 'reproductiveOrgansDisorderDiagnosisDate',
          nodeId: NodeIds.healthReproductiveOrgansDisorderDiagnosisDate,
        },
        { path: 'reproductiveOrgansDisorderMedications', nodeId: NodeIds.healthReproductiveOrgansDisorderMedications },
        {
          path: 'reproductiveOrgansDisorderAdditionalTreatment',
          nodeId: NodeIds.healthReproductiveOrgansDisorderAdditionalTreatment,
        },
        { path: 'reproductiveOrgansDisorderStatus', nodeId: NodeIds.healthReproductiveOrgansDisorderStatus },
        {
          path: 'reproductiveOrgansDisorderDateOfFullRecovery',
          nodeId: NodeIds.healthReproductiveOrgansDisorderDateOfFullRecovery,
        },
        {
          path: 'reproductiveOrgansDisorderLimitationDetails',
          nodeId: NodeIds.healthReproductiveOrgansDisorderLimitationDetails,
        },
        {
          path: 'reproductiveOrgansDisorderCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthReproductiveOrgansDisorderCurrentlyExperienceSymptoms,
        },
        {
          path: 'reproductiveOrgansDisorderDateOfLastSymptom',
          nodeId: NodeIds.healthReproductiveOrgansDisorderDateOfLastSymptom,
        },
        {
          path: 'reproductiveOrgansDisorderDateOfLastOccurrence',
          nodeId: NodeIds.healthReproductiveOrgansDisorderDateOfLastOccurrence,
        },
        {
          path: 'reproductiveOrgansDisorderNumberOfOccurrences',
          nodeId: NodeIds.healthReproductiveOrgansDisorderNumberOfOccurrences,
        },
        {
          path: 'reproductiveOrgansDisorderCausePhysicalLimitations',
          nodeId: NodeIds.healthReproductiveOrgansDisorderCausePhysicalLimitations,
        },
        {
          path: 'reproductiveOrgansDisorderLimitationDetails2',
          nodeId: 'health-reproductive-organs-disorder-limitation-details-2',
        },
        { path: 'reproductiveOrgansDisorderMedProv', nodeId: NodeIds.healthReproductiveOrgansDisorderMedProv },
        { path: 'reproductiveOrgansDisorderMedProvName', nodeId: NodeIds.healthReproductiveOrgansDisorderMedProvName },
        {
          path: 'reproductiveOrgansDisorderMedProvAddress',
          nodeId: NodeIds.healthReproductiveOrgansDisorderMedProvAddress,
        },
        {
          path: 'reproductiveOrgansDisorderMedProvPhone',
          nodeId: NodeIds.healthReproductiveOrgansDisorderMedProvPhone,
        },
        {
          path: 'reproductiveOrgansDisorderHospitalVisit',
          nodeId: NodeIds.healthReproductiveOrgansDisorderHospitalVisit,
        },
        {
          path: 'reproductiveOrgansDisorderHospitalVisitDate',
          nodeId: NodeIds.healthReproductiveOrgansDisorderHospitalVisitDate,
        },
        {
          path: 'reproductiveOrgansDisorderHospitalName',
          nodeId: NodeIds.healthReproductiveOrgansDisorderHospitalName,
        },
        {
          path: 'reproductiveOrgansDisorderHospitalAddress',
          nodeId: NodeIds.healthReproductiveOrgansDisorderHospitalAddress,
        },
      ],
    },
    {
      path: 'diverticulitis',
      children: [
        { path: 'diverticulitisDiagnosisDate', nodeId: NodeIds.healthDiverticulitisDiagnosisDate },
        { path: 'diverticulitisMedications', nodeId: NodeIds.healthDiverticulitisMedications },
        { path: 'diverticulitisAdditionalTreatment', nodeId: NodeIds.healthDiverticulitisAdditionalTreatment },
        { path: 'diverticulitisStatus', nodeId: NodeIds.healthDiverticulitisStatus },
        { path: 'diverticulitisDateOfFullRecovery', nodeId: NodeIds.healthDiverticulitisDateOfFullRecovery },
        { path: 'diverticulitisLimitationDetails', nodeId: NodeIds.healthDiverticulitisLimitationDetails },
        {
          path: 'diverticulitisCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthDiverticulitisCurrentlyExperienceSymptoms,
        },
        { path: 'diverticulitisDateOfLastSymptom', nodeId: NodeIds.healthDiverticulitisDateOfLastSymptom },
        { path: 'diverticulitisDateOfLastOccurrence', nodeId: NodeIds.healthDiverticulitisDateOfLastOccurrence },
        { path: 'diverticulitisNumberOfOccurrences', nodeId: NodeIds.healthDiverticulitisNumberOfOccurrences },
        {
          path: 'diverticulitisCausePhysicalLimitations',
          nodeId: NodeIds.healthDiverticulitisCausePhysicalLimitations,
        },
        { path: 'diverticulitisLimitationDetails2', nodeId: 'health-diverticulitis-limitation-details-2' },
        { path: 'diverticulitisMedProv', nodeId: NodeIds.healthDiverticulitisMedProv },
        { path: 'diverticulitisMedProvName', nodeId: NodeIds.healthDiverticulitisMedProvName },
        { path: 'diverticulitisMedProvAddress', nodeId: NodeIds.healthDiverticulitisMedProvAddress },
        { path: 'diverticulitisMedProvPhone', nodeId: NodeIds.healthDiverticulitisMedProvPhone },
        { path: 'diverticulitisHospitalVisit', nodeId: NodeIds.healthDiverticulitisHospitalVisit },
        { path: 'diverticulitisHospitalVisitDate', nodeId: NodeIds.healthDiverticulitisHospitalVisitDate },
        { path: 'diverticulitisHospitalName', nodeId: NodeIds.healthDiverticulitisHospitalName },
        { path: 'diverticulitisHospitalAddress', nodeId: NodeIds.healthDiverticulitisHospitalAddress },
      ],
    },
    {
      path: 'celiacDisease',
      children: [
        { path: 'celiacDiseaseDiagnosisDate', nodeId: NodeIds.healthCeliacDiseaseDiagnosisDate },
        { path: 'celiacDiseaseMedications', nodeId: NodeIds.healthCeliacDiseaseMedications },
        { path: 'celiacDiseaseAdditionalTreatment', nodeId: NodeIds.healthCeliacDiseaseAdditionalTreatment },
        { path: 'celiacDiseaseStatus', nodeId: NodeIds.healthCeliacDiseaseStatus },
        { path: 'celiacDiseaseDateOfFullRecovery', nodeId: NodeIds.healthCeliacDiseaseDateOfFullRecovery },
        { path: 'celiacDiseaseLimitationDetails', nodeId: NodeIds.healthCeliacDiseaseLimitationDetails },
        {
          path: 'celiacDiseaseCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthCeliacDiseaseCurrentlyExperienceSymptoms,
        },
        { path: 'celiacDiseaseDateOfLastSymptom', nodeId: NodeIds.healthCeliacDiseaseDateOfLastSymptom },
        { path: 'celiacDiseaseDateOfLastOccurrence', nodeId: NodeIds.healthCeliacDiseaseDateOfLastOccurrence },
        { path: 'celiacDiseaseNumberOfOccurrences', nodeId: NodeIds.healthCeliacDiseaseNumberOfOccurrences },
        { path: 'celiacDiseaseCausePhysicalLimitations', nodeId: NodeIds.healthCeliacDiseaseCausePhysicalLimitations },
        { path: 'celiacDiseaseLimitationDetails2', nodeId: 'health-celiac-disease-limitation-details-2' },
        { path: 'celiacDiseaseMedProv', nodeId: NodeIds.healthCeliacDiseaseMedProv },
        { path: 'celiacDiseaseMedProvName', nodeId: NodeIds.healthCeliacDiseaseMedProvName },
        { path: 'celiacDiseaseMedProvAddress', nodeId: NodeIds.healthCeliacDiseaseMedProvAddress },
        { path: 'celiacDiseaseMedProvPhone', nodeId: NodeIds.healthCeliacDiseaseMedProvPhone },
        { path: 'celiacDiseaseHospitalVisit', nodeId: NodeIds.healthCeliacDiseaseHospitalVisit },
        { path: 'celiacDiseaseHospitalVisitDate', nodeId: NodeIds.healthCeliacDiseaseHospitalVisitDate },
        { path: 'celiacDiseaseHospitalName', nodeId: NodeIds.healthCeliacDiseaseHospitalName },
        { path: 'celiacDiseaseHospitalAddress', nodeId: NodeIds.healthCeliacDiseaseHospitalAddress },
      ],
    },
    {
      path: 'crohnDisease',
      children: [
        { path: 'crohnDiseaseDiagnosisDate', nodeId: NodeIds.healthCrohnDiseaseDiagnosisDate },
        { path: 'crohnDiseaseMedications', nodeId: NodeIds.healthCrohnDiseaseMedications },
        { path: 'crohnDiseaseAdditionalTreatment', nodeId: NodeIds.healthCrohnDiseaseAdditionalTreatment },
        { path: 'crohnDiseaseStatus', nodeId: NodeIds.healthCrohnDiseaseStatus },
        { path: 'crohnDiseaseDateOfFullRecovery', nodeId: NodeIds.healthCrohnDiseaseDateOfFullRecovery },
        { path: 'crohnDiseaseLimitationDetails', nodeId: NodeIds.healthCrohnDiseaseLimitationDetails },
        {
          path: 'crohnDiseaseCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthCrohnDiseaseCurrentlyExperienceSymptoms,
        },
        { path: 'crohnDiseaseDateOfLastSymptom', nodeId: NodeIds.healthCrohnDiseaseDateOfLastSymptom },
        { path: 'crohnDiseaseDateOfLastOccurrence', nodeId: NodeIds.healthCrohnDiseaseDateOfLastOccurrence },
        { path: 'crohnDiseaseNumberOfOccurrences', nodeId: NodeIds.healthCrohnDiseaseNumberOfOccurrences },
        { path: 'crohnDiseaseCausePhysicalLimitations', nodeId: NodeIds.healthCrohnDiseaseCausePhysicalLimitations },
        { path: 'crohnDiseaseLimitationDetails2', nodeId: 'health-crohn-disease-limitation-details-2' },
        { path: 'crohnDiseaseMedProv', nodeId: NodeIds.healthCrohnDiseaseMedProv },
        { path: 'crohnDiseaseMedProvName', nodeId: NodeIds.healthCrohnDiseaseMedProvName },
        { path: 'crohnDiseaseMedProvAddress', nodeId: NodeIds.healthCrohnDiseaseMedProvAddress },
        { path: 'crohnDiseaseMedProvPhone', nodeId: NodeIds.healthCrohnDiseaseMedProvPhone },
        { path: 'crohnDiseaseHospitalVisit', nodeId: NodeIds.healthCrohnDiseaseHospitalVisit },
        { path: 'crohnDiseaseHospitalVisitDate', nodeId: NodeIds.healthCrohnDiseaseHospitalVisitDate },
        { path: 'crohnDiseaseHospitalName', nodeId: NodeIds.healthCrohnDiseaseHospitalName },
        { path: 'crohnDiseaseHospitalAddress', nodeId: NodeIds.healthCrohnDiseaseHospitalAddress },
      ],
    },
    {
      path: 'colitis',
      children: [
        { path: 'colitisDiagnosisDate', nodeId: NodeIds.healthColitisDiagnosisDate },
        { path: 'colitisMedications', nodeId: NodeIds.healthColitisMedications },
        { path: 'colitisAdditionalTreatment', nodeId: NodeIds.healthColitisAdditionalTreatment },
        { path: 'colitisStatus', nodeId: NodeIds.healthColitisStatus },
        { path: 'colitisDateOfFullRecovery', nodeId: NodeIds.healthColitisDateOfFullRecovery },
        { path: 'colitisLimitationDetails', nodeId: NodeIds.healthColitisLimitationDetails },
        { path: 'colitisCurrentlyExperienceSymptoms', nodeId: NodeIds.healthColitisCurrentlyExperienceSymptoms },
        { path: 'colitisDateOfLastSymptom', nodeId: NodeIds.healthColitisDateOfLastSymptom },
        { path: 'colitisDateOfLastOccurrence', nodeId: NodeIds.healthColitisDateOfLastOccurrence },
        { path: 'colitisNumberOfOccurrences', nodeId: NodeIds.healthColitisNumberOfOccurrences },
        { path: 'colitisCausePhysicalLimitations', nodeId: NodeIds.healthColitisCausePhysicalLimitations },
        { path: 'colitisLimitationDetails2', nodeId: 'health-colitis-limitation-details-2' },
        { path: 'colitisMedProv', nodeId: NodeIds.healthColitisMedProv },
        { path: 'colitisMedProvName', nodeId: NodeIds.healthColitisMedProvName },
        { path: 'colitisMedProvAddress', nodeId: NodeIds.healthColitisMedProvAddress },
        { path: 'colitisMedProvPhone', nodeId: NodeIds.healthColitisMedProvPhone },
        { path: 'colitisHospitalVisit', nodeId: NodeIds.healthColitisHospitalVisit },
        { path: 'colitisHospitalVisitDate', nodeId: NodeIds.healthColitisHospitalVisitDate },
        { path: 'colitisHospitalName', nodeId: NodeIds.healthColitisHospitalName },
        { path: 'colitisHospitalAddress', nodeId: NodeIds.healthColitisHospitalAddress },
      ],
    },
    {
      path: 'ulcers',
      children: [
        { path: 'ulcersDiagnosisDate', nodeId: NodeIds.healthUlcersDiagnosisDate },
        { path: 'ulcersMedications', nodeId: NodeIds.healthUlcersMedications },
        { path: 'ulcersAdditionalTreatment', nodeId: NodeIds.healthUlcersAdditionalTreatment },
        { path: 'ulcersStatus', nodeId: NodeIds.healthUlcersStatus },
        { path: 'ulcersDateOfFullRecovery', nodeId: NodeIds.healthUlcersDateOfFullRecovery },
        { path: 'ulcersLimitationDetails', nodeId: NodeIds.healthUlcersLimitationDetails },
        { path: 'ulcersCurrentlyExperienceSymptoms', nodeId: NodeIds.healthUlcersCurrentlyExperienceSymptoms },
        { path: 'ulcersDateOfLastSymptom', nodeId: NodeIds.healthUlcersDateOfLastSymptom },
        { path: 'ulcersDateOfLastOccurrence', nodeId: NodeIds.healthUlcersDateOfLastOccurrence },
        { path: 'ulcersNumberOfOccurrences', nodeId: NodeIds.healthUlcersNumberOfOccurrences },
        { path: 'ulcersCausePhysicalLimitations', nodeId: NodeIds.healthUlcersCausePhysicalLimitations },
        { path: 'ulcersLimitationDetails2', nodeId: 'health-ulcers-limitation-details-2' },
        { path: 'ulcersMedProv', nodeId: NodeIds.healthUlcersMedProv },
        { path: 'ulcersMedProvName', nodeId: NodeIds.healthUlcersMedProvName },
        { path: 'ulcersMedProvAddress', nodeId: NodeIds.healthUlcersMedProvAddress },
        { path: 'ulcersMedProvPhone', nodeId: NodeIds.healthUlcersMedProvPhone },
        { path: 'ulcersHospitalVisit', nodeId: NodeIds.healthUlcersHospitalVisit },
        { path: 'ulcersHospitalVisitDate', nodeId: NodeIds.healthUlcersHospitalVisitDate },
        { path: 'ulcersHospitalName', nodeId: NodeIds.healthUlcersHospitalName },
        { path: 'ulcersHospitalAddress', nodeId: NodeIds.healthUlcersHospitalAddress },
      ],
    },
    {
      path: 'bladderDisorder',
      children: [
        { path: 'bladderDisorderDiagnosisDate', nodeId: NodeIds.healthBladderDisorderDiagnosisDate },
        { path: 'bladderDisorderMedications', nodeId: NodeIds.healthBladderDisorderMedications },
        { path: 'bladderDisorderAdditionalTreatment', nodeId: NodeIds.healthBladderDisorderAdditionalTreatment },
        { path: 'bladderDisorderStatus', nodeId: NodeIds.healthBladderDisorderStatus },
        { path: 'bladderDisorderDateOfFullRecovery', nodeId: NodeIds.healthBladderDisorderDateOfFullRecovery },
        { path: 'bladderDisorderLimitationDetails', nodeId: NodeIds.healthBladderDisorderLimitationDetails },
        {
          path: 'bladderDisorderCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthBladderDisorderCurrentlyExperienceSymptoms,
        },
        { path: 'bladderDisorderDateOfLastSymptom', nodeId: NodeIds.healthBladderDisorderDateOfLastSymptom },
        { path: 'bladderDisorderDateOfLastOccurrence', nodeId: NodeIds.healthBladderDisorderDateOfLastOccurrence },
        { path: 'bladderDisorderNumberOfOccurrences', nodeId: NodeIds.healthBladderDisorderNumberOfOccurrences },
        {
          path: 'bladderDisorderCausePhysicalLimitations',
          nodeId: NodeIds.healthBladderDisorderCausePhysicalLimitations,
        },
        { path: 'bladderDisorderLimitationDetails2', nodeId: 'health-bladder-disorder-limitation-details-2' },
        { path: 'bladderDisorderMedProv', nodeId: NodeIds.healthBladderDisorderMedProv },
        { path: 'bladderDisorderMedProvName', nodeId: NodeIds.healthBladderDisorderMedProvName },
        { path: 'bladderDisorderMedProvAddress', nodeId: NodeIds.healthBladderDisorderMedProvAddress },
        { path: 'bladderDisorderMedProvPhone', nodeId: NodeIds.healthBladderDisorderMedProvPhone },
        { path: 'bladderDisorderHospitalVisit', nodeId: NodeIds.healthBladderDisorderHospitalVisit },
        { path: 'bladderDisorderHospitalVisitDate', nodeId: NodeIds.healthBladderDisorderHospitalVisitDate },
        { path: 'bladderDisorderHospitalName', nodeId: NodeIds.healthBladderDisorderHospitalName },
        { path: 'bladderDisorderHospitalAddress', nodeId: NodeIds.healthBladderDisorderHospitalAddress },
      ],
    },
    {
      path: 'kidneyDisorder',
      children: [
        { path: 'kidneyDisorderDiagnosisDate', nodeId: NodeIds.healthKidneyDisorderDiagnosisDate },
        { path: 'kidneyDisorderMedications', nodeId: NodeIds.healthKidneyDisorderMedications },
        { path: 'kidneyDisorderAdditionalTreatment', nodeId: NodeIds.healthKidneyDisorderAdditionalTreatment },
        { path: 'kidneyDisorderStatus', nodeId: NodeIds.healthKidneyDisorderStatus },
        { path: 'kidneyDisorderDateOfFullRecovery', nodeId: NodeIds.healthKidneyDisorderDateOfFullRecovery },
        { path: 'kidneyDisorderLimitationDetails', nodeId: NodeIds.healthKidneyDisorderLimitationDetails },
        {
          path: 'kidneyDisorderCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthKidneyDisorderCurrentlyExperienceSymptoms,
        },
        { path: 'kidneyDisorderDateOfLastSymptom', nodeId: NodeIds.healthKidneyDisorderDateOfLastSymptom },
        { path: 'kidneyDisorderDateOfLastOccurrence', nodeId: NodeIds.healthKidneyDisorderDateOfLastOccurrence },
        { path: 'kidneyDisorderNumberOfOccurrences', nodeId: NodeIds.healthKidneyDisorderNumberOfOccurrences },
        {
          path: 'kidneyDisorderCausePhysicalLimitations',
          nodeId: NodeIds.healthKidneyDisorderCausePhysicalLimitations,
        },
        { path: 'kidneyDisorderLimitationDetails2', nodeId: 'health-kidney-disorder-limitation-details-2' },
        { path: 'kidneyDisorderMedProv', nodeId: NodeIds.healthKidneyDisorderMedProv },
        { path: 'kidneyDisorderMedProvName', nodeId: NodeIds.healthKidneyDisorderMedProvName },
        { path: 'kidneyDisorderMedProvAddress', nodeId: NodeIds.healthKidneyDisorderMedProvAddress },
        { path: 'kidneyDisorderMedProvPhone', nodeId: NodeIds.healthKidneyDisorderMedProvPhone },
        { path: 'kidneyDisorderHospitalVisit', nodeId: NodeIds.healthKidneyDisorderHospitalVisit },
        { path: 'kidneyDisorderHospitalVisitDate', nodeId: NodeIds.healthKidneyDisorderHospitalVisitDate },
        { path: 'kidneyDisorderHospitalName', nodeId: NodeIds.healthKidneyDisorderHospitalName },
        { path: 'kidneyDisorderHospitalAddress', nodeId: NodeIds.healthKidneyDisorderHospitalAddress },
      ],
    },
    {
      path: 'pancreasDisorder',
      children: [
        { path: 'pancreasDisorderDiagnosisDate', nodeId: NodeIds.healthPancreasDisorderDiagnosisDate },
        { path: 'pancreasDisorderMedications', nodeId: NodeIds.healthPancreasDisorderMedications },
        { path: 'pancreasDisorderAdditionalTreatment', nodeId: NodeIds.healthPancreasDisorderAdditionalTreatment },
        { path: 'pancreasDisorderStatus', nodeId: NodeIds.healthPancreasDisorderStatus },
        { path: 'pancreasDisorderDateOfFullRecovery', nodeId: NodeIds.healthPancreasDisorderDateOfFullRecovery },
        { path: 'pancreasDisorderLimitationDetails', nodeId: NodeIds.healthPancreasDisorderLimitationDetails },
        {
          path: 'pancreasDisorderCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthPancreasDisorderCurrentlyExperienceSymptoms,
        },
        { path: 'pancreasDisorderDateOfLastSymptom', nodeId: NodeIds.healthPancreasDisorderDateOfLastSymptom },
        { path: 'pancreasDisorderDateOfLastOccurrence', nodeId: NodeIds.healthPancreasDisorderDateOfLastOccurrence },
        { path: 'pancreasDisorderNumberOfOccurrences', nodeId: NodeIds.healthPancreasDisorderNumberOfOccurrences },
        {
          path: 'pancreasDisorderCausePhysicalLimitations',
          nodeId: NodeIds.healthPancreasDisorderCausePhysicalLimitations,
        },
        { path: 'pancreasDisorderLimitationDetails2', nodeId: 'health-pancreas-disorder-limitation-details-2' },
        { path: 'pancreasDisorderMedProv', nodeId: NodeIds.healthPancreasDisorderMedProv },
        { path: 'pancreasDisorderMedProvName', nodeId: NodeIds.healthPancreasDisorderMedProvName },
        { path: 'pancreasDisorderMedProvAddress', nodeId: NodeIds.healthPancreasDisorderMedProvAddress },
        { path: 'pancreasDisorderMedProvPhone', nodeId: NodeIds.healthPancreasDisorderMedProvPhone },
        { path: 'pancreasDisorderHospitalVisit', nodeId: NodeIds.healthPancreasDisorderHospitalVisit },
        { path: 'pancreasDisorderHospitalVisitDate', nodeId: NodeIds.healthPancreasDisorderHospitalVisitDate },
        { path: 'pancreasDisorderHospitalName', nodeId: NodeIds.healthPancreasDisorderHospitalName },
        { path: 'pancreasDisorderHospitalAddress', nodeId: NodeIds.healthPancreasDisorderHospitalAddress },
      ],
    },
    {
      path: 'liverDisorder',
      children: [
        { path: 'liverDisorderDiagnosisDate', nodeId: NodeIds.healthLiverDisorderDiagnosisDate },
        { path: 'liverDisorderMedications', nodeId: NodeIds.healthLiverDisorderMedications },
        { path: 'liverDisorderAdditionalTreatment', nodeId: NodeIds.healthLiverDisorderAdditionalTreatment },
        { path: 'liverDisorderStatus', nodeId: NodeIds.healthLiverDisorderStatus },
        { path: 'liverDisorderDateOfFullRecovery', nodeId: NodeIds.healthLiverDisorderDateOfFullRecovery },
        { path: 'liverDisorderLimitationDetails', nodeId: NodeIds.healthLiverDisorderLimitationDetails },
        {
          path: 'liverDisorderCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthLiverDisorderCurrentlyExperienceSymptoms,
        },
        { path: 'liverDisorderDateOfLastSymptom', nodeId: NodeIds.healthLiverDisorderDateOfLastSymptom },
        { path: 'liverDisorderDateOfLastOccurrence', nodeId: NodeIds.healthLiverDisorderDateOfLastOccurrence },
        { path: 'liverDisorderNumberOfOccurrences', nodeId: NodeIds.healthLiverDisorderNumberOfOccurrences },
        { path: 'liverDisorderCausePhysicalLimitations', nodeId: NodeIds.healthLiverDisorderCausePhysicalLimitations },
        { path: 'liverDisorderLimitationDetails2', nodeId: 'health-liver-disorder-limitation-details-2' },
        { path: 'liverDisorderMedProv', nodeId: NodeIds.healthLiverDisorderMedProv },
        { path: 'liverDisorderMedProvName', nodeId: NodeIds.healthLiverDisorderMedProvName },
        { path: 'liverDisorderMedProvAddress', nodeId: NodeIds.healthLiverDisorderMedProvAddress },
        { path: 'liverDisorderMedProvPhone', nodeId: NodeIds.healthLiverDisorderMedProvPhone },
        { path: 'liverDisorderHospitalVisit', nodeId: NodeIds.healthLiverDisorderHospitalVisit },
        { path: 'liverDisorderHospitalVisitDate', nodeId: NodeIds.healthLiverDisorderHospitalVisitDate },
        { path: 'liverDisorderHospitalName', nodeId: NodeIds.healthLiverDisorderHospitalName },
        { path: 'liverDisorderHospitalAddress', nodeId: NodeIds.healthLiverDisorderHospitalAddress },
      ],
    },
    {
      path: 'rectumDisorder',
      children: [
        { path: 'rectumDisorderDiagnosisDate', nodeId: NodeIds.healthRectumDisorderDiagnosisDate },
        { path: 'rectumDisorderMedications', nodeId: NodeIds.healthRectumDisorderMedications },
        { path: 'rectumDisorderAdditionalTreatment', nodeId: NodeIds.healthRectumDisorderAdditionalTreatment },
        { path: 'rectumDisorderStatus', nodeId: NodeIds.healthRectumDisorderStatus },
        { path: 'rectumDisorderDateOfFullRecovery', nodeId: NodeIds.healthRectumDisorderDateOfFullRecovery },
        { path: 'rectumDisorderLimitationDetails', nodeId: NodeIds.healthRectumDisorderLimitationDetails },
        {
          path: 'rectumDisorderCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthRectumDisorderCurrentlyExperienceSymptoms,
        },
        { path: 'rectumDisorderDateOfLastSymptom', nodeId: NodeIds.healthRectumDisorderDateOfLastSymptom },
        { path: 'rectumDisorderDateOfLastOccurrence', nodeId: NodeIds.healthRectumDisorderDateOfLastOccurrence },
        { path: 'rectumDisorderNumberOfOccurrences', nodeId: NodeIds.healthRectumDisorderNumberOfOccurrences },
        {
          path: 'rectumDisorderCausePhysicalLimitations',
          nodeId: NodeIds.healthRectumDisorderCausePhysicalLimitations,
        },
        { path: 'rectumDisorderLimitationDetails2', nodeId: 'health-rectum-disorder-limitation-details-2' },
        { path: 'rectumDisorderMedProv', nodeId: NodeIds.healthRectumDisorderMedProv },
        { path: 'rectumDisorderMedProvName', nodeId: NodeIds.healthRectumDisorderMedProvName },
        { path: 'rectumDisorderMedProvAddress', nodeId: NodeIds.healthRectumDisorderMedProvAddress },
        { path: 'rectumDisorderMedProvPhone', nodeId: NodeIds.healthRectumDisorderMedProvPhone },
        { path: 'rectumDisorderHospitalVisit', nodeId: NodeIds.healthRectumDisorderHospitalVisit },
        { path: 'rectumDisorderHospitalVisitDate', nodeId: NodeIds.healthRectumDisorderHospitalVisitDate },
        { path: 'rectumDisorderHospitalName', nodeId: NodeIds.healthRectumDisorderHospitalName },
        { path: 'rectumDisorderHospitalAddress', nodeId: NodeIds.healthRectumDisorderHospitalAddress },
      ],
    },
    {
      path: 'intestinesDisorder',
      children: [
        { path: 'intestinesDisorderDiagnosisDate', nodeId: NodeIds.healthIntestinesDisorderDiagnosisDate },
        { path: 'intestinesDisorderMedications', nodeId: NodeIds.healthIntestinesDisorderMedications },
        { path: 'intestinesDisorderAdditionalTreatment', nodeId: NodeIds.healthIntestinesDisorderAdditionalTreatment },
        { path: 'intestinesDisorderStatus', nodeId: NodeIds.healthIntestinesDisorderStatus },
        { path: 'intestinesDisorderDateOfFullRecovery', nodeId: NodeIds.healthIntestinesDisorderDateOfFullRecovery },
        { path: 'intestinesDisorderLimitationDetails', nodeId: NodeIds.healthIntestinesDisorderLimitationDetails },
        {
          path: 'intestinesDisorderCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthIntestinesDisorderCurrentlyExperienceSymptoms,
        },
        { path: 'intestinesDisorderDateOfLastSymptom', nodeId: NodeIds.healthIntestinesDisorderDateOfLastSymptom },
        {
          path: 'intestinesDisorderDateOfLastOccurrence',
          nodeId: NodeIds.healthIntestinesDisorderDateOfLastOccurrence,
        },
        { path: 'intestinesDisorderNumberOfOccurrences', nodeId: NodeIds.healthIntestinesDisorderNumberOfOccurrences },
        {
          path: 'intestinesDisorderCausePhysicalLimitations',
          nodeId: NodeIds.healthIntestinesDisorderCausePhysicalLimitations,
        },
        { path: 'intestinesDisorderLimitationDetails2', nodeId: 'health-intestines-disorder-limitation-details-2' },
        { path: 'intestinesDisorderMedProv', nodeId: NodeIds.healthIntestinesDisorderMedProv },
        { path: 'intestinesDisorderMedProvName', nodeId: NodeIds.healthIntestinesDisorderMedProvName },
        { path: 'intestinesDisorderMedProvAddress', nodeId: NodeIds.healthIntestinesDisorderMedProvAddress },
        { path: 'intestinesDisorderMedProvPhone', nodeId: NodeIds.healthIntestinesDisorderMedProvPhone },
        { path: 'intestinesDisorderHospitalVisit', nodeId: NodeIds.healthIntestinesDisorderHospitalVisit },
        { path: 'intestinesDisorderHospitalVisitDate', nodeId: NodeIds.healthIntestinesDisorderHospitalVisitDate },
        { path: 'intestinesDisorderHospitalName', nodeId: NodeIds.healthIntestinesDisorderHospitalName },
        { path: 'intestinesDisorderHospitalAddress', nodeId: NodeIds.healthIntestinesDisorderHospitalAddress },
      ],
    },
    {
      path: 'otherRespiratoryDisease',
      children: [
        { path: 'otherRespiratoryDiseaseDiagnosisDate', nodeId: NodeIds.healthOtherRespiratoryDiseaseDiagnosisDate },
        { path: 'otherRespiratoryDiseaseMedications', nodeId: NodeIds.healthOtherRespiratoryDiseaseMedications },
        {
          path: 'otherRespiratoryDiseaseAdditionalTreatment',
          nodeId: NodeIds.healthOtherRespiratoryDiseaseAdditionalTreatment,
        },
        { path: 'otherRespiratoryDiseaseStatus', nodeId: NodeIds.healthOtherRespiratoryDiseaseStatus },
        {
          path: 'otherRespiratoryDiseaseDateOfFullRecovery',
          nodeId: NodeIds.healthOtherRespiratoryDiseaseDateOfFullRecovery,
        },
        {
          path: 'otherRespiratoryDiseaseLimitationDetails',
          nodeId: NodeIds.healthOtherRespiratoryDiseaseLimitationDetails,
        },
        {
          path: 'otherRespiratoryDiseaseCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthOtherRespiratoryDiseaseCurrentlyExperienceSymptoms,
        },
        {
          path: 'otherRespiratoryDiseaseDateOfLastSymptom',
          nodeId: NodeIds.healthOtherRespiratoryDiseaseDateOfLastSymptom,
        },
        {
          path: 'otherRespiratoryDiseaseDateOfLastOccurrence',
          nodeId: NodeIds.healthOtherRespiratoryDiseaseDateOfLastOccurrence,
        },
        {
          path: 'otherRespiratoryDiseaseNumberOfOccurrences',
          nodeId: NodeIds.healthOtherRespiratoryDiseaseNumberOfOccurrences,
        },
        {
          path: 'otherRespiratoryDiseaseCausePhysicalLimitations',
          nodeId: NodeIds.healthOtherRespiratoryDiseaseCausePhysicalLimitations,
        },
        {
          path: 'otherRespiratoryDiseaseLimitationDetails2',
          nodeId: 'health-other-respiratory-disease-limitation-details-2',
        },
        { path: 'otherRespiratoryDiseaseMedProv', nodeId: NodeIds.healthOtherRespiratoryDiseaseMedProv },
        { path: 'otherRespiratoryDiseaseMedProvName', nodeId: NodeIds.healthOtherRespiratoryDiseaseMedProvName },
        { path: 'otherRespiratoryDiseaseMedProvAddress', nodeId: NodeIds.healthOtherRespiratoryDiseaseMedProvAddress },
        { path: 'otherRespiratoryDiseaseMedProvPhone', nodeId: NodeIds.healthOtherRespiratoryDiseaseMedProvPhone },
        { path: 'otherRespiratoryDiseaseHospitalVisit', nodeId: NodeIds.healthOtherRespiratoryDiseaseHospitalVisit },
        {
          path: 'otherRespiratoryDiseaseHospitalVisitDate',
          nodeId: NodeIds.healthOtherRespiratoryDiseaseHospitalVisitDate,
        },
        { path: 'otherRespiratoryDiseaseHospitalName', nodeId: NodeIds.healthOtherRespiratoryDiseaseHospitalName },
        {
          path: 'otherRespiratoryDiseaseHospitalAddress',
          nodeId: NodeIds.healthOtherRespiratoryDiseaseHospitalAddress,
        },
      ],
    },
    {
      path: 'tuberculosis',
      children: [
        { path: 'tuberculosisDiagnosisDate', nodeId: NodeIds.healthTuberculosisDiagnosisDate },
        { path: 'tuberculosisMedications', nodeId: NodeIds.healthTuberculosisMedications },
        { path: 'tuberculosisAdditionalTreatment', nodeId: NodeIds.healthTuberculosisAdditionalTreatment },
        { path: 'tuberculosisStatus', nodeId: NodeIds.healthTuberculosisStatus },
        { path: 'tuberculosisDateOfFullRecovery', nodeId: NodeIds.healthTuberculosisDateOfFullRecovery },
        { path: 'tuberculosisLimitationDetails', nodeId: NodeIds.healthTuberculosisLimitationDetails },
        {
          path: 'tuberculosisCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthTuberculosisCurrentlyExperienceSymptoms,
        },
        { path: 'tuberculosisDateOfLastSymptom', nodeId: NodeIds.healthTuberculosisDateOfLastSymptom },
        { path: 'tuberculosisDateOfLastOccurrence', nodeId: NodeIds.healthTuberculosisDateOfLastOccurrence },
        { path: 'tuberculosisNumberOfOccurrences', nodeId: NodeIds.healthTuberculosisNumberOfOccurrences },
        { path: 'tuberculosisCausePhysicalLimitations', nodeId: NodeIds.healthTuberculosisCausePhysicalLimitations },
        { path: 'tuberculosisLimitationDetails2', nodeId: 'health-tuberculosis-limitation-details-2' },
        { path: 'tuberculosisMedProv', nodeId: NodeIds.healthTuberculosisMedProv },
        { path: 'tuberculosisMedProvName', nodeId: NodeIds.healthTuberculosisMedProvName },
        { path: 'tuberculosisMedProvAddress', nodeId: NodeIds.healthTuberculosisMedProvAddress },
        { path: 'tuberculosisMedProvPhone', nodeId: NodeIds.healthTuberculosisMedProvPhone },
        { path: 'tuberculosisHospitalVisit', nodeId: NodeIds.healthTuberculosisHospitalVisit },
        { path: 'tuberculosisHospitalVisitDate', nodeId: NodeIds.healthTuberculosisHospitalVisitDate },
        { path: 'tuberculosisHospitalName', nodeId: NodeIds.healthTuberculosisHospitalName },
        { path: 'tuberculosisHospitalAddress', nodeId: NodeIds.healthTuberculosisHospitalAddress },
      ],
    },
    {
      path: 'sleepApnea',
      children: [
        { path: 'sleepApneaDiagnosisDate', nodeId: NodeIds.healthSleepApneaDiagnosisDate },
        { path: 'sleepApneaSleepStudy', nodeId: NodeIds.healthSleepApneaSleepStudy },
        { path: 'sleepApneaSleepStudyDate', nodeId: NodeIds.healthSleepApneaSleepStudyDate },
        { path: 'sleepApneaSleepStudyResults', nodeId: NodeIds.healthSleepApneaSleepStudyResults },
        { path: 'sleepApneaIndex', nodeId: NodeIds.healthSleepApneaIndex },
        { path: 'sleepApneaTreatment', nodeId: NodeIds.healthSleepApneaTreatment },
        { path: 'sleepApneaCPAPOften', nodeId: NodeIds.healthSleepApneaCPAPOften },
        { path: 'sleepApneaTreatmentOther', nodeId: NodeIds.healthSleepApneaTreatmentOther },
        { path: 'sleepApneaComplaint', nodeId: NodeIds.healthSleepApneaComplaint },
        { path: 'sleepApneaSymptoms', nodeId: NodeIds.healthSleepApneaSymptoms },
        { path: 'sleepApneaIssues', nodeId: NodeIds.healthSleepApneaIssues },
        { path: 'sleepApneaName', nodeId: NodeIds.healthSleepApneaName },
        { path: 'sleepApneaAddress', nodeId: NodeIds.healthSleepApneaAddress },
        { path: 'sleepApneaPhone', nodeId: NodeIds.healthSleepApneaPhone },
      ],
    },
    {
      path: 'lungDisease',
      children: [
        { path: 'lungDiseaseDiagnosisDate', nodeId: NodeIds.healthLungDiseaseDiagnosisDate },
        { path: 'lungDiseaseMedications', nodeId: NodeIds.healthLungDiseaseMedications },
        { path: 'lungDiseaseAdditionalTreatment', nodeId: NodeIds.healthLungDiseaseAdditionalTreatment },
        { path: 'lungDiseaseStatus', nodeId: NodeIds.healthLungDiseaseStatus },
        { path: 'lungDiseaseDateOfFullRecovery', nodeId: NodeIds.healthLungDiseaseDateOfFullRecovery },
        { path: 'lungDiseaseLimitationDetails', nodeId: NodeIds.healthLungDiseaseLimitationDetails },
        {
          path: 'lungDiseaseCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthLungDiseaseCurrentlyExperienceSymptoms,
        },
        { path: 'lungDiseaseDateOfLastSymptom', nodeId: NodeIds.healthLungDiseaseDateOfLastSymptom },
        { path: 'lungDiseaseDateOfLastOccurrence', nodeId: NodeIds.healthLungDiseaseDateOfLastOccurrence },
        { path: 'lungDiseaseNumberOfOccurrences', nodeId: NodeIds.healthLungDiseaseNumberOfOccurrences },
        { path: 'lungDiseaseCausePhysicalLimitations', nodeId: NodeIds.healthLungDiseaseCausePhysicalLimitations },
        { path: 'lungDiseaseLimitationDetails2', nodeId: 'health-lung-disease-limitation-details-2' },
        { path: 'lungDiseaseMedProv', nodeId: NodeIds.healthLungDiseaseMedProv },
        { path: 'lungDiseaseMedProvName', nodeId: NodeIds.healthLungDiseaseMedProvName },
        { path: 'lungDiseaseMedProvAddress', nodeId: NodeIds.healthLungDiseaseMedProvAddress },
        { path: 'lungDiseaseMedProvPhone', nodeId: NodeIds.healthLungDiseaseMedProvPhone },
        { path: 'lungDiseaseHospitalVisit', nodeId: NodeIds.healthLungDiseaseHospitalVisit },
        { path: 'lungDiseaseHospitalVisitDate', nodeId: NodeIds.healthLungDiseaseHospitalVisitDate },
        { path: 'lungDiseaseHospitalName', nodeId: NodeIds.healthLungDiseaseHospitalName },
        { path: 'lungDiseaseHospitalAddress', nodeId: NodeIds.healthLungDiseaseHospitalAddress },
      ],
    },
    {
      path: 'throatDisease',
      children: [
        { path: 'throatDiseaseDiagnosisDate', nodeId: NodeIds.healthThroatDiseaseDiagnosisDate },
        { path: 'throatDiseaseMedications', nodeId: NodeIds.healthThroatDiseaseMedications },
        { path: 'throatDiseaseAdditionalTreatment', nodeId: NodeIds.healthThroatDiseaseAdditionalTreatment },
        { path: 'throatDiseaseStatus', nodeId: NodeIds.healthThroatDiseaseStatus },
        { path: 'throatDiseaseDateOfFullRecovery', nodeId: NodeIds.healthThroatDiseaseDateOfFullRecovery },
        { path: 'throatDiseaseLimitationDetails', nodeId: NodeIds.healthThroatDiseaseLimitationDetails },
        {
          path: 'throatDiseaseCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthThroatDiseaseCurrentlyExperienceSymptoms,
        },
        { path: 'throatDiseaseDateOfLastSymptom', nodeId: NodeIds.healthThroatDiseaseDateOfLastSymptom },
        { path: 'throatDiseaseDateOfLastOccurrence', nodeId: NodeIds.healthThroatDiseaseDateOfLastOccurrence },
        { path: 'throatDiseaseNumberOfOccurrences', nodeId: NodeIds.healthThroatDiseaseNumberOfOccurrences },
        { path: 'throatDiseaseCausePhysicalLimitations', nodeId: NodeIds.healthThroatDiseaseCausePhysicalLimitations },
        { path: 'throatDiseaseLimitationDetails2', nodeId: 'health-throat-disease-limitation-details-2' },
        { path: 'throatDiseaseMedProv', nodeId: NodeIds.healthThroatDiseaseMedProv },
        { path: 'throatDiseaseMedProvName', nodeId: NodeIds.healthThroatDiseaseMedProvName },
        { path: 'throatDiseaseMedProvAddress', nodeId: NodeIds.healthThroatDiseaseMedProvAddress },
        { path: 'throatDiseaseMedProvPhone', nodeId: NodeIds.healthThroatDiseaseMedProvPhone },
        { path: 'throatDiseaseHospitalVisit', nodeId: NodeIds.healthThroatDiseaseHospitalVisit },
        { path: 'throatDiseaseHospitalVisitDate', nodeId: NodeIds.healthThroatDiseaseHospitalVisitDate },
        { path: 'throatDiseaseHospitalName', nodeId: NodeIds.healthThroatDiseaseHospitalName },
        { path: 'throatDiseaseHospitalAddress', nodeId: NodeIds.healthThroatDiseaseHospitalAddress },
      ],
    },
    {
      path: 'noseDisease',
      children: [
        { path: 'noseDiseaseDiagnosisDate', nodeId: NodeIds.healthNoseDiseaseDiagnosisDate },
        { path: 'noseDiseaseMedications', nodeId: NodeIds.healthNoseDiseaseMedications },
        { path: 'noseDiseaseAdditionalTreatment', nodeId: NodeIds.healthNoseDiseaseAdditionalTreatment },
        { path: 'noseDiseaseStatus', nodeId: NodeIds.healthNoseDiseaseStatus },
        { path: 'noseDiseaseDateOfFullRecovery', nodeId: NodeIds.healthNoseDiseaseDateOfFullRecovery },
        { path: 'noseDiseaseLimitationDetails', nodeId: NodeIds.healthNoseDiseaseLimitationDetails },
        {
          path: 'noseDiseaseCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthNoseDiseaseCurrentlyExperienceSymptoms,
        },
        { path: 'noseDiseaseDateOfLastSymptom', nodeId: NodeIds.healthNoseDiseaseDateOfLastSymptom },
        { path: 'noseDiseaseDateOfLastOccurrence', nodeId: NodeIds.healthNoseDiseaseDateOfLastOccurrence },
        { path: 'noseDiseaseNumberOfOccurrences', nodeId: NodeIds.healthNoseDiseaseNumberOfOccurrences },
        { path: 'noseDiseaseCausePhysicalLimitations', nodeId: NodeIds.healthNoseDiseaseCausePhysicalLimitations },
        { path: 'noseDiseaseLimitationDetails2', nodeId: 'health-nose-disease-limitation-details-2' },
        { path: 'noseDiseaseMedProv', nodeId: NodeIds.healthNoseDiseaseMedProv },
        { path: 'noseDiseaseMedProvName', nodeId: NodeIds.healthNoseDiseaseMedProvName },
        { path: 'noseDiseaseMedProvAddress', nodeId: NodeIds.healthNoseDiseaseMedProvAddress },
        { path: 'noseDiseaseMedProvPhone', nodeId: NodeIds.healthNoseDiseaseMedProvPhone },
        { path: 'noseDiseaseHospitalVisit', nodeId: NodeIds.healthNoseDiseaseHospitalVisit },
        { path: 'noseDiseaseHospitalVisitDate', nodeId: NodeIds.healthNoseDiseaseHospitalVisitDate },
        { path: 'noseDiseaseHospitalName', nodeId: NodeIds.healthNoseDiseaseHospitalName },
        { path: 'noseDiseaseHospitalAddress', nodeId: NodeIds.healthNoseDiseaseHospitalAddress },
      ],
    },
    {
      path: 'unexplainedSwellingLymphGland',
      children: [
        {
          path: 'unexplainedSwellingLymphGlandDiagnosisDate',
          nodeId: NodeIds.healthUnexplainedSwellingLymphGlandDiagnosisDate,
        },
        {
          path: 'unexplainedSwellingLymphGlandMedications',
          nodeId: NodeIds.healthUnexplainedSwellingLymphGlandMedications,
        },
        {
          path: 'unexplainedSwellingLymphGlandAdditionalTreatment',
          nodeId: NodeIds.healthUnexplainedSwellingLymphGlandAdditionalTreatment,
        },
        { path: 'unexplainedSwellingLymphGlandStatus', nodeId: NodeIds.healthUnexplainedSwellingLymphGlandStatus },
        {
          path: 'unexplainedSwellingLymphGlandDateOfFullRecovery',
          nodeId: NodeIds.healthUnexplainedSwellingLymphGlandDateOfFullRecovery,
        },
        {
          path: 'unexplainedSwellingLymphGlandLimitationDetails',
          nodeId: NodeIds.healthUnexplainedSwellingLymphGlandLimitationDetails,
        },
        {
          path: 'unexplainedSwellingLymphGlandCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthUnexplainedSwellingLymphGlandCurrentlyExperienceSymptoms,
        },
        {
          path: 'unexplainedSwellingLymphGlandDateOfLastSymptom',
          nodeId: NodeIds.healthUnexplainedSwellingLymphGlandDateOfLastSymptom,
        },
        {
          path: 'unexplainedSwellingLymphGlandDateOfLastOccurrence',
          nodeId: NodeIds.healthUnexplainedSwellingLymphGlandDateOfLastOccurrence,
        },
        {
          path: 'unexplainedSwellingLymphGlandNumberOfOccurrences',
          nodeId: NodeIds.healthUnexplainedSwellingLymphGlandNumberOfOccurrences,
        },
        {
          path: 'unexplainedSwellingLymphGlandCausePhysicalLimitations',
          nodeId: NodeIds.healthUnexplainedSwellingLymphGlandCausePhysicalLimitations,
        },
        {
          path: 'unexplainedSwellingLymphGlandLimitationDetails2',
          nodeId: 'health-unexplained-swelling-lymph-gland-limitation-details-2',
        },
        { path: 'unexplainedSwellingLymphGlandMedProv', nodeId: NodeIds.healthUnexplainedSwellingLymphGlandMedProv },
        {
          path: 'unexplainedSwellingLymphGlandMedProvName',
          nodeId: NodeIds.healthUnexplainedSwellingLymphGlandMedProvName,
        },
        {
          path: 'unexplainedSwellingLymphGlandMedProvAddress',
          nodeId: NodeIds.healthUnexplainedSwellingLymphGlandMedProvAddress,
        },
        {
          path: 'unexplainedSwellingLymphGlandMedProvPhone',
          nodeId: NodeIds.healthUnexplainedSwellingLymphGlandMedProvPhone,
        },
        {
          path: 'unexplainedSwellingLymphGlandHospitalVisit',
          nodeId: NodeIds.healthUnexplainedSwellingLymphGlandHospitalVisit,
        },
        {
          path: 'unexplainedSwellingLymphGlandHospitalVisitDate',
          nodeId: NodeIds.healthUnexplainedSwellingLymphGlandHospitalVisitDate,
        },
        {
          path: 'unexplainedSwellingLymphGlandHospitalName',
          nodeId: NodeIds.healthUnexplainedSwellingLymphGlandHospitalName,
        },
        {
          path: 'unexplainedSwellingLymphGlandHospitalAddress',
          nodeId: NodeIds.healthUnexplainedSwellingLymphGlandHospitalAddress,
        },
      ],
    },
    {
      path: 'unexplainedSkinLesions',
      children: [
        { path: 'unexplainedSkinLesionsDiagnosisDate', nodeId: NodeIds.healthUnexplainedSkinLesionsDiagnosisDate },
        { path: 'unexplainedSkinLesionsMedications', nodeId: NodeIds.healthUnexplainedSkinLesionsMedications },
        {
          path: 'unexplainedSkinLesionsAdditionalTreatment',
          nodeId: NodeIds.healthUnexplainedSkinLesionsAdditionalTreatment,
        },
        { path: 'unexplainedSkinLesionsStatus', nodeId: NodeIds.healthUnexplainedSkinLesionsStatus },
        {
          path: 'unexplainedSkinLesionsDateOfFullRecovery',
          nodeId: NodeIds.healthUnexplainedSkinLesionsDateOfFullRecovery,
        },
        {
          path: 'unexplainedSkinLesionsLimitationDetails',
          nodeId: NodeIds.healthUnexplainedSkinLesionsLimitationDetails,
        },
        {
          path: 'unexplainedSkinLesionsCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthUnexplainedSkinLesionsCurrentlyExperienceSymptoms,
        },
        {
          path: 'unexplainedSkinLesionsDateOfLastSymptom',
          nodeId: NodeIds.healthUnexplainedSkinLesionsDateOfLastSymptom,
        },
        {
          path: 'unexplainedSkinLesionsDateOfLastOccurrence',
          nodeId: NodeIds.healthUnexplainedSkinLesionsDateOfLastOccurrence,
        },
        {
          path: 'unexplainedSkinLesionsNumberOfOccurrences',
          nodeId: NodeIds.healthUnexplainedSkinLesionsNumberOfOccurrences,
        },
        {
          path: 'unexplainedSkinLesionsCausePhysicalLimitations',
          nodeId: NodeIds.healthUnexplainedSkinLesionsCausePhysicalLimitations,
        },
        {
          path: 'unexplainedSkinLesionsLimitationDetails2',
          nodeId: 'health-unexplained-skin-lesions-limitation-details-2',
        },
        { path: 'unexplainedSkinLesionsMedProv', nodeId: NodeIds.healthUnexplainedSkinLesionsMedProv },
        { path: 'unexplainedSkinLesionsMedProvName', nodeId: NodeIds.healthUnexplainedSkinLesionsMedProvName },
        { path: 'unexplainedSkinLesionsMedProvAddress', nodeId: NodeIds.healthUnexplainedSkinLesionsMedProvAddress },
        { path: 'unexplainedSkinLesionsMedProvPhone', nodeId: NodeIds.healthUnexplainedSkinLesionsMedProvPhone },
        { path: 'unexplainedSkinLesionsHospitalVisit', nodeId: NodeIds.healthUnexplainedSkinLesionsHospitalVisit },
        {
          path: 'unexplainedSkinLesionsHospitalVisitDate',
          nodeId: NodeIds.healthUnexplainedSkinLesionsHospitalVisitDate,
        },
        { path: 'unexplainedSkinLesionsHospitalName', nodeId: NodeIds.healthUnexplainedSkinLesionsHospitalName },
        { path: 'unexplainedSkinLesionsHospitalAddress', nodeId: NodeIds.healthUnexplainedSkinLesionsHospitalAddress },
      ],
    },
    {
      path: 'severeNightSweats',
      children: [
        { path: 'severeNightSweatsDiagnosisDate', nodeId: NodeIds.healthSevereNightSweatsDiagnosisDate },
        { path: 'severeNightSweatsMedications', nodeId: NodeIds.healthSevereNightSweatsMedications },
        { path: 'severeNightSweatsAdditionalTreatment', nodeId: NodeIds.healthSevereNightSweatsAdditionalTreatment },
        { path: 'severeNightSweatsStatus', nodeId: NodeIds.healthSevereNightSweatsStatus },
        { path: 'severeNightSweatsDateOfFullRecovery', nodeId: NodeIds.healthSevereNightSweatsDateOfFullRecovery },
        { path: 'severeNightSweatsLimitationDetails', nodeId: NodeIds.healthSevereNightSweatsLimitationDetails },
        {
          path: 'severeNightSweatsCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthSevereNightSweatsCurrentlyExperienceSymptoms,
        },
        { path: 'severeNightSweatsDateOfLastSymptom', nodeId: NodeIds.healthSevereNightSweatsDateOfLastSymptom },
        { path: 'severeNightSweatsDateOfLastOccurrence', nodeId: NodeIds.healthSevereNightSweatsDateOfLastOccurrence },
        { path: 'severeNightSweatsNumberOfOccurrences', nodeId: NodeIds.healthSevereNightSweatsNumberOfOccurrences },
        {
          path: 'severeNightSweatsCausePhysicalLimitations',
          nodeId: NodeIds.healthSevereNightSweatsCausePhysicalLimitations,
        },
        { path: 'severeNightSweatsLimitationDetails2', nodeId: 'health-severe-night-sweats-limitation-details-2' },
        { path: 'severeNightSweatsMedProv', nodeId: NodeIds.healthSevereNightSweatsMedProv },
        { path: 'severeNightSweatsMedProvName', nodeId: NodeIds.healthSevereNightSweatsMedProvName },
        { path: 'severeNightSweatsMedProvAddress', nodeId: NodeIds.healthSevereNightSweatsMedProvAddress },
        { path: 'severeNightSweatsMedProvPhone', nodeId: NodeIds.healthSevereNightSweatsMedProvPhone },
        { path: 'severeNightSweatsHospitalVisit', nodeId: NodeIds.healthSevereNightSweatsHospitalVisit },
        { path: 'severeNightSweatsHospitalVisitDate', nodeId: NodeIds.healthSevereNightSweatsHospitalVisitDate },
        { path: 'severeNightSweatsHospitalName', nodeId: NodeIds.healthSevereNightSweatsHospitalName },
        { path: 'severeNightSweatsHospitalAddress', nodeId: NodeIds.healthSevereNightSweatsHospitalAddress },
      ],
    },
    {
      path: 'feverUnknownOrigin',
      children: [
        { path: 'feverUnknownOriginDiagnosisDate', nodeId: NodeIds.healthFeverUnknownOriginDiagnosisDate },
        { path: 'feverUnknownOriginMedications', nodeId: NodeIds.healthFeverUnknownOriginMedications },
        { path: 'feverUnknownOriginAdditionalTreatment', nodeId: NodeIds.healthFeverUnknownOriginAdditionalTreatment },
        { path: 'feverUnknownOriginStatus', nodeId: NodeIds.healthFeverUnknownOriginStatus },
        { path: 'feverUnknownOriginDateOfFullRecovery', nodeId: NodeIds.healthFeverUnknownOriginDateOfFullRecovery },
        { path: 'feverUnknownOriginLimitationDetails', nodeId: NodeIds.healthFeverUnknownOriginLimitationDetails },
        {
          path: 'feverUnknownOriginCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthFeverUnknownOriginCurrentlyExperienceSymptoms,
        },
        { path: 'feverUnknownOriginDateOfLastSymptom', nodeId: NodeIds.healthFeverUnknownOriginDateOfLastSymptom },
        {
          path: 'feverUnknownOriginDateOfLastOccurrence',
          nodeId: NodeIds.healthFeverUnknownOriginDateOfLastOccurrence,
        },
        { path: 'feverUnknownOriginNumberOfOccurrences', nodeId: NodeIds.healthFeverUnknownOriginNumberOfOccurrences },
        {
          path: 'feverUnknownOriginCausePhysicalLimitations',
          nodeId: NodeIds.healthFeverUnknownOriginCausePhysicalLimitations,
        },
        { path: 'feverUnknownOriginLimitationDetails2', nodeId: 'health-fever-unknown-origin-limitation-details-2' },
        { path: 'feverUnknownOriginMedProv', nodeId: NodeIds.healthFeverUnknownOriginMedProv },
        { path: 'feverUnknownOriginMedProvName', nodeId: NodeIds.healthFeverUnknownOriginMedProvName },
        { path: 'feverUnknownOriginMedProvAddress', nodeId: NodeIds.healthFeverUnknownOriginMedProvAddress },
        { path: 'feverUnknownOriginMedProvPhone', nodeId: NodeIds.healthFeverUnknownOriginMedProvPhone },
        { path: 'feverUnknownOriginHospitalVisit', nodeId: NodeIds.healthFeverUnknownOriginHospitalVisit },
        { path: 'feverUnknownOriginHospitalVisitDate', nodeId: NodeIds.healthFeverUnknownOriginHospitalVisitDate },
        { path: 'feverUnknownOriginHospitalName', nodeId: NodeIds.healthFeverUnknownOriginHospitalName },
        { path: 'feverUnknownOriginHospitalAddress', nodeId: NodeIds.healthFeverUnknownOriginHospitalAddress },
      ],
    },
    {
      path: 'diarrhea',
      children: [
        { path: 'diarrheaDiagnosisDate', nodeId: NodeIds.healthDiarrheaDiagnosisDate },
        { path: 'diarrheaMedications', nodeId: NodeIds.healthDiarrheaMedications },
        { path: 'diarrheaAdditionalTreatment', nodeId: NodeIds.healthDiarrheaAdditionalTreatment },
        { path: 'diarrheaStatus', nodeId: NodeIds.healthDiarrheaStatus },
        { path: 'diarrheaDateOfFullRecovery', nodeId: NodeIds.healthDiarrheaDateOfFullRecovery },
        { path: 'diarrheaLimitationDetails', nodeId: NodeIds.healthDiarrheaLimitationDetails },
        { path: 'diarrheaCurrentlyExperienceSymptoms', nodeId: NodeIds.healthDiarrheaCurrentlyExperienceSymptoms },
        { path: 'diarrheaDateOfLastSymptom', nodeId: NodeIds.healthDiarrheaDateOfLastSymptom },
        { path: 'diarrheaDateOfLastOccurrence', nodeId: NodeIds.healthDiarrheaDateOfLastOccurrence },
        { path: 'diarrheaNumberOfOccurrences', nodeId: NodeIds.healthDiarrheaNumberOfOccurrences },
        { path: 'diarrheaCausePhysicalLimitations', nodeId: NodeIds.healthDiarrheaCausePhysicalLimitations },
        { path: 'diarrheaLimitationDetails2', nodeId: 'health-diarrhea-limitation-details-2' },
        { path: 'diarrheaMedProv', nodeId: NodeIds.healthDiarrheaMedProv },
        { path: 'diarrheaMedProvName', nodeId: NodeIds.healthDiarrheaMedProvName },
        { path: 'diarrheaMedProvAddress', nodeId: NodeIds.healthDiarrheaMedProvAddress },
        { path: 'diarrheaMedProvPhone', nodeId: NodeIds.healthDiarrheaMedProvPhone },
        { path: 'diarrheaHospitalVisit', nodeId: NodeIds.healthDiarrheaHospitalVisit },
        { path: 'diarrheaHospitalVisitDate', nodeId: NodeIds.healthDiarrheaHospitalVisitDate },
        { path: 'diarrheaHospitalName', nodeId: NodeIds.healthDiarrheaHospitalName },
        { path: 'diarrheaHospitalAddress', nodeId: NodeIds.healthDiarrheaHospitalAddress },
      ],
    },
    {
      path: 'lossAppetite',
      children: [
        { path: 'lossAppetiteDiagnosisDate', nodeId: NodeIds.healthLossAppetiteDiagnosisDate },
        { path: 'lossAppetiteMedications', nodeId: NodeIds.healthLossAppetiteMedications },
        { path: 'lossAppetiteAdditionalTreatment', nodeId: NodeIds.healthLossAppetiteAdditionalTreatment },
        { path: 'lossAppetiteStatus', nodeId: NodeIds.healthLossAppetiteStatus },
        { path: 'lossAppetiteDateOfFullRecovery', nodeId: NodeIds.healthLossAppetiteDateOfFullRecovery },
        { path: 'lossAppetiteLimitationDetails', nodeId: NodeIds.healthLossAppetiteLimitationDetails },
        {
          path: 'lossAppetiteCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthLossAppetiteCurrentlyExperienceSymptoms,
        },
        { path: 'lossAppetiteDateOfLastSymptom', nodeId: NodeIds.healthLossAppetiteDateOfLastSymptom },
        { path: 'lossAppetiteDateOfLastOccurrence', nodeId: NodeIds.healthLossAppetiteDateOfLastOccurrence },
        { path: 'lossAppetiteNumberOfOccurrences', nodeId: NodeIds.healthLossAppetiteNumberOfOccurrences },
        { path: 'lossAppetiteCausePhysicalLimitations', nodeId: NodeIds.healthLossAppetiteCausePhysicalLimitations },
        { path: 'lossAppetiteLimitationDetails2', nodeId: 'health-loss-appetite-limitation-details-2' },
        { path: 'lossAppetiteMedProv', nodeId: NodeIds.healthLossAppetiteMedProv },
        { path: 'lossAppetiteMedProvName', nodeId: NodeIds.healthLossAppetiteMedProvName },
        { path: 'lossAppetiteMedProvAddress', nodeId: NodeIds.healthLossAppetiteMedProvAddress },
        { path: 'lossAppetiteMedProvPhone', nodeId: NodeIds.healthLossAppetiteMedProvPhone },
        { path: 'lossAppetiteHospitalVisit', nodeId: NodeIds.healthLossAppetiteHospitalVisit },
        { path: 'lossAppetiteHospitalVisitDate', nodeId: NodeIds.healthLossAppetiteHospitalVisitDate },
        { path: 'lossAppetiteHospitalName', nodeId: NodeIds.healthLossAppetiteHospitalName },
        { path: 'lossAppetiteHospitalAddress', nodeId: NodeIds.healthLossAppetiteHospitalAddress },
      ],
    },
    {
      path: 'malaise',
      children: [
        { path: 'malaiseDiagnosisDate', nodeId: NodeIds.healthMalaiseDiagnosisDate },
        { path: 'malaiseMedications', nodeId: NodeIds.healthMalaiseMedications },
        { path: 'malaiseAdditionalTreatment', nodeId: NodeIds.healthMalaiseAdditionalTreatment },
        { path: 'malaiseStatus', nodeId: NodeIds.healthMalaiseStatus },
        { path: 'malaiseDateOfFullRecovery', nodeId: NodeIds.healthMalaiseDateOfFullRecovery },
        { path: 'malaiseLimitationDetails', nodeId: NodeIds.healthMalaiseLimitationDetails },
        { path: 'malaiseCurrentlyExperienceSymptoms', nodeId: NodeIds.healthMalaiseCurrentlyExperienceSymptoms },
        { path: 'malaiseDateOfLastSymptom', nodeId: NodeIds.healthMalaiseDateOfLastSymptom },
        { path: 'malaiseDateOfLastOccurrence', nodeId: NodeIds.healthMalaiseDateOfLastOccurrence },
        { path: 'malaiseNumberOfOccurrences', nodeId: NodeIds.healthMalaiseNumberOfOccurrences },
        { path: 'malaiseCausePhysicalLimitations', nodeId: NodeIds.healthMalaiseCausePhysicalLimitations },
        { path: 'malaiseLimitationDetails2', nodeId: 'health-malaise-limitation-details-2' },
        { path: 'malaiseMedProv', nodeId: NodeIds.healthMalaiseMedProv },
        { path: 'malaiseMedProvName', nodeId: NodeIds.healthMalaiseMedProvName },
        { path: 'malaiseMedProvAddress', nodeId: NodeIds.healthMalaiseMedProvAddress },
        { path: 'malaiseMedProvPhone', nodeId: NodeIds.healthMalaiseMedProvPhone },
        { path: 'malaiseHospitalVisit', nodeId: NodeIds.healthMalaiseHospitalVisit },
        { path: 'malaiseHospitalVisitDate', nodeId: NodeIds.healthMalaiseHospitalVisitDate },
        { path: 'malaiseHospitalName', nodeId: NodeIds.healthMalaiseHospitalName },
        { path: 'malaiseHospitalAddress', nodeId: NodeIds.healthMalaiseHospitalAddress },
      ],
    },
    {
      path: 'unexplainedWeightLoss',
      children: [
        { path: 'unexplainedWeightLossDiagnosisDate', nodeId: NodeIds.healthUnexplainedWeightLossDiagnosisDate },
        { path: 'unexplainedWeightLossMedications', nodeId: NodeIds.healthUnexplainedWeightLossMedications },
        {
          path: 'unexplainedWeightLossAdditionalTreatment',
          nodeId: NodeIds.healthUnexplainedWeightLossAdditionalTreatment,
        },
        { path: 'unexplainedWeightLossStatus', nodeId: NodeIds.healthUnexplainedWeightLossStatus },
        {
          path: 'unexplainedWeightLossDateOfFullRecovery',
          nodeId: NodeIds.healthUnexplainedWeightLossDateOfFullRecovery,
        },
        {
          path: 'unexplainedWeightLossLimitationDetails',
          nodeId: NodeIds.healthUnexplainedWeightLossLimitationDetails,
        },
        {
          path: 'unexplainedWeightLossCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthUnexplainedWeightLossCurrentlyExperienceSymptoms,
        },
        {
          path: 'unexplainedWeightLossDateOfLastSymptom',
          nodeId: NodeIds.healthUnexplainedWeightLossDateOfLastSymptom,
        },
        {
          path: 'unexplainedWeightLossDateOfLastOccurrence',
          nodeId: NodeIds.healthUnexplainedWeightLossDateOfLastOccurrence,
        },
        {
          path: 'unexplainedWeightLossNumberOfOccurrences',
          nodeId: NodeIds.healthUnexplainedWeightLossNumberOfOccurrences,
        },
        {
          path: 'unexplainedWeightLossCausePhysicalLimitations',
          nodeId: NodeIds.healthUnexplainedWeightLossCausePhysicalLimitations,
        },
        {
          path: 'unexplainedWeightLossLimitationDetails2',
          nodeId: 'health-unexplained-weight-loss-limitation-details-2',
        },
        { path: 'unexplainedWeightLossMedProv', nodeId: NodeIds.healthUnexplainedWeightLossMedProv },
        { path: 'unexplainedWeightLossMedProvName', nodeId: NodeIds.healthUnexplainedWeightLossMedProvName },
        { path: 'unexplainedWeightLossMedProvAddress', nodeId: NodeIds.healthUnexplainedWeightLossMedProvAddress },
        { path: 'unexplainedWeightLossMedProvPhone', nodeId: NodeIds.healthUnexplainedWeightLossMedProvPhone },
        { path: 'unexplainedWeightLossHospitalVisit', nodeId: NodeIds.healthUnexplainedWeightLossHospitalVisit },
        {
          path: 'unexplainedWeightLossHospitalVisitDate',
          nodeId: NodeIds.healthUnexplainedWeightLossHospitalVisitDate,
        },
        { path: 'unexplainedWeightLossHospitalName', nodeId: NodeIds.healthUnexplainedWeightLossHospitalName },
        { path: 'unexplainedWeightLossHospitalAddress', nodeId: NodeIds.healthUnexplainedWeightLossHospitalAddress },
      ],
    },
    {
      path: 'fatigue',
      children: [
        { path: 'fatigueDiagnosisDate', nodeId: NodeIds.healthFatigueDiagnosisDate },
        { path: 'fatigueCause', nodeId: NodeIds.healthFatigueCause },
        { path: 'fatiguePhysician', nodeId: NodeIds.healthFatiguePhysician },
        { path: 'fatigueDateLastConsultation', nodeId: NodeIds.healthFatigueDateLastConsultation },
        { path: 'fatiguePhysicianVisitsFrequency', nodeId: NodeIds.healthFatiguePhysicianVisitsFrequency },
        { path: 'fatigueTreatment', nodeId: NodeIds.healthFatigueTreatment },
        { path: 'fatigueTreatmentDetails', nodeId: NodeIds.healthFatigueTreatmentDetails },
        { path: 'fatiguePsychotherapy', nodeId: NodeIds.healthFatiguePsychotherapy },
        { path: 'fatiguePsychotherapyDetails', nodeId: NodeIds.healthFatiguePsychotherapyDetails },
        { path: 'fatigueSymptoms', nodeId: NodeIds.healthFatigueSymptoms },
        { path: 'fatigueOffWork', nodeId: NodeIds.healthFatigueOffWork },
        { path: 'fatigueOffWorkDetails', nodeId: NodeIds.healthFatigueOffWorkDetails },
        { path: 'fatigueDetails', nodeId: NodeIds.healthFatigueDetails },
        { path: 'fatigueMedications', nodeId: NodeIds.healthFatigueMedications },
        { path: 'fatigueAdditionalTreatment', nodeId: NodeIds.healthFatigueAdditionalTreatment },
        { path: 'fatigueStatus', nodeId: NodeIds.healthFatigueStatus },
        { path: 'fatigueDateOfFullRecovery', nodeId: NodeIds.healthFatigueDateOfFullRecovery },
        { path: 'fatigueLimitationDetails', nodeId: NodeIds.healthFatigueLimitationDetails },
        { path: 'fatigueCurrentlyExperienceSymptoms', nodeId: NodeIds.healthFatigueCurrentlyExperienceSymptoms },
        { path: 'fatigueDateOfLastSymptom', nodeId: NodeIds.healthFatigueDateOfLastSymptom },
        { path: 'fatigueDateOfLastOccurrence', nodeId: NodeIds.healthFatigueDateOfLastOccurrence },
        { path: 'fatigueNumberOfOccurrences', nodeId: NodeIds.healthFatigueNumberOfOccurrences },
        { path: 'fatigueCausePhysicalLimitations', nodeId: NodeIds.healthFatigueCausePhysicalLimitations },
        { path: 'fatigueLimitationDetails2', nodeId: 'health-fatigue-limitation-details-2' },
        { path: 'fatigueMedProv', nodeId: NodeIds.healthFatigueMedProv },
        { path: 'fatigueMedProvName', nodeId: NodeIds.healthFatigueMedProvName },
        { path: 'fatigueMedProvAddress', nodeId: NodeIds.healthFatigueMedProvAddress },
        { path: 'fatigueMedProvPhone', nodeId: NodeIds.healthFatigueMedProvPhone },
        { path: 'fatigueHospitalVisit', nodeId: NodeIds.healthFatigueHospitalVisit },
        { path: 'fatigueHospitalVisitDate', nodeId: NodeIds.healthFatigueHospitalVisitDate },
        { path: 'fatigueHospitalName', nodeId: NodeIds.healthFatigueHospitalName },
        { path: 'fatigueHospitalAddress', nodeId: NodeIds.healthFatigueHospitalAddress },
      ],
    },
    {
      path: 'recurrentFever',
      children: [
        { path: 'recurrentFeverDiagnosisDate', nodeId: NodeIds.healthRecurrentFeverDiagnosisDate },
        { path: 'recurrentFeverMedications', nodeId: NodeIds.healthRecurrentFeverMedications },
        { path: 'recurrentFeverAdditionalTreatment', nodeId: NodeIds.healthRecurrentFeverAdditionalTreatment },
        { path: 'recurrentFeverStatus', nodeId: NodeIds.healthRecurrentFeverStatus },
        { path: 'recurrentFeverDateOfFullRecovery', nodeId: NodeIds.healthRecurrentFeverDateOfFullRecovery },
        { path: 'recurrentFeverLimitationDetails', nodeId: NodeIds.healthRecurrentFeverLimitationDetails },
        {
          path: 'recurrentFeverCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthRecurrentFeverCurrentlyExperienceSymptoms,
        },
        { path: 'recurrentFeverDateOfLastSymptom', nodeId: NodeIds.healthRecurrentFeverDateOfLastSymptom },
        { path: 'recurrentFeverDateOfLastOccurrence', nodeId: NodeIds.healthRecurrentFeverDateOfLastOccurrence },
        { path: 'recurrentFeverNumberOfOccurrences', nodeId: NodeIds.healthRecurrentFeverNumberOfOccurrences },
        {
          path: 'recurrentFeverCausePhysicalLimitations',
          nodeId: NodeIds.healthRecurrentFeverCausePhysicalLimitations,
        },
        { path: 'recurrentFeverLimitationDetails2', nodeId: 'health-recurrent-fever-limitation-details-2' },
        { path: 'recurrentFeverMedProv', nodeId: NodeIds.healthRecurrentFeverMedProv },
        { path: 'recurrentFeverMedProvName', nodeId: NodeIds.healthRecurrentFeverMedProvName },
        { path: 'recurrentFeverMedProvAddress', nodeId: NodeIds.healthRecurrentFeverMedProvAddress },
        { path: 'recurrentFeverMedProvPhone', nodeId: NodeIds.healthRecurrentFeverMedProvPhone },
        { path: 'recurrentFeverHospitalVisit', nodeId: NodeIds.healthRecurrentFeverHospitalVisit },
        { path: 'recurrentFeverHospitalVisitDate', nodeId: NodeIds.healthRecurrentFeverHospitalVisitDate },
        { path: 'recurrentFeverHospitalName', nodeId: NodeIds.healthRecurrentFeverHospitalName },
        { path: 'recurrentFeverHospitalAddress', nodeId: NodeIds.healthRecurrentFeverHospitalAddress },
      ],
    },
    {
      path: 'immuneDeficiency',
      children: [
        { path: 'immuneDeficiencySymptoms', nodeId: NodeIds.healthHasImmuneDeficiencySymptoms },
        { path: 'immuneDeficiencyChoices', nodeId: NodeIds.healthHasImmuneDeficiencyChoices },
        { path: 'immuneDeficiencyDiagnosisDate', nodeId: NodeIds.healthImmuneDeficiencyDiagnosisDate },
        { path: 'immuneDeficiencyMedications', nodeId: NodeIds.healthImmuneDeficiencyMedications },
        { path: 'immuneDeficiencyAdditionalTreatment', nodeId: NodeIds.healthImmuneDeficiencyAdditionalTreatment },
        { path: 'immuneDeficiencyStatus', nodeId: NodeIds.healthImmuneDeficiencyStatus },
        { path: 'immuneDeficiencyDateOfFullRecovery', nodeId: NodeIds.healthImmuneDeficiencyDateOfFullRecovery },
        { path: 'immuneDeficiencyLimitationDetails', nodeId: NodeIds.healthImmuneDeficiencyLimitationDetails },
        {
          path: 'immuneDeficiencyCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthImmuneDeficiencyCurrentlyExperienceSymptoms,
        },
        { path: 'immuneDeficiencyDateOfLastSymptom', nodeId: NodeIds.healthImmuneDeficiencyDateOfLastSymptom },
        { path: 'immuneDeficiencyDateOfLastOccurrence', nodeId: NodeIds.healthImmuneDeficiencyDateOfLastOccurrence },
        { path: 'immuneDeficiencyNumberOfOccurrences', nodeId: NodeIds.healthImmuneDeficiencyNumberOfOccurrences },
        {
          path: 'immuneDeficiencyCausePhysicalLimitations',
          nodeId: NodeIds.healthImmuneDeficiencyCausePhysicalLimitations,
        },
        { path: 'immuneDeficiencyLimitationDetails2', nodeId: 'health-immune-deficiency-limitation-details-2' },
        { path: 'immuneDeficiencyMedProv', nodeId: NodeIds.healthImmuneDeficiencyMedProv },
        { path: 'immuneDeficiencyMedProvName', nodeId: NodeIds.healthImmuneDeficiencyMedProvName },
        { path: 'immuneDeficiencyMedProvAddress', nodeId: NodeIds.healthImmuneDeficiencyMedProvAddress },
        { path: 'immuneDeficiencyMedProvPhone', nodeId: NodeIds.healthImmuneDeficiencyMedProvPhone },
        { path: 'immuneDeficiencyHospitalVisit', nodeId: NodeIds.healthImmuneDeficiencyHospitalVisit },
        { path: 'immuneDeficiencyHospitalVisitDate', nodeId: NodeIds.healthImmuneDeficiencyHospitalVisitDate },
        { path: 'immuneDeficiencyHospitalName', nodeId: NodeIds.healthImmuneDeficiencyHospitalName },
        { path: 'immuneDeficiencyHospitalAddress', nodeId: NodeIds.healthImmuneDeficiencyHospitalAddress },
      ],
    },
    {
      path: 'nsOtherDisease',
      children: [
        { path: 'nsOtherDiseaseDiagnosisDate', nodeId: NodeIds.healthNsOtherDiseaseDiagnosisDate },
        { path: 'nsOtherDiseaseMedications', nodeId: NodeIds.healthNsOtherDiseaseMedications },
        { path: 'nsOtherDiseaseAdditionalTreatment', nodeId: NodeIds.healthNsOtherDiseaseAdditionalTreatment },
        { path: 'nsOtherDiseaseStatus', nodeId: NodeIds.healthNsOtherDiseaseStatus },
        { path: 'nsOtherDiseaseDateOfFullRecovery', nodeId: NodeIds.healthNsOtherDiseaseDateOfFullRecovery },
        { path: 'nsOtherDiseaseLimitationDetails', nodeId: NodeIds.healthNsOtherDiseaseLimitationDetails },
        {
          path: 'nsOtherDiseaseCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthNsOtherDiseaseCurrentlyExperienceSymptoms,
        },
        { path: 'nsOtherDiseaseDateOfLastSymptom', nodeId: NodeIds.healthNsOtherDiseaseDateOfLastSymptom },
        { path: 'nsOtherDiseaseDateOfLastOccurrence', nodeId: NodeIds.healthNsOtherDiseaseDateOfLastOccurrence },
        { path: 'nsOtherDiseaseNumberOfOccurrences', nodeId: NodeIds.healthNsOtherDiseaseNumberOfOccurrences },
        {
          path: 'nsOtherDiseaseCausePhysicalLimitations',
          nodeId: NodeIds.healthNsOtherDiseaseCausePhysicalLimitations,
        },
        { path: 'nsOtherDiseaseLimitationDetails2', nodeId: 'health-ns-other-disease-limitation-details-2' },
        { path: 'nsOtherDiseaseMedProv', nodeId: NodeIds.healthNsOtherDiseaseMedProv },
        { path: 'nsOtherDiseaseMedProvName', nodeId: NodeIds.healthNsOtherDiseaseMedProvName },
        { path: 'nsOtherDiseaseMedProvAddress', nodeId: NodeIds.healthNsOtherDiseaseMedProvAddress },
        { path: 'nsOtherDiseaseMedProvPhone', nodeId: NodeIds.healthNsOtherDiseaseMedProvPhone },
        { path: 'nsOtherDiseaseHospitalVisit', nodeId: NodeIds.healthNsOtherDiseaseHospitalVisit },
        { path: 'nsOtherDiseaseHospitalVisitDate', nodeId: NodeIds.healthNsOtherDiseaseHospitalVisitDate },
        { path: 'nsOtherDiseaseHospitalName', nodeId: NodeIds.healthNsOtherDiseaseHospitalName },
        { path: 'nsOtherDiseaseHospitalAddress', nodeId: NodeIds.healthNsOtherDiseaseHospitalAddress },
      ],
    },
    {
      path: 'motorNeuronDisease',
      children: [
        { path: 'motorNeuronDiseaseDiagnosisDate', nodeId: NodeIds.healthMotorNeuronDiseaseDiagnosisDate },
        { path: 'motorNeuronDiseaseMedications', nodeId: NodeIds.healthMotorNeuronDiseaseMedications },
        { path: 'motorNeuronDiseaseAdditionalTreatment', nodeId: NodeIds.healthMotorNeuronDiseaseAdditionalTreatment },
        { path: 'motorNeuronDiseaseStatus', nodeId: NodeIds.healthMotorNeuronDiseaseStatus },
        { path: 'motorNeuronDiseaseDateOfFullRecovery', nodeId: NodeIds.healthMotorNeuronDiseaseDateOfFullRecovery },
        { path: 'motorNeuronDiseaseLimitationDetails', nodeId: NodeIds.healthMotorNeuronDiseaseLimitationDetails },
        {
          path: 'motorNeuronDiseaseCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthMotorNeuronDiseaseCurrentlyExperienceSymptoms,
        },
        { path: 'motorNeuronDiseaseDateOfLastSymptom', nodeId: NodeIds.healthMotorNeuronDiseaseDateOfLastSymptom },
        {
          path: 'motorNeuronDiseaseDateOfLastOccurrence',
          nodeId: NodeIds.healthMotorNeuronDiseaseDateOfLastOccurrence,
        },
        { path: 'motorNeuronDiseaseNumberOfOccurrences', nodeId: NodeIds.healthMotorNeuronDiseaseNumberOfOccurrences },
        {
          path: 'motorNeuronDiseaseCausePhysicalLimitations',
          nodeId: NodeIds.healthMotorNeuronDiseaseCausePhysicalLimitations,
        },
        { path: 'motorNeuronDiseaseLimitationDetails2', nodeId: 'health-motor-neuron-disease-limitation-details-2' },
        { path: 'motorNeuronDiseaseMedProv', nodeId: NodeIds.healthMotorNeuronDiseaseMedProv },
        { path: 'motorNeuronDiseaseMedProvName', nodeId: NodeIds.healthMotorNeuronDiseaseMedProvName },
        { path: 'motorNeuronDiseaseMedProvAddress', nodeId: NodeIds.healthMotorNeuronDiseaseMedProvAddress },
        { path: 'motorNeuronDiseaseMedProvPhone', nodeId: NodeIds.healthMotorNeuronDiseaseMedProvPhone },
        { path: 'motorNeuronDiseaseHospitalVisit', nodeId: NodeIds.healthMotorNeuronDiseaseHospitalVisit },
        { path: 'motorNeuronDiseaseHospitalVisitDate', nodeId: NodeIds.healthMotorNeuronDiseaseHospitalVisitDate },
        { path: 'motorNeuronDiseaseHospitalName', nodeId: NodeIds.healthMotorNeuronDiseaseHospitalName },
        { path: 'motorNeuronDiseaseHospitalAddress', nodeId: NodeIds.healthMotorNeuronDiseaseHospitalAddress },
      ],
    },
    {
      path: 'parkinson',
      children: [
        { path: 'parkinsonDiagnosisDate', nodeId: NodeIds.healthParkinsonDiagnosisDate },
        { path: 'parkinsonMedications', nodeId: NodeIds.healthParkinsonMedications },
        { path: 'parkinsonAdditionalTreatment', nodeId: NodeIds.healthParkinsonAdditionalTreatment },
        { path: 'parkinsonStatus', nodeId: NodeIds.healthParkinsonStatus },
        { path: 'parkinsonDateOfFullRecovery', nodeId: NodeIds.healthParkinsonDateOfFullRecovery },
        { path: 'parkinsonLimitationDetails', nodeId: NodeIds.healthParkinsonLimitationDetails },
        { path: 'parkinsonCurrentlyExperienceSymptoms', nodeId: NodeIds.healthParkinsonCurrentlyExperienceSymptoms },
        { path: 'parkinsonDateOfLastSymptom', nodeId: NodeIds.healthParkinsonDateOfLastSymptom },
        { path: 'parkinsonDateOfLastOccurrence', nodeId: NodeIds.healthParkinsonDateOfLastOccurrence },
        { path: 'parkinsonNumberOfOccurrences', nodeId: NodeIds.healthParkinsonNumberOfOccurrences },
        { path: 'parkinsonCausePhysicalLimitations', nodeId: NodeIds.healthParkinsonCausePhysicalLimitations },
        { path: 'parkinsonLimitationDetails2', nodeId: 'health-parkinson-limitation-details-2' },
        { path: 'parkinsonMedProv', nodeId: NodeIds.healthParkinsonMedProv },
        { path: 'parkinsonMedProvName', nodeId: NodeIds.healthParkinsonMedProvName },
        { path: 'parkinsonMedProvAddress', nodeId: NodeIds.healthParkinsonMedProvAddress },
        { path: 'parkinsonMedProvPhone', nodeId: NodeIds.healthParkinsonMedProvPhone },
        { path: 'parkinsonHospitalVisit', nodeId: NodeIds.healthParkinsonHospitalVisit },
        { path: 'parkinsonHospitalVisitDate', nodeId: NodeIds.healthParkinsonHospitalVisitDate },
        { path: 'parkinsonHospitalName', nodeId: NodeIds.healthParkinsonHospitalName },
        { path: 'parkinsonHospitalAddress', nodeId: NodeIds.healthParkinsonHospitalAddress },
      ],
    },
    {
      path: 'paralysis',
      children: [
        { path: 'paralysisDiagnosisDate', nodeId: NodeIds.healthParalysisDiagnosisDate },
        { path: 'paralysisMedications', nodeId: NodeIds.healthParalysisMedications },
        { path: 'paralysisAdditionalTreatment', nodeId: NodeIds.healthParalysisAdditionalTreatment },
        { path: 'paralysisStatus', nodeId: NodeIds.healthParalysisStatus },
        { path: 'paralysisDateOfFullRecovery', nodeId: NodeIds.healthParalysisDateOfFullRecovery },
        { path: 'paralysisLimitationDetails', nodeId: NodeIds.healthParalysisLimitationDetails },
        { path: 'paralysisCurrentlyExperienceSymptoms', nodeId: NodeIds.healthParalysisCurrentlyExperienceSymptoms },
        { path: 'paralysisDateOfLastSymptom', nodeId: NodeIds.healthParalysisDateOfLastSymptom },
        { path: 'paralysisDateOfLastOccurrence', nodeId: NodeIds.healthParalysisDateOfLastOccurrence },
        { path: 'paralysisNumberOfOccurrences', nodeId: NodeIds.healthParalysisNumberOfOccurrences },
        { path: 'paralysisCausePhysicalLimitations', nodeId: NodeIds.healthParalysisCausePhysicalLimitations },
        { path: 'paralysisLimitationDetails2', nodeId: 'health-paralysis-limitation-details-2' },
        { path: 'paralysisMedProv', nodeId: NodeIds.healthParalysisMedProv },
        { path: 'paralysisMedProvName', nodeId: NodeIds.healthParalysisMedProvName },
        { path: 'paralysisMedProvAddress', nodeId: NodeIds.healthParalysisMedProvAddress },
        { path: 'paralysisMedProvPhone', nodeId: NodeIds.healthParalysisMedProvPhone },
        { path: 'paralysisHospitalVisit', nodeId: NodeIds.healthParalysisHospitalVisit },
        { path: 'paralysisHospitalVisitDate', nodeId: NodeIds.healthParalysisHospitalVisitDate },
        { path: 'paralysisHospitalName', nodeId: NodeIds.healthParalysisHospitalName },
        { path: 'paralysisHospitalAddress', nodeId: NodeIds.healthParalysisHospitalAddress },
      ],
    },
    {
      path: 'multipleSclerosis',
      children: [
        { path: 'multipleSclerosisDiagnosisDate', nodeId: NodeIds.healthMultipleSclerosisDiagnosisDate },
        { path: 'multipleSclerosisMedications', nodeId: NodeIds.healthMultipleSclerosisMedications },
        { path: 'multipleSclerosisAdditionalTreatment', nodeId: NodeIds.healthMultipleSclerosisAdditionalTreatment },
        { path: 'multipleSclerosisStatus', nodeId: NodeIds.healthMultipleSclerosisStatus },
        { path: 'multipleSclerosisDateOfFullRecovery', nodeId: NodeIds.healthMultipleSclerosisDateOfFullRecovery },
        { path: 'multipleSclerosisLimitationDetails', nodeId: NodeIds.healthMultipleSclerosisLimitationDetails },
        {
          path: 'multipleSclerosisCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthMultipleSclerosisCurrentlyExperienceSymptoms,
        },
        { path: 'multipleSclerosisDateOfLastSymptom', nodeId: NodeIds.healthMultipleSclerosisDateOfLastSymptom },
        { path: 'multipleSclerosisDateOfLastOccurrence', nodeId: NodeIds.healthMultipleSclerosisDateOfLastOccurrence },
        { path: 'multipleSclerosisNumberOfOccurrences', nodeId: NodeIds.healthMultipleSclerosisNumberOfOccurrences },
        {
          path: 'multipleSclerosisCausePhysicalLimitations',
          nodeId: NodeIds.healthMultipleSclerosisCausePhysicalLimitations,
        },
        { path: 'multipleSclerosisLimitationDetails2', nodeId: 'health-multiple-sclerosis-limitation-details-2' },
        { path: 'multipleSclerosisMedProv', nodeId: NodeIds.healthMultipleSclerosisMedProv },
        { path: 'multipleSclerosisMedProvName', nodeId: NodeIds.healthMultipleSclerosisMedProvName },
        { path: 'multipleSclerosisMedProvAddress', nodeId: NodeIds.healthMultipleSclerosisMedProvAddress },
        { path: 'multipleSclerosisMedProvPhone', nodeId: NodeIds.healthMultipleSclerosisMedProvPhone },
        { path: 'multipleSclerosisHospitalVisit', nodeId: NodeIds.healthMultipleSclerosisHospitalVisit },
        { path: 'multipleSclerosisHospitalVisitDate', nodeId: NodeIds.healthMultipleSclerosisHospitalVisitDate },
        { path: 'multipleSclerosisHospitalName', nodeId: NodeIds.healthMultipleSclerosisHospitalName },
        { path: 'multipleSclerosisHospitalAddress', nodeId: NodeIds.healthMultipleSclerosisHospitalAddress },
      ],
    },
    {
      path: 'tremor',
      children: [
        { path: 'tremorDiagnosisDate', nodeId: NodeIds.healthTremorDiagnosisDate },
        { path: 'tremorMedications', nodeId: NodeIds.healthTremorMedications },
        { path: 'tremorAdditionalTreatment', nodeId: NodeIds.healthTremorAdditionalTreatment },
        { path: 'tremorStatus', nodeId: NodeIds.healthTremorStatus },
        { path: 'tremorDateOfFullRecovery', nodeId: NodeIds.healthTremorDateOfFullRecovery },
        { path: 'tremorLimitationDetails', nodeId: NodeIds.healthTremorLimitationDetails },
        { path: 'tremorCurrentlyExperienceSymptoms', nodeId: NodeIds.healthTremorCurrentlyExperienceSymptoms },
        { path: 'tremorDateOfLastSymptom', nodeId: NodeIds.healthTremorDateOfLastSymptom },
        { path: 'tremorDateOfLastOccurrence', nodeId: NodeIds.healthTremorDateOfLastOccurrence },
        { path: 'tremorNumberOfOccurrences', nodeId: NodeIds.healthTremorNumberOfOccurrences },
        { path: 'tremorCausePhysicalLimitations', nodeId: NodeIds.healthTremorCausePhysicalLimitations },
        { path: 'tremorLimitationDetails2', nodeId: 'health-tremor-limitation-details-2' },
        { path: 'tremorMedProv', nodeId: NodeIds.healthTremorMedProv },
        { path: 'tremorMedProvName', nodeId: NodeIds.healthTremorMedProvName },
        { path: 'tremorMedProvAddress', nodeId: NodeIds.healthTremorMedProvAddress },
        { path: 'tremorMedProvPhone', nodeId: NodeIds.healthTremorMedProvPhone },
        { path: 'tremorHospitalVisit', nodeId: NodeIds.healthTremorHospitalVisit },
        { path: 'tremorHospitalVisitDate', nodeId: NodeIds.healthTremorHospitalVisitDate },
        { path: 'tremorHospitalName', nodeId: NodeIds.healthTremorHospitalName },
        { path: 'tremorHospitalAddress', nodeId: NodeIds.healthTremorHospitalAddress },
      ],
    },
    {
      path: 'fainting',
      children: [
        { path: 'faintingDiagnosisDate', nodeId: NodeIds.healthFaintingDiagnosisDate },
        { path: 'faintingMedications', nodeId: NodeIds.healthFaintingMedications },
        { path: 'faintingAdditionalTreatment', nodeId: NodeIds.healthFaintingAdditionalTreatment },
        { path: 'faintingStatus', nodeId: NodeIds.healthFaintingStatus },
        { path: 'faintingDateOfFullRecovery', nodeId: NodeIds.healthFaintingDateOfFullRecovery },
        { path: 'faintingLimitationDetails', nodeId: NodeIds.healthFaintingLimitationDetails },
        { path: 'faintingCurrentlyExperienceSymptoms', nodeId: NodeIds.healthFaintingCurrentlyExperienceSymptoms },
        { path: 'faintingDateOfLastSymptom', nodeId: NodeIds.healthFaintingDateOfLastSymptom },
        { path: 'faintingDateOfLastOccurrence', nodeId: NodeIds.healthFaintingDateOfLastOccurrence },
        { path: 'faintingNumberOfOccurrences', nodeId: NodeIds.healthFaintingNumberOfOccurrences },
        { path: 'faintingCausePhysicalLimitations', nodeId: NodeIds.healthFaintingCausePhysicalLimitations },
        { path: 'faintingLimitationDetails2', nodeId: 'health-fainting-limitation-details-2' },
        { path: 'faintingMedProv', nodeId: NodeIds.healthFaintingMedProv },
        { path: 'faintingMedProvName', nodeId: NodeIds.healthFaintingMedProvName },
        { path: 'faintingMedProvAddress', nodeId: NodeIds.healthFaintingMedProvAddress },
        { path: 'faintingMedProvPhone', nodeId: NodeIds.healthFaintingMedProvPhone },
        { path: 'faintingHospitalVisit', nodeId: NodeIds.healthFaintingHospitalVisit },
        { path: 'faintingHospitalVisitDate', nodeId: NodeIds.healthFaintingHospitalVisitDate },
        { path: 'faintingHospitalName', nodeId: NodeIds.healthFaintingHospitalName },
        { path: 'faintingHospitalAddress', nodeId: NodeIds.healthFaintingHospitalAddress },
      ],
    },
    {
      path: 'seizure',
      children: [
        { path: 'seizureDiagnosisDate', nodeId: NodeIds.healthSeizureDiagnosisDate },
        { path: 'seizureMedications', nodeId: NodeIds.healthSeizureMedications },
        { path: 'seizureAdditionalTreatment', nodeId: NodeIds.healthSeizureAdditionalTreatment },
        { path: 'seizureFirstDate', nodeId: NodeIds.healthSeizureFirstDate },
        { path: 'seizureOccur', nodeId: NodeIds.healthSeizureOccur },
        { path: 'seizureLastDate', nodeId: NodeIds.healthSeizureLastDate },
        { path: 'seizureType', nodeId: NodeIds.healthSeizureType },
        { path: 'seizureOther', nodeId: NodeIds.healthSeizureOther },
        { path: 'seizureStatus', nodeId: NodeIds.healthSeizureStatus },
        { path: 'seizureDateOfFullRecovery', nodeId: NodeIds.healthSeizureDateOfFullRecovery },
        { path: 'seizureLimitationDetails', nodeId: NodeIds.healthSeizureLimitationDetails },
        { path: 'seizureCurrentlyExperienceSymptoms', nodeId: NodeIds.healthSeizureCurrentlyExperienceSymptoms },
        { path: 'seizureDateOfLastSymptom', nodeId: NodeIds.healthSeizureDateOfLastSymptom },
        { path: 'seizureDateOfLastOccurrence', nodeId: NodeIds.healthSeizureDateOfLastOccurrence },
        { path: 'seizureNumberOfOccurrences', nodeId: NodeIds.healthSeizureNumberOfOccurrences },
        { path: 'seizureCausePhysicalLimitations', nodeId: NodeIds.healthSeizureCausePhysicalLimitations },
        { path: 'seizureLimitationDetails2', nodeId: 'health-seizure-limitation-details-2' },
        { path: 'seizureMedProv', nodeId: NodeIds.healthSeizureMedProv },
        { path: 'seizureMedProvName', nodeId: NodeIds.healthSeizureMedProvName },
        { path: 'seizureMedProvAddress', nodeId: NodeIds.healthSeizureMedProvAddress },
        { path: 'seizureMedProvPhone', nodeId: NodeIds.healthSeizureMedProvPhone },
        { path: 'seizureMedicationNumber', nodeId: NodeIds.healthSeizureMedicationNumber },
        { path: 'seizureMedicationName', nodeId: NodeIds.healthSeizureMedicationName },
        { path: 'seizureHospitalVisit', nodeId: NodeIds.healthSeizureHospitalVisit },
        { path: 'seizureHospitalVisitDate', nodeId: NodeIds.healthSeizureHospitalVisitDate },
        { path: 'seizureHospitalName', nodeId: NodeIds.healthSeizureHospitalName },
        { path: 'seizureHospitalAddress', nodeId: NodeIds.healthSeizureHospitalAddress },
        { path: 'seizureEmergencyRoomVisit', nodeId: NodeIds.healthSeizureEmergencyRoomVisit },
        { path: 'seizureEmergencyRoomDate', nodeId: NodeIds.healthSeizureEmergencyRoomDate },
        { path: 'seizureEmergencyRoomName', nodeId: NodeIds.healthSeizureEmergencyRoomName },
        { path: 'seizureEmergencyRoomAddress', nodeId: NodeIds.healthSeizureEmergencyRoomAddress },
      ],
    },
    {
      path: 'injurySkin',
      children: [
        { path: 'injurySkinDiagnosisDate', nodeId: NodeIds.healthInjurySkinDiagnosisDate },
        { path: 'injurySkinMedications', nodeId: NodeIds.healthInjurySkinMedications },
        { path: 'injurySkinAdditionalTreatment', nodeId: NodeIds.healthInjurySkinAdditionalTreatment },
        { path: 'injurySkinStatus', nodeId: NodeIds.healthInjurySkinStatus },
        { path: 'injurySkinDateOfFullRecovery', nodeId: NodeIds.healthInjurySkinDateOfFullRecovery },
        { path: 'injurySkinLimitationDetails', nodeId: NodeIds.healthInjurySkinLimitationDetails },
        { path: 'injurySkinCurrentlyExperienceSymptoms', nodeId: NodeIds.healthInjurySkinCurrentlyExperienceSymptoms },
        { path: 'injurySkinDateOfLastSymptom', nodeId: NodeIds.healthInjurySkinDateOfLastSymptom },
        { path: 'injurySkinDateOfLastOccurrence', nodeId: NodeIds.healthInjurySkinDateOfLastOccurrence },
        { path: 'injurySkinNumberOfOccurrences', nodeId: NodeIds.healthInjurySkinNumberOfOccurrences },
        { path: 'injurySkinCausePhysicalLimitations', nodeId: NodeIds.healthInjurySkinCausePhysicalLimitations },
        { path: 'injurySkinLimitationDetails2', nodeId: 'health-injury-skin-limitation-details-2' },
        { path: 'injurySkinMedProv', nodeId: NodeIds.healthInjurySkinMedProv },
        { path: 'injurySkinMedProvName', nodeId: NodeIds.healthInjurySkinMedProvName },
        { path: 'injurySkinMedProvAddress', nodeId: NodeIds.healthInjurySkinMedProvAddress },
        { path: 'injurySkinMedProvPhone', nodeId: NodeIds.healthInjurySkinMedProvPhone },
        { path: 'injurySkinHospitalVisit', nodeId: NodeIds.healthInjurySkinHospitalVisit },
        { path: 'injurySkinHospitalVisitDate', nodeId: NodeIds.healthInjurySkinHospitalVisitDate },
        { path: 'injurySkinHospitalName', nodeId: NodeIds.healthInjurySkinHospitalName },
        { path: 'injurySkinHospitalAddress', nodeId: NodeIds.healthInjurySkinHospitalAddress },
      ],
    },
    {
      path: 'injuryEyes',
      children: [
        { path: 'injuryEyesDiagnosisDate', nodeId: NodeIds.healthInjuryEyesDiagnosisDate },
        { path: 'injuryEyesMedications', nodeId: NodeIds.healthInjuryEyesMedications },
        { path: 'injuryEyesAdditionalTreatment', nodeId: NodeIds.healthInjuryEyesAdditionalTreatment },
        { path: 'injuryEyesStatus', nodeId: NodeIds.healthInjuryEyesStatus },
        { path: 'injuryEyesDateOfFullRecovery', nodeId: NodeIds.healthInjuryEyesDateOfFullRecovery },
        { path: 'injuryEyesLimitationDetails', nodeId: NodeIds.healthInjuryEyesLimitationDetails },
        { path: 'injuryEyesCurrentlyExperienceSymptoms', nodeId: NodeIds.healthInjuryEyesCurrentlyExperienceSymptoms },
        { path: 'injuryEyesDateOfLastSymptom', nodeId: NodeIds.healthInjuryEyesDateOfLastSymptom },
        { path: 'injuryEyesDateOfLastOccurrence', nodeId: NodeIds.healthInjuryEyesDateOfLastOccurrence },
        { path: 'injuryEyesNumberOfOccurrences', nodeId: NodeIds.healthInjuryEyesNumberOfOccurrences },
        { path: 'injuryEyesCausePhysicalLimitations', nodeId: NodeIds.healthInjuryEyesCausePhysicalLimitations },
        { path: 'injuryEyesLimitationDetails2', nodeId: 'health-injury-eyes-limitation-details-2' },
        { path: 'injuryEyesMedProv', nodeId: NodeIds.healthInjuryEyesMedProv },
        { path: 'injuryEyesMedProvName', nodeId: NodeIds.healthInjuryEyesMedProvName },
        { path: 'injuryEyesMedProvAddress', nodeId: NodeIds.healthInjuryEyesMedProvAddress },
        { path: 'injuryEyesMedProvPhone', nodeId: NodeIds.healthInjuryEyesMedProvPhone },
        { path: 'injuryEyesHospitalVisit', nodeId: NodeIds.healthInjuryEyesHospitalVisit },
        { path: 'injuryEyesHospitalVisitDate', nodeId: NodeIds.healthInjuryEyesHospitalVisitDate },
        { path: 'injuryEyesHospitalName', nodeId: NodeIds.healthInjuryEyesHospitalName },
        { path: 'injuryEyesHospitalAddress', nodeId: NodeIds.healthInjuryEyesHospitalAddress },
      ],
    },
    {
      path: 'injuryJoint',
      children: [
        { path: 'injuryJointDiagnosisDate', nodeId: NodeIds.healthInjuryJointDiagnosisDate },
        { path: 'injuryJointMedications', nodeId: NodeIds.healthInjuryJointMedications },
        { path: 'injuryJointAdditionalTreatment', nodeId: NodeIds.healthInjuryJointAdditionalTreatment },
        { path: 'injuryJointStatus', nodeId: NodeIds.healthInjuryJointStatus },
        { path: 'injuryJointDateOfFullRecovery', nodeId: NodeIds.healthInjuryJointDateOfFullRecovery },
        { path: 'injuryJointLimitationDetails', nodeId: NodeIds.healthInjuryJointLimitationDetails },
        {
          path: 'injuryJointCurrentlyExperienceSymptoms',
          nodeId: NodeIds.healthInjuryJointCurrentlyExperienceSymptoms,
        },
        { path: 'injuryJointDateOfLastSymptom', nodeId: NodeIds.healthInjuryJointDateOfLastSymptom },
        { path: 'injuryJointDateOfLastOccurrence', nodeId: NodeIds.healthInjuryJointDateOfLastOccurrence },
        { path: 'injuryJointNumberOfOccurrences', nodeId: NodeIds.healthInjuryJointNumberOfOccurrences },
        { path: 'injuryJointCausePhysicalLimitations', nodeId: NodeIds.healthInjuryJointCausePhysicalLimitations },
        { path: 'injuryJointLimitationDetails2', nodeId: 'health-injury-joint-limitation-details-2' },
        { path: 'injuryJointMedProv', nodeId: NodeIds.healthInjuryJointMedProv },
        { path: 'injuryJointMedProvName', nodeId: NodeIds.healthInjuryJointMedProvName },
        { path: 'injuryJointMedProvAddress', nodeId: NodeIds.healthInjuryJointMedProvAddress },
        { path: 'injuryJointMedProvPhone', nodeId: NodeIds.healthInjuryJointMedProvPhone },
        { path: 'injuryJointHospitalVisit', nodeId: NodeIds.healthInjuryJointHospitalVisit },
        { path: 'injuryJointHospitalVisitDate', nodeId: NodeIds.healthInjuryJointHospitalVisitDate },
        { path: 'injuryJointHospitalName', nodeId: NodeIds.healthInjuryJointHospitalName },
        { path: 'injuryJointHospitalAddress', nodeId: NodeIds.healthInjuryJointHospitalAddress },
      ],
    },
    {
      path: 'cerebrovascular',
      children: [
        { path: 'hadEvent', nodeId: NodeIds.healthCerebrovascularEvent },
        { path: 'moreThanOneEvent', nodeId: NodeIds.healthCerebrovascularMoreThanOneEvent },
        { path: 'occurredLessThan8YearsAgo', nodeId: 'health-cerebrovascular-occurred-less-than-8-years-ago' },
      ],
    },
    {
      path: 'cardiovascular',
      children: [
        { path: 'hadEvent', nodeId: NodeIds.healthCardiovascularEvent },
        {
          path: 'cerebrovascularAccident',
          children: [{ path: 'date', nodeId: NodeIds.healthCerebrovascularAccident }],
        },
        {
          path: 'transientIschemiaAttack',
          children: [{ path: 'date', nodeId: NodeIds.healthTransientIschemiaAttackDate }],
        },
        { path: 'heartSurgery', children: [{ path: 'date', nodeId: NodeIds.healthHeartSurgeryDate }] },
        { path: 'heartAttack', children: [{ path: 'date', nodeId: NodeIds.healthHeartAttackDate }] },
        {
          path: 'heartMurmur',
          children: [
            { path: 'hadSymptoms', nodeId: NodeIds.healthCardiovascularHeartMurmurSymptoms },
            {
              path: 'heartMurmurDiagnosedInChildHood',
              nodeId: NodeIds.healthCardiovascularHeartMurmurDiagnosedInChildHood,
            },
            { path: 'heartMurmurStillPresent', nodeId: NodeIds.healthCardiovascularHeartMurmurStillPresent },
          ],
        },
        {
          path: 'chestPain',
          children: [
            { path: 'hadSymptoms', nodeId: NodeIds.healthCardiovascularChestPainSymptoms },
            { path: 'chestPainInvestigated', nodeId: NodeIds.healthCardiovascularChestPainInvestigated },
            { path: 'chestPainHeartRelated', nodeId: NodeIds.healthCardiovascularChestPainHeartRelated },
            { path: 'chestPainDiagnosisDate', nodeId: NodeIds.healthChestPainDiagnosisDate },
            { path: 'chestPainMedications', nodeId: NodeIds.healthChestPainMedications },
            { path: 'chestPainAdditionalTreatment', nodeId: NodeIds.healthChestPainAdditionalTreatment },
            { path: 'chestPainStatus', nodeId: NodeIds.healthChestPainStatus },
            { path: 'chestPainDateOfFullRecovery', nodeId: NodeIds.healthChestPainDateOfFullRecovery },
            { path: 'chestPainLimitationDetails', nodeId: NodeIds.healthChestPainLimitationDetails },
            {
              path: 'chestPainCurrentlyExperienceSymptoms',
              nodeId: NodeIds.healthChestPainCurrentlyExperienceSymptoms,
            },
            { path: 'chestPainDateOfLastSymptom', nodeId: NodeIds.healthChestPainDateOfLastSymptom },
            { path: 'chestPainDateOfLastOccurrence', nodeId: NodeIds.healthChestPainDateOfLastOccurrence },
            { path: 'chestPainNumberOfOccurrences', nodeId: NodeIds.healthChestPainNumberOfOccurrences },
            { path: 'chestPainCausePhysicalLimitations', nodeId: NodeIds.healthChestPainCausePhysicalLimitations },
            { path: 'chestPainLimitationDetails2', nodeId: 'health-chest-pain-limitation-details-2' },
            { path: 'chestPainMedProv', nodeId: NodeIds.healthChestPainMedProv },
            { path: 'chestPainMedProvName', nodeId: NodeIds.healthChestPainMedProvName },
            { path: 'chestPainMedProvAddress', nodeId: NodeIds.healthChestPainMedProvAddress },
            { path: 'chestPainMedProvPhone', nodeId: NodeIds.healthChestPainMedProvPhone },
            { path: 'chestPainHospitalVisit', nodeId: NodeIds.healthChestPainHospitalVisit },
            { path: 'chestPainHospitalVisitDate', nodeId: NodeIds.healthChestPainHospitalVisitDate },
            { path: 'chestPainHospitalName', nodeId: NodeIds.healthChestPainHospitalName },
            { path: 'chestPainHospitalAddress', nodeId: NodeIds.healthChestPainHospitalAddress },
          ],
        },
      ],
    },
    {
      path: 'thyroid',
      children: [
        { path: 'hadCancerSymptoms', nodeId: NodeIds.healthThyroidCancerSymptoms },
        { path: 'cancerPast10Years', nodeId: 'health-thyroid-cancer-past-10-years' },
        { path: 'hadTreatments', nodeId: NodeIds.healthThyroidTreatments },
        { path: 'thyroidDiagnosisDate', nodeId: NodeIds.healthThyroidDiagnosisDate },
        { path: 'thyroidMedications', nodeId: NodeIds.healthThyroidMedications },
        { path: 'thyroidAdditionalTreatment', nodeId: NodeIds.healthThyroidAdditionalTreatment },
        { path: 'thyroidStatus', nodeId: NodeIds.healthThyroidStatus },
        { path: 'thyroidDateOfFullRecovery', nodeId: NodeIds.healthThyroidDateOfFullRecovery },
        { path: 'thyroidLimitationDetails', nodeId: NodeIds.healthThyroidLimitationDetails },
        { path: 'thyroidCurrentlyExperienceSymptoms', nodeId: NodeIds.healthThyroidCurrentlyExperienceSymptoms },
        { path: 'thyroidDateOfLastSymptom', nodeId: NodeIds.healthThyroidDateOfLastSymptom },
        { path: 'thyroidDateOfLastOccurrence', nodeId: NodeIds.healthThyroidDateOfLastOccurrence },
        { path: 'thyroidNumberOfOccurrences', nodeId: NodeIds.healthThyroidNumberOfOccurrences },
        { path: 'thyroidCausePhysicalLimitations', nodeId: NodeIds.healthThyroidCausePhysicalLimitations },
        { path: 'thyroidLimitationDetails2', nodeId: 'health-thyroid-limitation-details-2' },
        { path: 'thyroidMedProv', nodeId: NodeIds.healthThyroidMedProv },
        { path: 'thyroidMedProvName', nodeId: NodeIds.healthThyroidMedProvName },
        { path: 'thyroidMedProvAddress', nodeId: NodeIds.healthThyroidMedProvAddress },
        { path: 'thyroidMedProvPhone', nodeId: NodeIds.healthThyroidMedProvPhone },
        { path: 'thyroidHospitalVisit', nodeId: NodeIds.healthThyroidHospitalVisit },
        { path: 'thyroidHospitalVisitDate', nodeId: NodeIds.healthThyroidHospitalVisitDate },
        { path: 'thyroidHospitalName', nodeId: NodeIds.healthThyroidHospitalName },
        { path: 'thyroidHospitalAddress', nodeId: NodeIds.healthThyroidHospitalAddress },
      ],
    },
    {
      path: 'cancer',
      children: [
        { path: 'hadCancerSymptoms', nodeId: NodeIds.healthCancerSymptoms },
        {
          path: 'breastOrOvarian',
          children: [
            { path: 'hasFamilyHistory', nodeId: NodeIds.healthBreastOrOvarianCancerHasFamilyHistory },
            { path: 'recentTests', nodeId: NodeIds.healthBreastOrOvarianCancerRecentTests },
            { path: 'followUp', nodeId: NodeIds.healthBreastOrOvarianCancerFollowUp },
            { path: 'testsOnHold', nodeId: NodeIds.healthBreastOrOvarianCancerTestsOnHold },
          ],
        },
        {
          path: 'prostate',
          children: [
            { path: 'hasFamilyHistory', nodeId: NodeIds.healthProstateCancerFamilyHistory },
            { path: 'recentTests', nodeId: NodeIds.healthProstateCancerRecentTests },
            { path: 'followUp', nodeId: NodeIds.healthProstateCancerFollowUp },
            { path: 'testsOnHold', nodeId: NodeIds.healthProstateCancerTestsOnHold },
          ],
        },
      ],
    },
    {
      path: 'diabetes',
      children: [
        { path: 'hadType1Symptoms', nodeId: 'health-diabetes-type-1-symptoms' },
        { path: 'hadType2Symptoms', nodeId: 'health-diabetes-type-2-symptoms' },
        { path: 'diagnosedOver15YearsAgo', nodeId: 'health-diabetes-diagnosed-over-15-years-ago' },
        { path: 'followupFrequency', nodeId: NodeIds.healthDiabetesFollowupFrequency },
        { path: 'medicationChangedPast6Months', nodeId: 'health-diabetes-medication-changed-past-6-months' },
        { path: 'complications', nodeId: NodeIds.healthDiabetesComplications },
        {
          path: 'type2',
          children: [
            { path: 'diagnosedOver15YearsAgo', nodeId: 'health-diabetes-type-2-diagnosed-over-15-years-ago' },
            { path: 'followupFrequency', nodeId: 'health-diabetes-type-2-followup-frequency' },
            { path: 'medicationChangedRecently', nodeId: 'health-diabetes-type-2-medication-changed-recently' },
            { path: 'complications', nodeId: 'health-diabetes-type-2-complications' },
          ],
        },
        { path: 'endocrine', nodeId: NodeIds.healthEndocrine },
        { path: 'endocrineChoice', nodeId: NodeIds.healthEndocrineChoice },
        { path: 'diabetesType', nodeId: NodeIds.healthDiabetesType },
        { path: 'diabetesDateHba1c', nodeId: 'health-diabetes-date-HbA1C' },
        { path: 'diabetesResultHba1c', nodeId: 'health-diabetes-result-HbA1C' },
        { path: 'diabetesComplicationsChoice', nodeId: NodeIds.healthDiabetesComplicationsChoice },
        { path: 'diabetesDiagnosisDate', nodeId: NodeIds.healthDiabetesDiagnosisDate },
        { path: 'diabetesMedications', nodeId: NodeIds.healthDiabetesMedications },
        { path: 'diabetesAdditionalTreatment', nodeId: NodeIds.healthDiabetesAdditionalTreatment },
        { path: 'diabetesStatus', nodeId: NodeIds.healthDiabetesStatus },
        { path: 'diabetesDateOfFullRecovery', nodeId: NodeIds.healthDiabetesDateOfFullRecovery },
        { path: 'diabetesLimitationDetails', nodeId: NodeIds.healthDiabetesLimitationDetails },
        { path: 'diabetesCurrentlyExperienceSymptoms', nodeId: NodeIds.healthDiabetesCurrentlyExperienceSymptoms },
        { path: 'diabetesDateOfLastSymptom', nodeId: NodeIds.healthDiabetesDateOfLastSymptom },
        { path: 'diabetesDateOfLastOccurrence', nodeId: NodeIds.healthDiabetesDateOfLastOccurrence },
        { path: 'diabetesNumberOfOccurrences', nodeId: NodeIds.healthDiabetesNumberOfOccurrences },
        { path: 'diabetesCausePhysicalLimitations', nodeId: NodeIds.healthDiabetesCausePhysicalLimitations },
        { path: 'diabetesLimitationDetails2', nodeId: 'health-diabetes-limitation-details-2' },
        { path: 'diabetesMedProv', nodeId: NodeIds.healthDiabetesMedProv },
        { path: 'diabetesMedProvName', nodeId: NodeIds.healthDiabetesMedProvName },
        { path: 'diabetesMedProvAddress', nodeId: NodeIds.healthDiabetesMedProvAddress },
        { path: 'diabetesMedProvPhone', nodeId: NodeIds.healthDiabetesMedProvPhone },
        { path: 'diabetesHospitalVisit', nodeId: NodeIds.healthDiabetesHospitalVisit },
        { path: 'diabetesHospitalVisitDate', nodeId: NodeIds.healthDiabetesHospitalVisitDate },
        { path: 'diabetesHospitalName', nodeId: NodeIds.healthDiabetesHospitalName },
        { path: 'diabetesHospitalAddress', nodeId: NodeIds.healthDiabetesHospitalAddress },
      ],
    },
    {
      path: 'hepatitis',
      children: [
        { path: 'hadSymptoms', nodeId: NodeIds.healthHepatitisSymptoms },
        { path: 'chronicKidneyDiseaseSymptoms', nodeId: NodeIds.healthHepatitisChronicKidneyDiseaseSymptoms },
        { path: 'hepatitisType', nodeId: NodeIds.healthHepatitisType },
        { path: 'hepatitisPresent', nodeId: NodeIds.healthHepatitisPresent },
        { path: 'hepatitisDiagnosisDate', nodeId: NodeIds.healthHepatitisDiagnosisDate },
        { path: 'hepatitisLiverFunctionTest', nodeId: NodeIds.healthHepatitisLiverFunctionTest },
        { path: 'insufficiency', children: [{ path: 'date', nodeId: NodeIds.healthHepatitisInsufficiencyDate }] },
      ],
    },
    {
      path: 'musculoskeletal',
      children: [
        { path: 'hadSymptoms', nodeId: NodeIds.healthMusculoskeletalSymptoms },
        { path: 'arthritis', nodeId: NodeIds.healthMusculoskeletalArthritis },
        { path: 'arthritisControlled', nodeId: NodeIds.healthMusculoskeletalArthritisControlled },
      ],
    },
    { path: 'eyeOrSkin', children: [{ path: 'hadSymptoms', nodeId: NodeIds.healthEyeOrSkinSymptoms }] },
    { path: 'glands', children: [{ path: 'hadSymptoms', nodeId: NodeIds.healthGlandSymptoms }] },
    {
      path: 'digestiveOrEndocrine',
      children: [
        { path: 'hadSymptoms', nodeId: NodeIds.healthDigestiveOrEndocrineSymptoms },
        { path: 'status', nodeId: NodeIds.healthDigestiveOrEndocrineStatus },
        {
          path: 'gastroesophagalRefluxDisease',
          children: [
            { path: 'hadSymptoms', nodeId: NodeIds.healthGastroesophagalRefluxDiseaseSymptoms },
            { path: 'anyComplications', nodeId: NodeIds.healthGastroesophagalRefluxDiseaseAnyComplications },
          ],
        },
      ],
    },
    {
      path: 'immuneSystem',
      children: [
        { path: 'hadSymptoms', nodeId: NodeIds.healthImmuneSystemSymptoms },
        { path: 'hadHivTest', nodeId: NodeIds.healthImmuneSystemHadHivTest },
        { path: 'hivTestNegative', nodeId: NodeIds.healthImmuneSystemHivTestNegative },
      ],
    },
    {
      path: 'genitourinary',
      children: [
        { path: 'hadConsulatedOrTreated', nodeId: NodeIds.healthGenitourinaryConsultedOrTreated },
        { path: 'status', nodeId: NodeIds.healthGenitourinaryStatus },
        { path: 'hadSymptoms', nodeId: NodeIds.healthGenitourinarySymptoms },
        { path: 'kidneyStones', nodeId: NodeIds.healthGenitourinaryKidneyStones },
        { path: 'kidneyStonesControlled', nodeId: NodeIds.healthGenitourinaryKidneyStonesControlled },
      ],
    },
    {
      path: 'anemia',
      children: [
        { path: 'hadSymptoms', nodeId: NodeIds.healthAnemiaSymptoms },
        { path: 'anemiaDiagnosisDate', nodeId: NodeIds.healthAnemiaDiagnosisDate },
        { path: 'anemiaMedications', nodeId: NodeIds.healthAnemiaMedications },
        { path: 'anemiaAdditionalTreatment', nodeId: NodeIds.healthAnemiaAdditionalTreatment },
        { path: 'anemiaStatus', nodeId: NodeIds.healthAnemiaStatus },
        { path: 'anemiaDateOfFullRecovery', nodeId: NodeIds.healthAnemiaDateOfFullRecovery },
        { path: 'anemiaLimitationDetails', nodeId: NodeIds.healthAnemiaLimitationDetails },
        { path: 'anemiaCurrentlyExperienceSymptoms', nodeId: NodeIds.healthAnemiaCurrentlyExperienceSymptoms },
        { path: 'anemiaDateOfLastSymptom', nodeId: NodeIds.healthAnemiaDateOfLastSymptom },
        { path: 'anemiaDateOfLastOccurrence', nodeId: NodeIds.healthAnemiaDateOfLastOccurrence },
        { path: 'anemiaNumberOfOccurrences', nodeId: NodeIds.healthAnemiaNumberOfOccurrences },
        { path: 'anemiaCausePhysicalLimitations', nodeId: NodeIds.healthAnemiaCausePhysicalLimitations },
        { path: 'anemiaLimitationDetails2', nodeId: 'health-anemia-limitation-details-2' },
        { path: 'anemiaMedProv', nodeId: NodeIds.healthAnemiaMedProv },
        { path: 'anemiaMedProvName', nodeId: NodeIds.healthAnemiaMedProvName },
        { path: 'anemiaMedProvAddress', nodeId: NodeIds.healthAnemiaMedProvAddress },
        { path: 'anemiaMedProvPhone', nodeId: NodeIds.healthAnemiaMedProvPhone },
        { path: 'anemiaHospitalVisit', nodeId: NodeIds.healthAnemiaHospitalVisit },
        { path: 'anemiaHospitalVisitDate', nodeId: NodeIds.healthAnemiaHospitalVisitDate },
        { path: 'anemiaHospitalName', nodeId: NodeIds.healthAnemiaHospitalName },
        { path: 'anemiaHospitalAddress', nodeId: NodeIds.healthAnemiaHospitalAddress },
      ],
    },
    {
      path: 'anemia2',
      children: [
        { path: 'anemia2DiagnosisDate', nodeId: 'health-anemia-2-diagnosis-date' },
        { path: 'anemia2Medications', nodeId: 'health-anemia-2-medications' },
        { path: 'anemia2AdditionalTreatment', nodeId: 'health-anemia-2-additional-treatment' },
        { path: 'anemia2Status', nodeId: 'health-anemia-2-status' },
        { path: 'anemia2DateOfFullRecovery', nodeId: 'health-anemia-2-date-of-full-recovery' },
        { path: 'anemia2LimitationDetails', nodeId: 'health-anemia-2-limitation-details' },
        { path: 'anemia2CurrentlyExperienceSymptoms', nodeId: 'health-anemia-2-currently-experience-symptoms' },
        { path: 'anemia2DateOfLastSymptom', nodeId: 'health-anemia-2-date-of-last-symptom' },
        { path: 'anemia2DateOfLastOccurrence', nodeId: 'health-anemia-2-date-of-last-occurrence' },
        { path: 'anemia2NumberOfOccurrences', nodeId: 'health-anemia-2-number-of-occurrences' },
        { path: 'anemia2CausePhysicalLimitations', nodeId: 'health-anemia-2-cause-physical-limitations' },
        { path: 'anemia2LimitationDetails2', nodeId: 'health-anemia-2-limitation-details-2' },
        { path: 'anemia2MedProvider', nodeId: 'health-anemia-2-med-provider' },
        { path: 'anemia2MedProviderName', nodeId: 'health-anemia-2-med-provider-name' },
        { path: 'anemia2MedProviderAddress', nodeId: 'health-anemia-2-med-provider-address' },
        { path: 'anemia2MedProviderPhone', nodeId: 'health-anemia-2-med-provider-phone' },
        { path: 'anemia2HospitalVisit', nodeId: 'health-anemia-2-hospital-visit' },
        { path: 'anemia2HospitalVisitDate', nodeId: 'health-anemia-2-hospital-visit-date' },
        { path: 'anemia2HospitalName', nodeId: 'health-anemia-2-hospital-name' },
        { path: 'anemia2HospitalAddress', nodeId: 'health-anemia-2-hospital-address' },
      ],
    },
    {
      path: 'mentalHealth',
      children: [
        { path: 'mentalHealthDiagnosis', nodeId: NodeIds.healthMentalHealthDiagnosis },
        { path: 'mentalHealthChoices', nodeId: NodeIds.healthMentalHealthChoices },
        { path: 'hadSymptoms', nodeId: NodeIds.healthMentalHealthSymptoms },
        { path: 'status', nodeId: NodeIds.healthMentalHealthStatus },
        { path: 'absentWork', nodeId: NodeIds.healthMentalHealthAbsentWork },
        { path: 'absentWorkDepression', nodeId: NodeIds.healthMentalHealthAbsentWorkDepression },
        { path: 'mentalNervousDisorderChoice', nodeId: NodeIds.healthMentalNervousDisorderChoice },
        {
          path: 'suicidalThoughts',
          children: [
            { path: 'diagnosisDate', nodeId: NodeIds.healthSuicidalThoughtsDiagnosisDate },
            { path: 'cause', nodeId: NodeIds.healthSuicidalThoughtsCause },
            { path: 'physician', nodeId: NodeIds.healthSuicidalThoughtsPhysician },
            { path: 'dateLastConsultation', nodeId: NodeIds.healthSuicidalThoughtsDateLastConsultation },
            { path: 'physicianVisitsFrequency', nodeId: NodeIds.healthSuicidalThoughtsPhysicianVisitsFrequency },
            { path: 'hospitalVisit', nodeId: NodeIds.healthSuicidalThoughtsHospitalVisit },
            { path: 'hospitalVisitDate', nodeId: NodeIds.healthSuicidalThoughtsHospitalVisitDate },
            { path: 'hospitalName', nodeId: NodeIds.healthSuicidalThoughtsHospitalName },
            { path: 'hospitalAddress', nodeId: NodeIds.healthSuicidalThoughtsHospitalAddress },
            { path: 'treatment', nodeId: NodeIds.healthSuicidalThoughtsTreatment },
            { path: 'treatmentDetails', nodeId: NodeIds.healthSuicidalThoughtsTreatmentDetails },
            { path: 'psychotherapy', nodeId: NodeIds.healthSuicidalThoughtsPsychotherapy },
            { path: 'psychotherapyDetails', nodeId: NodeIds.healthSuicidalThoughtsPsychotherapyDetails },
            { path: 'symptoms', nodeId: NodeIds.healthSuicidalThoughtsSymptoms },
            { path: 'offWork', nodeId: NodeIds.healthSuicidalThoughtsOffWork },
            { path: 'offWorkDetails', nodeId: NodeIds.healthSuicidalThoughtsOffWorkDetails },
            { path: 'details', nodeId: NodeIds.healthSuicidalThoughtsDetails },
          ],
        },
        {
          path: 'stress',
          children: [
            { path: 'diagnosisDate', nodeId: NodeIds.healthStressDiagnosisDate },
            { path: 'cause', nodeId: NodeIds.healthStressCause },
            { path: 'physician', nodeId: NodeIds.healthStressPhysician },
            { path: 'dateLastConsultation', nodeId: NodeIds.healthStressDateLastConsultation },
            { path: 'physicianVisitsFrequency', nodeId: NodeIds.healthStressPhysicianVisitsFrequency },
            { path: 'hospitalVisit', nodeId: NodeIds.healthStressHospitalVisit },
            { path: 'hospitalVisitDate', nodeId: NodeIds.healthStressHospitalVisitDate },
            { path: 'hospitalName', nodeId: NodeIds.healthStressHospitalName },
            { path: 'hospitalAddress', nodeId: NodeIds.healthStressHospitalAddress },
            { path: 'treatment', nodeId: NodeIds.healthStressTreatment },
            { path: 'treatmentDetails', nodeId: NodeIds.healthStressTreatmentDetails },
            { path: 'psychotherapy', nodeId: NodeIds.healthStressPsychotherapy },
            { path: 'psychotherapyDetails', nodeId: NodeIds.healthStressPsychotherapyDetails },
            { path: 'symptoms', nodeId: NodeIds.healthStressSymptoms },
            { path: 'offWork', nodeId: NodeIds.healthStressOffWork },
            { path: 'offWorkDetails', nodeId: NodeIds.healthStressOffWorkDetails },
            { path: 'details', nodeId: NodeIds.healthStressDetails },
          ],
        },
        {
          path: 'nerves',
          children: [
            { path: 'diagnosisDate', nodeId: NodeIds.healthNervesDiagnosisDate },
            { path: 'cause', nodeId: NodeIds.healthNervesCause },
            { path: 'physician', nodeId: NodeIds.healthNervesPhysician },
            { path: 'dateLastConsultation', nodeId: NodeIds.healthNervesDateLastConsultation },
            { path: 'physicianVisitsFrequency', nodeId: NodeIds.healthNervesPhysicianVisitsFrequency },
            { path: 'hospitalVisit', nodeId: NodeIds.healthNervesHospitalVisit },
            { path: 'hospitalVisitDate', nodeId: NodeIds.healthNervesHospitalVisitDate },
            { path: 'hospitalName', nodeId: NodeIds.healthNervesHospitalName },
            { path: 'hospitalAddress', nodeId: NodeIds.healthNervesHospitalAddress },
            { path: 'treatment', nodeId: NodeIds.healthNervesTreatment },
            { path: 'treatmentDetails', nodeId: NodeIds.healthNervesTreatmentDetails },
            { path: 'psychotherapy', nodeId: NodeIds.healthNervesPsychotherapy },
            { path: 'psychotherapyDetails', nodeId: NodeIds.healthNervesPsychotherapyDetails },
            { path: 'symptoms', nodeId: NodeIds.healthNervesSymptoms },
            { path: 'offWork', nodeId: NodeIds.healthNervesOffWork },
            { path: 'offWorkDetails', nodeId: NodeIds.healthNervesOffWorkDetails },
            { path: 'details', nodeId: NodeIds.healthNervesDetails },
          ],
        },
        {
          path: 'attentionDeficitHyperactivityDisorder',
          children: [
            { path: 'diagnosisDate', nodeId: NodeIds.healthAddAdhdDiagnosisDate },
            { path: 'cause', nodeId: NodeIds.healthAddAdhdCause },
            { path: 'physician', nodeId: NodeIds.healthAddAdhdPhysician },
            { path: 'dateLastConsultation', nodeId: NodeIds.healthAddAdhdDateLastConsultation },
            { path: 'physicianVisitsFrequency', nodeId: NodeIds.healthAddAdhdPhysicianVisitsFrequency },
            { path: 'hospitalVisit', nodeId: NodeIds.healthAddAdhdHospitalVisit },
            { path: 'hospitalVisitDate', nodeId: NodeIds.healthAddAdhdHospitalVisitDate },
            { path: 'hospitalName', nodeId: NodeIds.healthAddAdhdHospitalName },
            { path: 'hospitalAddress', nodeId: NodeIds.healthAddAdhdHospitalAddress },
            { path: 'treatment', nodeId: NodeIds.healthAddAdhdTreatment },
            { path: 'treatmentDetails', nodeId: NodeIds.healthAddAdhdTreatmentDetails },
            { path: 'psychotherapy', nodeId: NodeIds.healthAddAdhdPsychotherapy },
            { path: 'psychotherapyDetails', nodeId: NodeIds.healthAddAdhdPsychotherapyDetails },
            { path: 'symptoms', nodeId: NodeIds.healthAddAdhdSymptoms },
            { path: 'offWork', nodeId: NodeIds.healthAddAdhdOffWork },
            { path: 'offWorkDetails', nodeId: NodeIds.healthAddAdhdOffWorkDetails },
            { path: 'details', nodeId: NodeIds.healthAddAdhdDetails },
          ],
        },
        {
          path: 'nervous',
          children: [
            { path: 'alcoholType', nodeId: NodeIds.healthNervousAlcoholType },
            { path: 'alcoholFrequency', nodeId: NodeIds.healthNervousAlcoholFrequency },
            { path: 'alocholQuantity', nodeId: NodeIds.healthNervousAlocholQuantity },
            { path: 'treatmentAlcohol', nodeId: NodeIds.healthNervousTreatmentAlcohol },
            { path: 'treatmentDrug', nodeId: NodeIds.healthNervousTreatmentDrug },
          ],
        },
        {
          path: 'schizophrenia',
          children: [
            { path: 'date', nodeId: NodeIds.healthMentalHealthSchizophreniaDate },
            { path: 'schizophreniaDiagnosisDate', nodeId: NodeIds.healthSchizophreniaDiagnosisDate },
            { path: 'schizophreniaMedications', nodeId: NodeIds.healthSchizophreniaMedications },
            { path: 'schizophreniaAdditionalTreatment', nodeId: NodeIds.healthSchizophreniaAdditionalTreatment },
            { path: 'schizophreniaStatus', nodeId: NodeIds.healthSchizophreniaStatus },
            { path: 'schizophreniaDateOfFullRecovery', nodeId: NodeIds.healthSchizophreniaDateOfFullRecovery },
            { path: 'schizophreniaLimitationDetails', nodeId: NodeIds.healthSchizophreniaLimitationDetails },
            {
              path: 'schizophreniaCurrentlyExperienceSymptoms',
              nodeId: NodeIds.healthSchizophreniaCurrentlyExperienceSymptoms,
            },
            { path: 'schizophreniaDateOfLastSymptom', nodeId: NodeIds.healthSchizophreniaDateOfLastSymptom },
            { path: 'schizophreniaDateOfLastOccurrence', nodeId: NodeIds.healthSchizophreniaDateOfLastOccurrence },
            { path: 'schizophreniaNumberOfOccurrences', nodeId: NodeIds.healthSchizophreniaNumberOfOccurrences },
            {
              path: 'schizophreniaCausePhysicalLimitations',
              nodeId: NodeIds.healthSchizophreniaCausePhysicalLimitations,
            },
            { path: 'schizophreniaLimitationDetails2', nodeId: 'health-schizophrenia-limitation-details-2' },
            { path: 'schizophreniaMedProv', nodeId: NodeIds.healthSchizophreniaMedProv },
            { path: 'schizophreniaMedProvName', nodeId: NodeIds.healthSchizophreniaMedProvName },
            { path: 'schizophreniaMedProvAddress', nodeId: NodeIds.healthSchizophreniaMedProvAddress },
            { path: 'schizophreniaMedProvPhone', nodeId: NodeIds.healthSchizophreniaMedProvPhone },
            { path: 'schizophreniaHospitalVisit', nodeId: NodeIds.healthSchizophreniaHospitalVisit },
            { path: 'schizophreniaHospitalVisitDate', nodeId: NodeIds.healthSchizophreniaHospitalVisitDate },
            { path: 'schizophreniaHospitalName', nodeId: NodeIds.healthSchizophreniaHospitalName },
            { path: 'schizophreniaHospitalAddress', nodeId: NodeIds.healthSchizophreniaHospitalAddress },
            { path: 'schizophreniaHospitalVisitDays', nodeId: NodeIds.healthSchizophreniaHospitalVisitDays },
            { path: 'schizophreniaDaysOffWork', nodeId: NodeIds.healthSchizophreniaDaysOffWork },
          ],
        },
        { path: 'psychosis', children: [{ path: 'date', nodeId: NodeIds.healthMentalHealthPsychosisDate }] },
        {
          path: 'anxiety',
          children: [
            { path: 'anxietyDiagnosisDate', nodeId: NodeIds.healthAnxietyDiagnosisDate },
            { path: 'anxietyCause', nodeId: NodeIds.healthAnxietyCause },
            { path: 'anxietyPhysician', nodeId: NodeIds.healthAnxietyPhysician },
            { path: 'anxietyDateLastConsultation', nodeId: NodeIds.healthAnxietyDateLastConsultation },
            { path: 'anxietyPhysicianVisitsFrequency', nodeId: NodeIds.healthAnxietyPhysicianVisitsFrequency },
            { path: 'anxietyTreatment', nodeId: NodeIds.healthAnxietyTreatment },
            { path: 'anxietyTreatmentDetails', nodeId: NodeIds.healthAnxietyTreatmentDetails },
            { path: 'anxietyPsychotherapy', nodeId: NodeIds.healthAnxietyPsychotherapy },
            { path: 'anxietyPsychotherapyDetails', nodeId: NodeIds.healthAnxietyPsychotherapyDetails },
            { path: 'anxietySymptoms', nodeId: NodeIds.healthAnxietySymptoms },
            { path: 'anxietyOffWork', nodeId: NodeIds.healthAnxietyOffWork },
            { path: 'anxietyOffWorkDetails', nodeId: NodeIds.healthAnxietyOffWorkDetails },
            { path: 'anxietyDetails', nodeId: NodeIds.healthAnxietyDetails },
            { path: 'anxietyMedications', nodeId: NodeIds.healthAnxietyMedications },
            { path: 'anxietyAdditionalTreatment', nodeId: NodeIds.healthAnxietyAdditionalTreatment },
            { path: 'anxietyStatus', nodeId: NodeIds.healthAnxietyStatus },
            { path: 'anxietyDateOfFullRecovery', nodeId: NodeIds.healthAnxietyDateOfFullRecovery },
            { path: 'anxietyLimitationDetails', nodeId: NodeIds.healthAnxietyLimitationDetails },
            { path: 'anxietyCurrentlyExperienceSymptoms', nodeId: NodeIds.healthAnxietyCurrentlyExperienceSymptoms },
            { path: 'anxietyDateOfLastSymptom', nodeId: NodeIds.healthAnxietyDateOfLastSymptom },
            { path: 'anxietyDateOfLastOccurrence', nodeId: NodeIds.healthAnxietyDateOfLastOccurrence },
            { path: 'anxietyNumberOfOccurrences', nodeId: NodeIds.healthAnxietyNumberOfOccurrences },
            { path: 'anxietyCausePhysicalLimitations', nodeId: NodeIds.healthAnxietyCausePhysicalLimitations },
            { path: 'anxietyLimitationDetails2', nodeId: 'health-anxiety-limitation-details-2' },
            { path: 'anxietyMedProv', nodeId: NodeIds.healthAnxietyMedProv },
            { path: 'anxietyMedProvName', nodeId: NodeIds.healthAnxietyMedProvName },
            { path: 'anxietyMedProvAddress', nodeId: NodeIds.healthAnxietyMedProvAddress },
            { path: 'anxietyMedProvPhone', nodeId: NodeIds.healthAnxietyMedProvPhone },
            { path: 'anxietyHospitalVisit', nodeId: NodeIds.healthAnxietyHospitalVisit },
            { path: 'anxietyHospitalVisitDate', nodeId: NodeIds.healthAnxietyHospitalVisitDate },
            { path: 'anxietyHospitalName', nodeId: NodeIds.healthAnxietyHospitalName },
            { path: 'anxietyHospitalAddress', nodeId: NodeIds.healthAnxietyHospitalAddress },
            { path: 'anxietyHospitalVisitDays', nodeId: NodeIds.healthAnxietyHospitalVisitDays },
            { path: 'anxietyDaysOffWork', nodeId: NodeIds.healthAnxietyDaysOffWork },
          ],
        },
        {
          path: 'burnoutInfo',
          children: [
            { path: 'numberOfOccurrence', nodeId: NodeIds.healthBurnoutNumberOfOccurrence },
            { path: 'dateOfLastOccurrence', nodeId: NodeIds.healthBurnoutDateOfLastOccurrence },
            { path: 'medications', nodeId: NodeIds.healthBurnoutMedications },
            { path: 'causePhysicalLimitations', nodeId: NodeIds.healthBurnoutCausePhysicalLimitations },
            { path: 'limitationDetails', nodeId: NodeIds.healthBurnoutLimitationDetails },
          ],
        },
        {
          path: 'insomniaInfo',
          children: [
            { path: 'medication', nodeId: NodeIds.healthInsominaMedication },
            { path: 'medicationAmount', nodeId: NodeIds.healthInsominaMedicationAmount },
            { path: 'diagnosisDate', nodeId: NodeIds.healthInsomniaDiagnosisDate },
            { path: 'cause', nodeId: NodeIds.healthInsomniaCause },
            { path: 'physician', nodeId: NodeIds.healthInsomniaPhysician },
            { path: 'dateLastConsultation', nodeId: NodeIds.healthInsomniaDateLastConsultation },
            { path: 'physicianVisitsFrequency', nodeId: NodeIds.healthInsomniaPhysicianVisitsFrequency },
            { path: 'hospitalVisit', nodeId: NodeIds.healthInsomniaHospitalVisit },
            { path: 'hospitalVisitDate', nodeId: NodeIds.healthInsomniaHospitalVisitDate },
            { path: 'hospitalName', nodeId: NodeIds.healthInsomniaHospitalName },
            { path: 'hospitalAddress', nodeId: NodeIds.healthInsomniaHospitalAddress },
            { path: 'treatment', nodeId: NodeIds.healthInsomniaTreatment },
            { path: 'treatmentDetails', nodeId: NodeIds.healthInsomniaTreatmentDetails },
            { path: 'psychotherapy', nodeId: NodeIds.healthInsomniaPsychotherapy },
            { path: 'psychotherapyDetails', nodeId: NodeIds.healthInsomniaPsychotherapyDetails },
            { path: 'symptoms', nodeId: NodeIds.healthInsomniaSymptoms },
            { path: 'offWork', nodeId: NodeIds.healthInsomniaOffWork },
            { path: 'offWorkDetails', nodeId: NodeIds.healthInsomniaOffWorkDetails },
            { path: 'details', nodeId: NodeIds.healthInsomniaDetails },
          ],
        },
        { path: 'suicidal', children: [{ path: 'date', nodeId: NodeIds.healthMentalHealthSuicidalDate }] },
        {
          path: 'nervousDisorder',
          children: [
            { path: 'nervousDisorderDiagnosisDate', nodeId: NodeIds.healthNervousDisorderDiagnosisDate },
            { path: 'nervousDisorderMedications', nodeId: NodeIds.healthNervousDisorderMedications },
            { path: 'nervousDisorderAdditionalTreatment', nodeId: NodeIds.healthNervousDisorderAdditionalTreatment },
            { path: 'nervousDisorderStatus', nodeId: NodeIds.healthNervousDisorderStatus },
            { path: 'nervousDisorderDateOfFullRecovery', nodeId: NodeIds.healthNervousDisorderDateOfFullRecovery },
            { path: 'nervousDisorderLimitationDetails', nodeId: NodeIds.healthNervousDisorderLimitationDetails },
            {
              path: 'nervousDisorderCurrentlyExperienceSymptoms',
              nodeId: NodeIds.healthNervousDisorderCurrentlyExperienceSymptoms,
            },
            { path: 'nervousDisorderDateOfLastSymptom', nodeId: NodeIds.healthNervousDisorderDateOfLastSymptom },
            { path: 'nervousDisorderDateOfLastOccurrence', nodeId: NodeIds.healthNervousDisorderDateOfLastOccurrence },
            { path: 'nervousDisorderNumberOfOccurrences', nodeId: NodeIds.healthNervousDisorderNumberOfOccurrences },
            {
              path: 'nervousDisorderCausePhysicalLimitations',
              nodeId: NodeIds.healthNervousDisorderCausePhysicalLimitations,
            },
            { path: 'nervousDisorderLimitationDetails2', nodeId: 'health-nervous-disorder-limitation-details-2' },
            { path: 'nervousDisorderMedProv', nodeId: NodeIds.healthNervousDisorderMedProv },
            { path: 'nervousDisorderMedProvName', nodeId: NodeIds.healthNervousDisorderMedProvName },
            { path: 'nervousDisorderMedProvAddress', nodeId: NodeIds.healthNervousDisorderMedProvAddress },
            { path: 'nervousDisorderMedProvPhone', nodeId: NodeIds.healthNervousDisorderMedProvPhone },
            { path: 'nervousDisorderHospitalVisit', nodeId: NodeIds.healthNervousDisorderHospitalVisit },
            { path: 'nervousDisorderHospitalVisitDate', nodeId: NodeIds.healthNervousDisorderHospitalVisitDate },
            { path: 'nervousDisorderHospitalName', nodeId: NodeIds.healthNervousDisorderHospitalName },
            { path: 'nervousDisorderHospitalAddress', nodeId: NodeIds.healthNervousDisorderHospitalAddress },
            { path: 'nervousDisorderHospitalVisitDays', nodeId: NodeIds.healthNervousDisorderHospitalVisitDays },
            { path: 'nervousDisorderDaysOffWork', nodeId: NodeIds.healthNervousDisorderDaysOffWork },
          ],
        },
        {
          path: 'behavioralDisorder',
          children: [
            { path: 'behavioralDisorderDiagnosisDate', nodeId: NodeIds.healthBehavioralDisorderDiagnosisDate },
            { path: 'behavioralDisorderMedications', nodeId: NodeIds.healthBehavioralDisorderMedications },
            {
              path: 'behavioralDisorderAdditionalTreatment',
              nodeId: NodeIds.healthBehavioralDisorderAdditionalTreatment,
            },
            { path: 'behavioralDisorderStatus', nodeId: NodeIds.healthBehavioralDisorderStatus },
            {
              path: 'behavioralDisorderDateOfFullRecovery',
              nodeId: NodeIds.healthBehavioralDisorderDateOfFullRecovery,
            },
            { path: 'behavioralDisorderLimitationDetails', nodeId: NodeIds.healthBehavioralDisorderLimitationDetails },
            {
              path: 'behavioralDisorderCurrentlyExperienceSymptoms',
              nodeId: NodeIds.healthBehavioralDisorderCurrentlyExperienceSymptoms,
            },
            { path: 'behavioralDisorderDateOfLastSymptom', nodeId: NodeIds.healthBehavioralDisorderDateOfLastSymptom },
            {
              path: 'behavioralDisorderDateOfLastOccurrence',
              nodeId: NodeIds.healthBehavioralDisorderDateOfLastOccurrence,
            },
            {
              path: 'behavioralDisorderNumberOfOccurrences',
              nodeId: NodeIds.healthBehavioralDisorderNumberOfOccurrences,
            },
            {
              path: 'behavioralDisorderCausePhysicalLimitations',
              nodeId: NodeIds.healthBehavioralDisorderCausePhysicalLimitations,
            },
            { path: 'behavioralDisorderLimitationDetails2', nodeId: 'health-behavioral-disorder-limitation-details-2' },
            { path: 'behavioralDisorderMedProv', nodeId: NodeIds.healthBehavioralDisorderMedProv },
            { path: 'behavioralDisorderMedProvName', nodeId: NodeIds.healthBehavioralDisorderMedProvName },
            { path: 'behavioralDisorderMedProvAddress', nodeId: NodeIds.healthBehavioralDisorderMedProvAddress },
            { path: 'behavioralDisorderMedProvPhone', nodeId: NodeIds.healthBehavioralDisorderMedProvPhone },
            { path: 'behavioralDisorderHospitalVisit', nodeId: NodeIds.healthBehavioralDisorderHospitalVisit },
            { path: 'behavioralDisorderHospitalVisitDate', nodeId: NodeIds.healthBehavioralDisorderHospitalVisitDate },
            { path: 'behavioralDisorderHospitalName', nodeId: NodeIds.healthBehavioralDisorderHospitalName },
            { path: 'behavioralDisorderHospitalAddress', nodeId: NodeIds.healthBehavioralDisorderHospitalAddress },
            { path: 'behavioralDisorderHospitalVisitDays', nodeId: NodeIds.healthBehavioralDisorderHospitalVisitDays },
            { path: 'behavioralDisorderDaysOffWork', nodeId: NodeIds.healthBehavioralDisorderDaysOffWork },
          ],
        },
        {
          path: 'emotionalDisorder',
          children: [
            { path: 'emotionalDisorderDiagnosisDate', nodeId: NodeIds.healthEmotionalDisorderDiagnosisDate },
            { path: 'emotionalDisorderMedications', nodeId: NodeIds.healthEmotionalDisorderMedications },
            {
              path: 'emotionalDisorderAdditionalTreatment',
              nodeId: NodeIds.healthEmotionalDisorderAdditionalTreatment,
            },
            { path: 'emotionalDisorderStatus', nodeId: NodeIds.healthEmotionalDisorderStatus },
            { path: 'emotionalDisorderDateOfFullRecovery', nodeId: NodeIds.healthEmotionalDisorderDateOfFullRecovery },
            { path: 'emotionalDisorderLimitationDetails', nodeId: NodeIds.healthEmotionalDisorderLimitationDetails },
            {
              path: 'emotionalDisorderCurrentlyExperienceSymptoms',
              nodeId: NodeIds.healthEmotionalDisorderCurrentlyExperienceSymptoms,
            },
            { path: 'emotionalDisorderDateOfLastSymptom', nodeId: NodeIds.healthEmotionalDisorderDateOfLastSymptom },
            {
              path: 'emotionalDisorderDateOfLastOccurrence',
              nodeId: NodeIds.healthEmotionalDisorderDateOfLastOccurrence,
            },
            {
              path: 'emotionalDisorderNumberOfOccurrences',
              nodeId: NodeIds.healthEmotionalDisorderNumberOfOccurrences,
            },
            {
              path: 'emotionalDisorderCausePhysicalLimitations',
              nodeId: NodeIds.healthEmotionalDisorderCausePhysicalLimitations,
            },
            { path: 'emotionalDisorderLimitationDetails2', nodeId: 'health-emotional-disorder-limitation-details-2' },
            { path: 'emotionalDisorderMedProv', nodeId: NodeIds.healthEmotionalDisorderMedProv },
            { path: 'emotionalDisorderMedProvName', nodeId: NodeIds.healthEmotionalDisorderMedProvName },
            { path: 'emotionalDisorderMedProvAddress', nodeId: NodeIds.healthEmotionalDisorderMedProvAddress },
            { path: 'emotionalDisorderMedProvPhone', nodeId: NodeIds.healthEmotionalDisorderMedProvPhone },
            { path: 'emotionalDisorderHospitalVisit', nodeId: NodeIds.healthEmotionalDisorderHospitalVisit },
            { path: 'emotionalDisorderHospitalVisitDate', nodeId: NodeIds.healthEmotionalDisorderHospitalVisitDate },
            { path: 'emotionalDisorderHospitalName', nodeId: NodeIds.healthEmotionalDisorderHospitalName },
            { path: 'emotionalDisorderHospitalAddress', nodeId: NodeIds.healthEmotionalDisorderHospitalAddress },
            { path: 'emotionalDisorderHospitalVisitDays', nodeId: NodeIds.healthEmotionalDisorderHospitalVisitDays },
            { path: 'emotionalDisorderDaysOffWork', nodeId: NodeIds.healthEmotionalDisorderDaysOffWork },
          ],
        },
        {
          path: 'ptsd',
          children: [
            { path: 'ptsdDiagnosisDate', nodeId: NodeIds.healthPtsdDiagnosisDate },
            { path: 'ptsdCause', nodeId: NodeIds.healthPtsdCause },
            { path: 'ptsdPhysician', nodeId: NodeIds.healthPtsdPhysician },
            { path: 'ptsdDateLastConsultation', nodeId: NodeIds.healthPtsdDateLastConsultation },
            { path: 'ptsdPhysicianVisitsFrequency', nodeId: NodeIds.healthPtsdPhysicianVisitsFrequency },
            { path: 'ptsdTreatment', nodeId: NodeIds.healthPtsdTreatment },
            { path: 'ptsdTreatmentDetails', nodeId: NodeIds.healthPtsdTreatmentDetails },
            { path: 'ptsdPsychotherapy', nodeId: NodeIds.healthPtsdPsychotherapy },
            { path: 'ptsdPsychotherapyDetails', nodeId: NodeIds.healthPtsdPsychotherapyDetails },
            { path: 'ptsdSymptoms', nodeId: NodeIds.healthPtsdSymptoms },
            { path: 'ptsdOffWork', nodeId: NodeIds.healthPtsdOffWork },
            { path: 'ptsdOffWorkDetails', nodeId: NodeIds.healthPtsdOffWorkDetails },
            { path: 'ptsdDetails', nodeId: NodeIds.healthPtsdDetails },
            { path: 'ptsdMedications', nodeId: NodeIds.healthPtsdMedications },
            { path: 'ptsdAdditionalTreatment', nodeId: NodeIds.healthPtsdAdditionalTreatment },
            { path: 'ptsdStatus', nodeId: NodeIds.healthPtsdStatus },
            { path: 'ptsdDateOfFullRecovery', nodeId: NodeIds.healthPtsdDateOfFullRecovery },
            { path: 'ptsdLimitationDetails', nodeId: NodeIds.healthPtsdLimitationDetails },
            { path: 'ptsdCurrentlyExperienceSymptoms', nodeId: NodeIds.healthPtsdCurrentlyExperienceSymptoms },
            { path: 'ptsdDateOfLastSymptom', nodeId: NodeIds.healthPtsdDateOfLastSymptom },
            { path: 'ptsdDateOfLastOccurrence', nodeId: NodeIds.healthPtsdDateOfLastOccurrence },
            { path: 'ptsdNumberOfOccurrences', nodeId: NodeIds.healthPtsdNumberOfOccurrences },
            { path: 'ptsdCausePhysicalLimitations', nodeId: NodeIds.healthPtsdCausePhysicalLimitations },
            { path: 'ptsdLimitationDetails2', nodeId: 'health-ptsd-limitation-details-2' },
            { path: 'ptsdMedProv', nodeId: NodeIds.healthPtsdMedProv },
            { path: 'ptsdMedProvName', nodeId: NodeIds.healthPtsdMedProvName },
            { path: 'ptsdMedProvAddress', nodeId: NodeIds.healthPtsdMedProvAddress },
            { path: 'ptsdMedProvPhone', nodeId: NodeIds.healthPtsdMedProvPhone },
            { path: 'ptsdHospitalVisit', nodeId: NodeIds.healthPtsdHospitalVisit },
            { path: 'ptsdHospitalVisitDate', nodeId: NodeIds.healthPtsdHospitalVisitDate },
            { path: 'ptsdHospitalName', nodeId: NodeIds.healthPtsdHospitalName },
            { path: 'ptsdHospitalAddress', nodeId: NodeIds.healthPtsdHospitalAddress },
            { path: 'ptsdHospitalVisitDays', nodeId: NodeIds.healthPtsdHospitalVisitDays },
            { path: 'ptsdDaysOffWork', nodeId: NodeIds.healthPtsdDaysOffWork },
          ],
        },
        {
          path: 'depression',
          children: [
            { path: 'depressionDiagnosisDate', nodeId: NodeIds.healthDepressionDiagnosisDate },
            { path: 'depressionCause', nodeId: NodeIds.healthDepressionCause },
            { path: 'depressionPhysician', nodeId: NodeIds.healthDepressionPhysician },
            { path: 'depressionDateLastConsultation', nodeId: NodeIds.healthDepressionDateLastConsultation },
            { path: 'depressionPhysicianVisitsFrequency', nodeId: NodeIds.healthDepressionPhysicianVisitsFrequency },
            { path: 'depressionTreatment', nodeId: NodeIds.healthDepressionTreatment },
            { path: 'depressionTreatmentDetails', nodeId: NodeIds.healthDepressionTreatmentDetails },
            { path: 'depressionPsychotherapy', nodeId: NodeIds.healthDepressionPsychotherapy },
            { path: 'depressionPsychotherapyDetails', nodeId: NodeIds.healthDepressionPsychotherapyDetails },
            { path: 'depressionSymptoms', nodeId: NodeIds.healthDepressionSymptoms },
            { path: 'depressionOffWork', nodeId: NodeIds.healthDepressionOffWork },
            { path: 'depressionOffWorkDetails', nodeId: NodeIds.healthDepressionOffWorkDetails },
            { path: 'depressionDetails', nodeId: NodeIds.healthDepressionDetails },
            { path: 'depressionMedications', nodeId: NodeIds.healthDepressionMedications },
            { path: 'depressionAdditionalTreatment', nodeId: NodeIds.healthDepressionAdditionalTreatment },
            { path: 'depressionStatus', nodeId: NodeIds.healthDepressionStatus },
            { path: 'depressionDateOfFullRecovery', nodeId: NodeIds.healthDepressionDateOfFullRecovery },
            { path: 'depressionLimitationDetails', nodeId: NodeIds.healthDepressionLimitationDetails },
            {
              path: 'depressionCurrentlyExperienceSymptoms',
              nodeId: NodeIds.healthDepressionCurrentlyExperienceSymptoms,
            },
            { path: 'depressionDateOfLastSymptom', nodeId: NodeIds.healthDepressionDateOfLastSymptom },
            { path: 'depressionDateOfLastOccurrence', nodeId: NodeIds.healthDepressionDateOfLastOccurrence },
            { path: 'depressionNumberOfOccurrences', nodeId: NodeIds.healthDepressionNumberOfOccurrences },
            { path: 'depressionCausePhysicalLimitations', nodeId: NodeIds.healthDepressionCausePhysicalLimitations },
            { path: 'depressionLimitationDetails2', nodeId: 'health-depression-limitation-details-2' },
            { path: 'depressionMedProv', nodeId: NodeIds.healthDepressionMedProv },
            { path: 'depressionMedProvName', nodeId: NodeIds.healthDepressionMedProvName },
            { path: 'depressionMedProvA ddress', nodeId: NodeIds.healthDepressionMedProvAddress },
            { path: 'depressionMedProvPhone', nodeId: NodeIds.healthDepressionMedProvPhone },
            { path: 'depressionHospitalVisit', nodeId: NodeIds.healthDepressionHospitalVisit },
            { path: 'depressionHospitalVisitDate', nodeId: NodeIds.healthDepressionHospitalVisitDate },
            { path: 'depressionHospitalName', nodeId: NodeIds.healthDepressionHospitalName },
            { path: 'depressionHospitalAddress', nodeId: NodeIds.healthDepressionHospitalAddress },
            { path: 'depressionHospitalVisitDays', nodeId: NodeIds.healthDepressionHospitalVisitDays },
            { path: 'depressionDaysOffWork', nodeId: NodeIds.healthDepressionDaysOffWork },
          ],
        },
        {
          path: 'biPolar',
          children: [
            { path: 'biPolarDiagnosisDate', nodeId: NodeIds.healthBiPolarDiagnosisDate },
            { path: 'biPolarCause', nodeId: NodeIds.healthBiPolarCause },
            { path: 'biPolarPhysician', nodeId: NodeIds.healthBiPolarPhysician },
            { path: 'biPolarDateLastConsultation', nodeId: NodeIds.healthBiPolarDateLastConsultation },
            { path: 'biPolarPhysicianVisitsFrequency', nodeId: NodeIds.healthBiPolarPhysicianVisitsFrequency },
            { path: 'biPolarTreatment', nodeId: NodeIds.healthBiPolarTreatment },
            { path: 'biPolarTreatmentDetails', nodeId: NodeIds.healthBiPolarTreatmentDetails },
            { path: 'biPolarPsychotherapy', nodeId: NodeIds.healthBiPolarPsychotherapy },
            { path: 'biPolarPsychotherapyDetails', nodeId: NodeIds.healthBiPolarPsychotherapyDetails },
            { path: 'biPolarSymptoms', nodeId: NodeIds.healthBiPolarSymptoms },
            { path: 'biPolarOffWork', nodeId: NodeIds.healthBiPolarOffWork },
            { path: 'biPolarOffWorkDetails', nodeId: NodeIds.healthBiPolarOffWorkDetails },
            { path: 'biPolarDetails', nodeId: NodeIds.healthBiPolarDetails },
            { path: 'biPolarMedications', nodeId: NodeIds.healthBiPolarMedications },
            { path: 'biPolarAdditionalTreatment', nodeId: NodeIds.healthBiPolarAdditionalTreatment },
            { path: 'biPolarStatus', nodeId: NodeIds.healthBiPolarStatus },
            { path: 'biPolarDateOfFullRecovery', nodeId: NodeIds.healthBiPolarDateOfFullRecovery },
            { path: 'biPolarLimitationDetails', nodeId: NodeIds.healthBiPolarLimitationDetails },
            { path: 'biPolarCurrentlyExperienceSymptoms', nodeId: NodeIds.healthBiPolarCurrentlyExperienceSymptoms },
            { path: 'biPolarDateOfLastSymptom', nodeId: NodeIds.healthBiPolarDateOfLastSymptom },
            { path: 'biPolarDateOfLastOccurrence', nodeId: NodeIds.healthBiPolarDateOfLastOccurrence },
            { path: 'biPolarNumberOfOccurrences', nodeId: NodeIds.healthBiPolarNumberOfOccurrences },
            { path: 'biPolarCausePhysicalLimitations', nodeId: NodeIds.healthBiPolarCausePhysicalLimitations },
            { path: 'biPolarLimitationDetails2', nodeId: 'health-bi-polar-limitation-details-2' },
            { path: 'biPolarMedProv', nodeId: NodeIds.healthBiPolarMedProv },
            { path: 'biPolarMedProvName', nodeId: NodeIds.healthBiPolarMedProvName },
            { path: 'biPolarMedProvAddress', nodeId: NodeIds.healthBiPolarMedProvAddress },
            { path: 'biPolarMedProvPhone', nodeId: NodeIds.healthBiPolarMedProvPhone },
            { path: 'biPolarHospitalVisit', nodeId: NodeIds.healthBiPolarHospitalVisit },
            { path: 'biPolarHospitalVisitDate', nodeId: NodeIds.healthBiPolarHospitalVisitDate },
            { path: 'biPolarHospitalName', nodeId: NodeIds.healthBiPolarHospitalName },
            { path: 'biPolarHospitalAddress', nodeId: NodeIds.healthBiPolarHospitalAddress },
            { path: 'biPolarHospitalVisitDays', nodeId: NodeIds.healthBiPolarHospitalVisitDays },
            { path: 'biPolarDaysOffWork', nodeId: NodeIds.healthBiPolarDaysOffWork },
          ],
        },
        {
          path: 'mentalDisorder',
          children: [
            { path: 'mentalDisorderDiagnosisDate', nodeId: NodeIds.healthMentalDisorderDiagnosisDate },
            { path: 'mentalDisorderMedications', nodeId: NodeIds.healthMentalDisorderMedications },
            { path: 'mentalDisorderAdditionalTreatment', nodeId: NodeIds.healthMentalDisorderAdditionalTreatment },
            { path: 'mentalDisorderStatus', nodeId: NodeIds.healthMentalDisorderStatus },
            { path: 'mentalDisorderDateOfFullRecovery', nodeId: NodeIds.healthMentalDisorderDateOfFullRecovery },
            { path: 'mentalDisorderLimitationDetails', nodeId: NodeIds.healthMentalDisorderLimitationDetails },
            {
              path: 'mentalDisorderCurrentlyExperienceSymptoms',
              nodeId: NodeIds.healthMentalDisorderCurrentlyExperienceSymptoms,
            },
            { path: 'mentalDisorderDateOfLastSymptom', nodeId: NodeIds.healthMentalDisorderDateOfLastSymptom },
            { path: 'mentalDisorderDateOfLastOccurrence', nodeId: NodeIds.healthMentalDisorderDateOfLastOccurrence },
            { path: 'mentalDisorderNumberOfOccurrences', nodeId: NodeIds.healthMentalDisorderNumberOfOccurrences },
            {
              path: 'mentalDisorderCausePhysicalLimitations',
              nodeId: NodeIds.healthMentalDisorderCausePhysicalLimitations,
            },
            { path: 'mentalDisorderLimitationDetails2', nodeId: 'health-mental-disorder-limitation-details-2' },
            { path: 'mentalDisorderMedProv', nodeId: NodeIds.healthMentalDisorderMedProv },
            { path: 'mentalDisorderMedProvName', nodeId: NodeIds.healthMentalDisorderMedProvName },
            { path: 'mentalDisorderMedProvAddress', nodeId: NodeIds.healthMentalDisorderMedProvAddress },
            { path: 'mentalDisorderMedProvPhone', nodeId: NodeIds.healthMentalDisorderMedProvPhone },
            { path: 'mentalDisorderHospitalVisit', nodeId: NodeIds.healthMentalDisorderHospitalVisit },
            { path: 'mentalDisorderHospitalVisitDate', nodeId: NodeIds.healthMentalDisorderHospitalVisitDate },
            { path: 'mentalDisorderHospitalName', nodeId: NodeIds.healthMentalDisorderHospitalName },
            { path: 'mentalDisorderHospitalAddress', nodeId: NodeIds.healthMentalDisorderHospitalAddress },
            { path: 'mentalDisorderHospitalVisitDays', nodeId: NodeIds.healthMentalDisorderHospitalVisitDays },
            { path: 'mentalDisorderDaysOffWork', nodeId: NodeIds.healthMentalDisorderDaysOffWork },
          ],
        },
      ],
    },
    { path: 'neurological', children: [{ path: 'hadSymptoms', nodeId: NodeIds.healthNeurologicalSymptoms }] },
    { path: 'hiv', children: [{ path: 'hadPositiveTest', nodeId: NodeIds.healthHivPositiveTest }] },
    {
      path: 'ulcerative',
      children: [
        { path: 'hadSymptoms', nodeId: NodeIds.healthBowelSymptoms },
        { path: 'diagnosedPast1Year', nodeId: 'health-bowel-diagnosed-past-1-year' },
        { path: 'multipleAttacks', nodeId: NodeIds.healthBowelMultipleAttacks },
      ],
    },
    {
      path: 'pulmonary',
      children: [
        { path: 'hadSymptoms', nodeId: NodeIds.healthPulmonarySymptoms },
        { path: 'asthma', nodeId: NodeIds.healthPulmonaryAsthma },
        { path: 'asthmaControlled', nodeId: NodeIds.healthPulmonaryAsthmaControlled },
      ],
    },
    {
      path: 'arthritis',
      children: [
        { path: 'hadSymptoms', nodeId: NodeIds.healthArthritisHadSymptoms },
        { path: 'arthritisDiagnosisDate', nodeId: NodeIds.healthArthritisDiagnosisDate },
        { path: 'arthritisMedications', nodeId: NodeIds.healthArthritisMedications },
        { path: 'arthritisAdditionalTreatment', nodeId: NodeIds.healthArthritisAdditionalTreatment },
        { path: 'arthritisStatus', nodeId: NodeIds.healthArthritisStatus },
        { path: 'arthritisDateOfFullRecovery', nodeId: NodeIds.healthArthritisDateOfFullRecovery },
        { path: 'arthritisLimitationDetails', nodeId: NodeIds.healthArthritisLimitationDetails },
        { path: 'arthritisCurrentlyExperienceSymptoms', nodeId: NodeIds.healthArthritisCurrentlyExperienceSymptoms },
        { path: 'arthritisDateOfLastSymptom', nodeId: NodeIds.healthArthritisDateOfLastSymptom },
        { path: 'arthritisDateOfLastOccurrence', nodeId: NodeIds.healthArthritisDateOfLastOccurrence },
        { path: 'arthritisNumberOfOccurrences', nodeId: NodeIds.healthArthritisNumberOfOccurrences },
        { path: 'arthritisCausePhysicalLimitations', nodeId: NodeIds.healthArthritisCausePhysicalLimitations },
        { path: 'arthritisLimitationDetails2', nodeId: 'health-arthritis-limitation-details-2' },
        { path: 'arthritisMedProv', nodeId: NodeIds.healthArthritisMedProv },
        { path: 'arthritisMedProvName', nodeId: NodeIds.healthArthritisMedProvName },
        { path: 'arthritisMedProvAddress', nodeId: NodeIds.healthArthritisMedProvAddress },
        { path: 'arthritisMedProvPhone', nodeId: NodeIds.healthArthritisMedProvPhone },
        { path: 'arthritisHospitalVisit', nodeId: NodeIds.healthArthritisHospitalVisit },
        { path: 'arthritisHospitalVisitDate', nodeId: NodeIds.healthArthritisHospitalVisitDate },
        { path: 'arthritisHospitalName', nodeId: NodeIds.healthArthritisHospitalName },
        { path: 'arthritisHospitalAddress', nodeId: NodeIds.healthArthritisHospitalAddress },
      ],
    },
    {
      path: 'otherDiagnosis',
      children: [
        { path: 'otherDiagnosis', nodeId: NodeIds.otherDiagnosis },
        { path: 'hasOtherDiagnosis', nodeId: NodeIds.hasOtherDiagnosis },
        {
          path: 'otherDiagnosisInfo',
          nodeId: NodeIds.otherDiagnosisInfo,
          isCollection: true,
          children: [
            { path: 'otherDiagnosisChoices', nodeId: NodeIds.otherDiagnosisChoices },
            { path: 'otherDiagnosisDetails', nodeId: NodeIds.otherDiagnosisDetails },
            { path: 'otherDiagnosisMedProvName', nodeId: NodeIds.otherDiagnosisMedProvName },
            { path: 'otherDiagnosisMedProvAddress', nodeId: NodeIds.otherDiagnosisMedProvAddress },
            { path: 'otherDiagnosisMedProvPhone', nodeId: NodeIds.otherDiagnosisMedProvPhone },
            { path: 'otherDiagnosisAddressAutoComplete', nodeId: NodeIds.otherDiagnosisAddressAutoComplete },
          ],
        },
      ],
    },
    {
      path: 'pendingTreatments',
      children: [
        { path: 'pendingTreatments', nodeId: NodeIds.pendingTreatments },
        { path: 'hasPendingTreatments', nodeId: NodeIds.hasPendingTreatments },
        {
          path: 'pendingTreatmentsInfo',
          nodeId: NodeIds.pendingTreatmentsInfo,
          isCollection: true,
          children: [
            { path: 'pendingTreatmentChoices', nodeId: NodeIds.pendingTreatmentChoices },
            { path: 'pendingTreatmentDetails', nodeId: NodeIds.pendingTreatmentDetails },
            { path: 'pendingTreatmentMedProvName', nodeId: NodeIds.pendingTreatmentMedProvName },
            { path: 'pendingTreatmentMedProvAddress', nodeId: NodeIds.pendingTreatmentMedProvAddress },
            { path: 'pendingTreatmentMedProvPhone', nodeId: NodeIds.pendingTreatmentMedProvPhone },
            { path: 'pendingTreatmentAddressAutoComplete', nodeId: NodeIds.pendingTreatmentAddressAutoComplete },
          ],
        },
      ],
    },
    {
      path: 'pendingMedicalTests',
      children: [
        { path: 'pendingMedicalTests', nodeId: NodeIds.pendingMedicalTests },
        { path: 'hasPendingMedicalTests', nodeId: NodeIds.hasPendingMedicalTests },
        {
          path: 'pendingMedicalTestsInfo',
          nodeId: NodeIds.pendingMedicalTestsInfo,
          isCollection: true,
          children: [
            { path: 'pendingMedicalTestsType', nodeId: NodeIds.pendingMedicalTestsType },
            { path: 'pendingMedicalTestsResults', nodeId: NodeIds.pendingMedicalTestsResults },
            { path: 'pendingMedicalTestsMedProvName', nodeId: NodeIds.pendingMedicalTestsMedProvName },
            { path: 'pendingMedicalTestsMedProvAddress', nodeId: NodeIds.pendingMedicalTestsMedProvAddress },
            { path: 'pendingMedicalTestsMedProvPhone', nodeId: NodeIds.pendingMedicalTestsMedProvPhone },
            { path: 'pendingMedicalTestsAddressAutoComplete', nodeId: NodeIds.pendingMedicalTestsAddressAutoComplete },
          ],
        },
      ],
    },
    {
      path: 'medicalTests',
      children: [
        { path: 'abnormalResults', nodeId: NodeIds.healthMedicalTestAbnormalResults },
        { path: 'abnormalUltrasound', nodeId: NodeIds.healthMedicalTestsUltrasound },
        { path: 'abnormalMammogram', nodeId: NodeIds.healthMedicalTestsMammogram },
        { path: 'abnormalElectrocardiogram', nodeId: NodeIds.healthMedicalTestsElectrocardiogram },
        { path: 'abnormalBiopsyOrPathologyReport', nodeId: NodeIds.healthMedicalTestsBiopsyOrPathologyReport },
        { path: 'abnormalChestXRayOrPulmonaryScan', nodeId: NodeIds.healthMedicalTestsChestXRay },
        { path: 'abnormalMRI', nodeId: NodeIds.healthMedicalTestsMRI },
        { path: 'abnormalEchocardiogram', nodeId: NodeIds.healthMedicalTestsEchocardiogram },
        { path: 'abnormalProstateSpecificAntigen', nodeId: NodeIds.healthMedicalTestsProstateSpecificAntigen },
      ],
    },
    {
      path: 'hospitalization',
      children: [{ path: 'hospitalizedMoreThan24Hours', nodeId: 'health-hospitalized-more-than-24-hours' }],
    },
    {
      path: 'familyHistory',
      nodeId: NodeIds.healthFamilyHistory,
      children: [
        {
          path: 'familyHealthFatherInfo',
          children: [
            { path: 'familyHealthIsFatherAlive', nodeId: NodeIds.familyHealthIsFatherAlive },
            { path: 'familyHealthFatherCurrentAge', nodeId: NodeIds.familyHealthFatherCurrentAge },
            { path: 'familyHealthFatherMedicalHistory', nodeId: NodeIds.familyHealthFatherMedicalHistory },
            { path: 'familyHealthFatherAgeOfDeath', nodeId: NodeIds.familyHealthFatherAgeOfDeath },
            { path: 'familyHealthFatherCauseOfDeath', nodeId: NodeIds.familyHealthFatherCauseOfDeath },
          ],
        },
        {
          path: 'familyHealthMotherInfo',
          children: [
            { path: 'familyHealthIsMotherAlive', nodeId: NodeIds.familyHealthIsMotherAlive },
            { path: 'familyHealthMotherCurrentAge', nodeId: NodeIds.familyHealthMotherCurrentAge },
            { path: 'familyHealthMotherMedicalHistory', nodeId: NodeIds.familyHealthMotherMedicalHistory },
            { path: 'familyHealthMotherAgeOfDeath', nodeId: NodeIds.familyHealthMotherAgeOfDeath },
            { path: 'familyHealthMotherCauseOfDeath', nodeId: NodeIds.familyHealthMotherCauseOfDeath },
          ],
        },
        {
          path: 'familyHealthSiblingInfo',
          nodeId: NodeIds.familyHealthSiblingInfo,
          isCollection: true,
          children: [
            { path: 'familyHealthSiblingIsAlive', nodeId: NodeIds.familyHealthSiblingIsAlive },
            { path: 'familyHealthSiblingCurrentAge', nodeId: NodeIds.familyHealthSiblingCurrentAge },
            { path: 'familyHealthSiblingMedicalHistory', nodeId: NodeIds.familyHealthSiblingMedicalHistory },
            { path: 'familyHealthSiblingAgeOfDeath', nodeId: NodeIds.familyHealthSiblingAgeOfDeath },
            { path: 'familyHealthSiblingCauseOfDeath', nodeId: NodeIds.familyHealthSiblingCauseOfDeath },
          ],
        },
      ],
    },
    {
      path: 'medicalHistory',
      children: [
        { path: 'hasSymptoms', nodeId: NodeIds.healthMedicalHistoryHasSymptoms },
        { path: 'consultedPhysicianAwaitingDiagnosis', nodeId: NodeIds.healthConsultedPhysicianAwaitingDiagnosis },
        { path: 'waitingOnExternalDependency', nodeId: NodeIds.healthWaitingOnExternalDependency },
        { path: 'abnormalTestPhysicianNotYetConsulted', nodeId: NodeIds.healthAbnormalTestPhysicianNotYetConsulted },
        { path: 'healthProblemsPhysicianNotYetConsulted', nodeId: NodeIds.healthProblemsPhysicianNotYetConsulted },
        { path: 'familyMedicalConditions', nodeId: NodeIds.healthFamilyMedicalHistory },
        { path: 'waitingOnRoutineScreeningResults', nodeId: NodeIds.healthWaitingRoutineScreeningResults },
        { path: 'testsForForSymptomsOrFamilyHistory', nodeId: NodeIds.healthTestsForSymptomsOrFamilyHistory },
        { path: 'consultedForHighBloodPressure', nodeId: NodeIds.healthConsultedForHighBloodPressure },
        { path: 'familyHistoryHypercholesterolemia', nodeId: NodeIds.healthFamilyHistoryHypercholesterolemia },
        { path: 'historyDiabetesOrCardiovascular', nodeId: NodeIds.healthHistoryDiabetesOrCardiovascular },
        { path: 'historyBloodPressureCholesterol', nodeId: NodeIds.healthHistoryBloodPressureCholesterol },
        { path: 'disabilityCauseOtherThanDepression', nodeId: NodeIds.healthDisabilityCauseOtherThanDepression },
        {
          path: 'depression30DayMissedWorkOrHospitalized',
          nodeId: 'health-depression-30-day-missed-work-or-hospitalized',
        },
        { path: 'mentalHealthBipolarDate', nodeId: NodeIds.healthMentalHealthBipolarDate },
        { path: 'mentalHealthHospitalizationDate', nodeId: NodeIds.healthMentalHealthHospitalizationDate },
      ],
    },
  ],
};

import { DataLabel } from '@breathelife/meta-cruncher';
import { FieldTypes, Section, Validations } from '@breathelife/questionnaire-engine';
import {
  ComparisonOperator,
  DateUnit,
  PdfDocumentType,
  FieldSizes,
  IconName,
  InsuranceModule,
  InsuranceScopes,
  PlatformType,
  QueryOperator,
  RenderingType,
} from '@breathelife/types';

import { ageIsGreaterThanOrEqualTo, ageIsLessThanOrEqualTo } from '../../helpers/conditions';
import { provinceOptions } from '../../helpers/geoOptions';
import { yesNoOptions } from '../../helpers/options';
import { GenderChoice, NeedsAnalysisStepId } from '../../models/steps';
import { NodeIds } from '../../nodeIds/nodeIds';

export const generalInfoSection: Section = {
  id: 'generalInfo',
  blueprintId: 'c357c153-a939-4171-b7f4-c82193834246',
  index: '1.1',
  title: {
    en: 'General information',
    fr: 'Informations générales',
  },
  documentTypes: [PdfDocumentType.application, PdfDocumentType.advisorsReport],
  platformTypeFilter: [PlatformType.consumer],
  insuranceModuleFilter: [InsuranceModule.needsAnalysis],
  renderingTypeFilter: [RenderingType.web, RenderingType.proSummary],
  subsections: [
    {
      id: NeedsAnalysisStepId.personalInformation,
      blueprintId: '21a7a995-a966-4f41-baaf-7de461beefe6',
      iconName: IconName.personalInformation,
      index: '1.1.1',
      title: { en: 'Tell me more about yourself', fr: "Dites-m'en plus sur vous" },
      text: {
        en: 'This will help me quickly understand what type of products would be best for you.',
        fr: 'Cela me permettra de savoir rapidement quels types de produits seront disponibles pour vous.',
      },
      questions: [
        {
          id: NeedsAnalysisStepId.personalInformation,
          blueprintId: '9e54b948-7062-4b59-a867-2dfdccc44f2c',
          fields: [
            {
              id: 'genderAtBirth',
              blueprintId: 'f85ca4df-a0cb-4da3-a205-4f88bb88a43d',
              optionSize: 'half',
              nodeId: NodeIds.insuredGender,
              type: FieldTypes.radio,
              validation: { type: Validations.string },
              title: { en: 'Gender at birth', fr: 'Votre sexe à la naissance' },
              options: [
                { id: GenderChoice.male, text: { fr: 'Homme', en: 'Male' } },
                { id: GenderChoice.female, text: { fr: 'Femme', en: 'Female' } },
              ],
              dataLabel: DataLabel.KnownSafe,
            },
            {
              id: 'dateOfBirth',
              blueprintId: '47cff25d-0185-4079-96b2-9250daf2e4eb',
              nodeId: NodeIds.insuredDateOfBirth,
              dataLabel: DataLabel.DateOfBirth,
              type: FieldTypes.date,
              validation: { type: Validations.pastDate },
              placeholder: { en: 'YYYY-MM-DD', fr: 'AAAA-MM-JJ' },
              validIf: [
                {
                  conditions: {
                    conditions: [ageIsGreaterThanOrEqualTo(NodeIds.insuredDateOfBirth, 18)],
                  },
                  message: {
                    en: 'Unfortunately, based on your date of birth, you are not eligible to continue',
                    fr: 'Malheureusement, compte tenu de votre date de naissance, vous ne pouvez pas continuer',
                  },
                },
                {
                  conditions: {
                    conditions: [ageIsLessThanOrEqualTo(NodeIds.insuredDateOfBirth, 70)],
                  },
                  message: {
                    en: 'Unfortunately, based on your date of birth, you are not eligible to continue',
                    fr: 'Malheureusement, compte tenu de votre date de naissance, vous ne pouvez pas continuer',
                  },
                },
              ],
              title: { en: 'Date of birth', fr: 'Date de naissance' },
              layout: { forceNewLine: false },
            },
            {
              id: 'province',
              blueprintId: '07f3b680-ea72-4d03-9065-f4dfe8a2df5c',
              nodeId: NodeIds.insuredAddressProvinceOrState,
              dataLabel: DataLabel.KnownSafe,
              type: FieldTypes.dropdown,
              options: provinceOptions,
              layout: { size: FieldSizes.half, forceNewLine: true },
              validation: { type: Validations.string },
              title: { en: 'Province', fr: 'Province' },
              placeholder: {
                en: 'Select your province',
                fr: `Choisissez votre province`,
              },
            },
            {
              id: 'isTobaccoUser',
              blueprintId: '080867c1-a7f3-45e8-8158-f08028232a74',
              optionSize: 'half',
              nodeId: NodeIds.insuredIsTobaccoUser,
              dataLabel: DataLabel.PHI,
              type: FieldTypes.radio,
              validation: { type: Validations.string },
              encrypted: true,
              title: {
                en: 'Are you a smoker?',
                fr: 'Êtes-vous un fumeur ?',
              },
              info: {
                text: {
                  en: 'In the past 12 months have you used any form of tobacco, other than one large cigar per month; or betel nuts or leaves more than once per month; or e-cigarettes, vaping products, water-pipe, nicotine products or smoking cessation products?',
                  fr: 'Au cours des 12 derniers mois, avez-vous fait usage de: tabac, autre qu’un gros cigare par mois ; ou feuilles ou de noix de bétel, plus d’une fois par mois ; ou cigarettes électroniques, de produits de vapotage, de pipes à eau, de produits à base de nicotine ou de produits de désaccoutumance au tabac ?',
                },
              },
              options: yesNoOptions,
            },
            {
              id: 'englishOrFrench',
              blueprintId: '4ac851c3-f6a3-4c17-a908-a79ed9c05d4e',
              optionSize: 'half',
              nodeId: NodeIds.insuredEnglishOrFrench,
              dataLabel: DataLabel.KnownSafe,
              type: FieldTypes.radio,
              validation: { type: Validations.string },
              title: {
                en: 'Do you understand English or French?',
                fr: "Comprenez-vous le français ou l'anglais ?",
              },
              options: yesNoOptions,
            },
            {
              id: 'retirementDate',
              blueprintId: '63c5c48d-fe77-4b1b-917e-4fd0ed17ee8d',
              type: FieldTypes.date,
              scopeFilter: [InsuranceScopes.retirement],
              validation: { type: Validations.date },
              validIf: [
                {
                  conditions: {
                    conditions: [
                      {
                        query: {
                          // TODO: currently using FieldType.date but it should be a FieldTypes.number because it is a little bit
                          // weird to ask for a retirement date. Question is still asking for age, but the field is a date field for now.
                          // New operator is needed to compare retirement age as number and insuredDateOfBirth as date string
                          select: [NodeIds.retirementDate, NodeIds.insuredDateOfBirth],
                          operator: QueryOperator.subtractDates,
                        },
                        operator: ComparisonOperator.greaterThan,
                        value: 0,
                      },
                    ],
                  },
                  message: {
                    en: 'Your retirement age needs to be higher than your current age',
                    fr: 'Votre âge de retraite doit être plus élevé que votre âge actuel',
                  },
                },
                {
                  conditions: {
                    conditions: [
                      {
                        query: {
                          operator: QueryOperator.subtractDates,
                          select: [NodeIds.retirementDate, NodeIds.insuredDateOfBirth],
                          operatorParams: { dateUnit: DateUnit.year },
                        },
                        operator: ComparisonOperator.lessThan,
                        value: 100,
                      },
                    ],
                  },
                  message: {
                    en: 'Your desired retirement age must be less than age 100',
                    fr: "L'âge souhaité de votre retraite doit être inférieur à 100 ans",
                  },
                },
              ],
              title: {
                en: 'What is your desired retirement age?',
                fr: "Quel est l'âge souhaité pour votre retraite?",
              },
              placeholder: {
                en: 'Years',
                fr: 'Âge',
              },
              nodeId: NodeIds.retirementDate,
              dataLabel: DataLabel.KnownSafe,
            },
          ],
        },
      ],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { cancer } from './cancer';
import { cardiovascular } from './cardiovascular';
import { digestiveSystem } from './digestiveSystem';
import { disabilityOrAbsence } from './disabilityOrAbsence';
import { earEyeNoseThroatDisorders } from './earEyeNoseThroatDisorders';
import { endocrineSystem } from './endocrineSystem';
import { familyHistory } from './familyHistory';
import { general } from './general';
import { genitourinary } from './genetourinary';
import { genetourinary } from './genitourinary.ts';
import { heightAndWeight } from './heightAndWeight';
import { immuneSystem } from './immuneSystem';
import { medicalConsultations } from './medicalConsultations';
import { mentalHealth } from './mentalHealth';
import { musculoskeletal } from './musculoskeletal';
import { neurologicalDisorder } from './neurological';
import { respiratorySystem } from './respiratorySystem';

export const healthInsured: AnswerPathTreeNode = {
  path: 'healthInsured',
  children: [
    mentalHealth,
    musculoskeletal,
    cancer,
    cardiovascular,
    earEyeNoseThroatDisorders,
    general,
    genetourinary,
    neurologicalDisorder,
    respiratorySystem,
    familyHistory,
    heightAndWeight,
    digestiveSystem,
    endocrineSystem,
    genitourinary,
    immuneSystem,
    medicalConsultations,
    disabilityOrAbsence,
  ],
};

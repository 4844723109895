import { emptyTitle, Section } from '@breathelife/questionnaire-engine';
import {
  PdfDocumentType,
  IconName,
  InsuranceModule,
  PlatformType,
  RenderingType,
  SubsectionVariant,
} from '@breathelife/types';

import { InsuranceApplicationStepId } from '../../models/steps';

export const summarySection: Section = {
  id: 'summary',
  blueprintId: '1a510aa9-e187-4a6c-be4d-be0c2eed4313',
  index: '',
  title: emptyTitle,
  documentTypes: [PdfDocumentType.application, PdfDocumentType.advisorsReport],
  platformTypeFilter: [PlatformType.consumer],
  insuranceModuleFilter: [InsuranceModule.insuranceApplication],
  renderingTypeFilter: [RenderingType.web],
  subsections: [
    {
      id: InsuranceApplicationStepId.summary,
      blueprintId: '6a2b23eb-b89d-4f42-8940-f5b229365cb4',
      index: '',
      title: {
        en: 'The summary of the information collected',
        fr: 'Sommaire des informations recueilles',
      },
      text: {
        en: 'Please confirm the information is correct.',
        fr: 'Veuillez confirmer que les informations sont correctes.',
      },
      iconName: IconName.summary,
      variant: SubsectionVariant.summary,
      questions: [],
    },
  ],
};

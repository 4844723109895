import { emptyTitle, Section, Subsection } from '@breathelife/questionnaire-engine';
import { ComparisonOperator, PdfDocumentType, RenderingType } from '@breathelife/types';

import { NeedsAnalysisStepId } from '../../models/steps';
import { NodeIds } from '../../nodeIds/nodeIds';

const smokerAcknowledgementStepYes: Subsection = {
  id: NeedsAnalysisStepId.acknowledgeSmokerStatusYes,
  blueprintId: '359027f6-9479-4ec4-9b0f-0114be46829d',
  index: '1.2',
  visibleIf: {
    conditions: [
      {
        nodeId: NodeIds.insuredIsTobaccoUser,
        value: 'yes',
        operator: ComparisonOperator.equal,
      },
    ],
  },
  title: { en: 'Smoker Status', fr: 'Statut de fumeur' },
  text: {
    en: 'You confirm that in the past 12 months, you have used any form of Tobacco, other than one large cigar per month; Betel nuts or leaves more than once per month; or E-cigarettes, vaping products, water-pipe, nicotine products or smoking cessation products?',
    fr: "Confirmez-vous qu'au cours des 12 derniers mois, vous avez fait usage de: tabac, autre qu’un gros cigare par mois ; ou feuilles ou de noix de bétel, plus d’une fois par mois ; ou cigarettes électroniques, de produits de vapotage, de pipes à eau, de produits à base de nicotine ou de produits de désaccoutumance au tabac?",
  },
  questions: [],
};

const smokerAcknowledgementStepNo: Subsection = {
  id: NeedsAnalysisStepId.acknowledgeSmokerStatusNo,
  blueprintId: 'f2607098-daa8-408b-a04c-d40dd2b8fecf',
  index: '1.2',
  visibleIf: {
    conditions: [
      {
        nodeId: NodeIds.insuredIsTobaccoUser,
        value: 'no',
        operator: ComparisonOperator.equal,
      },
    ],
  },
  title: { en: 'Smokers Status', fr: 'Statut des fumeurs' },
  text: {
    en: "Do you confirm that in the past 12 months you haven't used any form of Tobacco, other than one large cigar per month; Betel nuts or leaves more than once per month; or E-cigarettes, vaping products, water-pipe, nicotine products or smoking cessation products?",
    fr: "Confirmez-vous qu'au cours des 12 derniers mois, vous n’avez pas fait usage de: tabac, autre qu’un gros cigare par mois ; ou feuilles ou de noix de bétel, plus d’une fois par mois ; ou cigarettes électroniques, de produits de vapotage, de pipes à eau, de produits à base de nicotine ou de produits de désaccoutumance au tabac?",
  },
  questions: [],
};

const smokerAcknowledgementSection: Section = {
  id: 'smokerAcknowledgement',
  blueprintId: 'b201c70a-f91f-45a9-8104-8a79c20b3df9',
  index: '',
  documentTypes: [PdfDocumentType.application, PdfDocumentType.advisorsReport],
  renderingTypeFilter: [RenderingType.web],
  title: emptyTitle,
  subsections: [smokerAcknowledgementStepNo, smokerAcknowledgementStepYes],
};

export const optionalSections = {
  smokerAcknowledgementSection,
};

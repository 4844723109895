import { DataLabel } from '@breathelife/meta-cruncher';
import { FieldTypes, Section, Subsection, Validations } from '@breathelife/questionnaire-engine';
import { PdfDocumentType, IconName, InsuranceModule, PlatformType, RenderingType } from '@breathelife/types';

import { NeedsAnalysisStepId } from '../../models/steps';
import { NodeIds } from '../../nodeIds/nodeIds';

const contactInfoStep: Subsection = {
  id: NeedsAnalysisStepId.contactInfo,
  blueprintId: '96dc2880-24ca-4a41-82f8-1824e07d4fee',
  dataLabel: DataLabel.PII,
  index: '1.4.1',
  iconName: IconName.personalInformation,
  title: {
    en: 'Contact Information',
    fr: 'Vos informations de contact',
  },
  questions: [
    {
      id: NeedsAnalysisStepId.contactInfo,
      blueprintId: 'd43594f5-b0f5-4cfe-bacd-a3b4ff534ff9',
      title: {
        en: 'In order to properly follow up on your file, please fill out your contact information below.',
        fr: "Afin d'assurer un suivi adéquat de votre dossier, veuillez remplir vos informations de contact ci-dessous.",
      },
      fields: [
        {
          id: 'firstName',
          blueprintId: '6e42ec61-e838-4235-876b-549f6ea899c6',
          nodeId: NodeIds.insuredFirstName,
          dataLabel: DataLabel.PII,
          type: FieldTypes.input,
          validation: { type: Validations.firstName },
          title: {
            en: 'First name',
            fr: 'Prénom',
          },
        },
        {
          id: 'lastName',
          blueprintId: 'd2dd0d8f-2cb3-4442-8933-51daa4ca180a',
          nodeId: NodeIds.insuredLastName,
          dataLabel: DataLabel.PII,
          type: FieldTypes.input,
          validation: { type: Validations.lastName },
          title: {
            en: 'Last name',
            fr: 'Nom de famille',
          },
        },
        {
          id: 'email',
          blueprintId: '5d2ef017-98d0-4bee-a75d-fc589a5b9ad3',
          nodeId: NodeIds.insuredEmail,
          dataLabel: DataLabel.PII,
          type: FieldTypes.input,
          validation: { type: Validations.email },
          title: {
            en: 'Email',
            fr: 'Courriel',
          },
        },
        {
          id: 'phoneNumber',
          blueprintId: '09c6370d-6679-4317-ad30-9a49840825e3',
          nodeId: NodeIds.insuredPhoneNumber,
          dataLabel: DataLabel.PII,
          type: FieldTypes.phone,
          validation: { type: Validations.phoneNumber },
          optional: true,
          title: {
            en: 'Phone number',
            fr: 'Numéro de téléphone',
          },
          placeholder: { en: 'Enter your phone number', fr: 'Entrez votre numéro de téléphone' },
        },
      ],
    },
  ],
};

export const contactInfoSection: Section = {
  id: 'contactInformation',
  blueprintId: '9e7f02fe-eeb0-4f4d-983c-9e8eb28d9bcb',
  index: '1.4',
  title: {
    en: 'Contact information',
    fr: 'Informations de contact',
  },
  documentTypes: [PdfDocumentType.application, PdfDocumentType.advisorsReport],
  platformTypeFilter: [PlatformType.consumer],
  renderingTypeFilter: [RenderingType.web],
  insuranceModuleFilter: [InsuranceModule.needsAnalysis],
  subsections: [contactInfoStep],
};

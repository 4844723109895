export const consentGeneral = {
  en: `<p>Upon acceptance of your insurance application, do you agree to be bound by all of the terms and conditions of the insurance policy?</p>
  <p>Do you declare that all information provided to the insurer and its reinsurers is accurate?</p>
  <p>Do you agree to notify the insurer as quickly as possible of any change to your contact information, so that our records may be kept up to date?</p>
  <p>Do you understand that this consent will be valid as of today and will remain so for the time period permitted by law.</p>
  <p>Do you understand that you can cancel your policy at any time by contacting us. If you do so within 30 days of receiving it, any premium paid will be refunded and your insurance contract will be considered to have never been in effect.</p>`,

  fr: `Sur acceptation de votre demande d’assurance, consentez-vous d’être lié par toutes les dispositions du contrat d’assurance?</p>
  <p>Déclarez-vous que tous les renseignements fournis à l’assureur et à ses réassureurs sont exacts?</p>
  <p>Engagez-vous à aviser l’assureur dans les meilleurs délais de tout changement relatif à vos coordonnées afin qu’il puisse mettre à jour ses dossiers?</p>
  <p>Comprenez-vous que votre consentement sera valable à compter d’aujourd’hui et le restera pour la période de temps permise par la loi?</p>
  <p>Comprenez-vous que vous pouvez en tout temps annuler votre contrat d’assurance en communiquant avec nous et si vous le faites dans les 30 jours après l’avoir reçu, toute prime perçue vous sera remboursée et votre contrat d’assurance sera considéré comme n’ayant jamais été en vigueur.</p>`,
};

export const consentPreviousInformation = {
  en: `Do you allow the insurer to use all necessary information it holds on you, including those from all previous records?`,
  fr: `Autorisez-vous l’assureur à utiliser tous les renseignements nécessaires qu’il détient sur vous, incluant ceux provenant de tous dossiers antérieurs?`,
};

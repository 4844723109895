import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const birthLocation: AnswerPathTreeNode = {
  path: 'birthLocation',
  children: [
    { path: 'provinceOrState', nodeId: NodeIds.insuredBirthProvinceOrState },
    { path: 'country', nodeId: NodeIds.insuredBirthCountry },
    { path: 'city', nodeId: NodeIds.insuredBirthCity },
    { path: 'otherCountry', nodeId: NodeIds.insuredBirthCountryOther },
  ],
};

import { NodeIdToAnswerPathMap } from '@breathelife/questionnaire-engine';
import { ConditionalConsideration, ConsiderationBlueprint } from '@breathelife/types';

import { ConditionsBuilder } from '../helpers/blueprintToConditions';

export function generateSalesDecisionRuleConsiderations(
  considerationBlueprints: ConsiderationBlueprint[],
  nodeIdToAnswerPathMap: NodeIdToAnswerPathMap
): ConditionalConsideration[] {
  return considerationBlueprints.map((blueprint) =>
    generateSalesDecisionRuleConsideration(blueprint, nodeIdToAnswerPathMap)
  );
}

export function generateSalesDecisionRuleConsideration(
  blueprint: ConsiderationBlueprint,
  nodeIdToAnswerPathMap: NodeIdToAnswerPathMap
): ConditionalConsideration {
  const { outcomeCode, conditions, reason } = blueprint;

  const generatedConditions = ConditionsBuilder.buildConditions(conditions, nodeIdToAnswerPathMap);

  if (!generatedConditions) {
    throw new Error('Failed to generate conditions from consideration blueprint');
  }

  const consideration: ConditionalConsideration = {
    ...generatedConditions,
    outcomeCode,
    reason,
  };

  return consideration;
}

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const bloodOrSkinDisease: AnswerPathTreeNode = {
  path: 'bloodOrSkinDisease',
  children: [
    {
      path: 'hadConsultationTreatmentForBloodOrSkinDisease',
      nodeId: NodeIds.healthInsuredHadConsultationTreatmentForBloodOrSkinDisease,
    },
    {
      path: 'hadConsultationTreatmentForBloodOrSkinDiseaseDetails',
      nodeId: NodeIds.healthInsuredHadConsultationTreatmentForBloodOrSkinDiseaseDetails,
    },
  ],
};

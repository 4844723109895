import { Questionnaire } from '@breathelife/questionnaire-engine';

import {
  contactInfoSection,
  financialNeedsAnalysisSection,
  generalInfoSection,
  productNeedsAnalysisSection,
  quoterSection,
} from './needsAnalysis';

export const defaultQuestionnaire: Questionnaire = [
  {
    id: 'sectionGroupId',
    blueprintId: 'f376b55a-5ab6-4a2a-8a9b-507e6a03e6cc',
    index: '1',
    title: { en: 'SectionGroup Title', fr: 'SectionGroup Title' },
    sections: [
      generalInfoSection,
      financialNeedsAnalysisSection,
      productNeedsAnalysisSection,
      contactInfoSection,
      quoterSection,
    ],
  },
];

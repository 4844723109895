import { DataLabel } from '@breathelife/meta-cruncher';
import { FieldTypes, Question, Validations, valueDoesNotExceedCharacterLimit } from '@breathelife/questionnaire-engine';

import { ageIsGreaterThanOrEqualTo, ageIsLessThanOrEqualTo } from '../../../helpers/conditions';
import { provinceOptions } from '../../../helpers/geoOptions';
import { GenderChoice } from '../../../models/steps';
import { NodeIds } from '../../../nodeIds/nodeIds';

export function baseContactQuestion(): Question {
  return {
    id: 'baseContactQuestionId',
    blueprintId: '528dbfb4-3134-41f3-9e06-08510b65d340',
    fields: [
      {
        id: 'confirm.firstName',
        blueprintId: 'd4df14a5-4d21-46b7-8f4d-f34a93cb58d9',
        nodeId: NodeIds.insuredFirstName,
        dataLabel: DataLabel.PII,
        type: FieldTypes.input,
        title: { en: 'First name', fr: 'Prénom' },
        placeholder: {
          en: 'Enter your first name',
          fr: 'Entrez votre prénom',
        },
        validation: { type: Validations.firstName },
        validIf: [valueDoesNotExceedCharacterLimit(NodeIds.insuredFirstName, 25)],
      },
      {
        id: 'confirm.lastName',
        blueprintId: '92e493b2-8ac4-4f30-9c9c-be449adba7a3',
        nodeId: NodeIds.insuredLastName,
        dataLabel: DataLabel.PII,
        type: FieldTypes.input,
        title: { en: 'Last name', fr: 'Nom de famille' },
        placeholder: {
          en: 'Enter your last name',
          fr: 'Entrez votre nom de famille',
        },
        validation: { type: Validations.lastName },
        validIf: [valueDoesNotExceedCharacterLimit(NodeIds.insuredLastName, 25)],
      },
      {
        id: 'confirm.dateOfBirth',
        blueprintId: '710fc751-a388-471e-9ddb-c658befe06c0',
        nodeId: NodeIds.insuredDateOfBirth,
        dataLabel: DataLabel.DateOfBirth,
        type: FieldTypes.date,
        validation: { type: Validations.pastDate },
        placeholder: { en: 'YYYY-MM-DD', fr: 'AAAA-MM-JJ' },
        validIf: [
          {
            conditions: {
              conditions: [ageIsGreaterThanOrEqualTo(NodeIds.insuredDateOfBirth, 18)],
            },
            message: {
              en: 'You need to be 18 years old or older in order to continue',
              fr: "L'âge minimum pour continuer est de 18 ans",
            },
          },
          {
            conditions: {
              conditions: [ageIsLessThanOrEqualTo(NodeIds.insuredDateOfBirth, 60)],
            },
            message: {
              en: 'You need to be 60 years old or younger in order to continue',
              fr: "L'âge maximum pour continuer est de 60 ans",
            },
          },
        ],
        title: { en: 'Date of birth', fr: 'Date de naissance' },
        layout: { forceNewLine: false },
      },
      {
        id: 'confirm.genderAtBirth',
        blueprintId: '6518078f-57f1-4591-89ab-61680860ba43',
        optionSize: 'half',
        nodeId: NodeIds.insuredGender,
        type: FieldTypes.radio,
        validation: { type: Validations.string },
        title: { en: 'Gender at birth', fr: 'Votre sexe à la naissance' },
        options: [
          { id: GenderChoice.male, text: { fr: 'Homme', en: 'Male' } },
          { id: GenderChoice.female, text: { fr: 'Femme', en: 'Female' } },
        ],
        dataLabel: DataLabel.KnownSafe,
      },
      {
        id: 'confirm.email',
        blueprintId: '9795f1e2-c298-4f40-b602-3c10a897259c',
        nodeId: NodeIds.insuredEmail,
        dataLabel: DataLabel.PII,
        type: FieldTypes.input,
        validation: { type: Validations.email },
        title: {
          en: 'Email',
          fr: 'Courriel',
        },
        placeholder: {
          en: 'Enter your email',
          fr: `Entrez votre courriel`,
        },
        validIf: [valueDoesNotExceedCharacterLimit(NodeIds.insuredEmail, 50)],
      },
      {
        id: 'confirm.phoneNumber',
        blueprintId: '6fa68466-b460-413e-bc81-9994ecce1639',
        nodeId: NodeIds.insuredPhoneNumber,
        dataLabel: DataLabel.PII,
        type: FieldTypes.phone,
        validation: { type: Validations.phoneNumber },
        optional: true,
        title: {
          en: 'Phone number',
          fr: 'Numéro de téléphone',
        },
        placeholder: { en: 'Enter your phone number', fr: 'Entrez votre numéro de téléphone' },
      },
      {
        id: 'postalAddress',
        blueprintId: 'cb7aeecd-8541-4b7b-8123-df4391a37083',
        nodeId: NodeIds.insuredAddressStreet,
        type: FieldTypes.autocomplete,
        validation: { type: Validations.string },
        dataLabel: DataLabel.PII,
        title: {
          en: 'Street address',
          fr: 'Adresse postale',
        },
        placeholder: {
          en: 'Enter an address',
          fr: 'Entrez une adresse',
        },
        countryCode: 'CA',
        nodeIdsToUpdate: {
          autocomplete: NodeIds.insuredAddressAutocomplete,
          streetAddress: NodeIds.insuredAddressStreet,
          city: NodeIds.insuredAddressCity,
          provinceOrState: NodeIds.insuredAddressProvinceOrState,
          postalOrZipCode: NodeIds.insuredAddressPostalCode,
        },
      },
      {
        id: 'apartment',
        blueprintId: '57b2b5fa-277c-4193-bac7-361fc0ac7f63',
        nodeId: NodeIds.insuredAddressApartment,
        type: FieldTypes.input,
        validation: { type: Validations.string },
        dataLabel: DataLabel.PII,
        title: {
          en: 'Apartment',
          fr: 'Appartement',
        },
        optional: true,
        placeholder: {
          en: 'Enter your apartment number',
          fr: `Entrez votre numéro d'appartement`,
        },
      },
      {
        id: 'city',
        blueprintId: 'cef32fca-71ae-4437-93e5-d201ccab127c',
        nodeId: NodeIds.insuredAddressCity,
        type: FieldTypes.input,
        validation: { type: Validations.string },
        dataLabel: DataLabel.PII,
        title: {
          en: 'City',
          fr: 'Ville',
        },
        placeholder: {
          en: 'Enter your city',
          fr: `Entrez votre ville`,
        },
        relatesTo: NodeIds.insuredAddressAutocomplete,
      },
      {
        id: 'province',
        blueprintId: '0f61e310-4dda-4123-afd7-99e982a1bb79',
        nodeId: NodeIds.insuredAddressProvinceOrState,
        type: FieldTypes.dropdown,
        validation: { type: Validations.string },
        dataLabel: DataLabel.KnownSafe,
        options: provinceOptions,
        title: {
          en: 'Province',
          fr: 'Province',
        },
        placeholder: {
          en: 'Select your province',
          fr: `Choisissez votre province`,
        },
        relatesTo: NodeIds.insuredAddressAutocomplete,
      },
      {
        id: 'postalCode',
        blueprintId: '73f9c900-a660-4e78-9c24-d2bebb631d3d',
        nodeId: NodeIds.insuredAddressPostalCode,
        type: FieldTypes.input,
        validation: { type: Validations.string },
        dataLabel: DataLabel.PostalCode,
        title: {
          en: 'Postal code',
          fr: 'Code postal',
        },
        placeholder: {
          en: 'Enter your postal code',
          fr: `Entrez votre code postal`,
        },
        relatesTo: NodeIds.insuredAddressAutocomplete,
      },
    ],
  };
}

import { DataLabel } from '@breathelife/meta-cruncher';
import {
  FieldTypes,
  Section,
  Subsection,
  Validations,
  valueAboveMinimum,
  valueBelowMaximum,
} from '@breathelife/questionnaire-engine';
import {
  ComparisonOperator,
  PdfDocumentType,
  FieldSizes,
  IconName,
  InsuranceModule,
  InsuranceScopes,
  PlatformType,
  RenderingType,
} from '@breathelife/types';

import {
  CoverageDuration,
  CurrentFinancialHealth,
  CurrentHealth,
  FutureGoals,
  NeedsAnalysisStepId,
  SavingsGoals,
  SavingsObstacles,
  SavingsPreference,
} from '../../models/steps';
import { NodeIds } from '../../nodeIds/nodeIds';

const currentHealthStep: Subsection = {
  id: NeedsAnalysisStepId.currentHealth,
  blueprintId: '993b52b5-9c34-48f7-addc-6c4703174d37',
  scopeFilter: [InsuranceScopes.life],
  iconName: IconName.health,
  index: '1.3.1',
  title: {
    en: 'How healthy are you?',
    fr: 'Quel est votre état de santé actuel ?',
  },
  questions: [
    {
      id: NeedsAnalysisStepId.currentHealth,
      blueprintId: 'deb3c37a-88e5-49ef-9630-80f07276eafe',
      title: {
        en: 'Select the option that best fits your current lifestyle.',
        fr: 'Choisissez une description qui correspond le mieux à votre état de santé.',
      },
      fields: [
        {
          id: 'status',
          blueprintId: 'dafb7dfa-58ad-4cc0-93a2-4077e37a1c88',
          optionSize: 'full',
          nodeId: NodeIds.insuredCurrentHealth,
          dataLabel: DataLabel.PHI,
          type: FieldTypes.radio,
          validation: { type: Validations.string },
          options: [
            {
              id: CurrentHealth.poor,
              title: {
                en: 'Poor',
                fr: 'Pauvre',
              },
              iconName: IconName.poorHealth,
              text: {
                en: 'Your favorite physical activity is getting up to get the TV remote.',
                fr: 'Votre activité physique préférée est de vous lever pour aller chercher la télécommande.',
              },
            },
            {
              id: CurrentHealth.good,
              title: {
                en: 'Good',
                fr: 'Bon',
              },
              iconName: IconName.goodHealth,
              text: {
                en: 'You walk to work from time to time or enjoy long strolls on the weekend.',
                fr: 'Vous vous rendez au travail à pied de temps en temps ou vous faites de longues promenades le week-end.',
              },
            },
            {
              id: CurrentHealth.great,
              title: {
                en: 'Great',
                fr: 'Super',
              },
              iconName: IconName.greatHealth,
              text: {
                en: 'You hop on your bicycle often or go to the gym once a week.',
                fr: 'Vous montez souvent sur votre vélo ou vous allez à la salle de sport plusieurs fois par mois.',
              },
            },
            {
              id: CurrentHealth.excellent,
              title: {
                en: 'Excellent',
                fr: 'Excellent',
              },
              iconName: IconName.excellentHealth,
              text: {
                en: 'You go to the gym everyday or are on several sports teams.',
                fr: 'Vous allez à la salle de sport tous les jours ou vous avez quatre partenaires de tennis différents.',
              },
            },
          ],
        },
      ],
    },
  ],
};

const futureGoalsStep: Subsection = {
  id: NeedsAnalysisStepId.futureGoals,
  blueprintId: '205e7b6d-f4c7-41d6-8a0b-aefa63b06fbe',
  scopeFilter: [InsuranceScopes.life],
  index: '1.3.2',
  iconName: IconName.future,
  title: {
    en: 'What does your future look like?',
    fr: 'Quels sont vos objectifs futurs ?',
  },
  questions: [
    {
      id: NeedsAnalysisStepId.futureGoals,
      blueprintId: 'afae6238-a03b-445b-9c84-f7012d0f1480',
      title: {
        en: 'Tell us what is important to you in planning for your financial future.',
        fr: 'Dites-nous ce qui est important pour vous dans la planification de votre avenir :',
      },
      fields: [
        {
          id: 'goal',
          blueprintId: 'b39990d7-5559-4d8a-a073-e8cfb3fa3a47',
          optionSize: 'full',
          nodeId: NodeIds.insuredFinancialGoals,
          dataLabel: DataLabel.KnownSafe,
          type: FieldTypes.checkboxGroup,
          validation: { type: Validations.string },
          options: [
            {
              id: FutureGoals.accessCash,
              text: {
                en: 'I want to be able to access cash, if needed, to supplement my retirement savings and pension.',
                fr: 'Je veux pouvoir avoir accès à des liquidités si nécessaire pour compléter ma retraite',
              },
            },
            {
              id: FutureGoals.accessDeathBenefits,
              text: {
                en: 'I want to be able to access a portion of my death benefit if I become seriously ill or disabled.',
                fr: 'Si je tombe gravement malade, je voudrais avoir accès à une partie de mon capital décès, de mon vivant',
              },
            },
            {
              id: FutureGoals.debtsPaidOff,
              text: {
                en: 'I want to ensure my debts are paid off should something happen to me.',
                fr: "Si je suis parti, je veux m'assurer que mes dépenses de logement et mes dettes sont payées",
              },
            },
            {
              id: FutureGoals.supplementOther,
              text: {
                en: "I would like to supplement my work insurance with my own coverage so I don't lose it if I change jobs.",
                fr: "J'ai une assurance professionnelle mais je voudrais la compléter par ma propre couverture pour qu'elle reste avec moi",
              },
            },
          ],
        },
      ],
    },
  ],
};

const coverageDurationStep: Subsection = {
  id: NeedsAnalysisStepId.coverageDuration,
  blueprintId: '69e41402-0ef1-4788-bd5e-baf7a840f095',
  scopeFilter: [InsuranceScopes.life],
  index: '1.3.3',
  iconName: IconName.future,
  title: {
    en: 'How long do you want your financial protection for?',
    fr: 'Combien de temps voulez-vous que votre protection financière dure ?',
  },
  questions: [
    {
      id: NeedsAnalysisStepId.coverageDuration,
      blueprintId: 'bf560a7d-d637-4fd0-990d-f3565f839fd1',
      title: {
        en: 'Tell us how long you want your coverage to last for.',
        fr: 'Dites-nous combien de temps vous souhaitez que votre couverture dure.',
      },
      fields: [
        {
          id: 'coverageLengthType',
          blueprintId: '0e62914c-6bb2-4f71-aee8-8181028845d7',
          optionSize: 'full',
          nodeId: NodeIds.insuredCoverageDurationType,
          dataLabel: DataLabel.KnownSafe,
          type: FieldTypes.radio,
          validation: { type: Validations.string },
          options: [
            {
              id: CoverageDuration.lifetime,
              text: {
                en: 'My lifetime',
                fr: 'Toute ma vie',
              },
            },
            {
              id: CoverageDuration.specifiedPeriod,
              text: {
                en: 'A specific number of years',
                fr: 'Une période de temps précise',
              },
            },
          ],
        },
        {
          id: 'coverageLengthYears',
          blueprintId: 'b1f397e3-d580-45d9-8792-74f6ae65c1cf',
          nodeId: NodeIds.insuredCoverageDuration,
          dataLabel: DataLabel.KnownSafe,
          type: FieldTypes.number,
          layout: { size: FieldSizes.third },
          visibleIf: {
            conditions: [
              {
                nodeId: NodeIds.insuredCoverageDurationType,
                operator: ComparisonOperator.equal,
                value: CoverageDuration.specifiedPeriod,
              },
            ],
          },
          title: {
            en: 'Please specify the number of years',
            fr: 'Veuillez préciser combien d’années exactement',
          },
          validation: { type: Validations.integer },
          validIf: [
            valueAboveMinimum(NodeIds.insuredCoverageDuration, 1, ComparisonOperator.greaterThanOrEqual),
            valueBelowMaximum(NodeIds.insuredCoverageDuration, 100, ComparisonOperator.lessThanOrEqual),
          ],
        },
      ],
    },
  ],
};

const preferenceStep: Subsection = {
  blueprintId: 'e3545825-9138-40d3-93b8-002e1179864e',
  id: NeedsAnalysisStepId.preference,
  scopeFilter: [InsuranceScopes.life],
  index: '1.3.4',
  iconName: IconName.preference,
  title: {
    en: 'What is your preference?',
    fr: 'Quelle est votre préférence?',
  },
  questions: [
    {
      id: NeedsAnalysisStepId.preference,
      blueprintId: 'ee22d92d-d970-4d8c-a99a-ac1a310a1320',
      title: {
        en: 'Tell us what matters the most to you when planning your financial future:',
        fr: 'Dites-nous ce qui est important pour vous dans la planification de votre avenir :',
      },
      dataLabel: DataLabel.KnownSafe,
      fields: [
        {
          id: 'purpose',
          blueprintId: 'df37209f-3a27-4f50-810e-1e859cc9013a',
          nodeId: NodeIds.insuredPurposeOfInsurance,
          type: FieldTypes.radio,
          validation: { type: Validations.string },
          options: [
            {
              id: 'accumulateCashValue',
              text: {
                en: 'Giving financial security to my loved ones with the option to accumulate cash value that I can use towards other expenses. This will likely come at a higher cost.',
                fr: "Protéger mes proches avec la possibilité d'accumuler une valeur monétaire qui pourra être utilisée pour d'autres objectifs ou dépenses, mais probablement à un coût plus élevé.",
              },
            },
            {
              id: 'temporaryReplacementIncome',
              text: {
                en: "Temporarily protecting the financial future of my loved ones with the replacement of my income if I'm gone. This will likely come at a lower cost. ",
                fr: 'Une protection temporaire qui contribue à garantir le remplacement de mes revenus si je décède mais à un coût potentiellement moindre',
              },
            },
          ],
        },
      ],
    },
  ],
};

const mattersStep: Subsection = {
  id: NeedsAnalysisStepId.matters,
  blueprintId: 'd62c772d-b0ae-4654-8e16-fa740a308ac5',
  scopeFilter: [InsuranceScopes.life],
  index: '1.3.5',
  iconName: IconName.preference,
  title: {
    en: "What's most important to you?",
    fr: "Qu'est-ce qui vous importe le plus ?",
  },
  questions: [
    {
      id: NeedsAnalysisStepId.matters,
      blueprintId: 'd5a48b9c-333b-48fc-8be0-aa54cec25fc6',
      title: {
        en: 'Which of the below is the most important when planning for your financial future?',
        fr: 'Dites-nous ce qui est important pour vous dans la planification de votre avenir :',
      },
      dataLabel: DataLabel.KnownSafe,
      fields: [
        {
          id: 'costPreference',
          blueprintId: '455ba601-b687-47d7-b0c6-06b9c8363275',
          nodeId: NodeIds.insuredCostPreference,
          type: FieldTypes.radio,
          validation: { type: Validations.string },
          options: [
            {
              id: 'higherCost',
              text: {
                en: "Higher cost protection that ensures my loved ones' financial future while also offering the option to supplement my retirement income through loans and withdrawals.",
                fr: 'Une protection qui coûte plus cher, mais qui protège mes proches et offre la possibilité de compléter mon revenu de retraite par des prêts et des retraits de police',
              },
            },
            {
              id: 'lowerCost',
              text: {
                en: 'Protection that costs less and ensures income-replacement long term.',
                fr: "Une protection à faible coût qui permet à mes proches de disposer d'un revenu pour vivre",
              },
            },
          ],
        },
      ],
    },
  ],
};

const retirementCurrentFinancialHealthStep: Subsection = {
  id: NeedsAnalysisStepId.retirementCurrentFinancialHealthStatus,
  blueprintId: '48045515-eac7-404c-a7c9-670fc91c1e21',
  scopeFilter: [InsuranceScopes.retirement],
  index: '1.3.6',
  title: {
    en: 'What is your current financial health?',
    fr: 'Quel est votre santé financière actuelle?',
  },
  questions: [
    {
      id: NeedsAnalysisStepId.retirementCurrentFinancialHealthStatus,
      blueprintId: '8ceb50d6-9255-4426-ab72-3a08eaacac85',
      fields: [
        {
          id: 'financialStatus',
          blueprintId: 'd2d482c1-39d6-4f79-b191-b896f4fab68c',
          type: FieldTypes.radio,
          validation: { type: Validations.string },
          options: [
            {
              id: CurrentFinancialHealth.poor,
              title: {
                en: 'Weak',
                fr: 'Faible',
              },
              text: {
                en: 'I have 3 or more outstanding credit card debt(s).',
                fr: "J'ai au moins 3 dettes de carte de crédit impayées.",
              },
              iconName: IconName.poorFinancialHealth,
            },
            {
              id: CurrentFinancialHealth.average,
              title: {
                en: 'Average',
                fr: 'La moyenne',
              },
              text: {
                en: 'I understand my finances but need help understanding how to start saving.',
                fr: "Je comprends mes finances, mais j'ai besoin d'aide pour comprendre comment commencer à épargner.",
              },
              iconName: IconName.averageFinancialHealth,
            },
            {
              id: CurrentFinancialHealth.good,
              title: {
                en: 'Savvy',
                fr: 'Avisé',
              },
              text: {
                en: 'I know what I have. I understand my savings and am not burdened with credit card debt.',
                fr: "Je sais ce que j'ai. Je comprends mes économies et je ne suis pas accablé de dettes de carte de crédit.",
              },
              iconName: IconName.goodFinancialHealth,
            },
            {
              id: CurrentFinancialHealth.excellent,
              title: {
                en: 'Expert',
                fr: 'Expert',
              },
              text: {
                en: 'I fully understand my finances. I have control over my savings, am not living paycheck to paycheck, and have no credit card debt.',
                fr: "Je comprends parfaitement mes finances. J'ai le contrôle de mes économies, je ne vis pas de chèque de paie à chèque de paie et je n'ai aucune dette de carte de crédit.",
              },
              iconName: IconName.excellentFinancialHealth,
            },
          ],
          nodeId: NodeIds.retirementCurrentFinancialHealthStatus,
          dataLabel: DataLabel.KnownSafe,
        },
      ],
    },
  ],
};

const retirementIsCurrentlySavingStep: Subsection = {
  id: NeedsAnalysisStepId.retirementIsCurrentlySaving,
  blueprintId: '14b84e2d-ca5c-47cc-986d-75ca9d35a691',
  scopeFilter: [InsuranceScopes.retirement],
  index: '1.3.7',
  title: {
    en: 'Are you currently saving for your retirement?',
    fr: 'Épargnez-vous actuellement pour votre retraite?',
  },
  questions: [
    {
      id: NeedsAnalysisStepId.retirementIsCurrentlySaving,
      blueprintId: 'ccf7ec6e-a079-4f5a-a11c-74e9efb0a522',
      fields: [
        {
          id: 'isCurrentlySaving',
          blueprintId: '20d6d96b-3630-4424-9a87-41103e6006b1',
          type: FieldTypes.radio,
          validation: { type: Validations.string },
          options: [
            {
              id: 'yes',
              title: {
                en: 'Yes',
                fr: 'Oui',
              },
              text: {
                en: "I'd like to make sure my savings are safe and growing for my future.",
                fr: "Je voudrais m'assurer que mes économies sont sûres et croissantes pour mon avenir.",
              },
            },
            {
              id: 'some',
              title: {
                en: 'Some',
                fr: 'Un peu',
              },
              text: {
                en: 'While I do save, I wish I could save more.',
                fr: "Bien que j'économise, j'aimerais pouvoir économiser davantage.",
              },
            },
            {
              id: 'no',
              title: {
                en: 'No',
                fr: 'Non',
              },
              text: {
                en: "I haven't started saving yet. I need help jump starting my savings.",
                fr: "Je n'ai pas encore commencé à épargner. J'ai besoin d'aide pour démarrer mes économies.",
              },
            },
          ],
          nodeId: NodeIds.retirementIsCurrentlySaving,
          dataLabel: DataLabel.KnownSafe,
        },
      ],
    },
  ],
};

const retirementSavingsObstaclesStep: Subsection = {
  id: NeedsAnalysisStepId.retirementSavingsObstacles,
  blueprintId: '0fe12dea-33be-45c5-9fb3-cb5ba2a9d0f2',
  scopeFilter: [InsuranceScopes.retirement],
  index: '1.3.8',
  title: {
    en: 'What obstacles prevent you from saving more for retirement? (Please select all that apply.)',
    fr: "Quels obstacles vous empêchent d'épargner davantage pour la retraite? (Veuillez sélectionner tout ceux qui s'appliquent.)",
  },
  questions: [
    {
      id: NeedsAnalysisStepId.retirementSavingsObstacles,
      blueprintId: '4667e61e-2c2e-47d0-b360-816aea762003',
      fields: [
        {
          id: 'savingsObstacles',
          blueprintId: '86c0e73a-055a-4e4c-a7b5-7aa7ae87a66c',
          type: FieldTypes.checkboxGroup,
          validation: { type: Validations.string },
          options: [
            {
              id: SavingsObstacles.notUnderstandingAssets,
              text: {
                en: "I don't understand what I have.",
                fr: "Je ne comprends pas ce que j'ai.",
              },
            },
            {
              id: SavingsObstacles.notUnderstandingOptions,
              text: {
                en: "I don't understand what my options are.",
                fr: 'Je ne comprends pas quelles sont mes options.',
              },
            },
            {
              id: SavingsObstacles.livingPaycheckToPaycheck,
              text: {
                en: 'I am living paycheck to paycheck.',
                fr: 'Je vis de chèque de paie à chèque de paie',
              },
            },
            {
              id: SavingsObstacles.debts,
              text: {
                en: 'I am burdened with debt (credit card, student loans, mortgage, etc.)',
                fr: 'Je suis chargé de dettes (carte de crédit, prêts étudiants, hypothèque, etc.)',
              },
            },
            {
              id: SavingsObstacles.pensionWillCoverNeeds,
              text: {
                en: "I don't think I need to save. I have my pension plan that will cover all my needs.",
                fr: "Je ne pense pas avoir besoin de sauver. J'ai ma pension qui couvrira tous mes besoins.",
              },
            },
          ],
          nodeId: NodeIds.retirementSavingsObstacles,
          dataLabel: DataLabel.KnownSafe,
        },
      ],
    },
  ],
};

const retirementSavingsGoalsStep: Subsection = {
  id: NeedsAnalysisStepId.retirementSavingsGoals,
  blueprintId: 'b93bb8c9-61ea-463d-9e05-313f2111bc4d',
  scopeFilter: [InsuranceScopes.retirement],
  index: '1.3.9',
  title: {
    en: 'What matters more to you?',
    fr: "Qu'est-ce qui compte le plus pour vous?",
  },
  questions: [
    {
      id: NeedsAnalysisStepId.retirementSavingsGoals,
      blueprintId: '8dc782f3-8360-4dfb-9e61-0f17caa4924f',
      fields: [
        {
          id: 'savingsGoals',
          blueprintId: '3a34f3b7-2d08-4848-90f2-5e8be9ed5ac6',
          type: FieldTypes.radio,
          validation: { type: Validations.string },
          options: [
            {
              id: SavingsGoals.generateIncomeOverTime,
              text: {
                en: 'Generating an income over time without worrying about running out of money.',
                fr: "Générer un revenu au fil du temps sans craindre de manquer d'argent.",
              },
            },
            {
              id: SavingsGoals.manageOwnIncomeInRetirement,
              text: {
                en: 'I want to manage my income myself in retirement.',
                fr: 'Je veux gérer moi-même mes revenus pour la retraite.',
              },
            },
          ],
          nodeId: NodeIds.retirementSavingsGoals,
          dataLabel: DataLabel.KnownSafe,
        },
      ],
    },
  ],
};

const retirementSavingsPreferenceStep: Subsection = {
  id: NeedsAnalysisStepId.retirementSavingsPreference,
  blueprintId: '53e5d2a7-42f5-46ea-8235-a48aa10cadee',
  scopeFilter: [InsuranceScopes.retirement],
  index: '1.3.10',
  title: {
    en: 'Which would you prefer?',
    fr: 'Que préféreriez-vous?',
  },
  questions: [
    {
      id: NeedsAnalysisStepId.retirementSavingsPreference,
      blueprintId: '59ca528d-7070-4385-9107-bd9737b883c8',
      fields: [
        {
          id: 'savingsPreference',
          blueprintId: 'cabc7e0b-29bc-4c9b-aba6-2e65caa9f0ab',
          type: FieldTypes.radio,
          validation: { type: Validations.string },
          options: [
            {
              id: SavingsPreference.regularSmallContributionsOverLongerTime,
              text: {
                en: 'Contributing small amounts regularly to build savings over a longer time horizon.',
                fr: 'Contribuer régulièrement à de petites sommes pour réaliser des économies sur le long terme.',
              },
            },
            {
              id: SavingsPreference.largeContributionsOverShorterTime,
              text: {
                en: 'Contributing larger amounts now to build savings over a shorter time horizon.',
                fr: 'Contribuer maintenant à des montants plus importants pour réaliser des économies sur le court terme.',
              },
            },
          ],
          nodeId: NodeIds.retirementSavingsPreference,
          dataLabel: DataLabel.KnownSafe,
        },
      ],
    },
  ],
};

export const productNeedsAnalysisSection: Section = {
  id: 'productNeedsAnalysis',
  blueprintId: 'e5667baa-8552-4217-b538-ee0a2b6c95c7',
  index: '1.3',
  title: {
    en: 'Product needs analysis',
    fr: 'Analyse des besoins de produit',
  },
  documentTypes: [PdfDocumentType.application, PdfDocumentType.advisorsReport],
  platformTypeFilter: [PlatformType.consumer],
  insuranceModuleFilter: [InsuranceModule.needsAnalysis],
  renderingTypeFilter: [RenderingType.web, RenderingType.proSummary],
  subsections: [
    currentHealthStep,
    futureGoalsStep,
    coverageDurationStep,
    preferenceStep,
    mattersStep,
    retirementCurrentFinancialHealthStep,
    retirementIsCurrentlySavingStep,
    retirementSavingsObstaclesStep,
    retirementSavingsGoalsStep,
    retirementSavingsPreferenceStep,
  ],
};

import { overrideQuestion, Section } from '@breathelife/questionnaire-engine';
import { PdfDocumentType, FieldSizes, InsuranceModule, PlatformType, RenderingType } from '@breathelife/types';

import { InsuranceApplicationStepId } from '../../../models/steps';
import { baseContactQuestion } from './baseContactQuestion';

const proposedInsuredQuestion = overrideQuestion(baseContactQuestion(), {
  id: InsuranceApplicationStepId.proposedInsuredInformation,
  fields: [
    {
      id: 'apartment',
      layout: { size: FieldSizes.half },
    },
    {
      id: 'city',
      layout: { size: FieldSizes.half },
    },
    {
      id: 'province',
      layout: { size: FieldSizes.half },
    },
    {
      id: 'postalCode',
      layout: { size: FieldSizes.half },
    },
    {
      id: 'confirm.email',
      layout: { size: FieldSizes.half },
    },
    {
      id: 'confirm.phoneNumber',
      layout: { size: FieldSizes.half },
    },
  ],
});

export const proposedInsuredSection: Section = {
  id: InsuranceApplicationStepId.proposedInsuredInformation,
  blueprintId: 'e556a9bd-ae3d-455e-85db-7b1ff58a3fb7',
  index: '',
  title: { en: "Proposed Insured's Information", fr: 'Identification de la personne à assurer' },
  documentTypes: [PdfDocumentType.application, PdfDocumentType.advisorsReport],
  insuranceModuleFilter: [InsuranceModule.insuranceApplication],
  platformTypeFilter: [PlatformType.producer],
  renderingTypeFilter: [RenderingType.web, RenderingType.pdf],
  subsections: [
    {
      id: InsuranceApplicationStepId.proposedInsuredInformation,
      blueprintId: '5697c32e-4562-4a41-bf65-10ac2d4a8080',
      index: '',
      title: { en: '', fr: '' },
      questions: [proposedInsuredQuestion],
    },
  ],
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const breastDisorder: AnswerPathTreeNode = {
  path: 'breastDisorder',
  children: [
    { path: 'hasCondition', nodeId: NodeIds.healthInsuredBreastDisorderHasCondition },
    { path: 'applicableConditions', nodeId: NodeIds.healthInsuredBreastDisorderApplicableConditions },
  ],
};

import { agreeFieldIsAccepted, FieldTypes, Section, Validations } from '@breathelife/questionnaire-engine';
import { PdfDocumentType, IconName, InsuranceModule, RenderingType } from '@breathelife/types';

import { InsuranceApplicationStepId } from '../../models/steps';
import { NodeIds } from '../../nodeIds/nodeIds';
import { consentGeneral, consentPreviousInformation } from './consentText';

export const defaultAgreeLabel = { fr: 'Voir et accepter le préavis', en: 'Read and accept the notice' };
export const defaultAgreeConfirmedLabel = { fr: 'Préavis accepté', en: 'Notice accepted' };

export function buildConsentSection(): Section {
  return {
    id: 'consent',
    blueprintId: 'f9b7c1f4-c2c6-4000-8132-f14b98256cf5',
    index: '',
    title: { en: 'Consent', fr: 'Consentement' },
    documentTypes: [PdfDocumentType.application, PdfDocumentType.advisorsReport],
    renderingTypeFilter: [RenderingType.web, RenderingType.pdf],
    insuranceModuleFilter: [InsuranceModule.insuranceApplication],
    subsections: [
      {
        id: InsuranceApplicationStepId.consentGeneral,
        blueprintId: 'ad12b5b7-0b8e-4f58-91ea-730033fdec54',
        index: '',
        title: {
          fr: 'Déclarations, autorisations et signatures',
          en: 'Declarations, authorizations and signatures',
        },
        iconName: IconName.confirmation,
        questions: [
          {
            id: 'consent.general',
            blueprintId: 'f78a6fe1-2b7a-4584-9a07-69a58d30f65c',
            fields: [
              {
                id: 'consent.general',
                blueprintId: '88937a09-1540-458f-bc68-5aecd50dabc4',
                nodeId: NodeIds.consentGeneral,
                type: FieldTypes.agree,
                label: defaultAgreeLabel,
                confirmedLabel: defaultAgreeConfirmedLabel,
                modalHeader: {
                  fr: 'Déclarations, autorisations et signatures',
                  en: 'Declarations, authorizations and signatures',
                },
                modalText: { en: consentGeneral.en, fr: consentGeneral.fr },
                validation: { type: Validations.boolean },
                validIf: [agreeFieldIsAccepted(NodeIds.consentGeneral)],
              },
            ],
          },
        ],
      },
      {
        id: InsuranceApplicationStepId.consentPreviousRecords,
        blueprintId: 'c82bdd64-d1cd-4a30-8c38-8abe6afb8cb0',
        index: '',
        title: { fr: 'Autorisation', en: 'Authorization' },
        iconName: IconName.confirmation,
        questions: [
          {
            id: 'consent.previousRecords',
            blueprintId: 'e072e3e7-8356-4ef1-a3d7-c1a03c6ddbc5',
            fields: [
              {
                id: 'consent.previousRecords',
                blueprintId: '0b042878-211e-4525-bec0-6f67098fa09b',
                nodeId: NodeIds.consentPreviousInformation,
                type: FieldTypes.agree,
                label: defaultAgreeLabel,
                confirmedLabel: defaultAgreeConfirmedLabel,
                modalHeader: { fr: 'Autorisation', en: 'Authorization' },
                modalText: { en: consentPreviousInformation.en, fr: consentPreviousInformation.fr },
                validation: { type: Validations.boolean },
                validIf: [agreeFieldIsAccepted(NodeIds.consentPreviousInformation)],
              },
            ],
          },
        ],
      },
    ],
  };
}

import { overrideQuestion, Section } from '@breathelife/questionnaire-engine';
import { PdfDocumentType, IconName, InsuranceModule, PlatformType, RenderingType } from '@breathelife/types';

import { InsuranceApplicationStepId } from '../../../models/steps';
import { baseContactQuestion } from './baseContactQuestion';

const contactAndConfirmationQuestion = overrideQuestion(baseContactQuestion(), {
  id: InsuranceApplicationStepId.contactAndConfirmation,
});

export function buildContactAndConfirmationSection(): Section {
  return {
    id: InsuranceApplicationStepId.contactAndConfirmation,
    blueprintId: '41472d32-fe30-4ce6-be92-ff14099626cd',
    index: '',
    title: { en: 'Contact Information', fr: 'Vos informations' },
    documentTypes: [PdfDocumentType.application, PdfDocumentType.advisorsReport],
    insuranceModuleFilter: [InsuranceModule.insuranceApplication],
    platformTypeFilter: [PlatformType.consumer],
    renderingTypeFilter: [RenderingType.web, RenderingType.pdf, RenderingType.consumerSummary],
    subsections: [
      {
        id: InsuranceApplicationStepId.contactAndConfirmation,
        blueprintId: 'c4b1db7c-403c-41cf-8d8a-6b2adcb625bc',
        index: '',
        title: { en: 'Contact Information', fr: 'Vos informations' },
        text: {
          en: `In order to properly follow up on your file, confirm your contact information below.`,
          fr: `Afin d'assurer un suivi adéquat de votre dossier, veuillez compléter vos informations ci-dessous.`,
        },
        iconName: IconName.personalInformation,
        questions: [contactAndConfirmationQuestion],
      },
    ],
  };
}

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const highCholesterol: AnswerPathTreeNode = {
  path: 'highCholesterol',
  children: [
    { path: 'details', nodeId: NodeIds.healthInsuredCirculatorySystemCholesterolDetails },
    { path: 'diagnosisDate', nodeId: NodeIds.healthInsuredHighCholesterolDiagnosisDate },
    {
      path: 'isControlled',
      nodeId: NodeIds.healthInsuredHighCholesterolConditionIsControlled,
    },
    { path: 'highCholesterolDetails', nodeId: NodeIds.healthInsuredHighCholesterolDetails },
  ],
};

import { DataLabel } from '@breathelife/meta-cruncher';
import { FieldTypes, Section, Subsection, Validations } from '@breathelife/questionnaire-engine';
import {
  BooleanOperator,
  ComparisonOperator,
  PdfDocumentType,
  FieldSizes,
  IconName,
  InsuranceModule,
  PlatformType,
  RenderingType,
  SubsectionVariant,
} from '@breathelife/types';

import { NeedsAnalysisStepId } from '../../models/steps';
import { NodeIds } from '../../nodeIds/nodeIds';

const quoterStep: Subsection = {
  id: NeedsAnalysisStepId.quoter,
  blueprintId: '7f0903bc-7d5d-4de4-a838-020ad676da95',
  dataLabel: DataLabel.KnownSafe,
  index: '1.5.1',
  iconName: IconName.coverage,
  variant: SubsectionVariant.quoter,
  variantOptions: {
    simpleQuoter: false,
    productNodeId: NodeIds.productId,
    coverageAmountNodeId: NodeIds.coverageAmount,
  },
  selfServeBlockedIf: {
    operator: BooleanOperator.or,
    conditions: [
      {
        nodeId: NodeIds.otherLifeInsurancePolicesWillBeReplaced,
        operator: ComparisonOperator.equal,
        value: 'yes',
      },
      {
        nodeId: NodeIds.insuredEnglishOrFrench,
        operator: ComparisonOperator.equal,
        value: 'no',
      },
    ],
  },
  // TODO: The title looks off in English vs French, verify with product team
  title: {
    en: "Here's what we think you need in terms of coverage",
    fr: 'Voici ce que je recommande pour votre couverture',
  },
  questions: [
    {
      id: NeedsAnalysisStepId.quoter,
      blueprintId: '0d528c97-d112-4766-82b2-9cd36b256265',
      fields: [
        {
          id: 'coverageAmount',
          blueprintId: 'da85f630-fce7-4d31-91ac-7e1ecee52eb5',
          nodeId: NodeIds.coverageAmount,
          type: FieldTypes.money,
          validation: { type: Validations.integer },
          layout: { size: FieldSizes.full },
        },
        {
          id: 'productId',
          blueprintId: '03105aa8-a14e-4a5d-8441-e216458f067d',
          nodeId: NodeIds.productId,
          type: FieldTypes.input,
          validation: { type: Validations.string },
        },
      ],
    },
  ],
};

export const quoterSection: Section = {
  id: 'quoter',
  blueprintId: '1ae0d27e-e250-47ce-bd49-d951a649a4c3',
  index: '1.5',
  title: {
    en: 'Quoter',
    fr: 'Soumission',
  },
  documentTypes: [PdfDocumentType.application, PdfDocumentType.advisorsReport],
  platformTypeFilter: [PlatformType.consumer],
  insuranceModuleFilter: [InsuranceModule.insuranceApplication],
  renderingTypeFilter: [RenderingType.web],
  subsections: [quoterStep],
};

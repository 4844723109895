import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const lifestyleSecondOwner: AnswerPathTreeNode = {
  path: 'lifestyleSecondOwner',
  children: [
    {
      path: 'alcohol',
      children: [
        {
          path: 'use',
          children: [
            { path: 'use', nodeId: NodeIds.lifestyleSecondOwnerAlcoholUse },
            { path: 'frequency', nodeId: NodeIds.lifestyleSecondOwnerAlcoholUseFrequency },
            { path: 'quantity', nodeId: NodeIds.lifestyleSecondOwnerAlcoholUseQuantity },
            { path: 'history', nodeId: NodeIds.lifestyleSecondOwnerAlcoholUseHistory },
            { path: 'historyFrequency', nodeId: NodeIds.lifestyleSecondOwnerAlcoholUseHistoryFrequency },
            { path: 'historyQuantity', nodeId: NodeIds.lifestyleSecondOwnerAlcoholUseHistoryQuantity },
          ],
        },
        {
          path: 'advisedOrCeaseConsumption',
          children: [
            { path: 'advisedOrCeaseConsumption', nodeId: NodeIds.lifestyleSecondOwnerAlcoholAdvisedOrCeaseConsumption },
            { path: 'date', nodeId: NodeIds.lifestyleSecondOwnerAlcoholAdvisedOrCeaseConsumptionDate },
            {
              path: 'professionalContactInformation',
              nodeId: NodeIds.lifestyleSecondOwnerAlcoholAdvisedOrCeaseConsumptionProfessionalContactInformation,
            },
          ],
        },
        {
          path: 'therapyOrTreatment',
          children: [
            { path: 'hasTherapy', nodeId: NodeIds.lifestyleSecondOwnerAlcoholHasTherapy },
            { path: 'startDate', nodeId: NodeIds.lifestyleSecondOwnerAlcoholTherapyOrTreatmentStartDate },
            { path: 'endDate', nodeId: NodeIds.lifestyleSecondOwnerAlcoholTherapyOrTreatmentEndDate },
            { path: 'type', nodeId: NodeIds.lifestyleSecondOwnerAlcoholTherapyOrTreatmentType },
            {
              path: 'professionalContactInformation',
              nodeId: NodeIds.lifestyleSecondOwnerAlcoholTherapyOrTreatmentProfessionalContactInformation,
            },
            { path: 'additionalPeriod', nodeId: NodeIds.lifestyleSecondOwnerAlcoholTherapyOrTreatmentAdditionalPeriod },
            {
              path: 'lastDateOfTheLastTreatment',
              nodeId: NodeIds.lifestyleSecondOwnerAlcoholLastDateOfTheLastTreatment,
            },
          ],
        },
        {
          path: 'supportGroup',
          children: [
            { path: 'meetings', nodeId: NodeIds.lifestyleSecondOwnerAlcoholSupportGroupMeetings },
            { path: 'stillAttending', nodeId: NodeIds.lifestyleSecondOwnerAlcoholSupportGroupStillAttending },
            { path: 'startDate', nodeId: NodeIds.lifestyleSecondOwnerAlcoholSupportGroupStartDate },
            { path: 'endDate', nodeId: NodeIds.lifestyleSecondOwnerAlcoholSupportGroupEndDate },
          ],
        },
        {
          path: 'changeHabitConsumption',
          children: [
            { path: 'date', nodeId: NodeIds.lifestyleSecondOwnerAlcoholChangeHabitConsumptionDate },
            { path: 'reason', nodeId: NodeIds.lifestyleSecondOwnerAlcoholChangeHabitConsumptionReason },
          ],
        },
      ],
    },
    {
      path: 'drugsOrNarcotics',
      children: [
        {
          path: 'marijuana',
          children: [
            { path: 'use', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaUse },
            { path: 'useForm', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaUseForm },
            { path: 'useFormDetails', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaUseFormDetails },
            {
              path: 'joint',
              children: [
                {
                  path: 'consumptionHabits',
                  children: [
                    { path: 'quantity', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaJointConsumptionHabitsQuantity },
                    { path: 'frequency', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaJointConsumptionHabitsFrequency },
                    { path: 'usageDate', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaJointConsumptionHabitsUsageDate },
                    { path: 'usageType', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaJointConsumptionHabitsUsageType },
                  ],
                },
                {
                  path: 'consumptionMedical',
                  children: [
                    {
                      path: 'condition',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaJointConsumptionMedicalCondition,
                    },
                    {
                      path: 'prescribed',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaJointConsumptionMedicalPrescribed,
                    },
                    {
                      path: 'prescribingPhysician',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaJointConsumptionMedicalPrescribingPhysician,
                    },
                    { path: 'pastUse', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaJointPastUse },
                  ],
                },
                {
                  path: 'pastConsumptionHabits',
                  children: [
                    {
                      path: 'quantity',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaJointPastConsumptionHabitsQuantity,
                    },
                    {
                      path: 'frequency',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaJointPastConsumptionHabitsFrequency,
                    },
                    {
                      path: 'usageType',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaJointPastConsumptionHabitsUsageType,
                    },
                  ],
                },
                {
                  path: 'pastConsumptionMedical',
                  children: [
                    {
                      path: 'condition',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaJointPastConsumptionMedicalCondition,
                    },
                    {
                      path: 'prescribed',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaJointPastConsumptionMedicalPrescribed,
                    },
                    {
                      path: 'prescribingPhysician',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaJointPastConsumptionMedicalPrescribingPhysician,
                    },
                  ],
                },
                {
                  path: 'changeHabitConsumption',
                  children: [
                    { path: 'date', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaJointChangeHabitConsumptionDate },
                    { path: 'reason', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaJointChangeHabitConsumptionReason },
                  ],
                },
              ],
            },
            {
              path: 'edible',
              children: [
                {
                  path: 'consumptionHabits',
                  children: [
                    { path: 'quantity', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaEdibleConsumptionHabitsQuantity },
                    {
                      path: 'frequency',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaEdibleConsumptionHabitsFrequency,
                    },
                    {
                      path: 'usageDate',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaEdibleConsumptionHabitsUsageDate,
                    },
                    {
                      path: 'usageType',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaEdibleConsumptionHabitsUsageType,
                    },
                  ],
                },
                {
                  path: 'consumptionMedical',
                  children: [
                    {
                      path: 'condition',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaEdibleConsumptionMedicalCondition,
                    },
                    {
                      path: 'prescribed',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaEdibleConsumptionMedicalPrescribed,
                    },
                    {
                      path: 'prescribingPhysician',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaEdibleConsumptionMedicalPrescribingPhysician,
                    },
                    { path: 'pastUse', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaEdiblePastUse },
                  ],
                },
                {
                  path: 'pastConsumptionHabits',
                  children: [
                    {
                      path: 'quantity',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaEdiblePastConsumptionHabitsQuantity,
                    },
                    {
                      path: 'frequency',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaEdiblePastConsumptionHabitsFrequency,
                    },
                    {
                      path: 'usageType',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaEdiblePastConsumptionHabitsUsageType,
                    },
                  ],
                },
                {
                  path: 'pastConsumptionMedical',
                  children: [
                    {
                      path: 'condition',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaEdiblePastConsumptionMedicalCondition,
                    },
                    {
                      path: 'prescribed',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaEdiblePastConsumptionMedicalPrescribed,
                    },
                    {
                      path: 'prescribingPhysician',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaEdiblePastConsumptionMedicalPrescribingPhysician,
                    },
                  ],
                },
                {
                  path: 'changeHabitConsumption',
                  children: [
                    { path: 'date', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaEdibleChangeHabitConsumptionDate },
                    { path: 'reason', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaEdibleChangeHabitConsumptionReason },
                  ],
                },
              ],
            },
            {
              path: 'oil',
              children: [
                {
                  path: 'consumptionHabits',
                  children: [
                    { path: 'quantity', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOilConsumptionHabitsQuantity },
                    { path: 'frequency', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOilConsumptionHabitsFrequency },
                    { path: 'usageDate', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOilConsumptionHabitsUsageDate },
                    { path: 'usageType', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOilConsumptionHabitsUsageType },
                  ],
                },
                {
                  path: 'consumptionMedical',
                  children: [
                    { path: 'condition', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOilConsumptionMedicalCondition },
                    {
                      path: 'prescribed',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOilConsumptionMedicalPrescribed,
                    },
                    {
                      path: 'prescribingPhysician',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOilConsumptionMedicalPrescribingPhysician,
                    },
                    { path: 'pastUse', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOilPastUse },
                  ],
                },
                {
                  path: 'pastConsumptionHabits',
                  children: [
                    { path: 'quantity', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOilPastConsumptionHabitsQuantity },
                    {
                      path: 'frequency',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOilPastConsumptionHabitsFrequency,
                    },
                    {
                      path: 'usageType',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOilPastConsumptionHabitsUsageType,
                    },
                  ],
                },
                {
                  path: 'pastConsumptionMedical',
                  children: [
                    {
                      path: 'condition',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOilPastConsumptionMedicalCondition,
                    },
                    {
                      path: 'prescribed',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOilPastConsumptionMedicalPrescribed,
                    },
                    {
                      path: 'prescribingPhysician',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOilPastConsumptionMedicalPrescribingPhysician,
                    },
                  ],
                },
                {
                  path: 'changeHabitConsumption',
                  children: [
                    { path: 'date', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOilChangeHabitConsumptionDate },
                    { path: 'reason', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOilChangeHabitConsumptionReason },
                  ],
                },
              ],
            },
            {
              path: 'other',
              children: [
                {
                  path: 'consumptionHabits',
                  children: [
                    { path: 'quantity', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOtherConsumptionHabitsQuantity },
                    { path: 'frequency', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOtherConsumptionHabitsFrequency },
                    { path: 'usageDate', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOtherConsumptionHabitsUsageDate },
                    { path: 'usageType', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOtherConsumptionHabitsUsageType },
                  ],
                },
                {
                  path: 'consumptionMedical',
                  children: [
                    {
                      path: 'condition',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOtherConsumptionMedicalCondition,
                    },
                    {
                      path: 'prescribed',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOtherConsumptionMedicalPrescribed,
                    },
                    {
                      path: 'prescribingPhysician',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOtherConsumptionMedicalPrescribingPhysician,
                    },
                    { path: 'pastUse', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOtherPastUse },
                  ],
                },
                {
                  path: 'pastConsumptionHabits',
                  children: [
                    {
                      path: 'quantity',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOtherPastConsumptionHabitsQuantity,
                    },
                    {
                      path: 'frequency',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOtherPastConsumptionHabitsFrequency,
                    },
                    {
                      path: 'usageType',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOtherPastConsumptionHabitsUsageType,
                    },
                  ],
                },
                {
                  path: 'pastConsumptionMedical',
                  children: [
                    {
                      path: 'condition',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOtherPastConsumptionMedicalCondition,
                    },
                    {
                      path: 'prescribed',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOtherPastConsumptionMedicalPrescribed,
                    },
                    {
                      path: 'prescribingPhysician',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOtherPastConsumptionMedicalPrescribingPhysician,
                    },
                  ],
                },
                {
                  path: 'changeHabitConsumption',
                  children: [
                    { path: 'date', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOtherChangeHabitConsumptionDate },
                    { path: 'reason', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaOtherChangeHabitConsumptionReason },
                  ],
                },
              ],
            },
            {
              path: 'advisedOrCeaseConsumption',
              children: [
                {
                  path: 'advisedOrCeaseConsumption',
                  nodeId: NodeIds.lifestyleSecondOwnerMarijuanaAdvisedOrCeaseConsumption,
                },
                { path: 'date', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaAdvisedOrCeaseConsumptionDate },
                {
                  path: 'professionalContactInformation',
                  nodeId: NodeIds.lifestyleSecondOwnerMarijuanaAdvisedOrCeaseConsumptionProfessionalContactInformation,
                },
              ],
            },
            {
              path: 'therapyOrTreatment',
              children: [
                { path: 'hasTherapy', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaHasTherapy },
                { path: 'startDate', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaTherapyOrTreatmentStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaTherapyOrTreatmentEndDate },
                { path: 'type', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaTherapyOrTreatmentType },
                {
                  path: 'professionalContactInformation',
                  nodeId: NodeIds.lifestyleSecondOwnerMarijuanaTherapyOrTreatmentProfessionalContactInformation,
                },
                {
                  path: 'additionalPeriod',
                  children: [
                    {
                      path: 'isOnlyPeriod',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaTherapyOrTreatmentIsOnlyPeriod,
                    },
                    {
                      path: 'details',
                      nodeId: NodeIds.lifestyleSecondOwnerMarijuanaTherapyOrTreatmentAdditionalPeriodDetails,
                    },
                  ],
                },
              ],
            },
            {
              path: 'supportGroup',
              children: [
                { path: 'meetings', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaSupportGroupMeetings },
                { path: 'stillAttending', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaSupportGroupStillAttending },
                { path: 'startDate', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaSupportGroupStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaSupportGroupEndDate },
              ],
            },
            {
              path: 'pastUse',
              children: [
                { path: 'type', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaPastUseType },
                { path: 'typeOther', nodeId: NodeIds.lifestyleSecondOwnerMarijuanaPastUseTypeOther },
              ],
            },
          ],
        },
        {
          path: 'use',
          children: [
            { path: 'use', nodeId: NodeIds.lifestyleSecondOwnerDrugsOrNarcoticsUse },
            { path: 'type', nodeId: NodeIds.lifestyleSecondOwnerDrugsOrNarcoticsUseType },
            { path: 'formOther', nodeId: NodeIds.lifestyleSecondOwnerDrugsOrNarcoticsUseFormOther },
          ],
        },
        {
          path: 'consumptionAndTreatment',
          children: [
            {
              path: 'advisedOrCeaseConsumption',
              nodeId: NodeIds.lifestyleSecondOwnerDrugsOrNarcoticsAdvisedOrCeaseConsumption,
            },
            { path: 'hasTherapy', nodeId: NodeIds.lifestyleSecondOwnerDrugsOrNarcoticsHasTherapy },
            { path: 'supportGroupMeetings', nodeId: NodeIds.lifestyleSecondOwnerDrugsOrNarcoticsSupportGroupMeetings },
          ],
        },
        {
          path: 'advisedOrCeaseConsumption',
          children: [
            {
              path: 'date',
              nodeId: NodeIds.lifestyleSecondOwnerDrugsOrNarcoticsAdvisedOrCeaseConsumptionDate,
            },
            {
              path: 'professionalContactInformation',
              nodeId:
                NodeIds.lifestyleSecondOwnerDrugsOrNarcoticsAdvisedOrCeaseConsumptionProfessionalContactInformation,
            },
          ],
        },
        {
          path: 'therapyOrTreatment',
          children: [
            {
              path: 'startDate',
              nodeId: NodeIds.lifestyleSecondOwnerDrugsOrNarcoticsTherapyOrTreatmentStartDate,
            },
            { path: 'endDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsOrNarcoticsTherapyOrTreatmentEndDate },
            { path: 'type', nodeId: NodeIds.lifestyleSecondOwnerDrugsOrNarcoticsTherapyOrTreatmentType },
            {
              path: 'professionalContactInformation',
              nodeId: NodeIds.lifestyleSecondOwnerDrugsOrNarcoticsTherapyOrTreatmentProfessionalContactInformation,
            },
            {
              path: 'additionnalPeriod',
              nodeId: NodeIds.lifestyleSecondOwnerDrugsOrNarcoticsTherapyOrTreatmentAdditionnalPeriod,
            },
            {
              path: 'lastDateOfTheLastTreatment',
              nodeId: NodeIds.lifestyleSecondOwnerDrugsOrNarcoticsLastDateOfTheLastTreatment,
            },
          ],
        },
        {
          path: 'supportGroup',
          children: [
            { path: 'stillAttending', nodeId: NodeIds.lifestyleSecondOwnerDrugsOrNarcoticsSupportGroupStillAttending },
            { path: 'startDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsOrNarcoticsSupportGroupStartDate },
            { path: 'endDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsOrNarcoticsSupportGroupEndDate },
          ],
        },
        {
          path: 'cocaine',
          children: [
            {
              path: 'use',
              children: [
                { path: 'stillConsume', nodeId: NodeIds.lifestyleSecondOwnerDrugsCocaineUseStillConsume },
                { path: 'startDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsCocaineUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsCocaineUseEndDate },
              ],
            },
            {
              path: 'consumptionHabits',
              children: [
                { path: 'quantity', nodeId: NodeIds.lifestyleSecondOwnerDrugsCocaineConsumptionHabitsQuantity },
                { path: 'frequency', nodeId: NodeIds.lifestyleSecondOwnerDrugsCocaineConsumptionHabitsFrequency },
              ],
            },
            {
              path: 'pastUse',
              children: [
                { path: 'use', nodeId: NodeIds.lifestyleSecondOwnerDrugsCocainePastUse },
                { path: 'startDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsCocainePastUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsCocainePastUseEndDate },
              ],
            },
            {
              path: 'pastConsumptionHabits',
              children: [
                {
                  path: 'quantity',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsCocainePastConsumptionHabitsQuantity,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsCocainePastConsumptionHabitsFrequency,
                },
              ],
            },
            {
              path: 'pastChangeHabitConsumption',
              children: [
                {
                  path: 'date',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsCocainePastChangeHabitConsumptionDate,
                },
                {
                  path: 'reason',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsCocainePastChangeHabitConsumptionReason,
                },
              ],
            },
          ],
        },
        {
          path: 'ecstasy',
          children: [
            {
              path: 'use',
              children: [
                { path: 'stillConsume', nodeId: NodeIds.lifestyleSecondOwnerDrugsEcstasyUseStillConsume },
                { path: 'startDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsEcstasyUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsEcstasyUseEndDate },
              ],
            },
            {
              path: 'consumptionHabits',
              children: [
                { path: 'quantity', nodeId: NodeIds.lifestyleSecondOwnerDrugsEcstasyConsumptionHabitsQuantity },
                { path: 'frequency', nodeId: NodeIds.lifestyleSecondOwnerDrugsEcstasyConsumptionHabitsFrequency },
              ],
            },
            {
              path: 'pastUse',
              children: [
                { path: 'use', nodeId: NodeIds.lifestyleSecondOwnerDrugsEcstasyPastUse },
                { path: 'startDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsEcstasyPastUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsEcstasyPastUseEndDate },
              ],
            },
            {
              path: 'pastConsumptionHabits',
              children: [
                {
                  path: 'quantity',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsEcstasyPastConsumptionHabitsQuantity,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsEcstasyPastConsumptionHabitsFrequency,
                },
              ],
            },
            {
              path: 'pastChangeHabitConsumption',
              children: [
                {
                  path: 'date',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsEcstasyPastChangeHabitConsumptionDate,
                },
                {
                  path: 'reason',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsEcstasyPastChangeHabitConsumptionReason,
                },
              ],
            },
          ],
        },
        {
          path: 'lsd',
          children: [
            {
              path: 'use',
              children: [
                { path: 'stillConsume', nodeId: NodeIds.lifestyleSecondOwnerDrugsLsdUseStillConsume },
                { path: 'startDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsLsdUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsLsdUseEndDate },
              ],
            },
            {
              path: 'consumptionHabits',
              children: [
                { path: 'quantity', nodeId: NodeIds.lifestyleSecondOwnerDrugsLsdConsumptionHabitsQuantity },
                { path: 'frequency', nodeId: NodeIds.lifestyleSecondOwnerDrugsLsdConsumptionHabitsFrequency },
              ],
            },
            {
              path: 'pastUse',
              children: [
                { path: 'use', nodeId: NodeIds.lifestyleSecondOwnerDrugsLsdPastUse },
                { path: 'startDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsLsdPastUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsLsdPastUseEndDate },
              ],
            },
            {
              path: 'pastConsumptionHabits',
              children: [
                { path: 'quantity', nodeId: NodeIds.lifestyleSecondOwnerDrugsLsdPastConsumptionHabitsQuantity },
                {
                  path: 'frequency',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsLsdPastConsumptionHabitsFrequency,
                },
              ],
            },
            {
              path: 'pastChangeHabitConsumption',
              children: [
                {
                  path: 'date',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsLsdPastChangeHabitConsumptionDate,
                },
                {
                  path: 'reason',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsLsdPastChangeHabitConsumptionReason,
                },
              ],
            },
          ],
        },
        {
          path: 'magicMushrooms',
          children: [
            {
              path: 'use',
              children: [
                { path: 'stillConsume', nodeId: NodeIds.lifestyleSecondOwnerDrugsMagicMushroomsUseStillConsume },
                { path: 'startDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsMagicMushroomsUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsMagicMushroomsUseEndDate },
              ],
            },
            {
              path: 'consumptionHabits',
              children: [
                {
                  path: 'quantity',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsMagicMushroomsConsumptionHabitsQuantity,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsMagicMushroomsConsumptionHabitsFrequency,
                },
              ],
            },
            {
              path: 'pastUse',
              children: [
                { path: 'use', nodeId: NodeIds.lifestyleSecondOwnerDrugsMagicMushroomsPastUse },
                { path: 'startDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsMagicMushroomsPastUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsMagicMushroomsPastUseEndDate },
              ],
            },
            {
              path: 'pastConsumptionHabits',
              children: [
                {
                  path: 'quantity',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsMagicMushroomsPastConsumptionHabitsQuantity,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsMagicMushroomsPastConsumptionHabitsFrequency,
                },
              ],
            },
            {
              path: 'pastChangeHabitConsumption',
              children: [
                {
                  path: 'date',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsMagicMushroomsPastChangeHabitConsumptionDate,
                },
                {
                  path: 'reason',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsMagicMushroomsPastChangeHabitConsumptionReason,
                },
              ],
            },
          ],
        },
        {
          path: 'heroin',
          children: [
            {
              path: 'use',
              children: [
                { path: 'stillConsume', nodeId: NodeIds.lifestyleSecondOwnerDrugsHeroinUseStillConsume },
                { path: 'startDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsHeroinUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsHeroinUseEndDate },
              ],
            },
            {
              path: 'consumptionHabits',
              children: [
                { path: 'quantity', nodeId: NodeIds.lifestyleSecondOwnerDrugsHeroinConsumptionHabitsQuantity },
                { path: 'frequency', nodeId: NodeIds.lifestyleSecondOwnerDrugsHeroinConsumptionHabitsFrequency },
              ],
            },
            {
              path: 'pastUse',
              children: [
                { path: 'use', nodeId: NodeIds.lifestyleSecondOwnerDrugsHeroinPastUse },
                { path: 'startDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsHeroinPastUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsHeroinPastUseEndDate },
              ],
            },
            {
              path: 'pastConsumptionHabits',
              children: [
                {
                  path: 'quantity',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsHeroinPastConsumptionHabitsQuantity,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsHeroinPastConsumptionHabitsFrequency,
                },
              ],
            },
            {
              path: 'pastChangeHabitConsumption',
              children: [
                {
                  path: 'date',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsHeroinPastChangeHabitConsumptionDate,
                },
                {
                  path: 'reason',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsHeroinPastChangeHabitConsumptionReason,
                },
              ],
            },
          ],
        },
        {
          path: 'fentanyl',
          children: [
            {
              path: 'use',
              children: [
                { path: 'stillConsume', nodeId: NodeIds.lifestyleSecondOwnerDrugsFentanylUseStillConsume },
                { path: 'startDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsFentanylUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsFentanylUseEndDate },
              ],
            },
            {
              path: 'consumptionHabits',
              children: [
                { path: 'quantity', nodeId: NodeIds.lifestyleSecondOwnerDrugsFentanylConsumptionHabitsQuantity },
                { path: 'frequency', nodeId: NodeIds.lifestyleSecondOwnerDrugsFentanylConsumptionHabitsFrequency },
              ],
            },
            {
              path: 'pastUse',
              children: [
                { path: 'use', nodeId: NodeIds.lifestyleSecondOwnerDrugsFentanylPastUse },
                { path: 'startDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsFentanylPastUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsFentanylPastUseEndDate },
              ],
            },
            {
              path: 'pastConsumptionHabits',
              children: [
                {
                  path: 'quantity',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsFentanylPastConsumptionHabitsQuantity,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsFentanylPastConsumptionHabitsFrequency,
                },
              ],
            },
            {
              path: 'pastChangeHabitConsumption',
              children: [
                {
                  path: 'date',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsFentanylPastChangeHabitConsumptionDate,
                },
                {
                  path: 'reason',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsFentanylPastChangeHabitConsumptionReason,
                },
              ],
            },
          ],
        },
        {
          path: 'anabolicSteroids',
          children: [
            {
              path: 'use',
              children: [
                { path: 'stillConsume', nodeId: NodeIds.lifestyleSecondOwnerDrugsAnabolicSteroidsUseStillConsume },
                { path: 'startDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsAnabolicSteroidsUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsAnabolicSteroidsUseEndDate },
              ],
            },
            {
              path: 'consumptionHabits',
              children: [
                {
                  path: 'quantity',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsAnabolicSteroidsConsumptionHabitsQuantity,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsAnabolicSteroidsConsumptionHabitsFrequency,
                },
              ],
            },
            {
              path: 'pastUse',
              children: [
                { path: 'use', nodeId: NodeIds.lifestyleSecondOwnerDrugsAnabolicSteroidsPastUse },
                { path: 'startDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsAnabolicSteroidsPastUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleSecondOwnerDrugsAnabolicSteroidsPastUseEndDate },
              ],
            },
            {
              path: 'pastConsumptionHabits',
              children: [
                {
                  path: 'quantity',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsAnabolicSteroidsPastConsumptionHabitsQuantity,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsAnabolicSteroidsPastConsumptionHabitsFrequency,
                },
              ],
            },
            {
              path: 'pastChangeHabitConsumption',
              children: [
                {
                  path: 'date',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsAnabolicSteroidsPastChangeHabitConsumptionDate,
                },
                {
                  path: 'reason',
                  nodeId: NodeIds.lifestyleSecondOwnerDrugsAnabolicSteroidsPastChangeHabitConsumptionReason,
                },
              ],
            },
          ],
        },
        {
          path: 'other',
          children: [
            {
              path: 'use',
              children: [
                { path: 'stillConsume', nodeId: NodeIds.lifestyleSecondOwnerOtherDrugsOrNarcoticsUseStillConsume },
                { path: 'startDate', nodeId: NodeIds.lifestyleSecondOwnerOtherDrugsOrNarcoticsUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleSecondOwnerOtherDrugsOrNarcoticsUseEndDate },
              ],
            },
            {
              path: 'consumptionHabits',
              children: [
                {
                  path: 'quantity',
                  nodeId: NodeIds.lifestyleSecondOwnerOtherDrugsOrNarcoticsConsumptionHabitsQuantity,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.lifestyleSecondOwnerOtherDrugsOrNarcoticsConsumptionHabitsFrequency,
                },
              ],
            },
            {
              path: 'changeHabitConsumption',
              children: [
                {
                  path: 'date',
                  nodeId: NodeIds.lifestyleSecondOwnerOtherDrugsOrNarcoticsChangeHabitConsumptionDate,
                },
                {
                  path: 'reason',
                  nodeId: NodeIds.lifestyleSecondOwnerOtherDrugsOrNarcoticsChangeHabitConsumptionReason,
                },
              ],
            },
            {
              path: 'pastUse',
              children: [
                { path: 'startDate', nodeId: NodeIds.lifestyleSecondOwnerOtherDrugsOrNarcoticsPastUseStartDate },
                { path: 'endDate', nodeId: NodeIds.lifestyleSecondOwnerOtherDrugsOrNarcoticsPastUseEndDate },
              ],
            },
            {
              path: 'pastChangeHabitConsumption',
              children: [
                {
                  path: 'quantity',
                  nodeId: NodeIds.lifestyleSecondOwnerOtherDrugsOrNarcoticsPastConsumptionHabitsQuantity,
                },
                {
                  path: 'frequency',
                  nodeId: NodeIds.lifestyleSecondOwnerOtherDrugsOrNarcoticsPastConsumptionHabitsFrequency,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: 'drivingRecord',
      children: [
        {
          path: 'violations',
          children: [
            { path: 'hasViolations', nodeId: NodeIds.lifestyleSecondOwnerDrivingHasViolations },
            { path: 'hasViolationsInPast3Years', nodeId: NodeIds.lifestyleSecondOwnerDrivingHasViolationsInPast3Years },
            { path: 'reasons', nodeId: NodeIds.lifestyleSecondOwnerDrivingViolationsReasons },
            {
              path: 'firstViolation',
              children: [
                { path: 'date', nodeId: NodeIds.lifestyleSecondOwner1stViolationDate },
                { path: 'movingViolationDetails', nodeId: NodeIds.lifestyleSecondOwnerMovingViolationDetails },
                { path: '1stSpeedingLimitDetails', nodeId: NodeIds.lifestyleSecondOwner1stSpeedingLimitDetails },
                { path: 'OthersDetails', nodeId: NodeIds.lifestyleSecondOwner1stDrivingViolationsOthersDetails },
              ],
            },
            {
              path: 'secondViolation',
              children: [
                { path: 'date', nodeId: NodeIds.lifestyleSecondOwner2ndViolationDate },
                { path: 'choices', nodeId: NodeIds.lifestyleSecondOwner2ndDrivingViolationsChoices },
                { path: '2ndSpeedingLimitDetails', nodeId: NodeIds.lifestyleSecondOwner2ndSpeedingLimitDetails },
                { path: 'OthersDetails', nodeId: NodeIds.lifestyleSecondOwner2ndDrivingViolationsOthersDetails },
              ],
            },
            { path: 'additionalDrivingViolations', nodeId: NodeIds.lifestyleSecondOwnerAdditionalDrivingViolations },
          ],
        },
      ],
    },
    {
      path: 'impairedDrivingOffence',
      nodeId: NodeIds.lifestyleSecondOwnerImpairedDrivingOffence,
      isCollection: true,
      children: [
        { path: 'lastOffenceDate', nodeId: NodeIds.lifestyleSecondOwnerImpairedDrivingLastOffenceDate },
        { path: 'licenseSuspensionDate', nodeId: NodeIds.lifestyleSecondOwnerImpairedDrivingLicenseSuspensionDate },
        { path: 'licenseRecoveryDate', nodeId: NodeIds.lifestyleSecondOwnerImpairedDrivingLicenseRecoveryDate },
        {
          path: 'hasIgnitionInterlockDevice',
          nodeId: NodeIds.lifestyleSecondOwnerImpairedDrivingHasIgnitionInterlockDevice,
        },
        { path: 'withSuspendedLicense', nodeId: NodeIds.lifestyleSecondOwnerImpairedDrivingWithSuspendedLicense },
        {
          path: 'anyOtherImpairedDrivingCharges',
          nodeId: NodeIds.lifestyleSecondOwnerImpairedDrivingAnyOtherImpairedDrivingCharges,
        },
        {
          path: 'anyOtherImpairedDrivingChargesDates',
          nodeId: NodeIds.lifestyleSecondOwnerImpairedDrivingAnyOtherImpairedDrivingChargesDates,
        },
      ],
    },
    {
      path: 'licenseSuspendedOtherReason',
      nodeId: NodeIds.lifestyleSecondOwnerLicenseSuspendedOtherReason,
      isCollection: true,
      children: [
        { path: 'reasons', nodeId: NodeIds.lifestyleSecondOwnerLicenseSuspendedOtherReasonReasons },
        {
          path: 'medicalReasonDetails',
          nodeId: NodeIds.lifestyleSecondOwnerLicenseSuspendedOtherReasonMedicalReasonDetails,
        },
        { path: 'otherDetails', nodeId: NodeIds.lifestyleSecondOwnerLicenseSuspendedOtherReasonOtherDetails },
        { path: 'suspensionDate', nodeId: NodeIds.lifestyleSecondOwnerLicenseSuspendedOtherReasonSuspensionDate },
        { path: 'recoveryDate', nodeId: NodeIds.lifestyleSecondOwnerLicenseSuspendedOtherReasonRecoveryDate },
        {
          path: 'droveWithSuspendedLicense',
          nodeId: NodeIds.lifestyleSecondOwnerLicenseSuspendedOtherReasonDroveWithSuspendedLicense,
        },
      ],
    },
    {
      path: 'additionalDrivingViolationDetails',
      nodeId: NodeIds.lifestyleSecondOwnerAdditionalDrivingViolationDetails,
      isCollection: true,
      children: [
        { path: 'date', nodeId: NodeIds.lifestyleSecondOwnerAdditionalDrivingViolationsDate },
        { path: 'type', nodeId: NodeIds.lifestyleSecondOwnerAdditionalDrivingViolationType },
        {
          path: 'kilometersOverSpeedLimit',
          nodeId: NodeIds.lifestyleSecondOwnerAdditionalDrivingViolationKmOverSpeedLimit,
        },
        {
          path: 'drivingViolationsOtherDetails',
          nodeId: NodeIds.lifestyleSecondOwnerAdditionalDrivingViolationsOtherDetails,
        },
      ],
    },
    {
      path: 'criminalRecord',
      children: [
        { path: 'hasCriminalHistory', nodeId: NodeIds.lifestyleSecondOwnerHasCriminalHistory },
        { path: 'hasCriminalHistory1', nodeId: NodeIds.lifestyleSecondOwnerHasCriminalHistory1 },
        { path: 'criminalHistoryChoices', nodeId: NodeIds.lifestyleSecondOwnerCriminalHistoryChoices },
        {
          path: 'underInvestigations',
          children: [
            { path: 'circumstances', nodeId: NodeIds.lifestyleSecondOwnerUnderInvestigationsCircumstances },
            { path: 'date', nodeId: NodeIds.lifestyleSecondOwnerUnderInvestigationsDate },
            { path: 'charges', nodeId: NodeIds.lifestyleSecondOwnerUnderInvestigationsCharges },
          ],
        },
        {
          path: 'awaitingTrial',
          children: [
            { path: 'circumstances', nodeId: NodeIds.lifestyleSecondOwnerTrialCircumstances },
            { path: 'date', nodeId: NodeIds.lifestyleSecondOwnerTrialDate },
            { path: 'charges', nodeId: NodeIds.lifestyleSecondOwnerTrialCharges },
          ],
        },
        {
          path: 'onProbation',
          children: [
            { path: 'startDate', nodeId: NodeIds.lifestyleSecondOwnerProbationStartDate },
            { path: 'endDate', nodeId: NodeIds.lifestyleSecondOwnerProbationEndDate },
            { path: 'circumstances', nodeId: NodeIds.lifestyleSecondOwnerProbationCircumstances },
            { path: 'date', nodeId: NodeIds.lifestyleSecondOwnerProbationDate },
            { path: 'charges', nodeId: NodeIds.lifestyleSecondOwnerProbationCharges },
            { path: 'sentenceDetails', nodeId: NodeIds.lifestyleSecondOwnerProbationSentenceDetails },
          ],
        },
        {
          path: 'otherOffences',
          children: [
            { path: 'anyOtherOffences', nodeId: NodeIds.lifestyleSecondOwnerAnyOtherOffences },
            { path: 'circumstances', nodeId: NodeIds.lifestyleSecondOwnerOtherOffencesCircumstances },
            { path: 'date', nodeId: NodeIds.lifestyleSecondOwnerOtherOffencesDate },
            { path: 'charges', nodeId: NodeIds.lifestyleSecondOwnerOtherOffencesCharges },
          ],
        },
        {
          path: 'chargedOrConvicted',
          children: [
            { path: 'circumstances', nodeId: NodeIds.lifestyleSecondOwnerChargedOrConvictedCircumstances },
            { path: 'date', nodeId: NodeIds.lifestyleSecondOwnerChargedOrConvictedDate },
            { path: 'charges', nodeId: NodeIds.lifestyleSecondOwnerChargedOrConvictedCharges },
            { path: 'sentenceDetails', nodeId: NodeIds.lifestyleSecondOwnerChargedOrConvictedSentenceDetails },
            {
              path: 'additionalOffence',
              nodeId: NodeIds.lifestyleSecondOwnerChargedOrConvictedAdditionalOffence,
            },
          ],
        },
        {
          path: 'additionalChargedOrConvictedAdditionalOffence',
          children: [
            {
              path: 'circumstances',
              nodeId: NodeIds.lifestyleSecondOwnerChargedOrConvictedAdditionalOffenceCircumstances,
            },
            {
              path: 'date',
              nodeId: NodeIds.lifestyleSecondOwnerChargedOrConvictedAdditionalOffenceOtherOffencesDate,
            },
            {
              path: 'charges',
              nodeId: NodeIds.lifestyleSecondOwnerChargedOrConvictedAdditionalOffenceCharges,
            },
          ],
        },
      ],
    },
    {
      path: 'travel',
      children: [
        { path: 'hasTravel', nodeId: NodeIds.lifestyleSecondOwnerHasTravel },
        { path: 'pastOrFutureTravelWithin12Months', nodeId: NodeIds.lifestyleSecondOwnerTravel12MonthsPastOrFuture },
      ],
    },
    { path: 'personalMedicalQuestionnaire', nodeId: NodeIds.lifestyleSecondOwnerPersonalMedicalQuestionnaire },
    { path: 'hasFlownAsOtherThanPassenger', nodeId: NodeIds.lifestyleSecondOwnerHasFlownAsOtherThanPassenger },
    {
      path: 'participation',
      children: [
        { path: 'inExtremeSport', nodeId: NodeIds.lifestyleSecondOwnerParticipationInExtremeSport },
        { path: 'inExtremeSportChoices', nodeId: NodeIds.lifestyleSecondOwnerParticipationInExtremeSportChoices },
      ],
    },
    {
      path: 'tobacco',
      children: [
        { path: 'isTobaccoUser', nodeId: NodeIds.lifestyleSecondOwnerIsTobaccoUser },
        { path: 'usedWithin12Months', nodeId: NodeIds.lifestyleSecondOwnerTobaccoUsedWithin12Months },
        { path: 'choices', nodeId: NodeIds.lifestyleSecondOwnerTobaccoChoices },
        { path: 'mostRecentUsage', nodeId: NodeIds.lifestyleSecondOwnerTobaccoMostRecentUsage },
        { path: 'lastDateTobaccoUse', nodeId: NodeIds.lifestyleSecondOwnerTobaccoLastDateTobaccoUse },
        { path: 'bigCigarsQuantity', nodeId: NodeIds.lifestyleSecondOwnerTobaccoBigCigarsQuantity },
        { path: 'lastDateBigCigars', nodeId: NodeIds.lifestyleSecondOwnerTobaccoLastDateBigCigars },
      ],
    },
    {
      path: 'travelLast12MonthsDetails',
      nodeId: NodeIds.lifestyleSecondOwnerTravelLast12MonthsDetails,
      isCollection: true,
      children: [
        { path: 'destination', nodeId: NodeIds.lifestyleSecondOwnerTravelLast12MonthsDestination },
        { path: 'destinationOther', nodeId: NodeIds.lifestyleSecondOwnerTravelLast12MonthsDestinationOther },
        { path: 'reason', nodeId: NodeIds.lifestyleSecondOwnerTravelLast12MonthsReason },
        { path: 'reasonOther', nodeId: NodeIds.lifestyleSecondOwnerTravelLast12MonthsReasonOther },
        { path: 'departureDate', nodeId: NodeIds.lifestyleSecondOwnerTravelLast12MonthsDepartureDate },
        { path: 'returnDate', nodeId: NodeIds.lifestyleSecondOwnerTravelLast12MonthsReturnDate },
      ],
    },
    {
      path: 'travelNext12MonthsDetails',
      nodeId: NodeIds.lifestyleSecondOwnerTravelNext12MonthsDetails,
      isCollection: true,
      children: [
        { path: 'destination', nodeId: NodeIds.lifestyleSecondOwnerTravelNext12MonthsDestination },
        { path: 'destinationOther', nodeId: NodeIds.lifestyleSecondOwnerTravelNext12MonthsDestinationOther },
        { path: 'reason', nodeId: NodeIds.lifestyleSecondOwnerTravelNext12MonthsReason },
        { path: 'reasonOther', nodeId: NodeIds.lifestyleSecondOwnerTravelNext12MonthsReasonOther },
        { path: 'departureDate', nodeId: NodeIds.lifestyleSecondOwnerTravelNext12MonthsDepartureDate },
        { path: 'returnDate', nodeId: NodeIds.lifestyleSecondOwnerTravelNext12MonthsReturnDate },
        { path: 'unknownDate', nodeId: NodeIds.lifestyleSecondOwnerTravelNext12MonthsUnknownDate },
      ],
    },
  ],
};

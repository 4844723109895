import { ComparisonOperator, QueryOperator } from '@breathelife/types';
import { Query } from '@breathelife/types/dist/questionnaire/conditions/conditions';

export type JointProductRelatedNodeIds = {
  surrogateId: string;
  productTypeId: string;
};

export function fetchExistingJointProductSurrogateId(
  repeatableCollectionId: string,
  jointProductRelatedNodeIds: JointProductRelatedNodeIds[]
): Query {
  return {
    select: [],
    selectRepeatable: jointProductRelatedNodeIds.map((jointProductRelatedNodeId) => {
      return {
        fromCollection: repeatableCollectionId,
        selectWithConditions: {
          nodeId: jointProductRelatedNodeId.surrogateId,
          selectIf: {
            conditions: [
              {
                nodeId: jointProductRelatedNodeId.productTypeId,
                operator: ComparisonOperator.equal,
                value: 'joint', // Turn into a product type
              },
            ],
          },
          defaultValue: undefined,
        },
      };
    }),
    operator: QueryOperator.fetch,
    operatorParams: {
      fetch: {
        firstNonEmptyValueAt: jointProductRelatedNodeIds.map(
          (jointProductRelatedNodeId) => jointProductRelatedNodeId.surrogateId
        ),
      },
    },
  };
}

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const legalGuardian: AnswerPathTreeNode = {
  path: 'legalGuardian',
  children: [
    { path: 'isLegalGuardianSameAsOwner', nodeId: NodeIds.isLegalGuardianSameAsOwner },
    { path: 'addLegalGuardian', nodeId: NodeIds.addLegalGuardian },
    {
      path: 'legalGuardianInfo',
      children: [
        { path: 'firstName', nodeId: NodeIds.legalGuardianFirstName },
        { path: 'lastName', nodeId: NodeIds.legalGuardianLastName },
        { path: 'primaryPhoneNumber', nodeId: NodeIds.legalGuardianPrimaryPhoneNumber },
        { path: 'primaryPhoneType', nodeId: NodeIds.legalGuardianPrimaryPhoneType },
        { path: 'email', nodeId: NodeIds.legalGuardianEmail },
        { path: 'dateOfBirth', nodeId: NodeIds.legalGuardianDateOfBirth },
        { path: 'address', nodeId: NodeIds.legalGuardianAddress },
        { path: 'apartment', nodeId: NodeIds.legalGuardianApartment },
        { path: 'city', nodeId: NodeIds.legalGuardianCity },
        { path: 'country', nodeId: NodeIds.legalGuardianCountry },
        { path: 'province', nodeId: NodeIds.legalGuardianProvince },
        { path: 'postalCode', nodeId: NodeIds.legalGuardianPostalCode },
        { path: 'primaryPhoneExtension', nodeId: NodeIds.legalGuardianPrimaryPhoneExtension },
        { path: 'secondaryPhoneNumber', nodeId: NodeIds.legalGuardianSecondaryPhoneNumber },
        { path: 'secondaryPhoneType', nodeId: NodeIds.legalGuardianSecondaryPhoneType },
        { path: 'secondaryPhoneExtension', nodeId: NodeIds.legalGuardianSecondaryPhoneExtension },
        { path: 'tertiaryPhoneNumber', nodeId: NodeIds.legalGuardianTertiaryPhoneNumber },
        { path: 'tertiaryPhoneType', nodeId: NodeIds.legalGuardianTertiaryPhoneType },
        { path: 'tertiaryPhoneExtension', nodeId: NodeIds.legalGuardianTertiaryPhoneExtension },
        { path: 'gender', nodeId: NodeIds.legalGuardianGender },
        { path: 'maritalStatus', nodeId: NodeIds.legalGuardianMaritalStatus },
        { path: 'relationshipToInsured', nodeId: NodeIds.legalGuardianRelationshipToInsured },
        { path: 'birthCountry', nodeId: NodeIds.legalGuardianBirthCountry },
        { path: 'birthCountryOther', nodeId: NodeIds.legalGuardianBirthCountryOther },
        { path: 'occupation', nodeId: NodeIds.legalGuardianOccupation },
        { path: 'birthProvinceOrState', nodeId: NodeIds.legalGuardianBirthProvinceOrState },
      ],
    },
  ],
};

import { maximumAgeCondition, minimumAgeCondition } from '@breathelife/condition-engine';
import { AgeRoundingType, Condition, DateUnit } from '@breathelife/types';

import { NodeIds } from '../nodeIds/nodeIds';

export function ageIsLessThanOrEqualTo(
  nodeId: NodeIds,
  age: number,
  ageRoundingType: AgeRoundingType = AgeRoundingType.closestBirthday,
  unit?: DateUnit.year | DateUnit.month
): Condition {
  return maximumAgeCondition(nodeId, age, unit || DateUnit.year, ageRoundingType);
}

export function ageIsGreaterThanOrEqualTo(
  nodeId: NodeIds,
  age: number,
  ageRoundingType: AgeRoundingType = AgeRoundingType.closestBirthday,
  unit?: DateUnit.year | DateUnit.month
): Condition {
  return minimumAgeCondition(nodeId, age, unit || DateUnit.year, ageRoundingType);
}

import {
  QuestionnaireBlueprintFieldTypes,
  BooleanFieldValidation,
  DateFieldValidation,
  InputFieldValidation,
  MoneyFieldValidation,
  NumberFieldValidation,
  PhoneFieldValidation,
  StringFieldValidation,
  AlwaysValid,
} from '@breathelife/types';

export function isOptionFieldType(fieldType: unknown): boolean {
  return (
    fieldType === QuestionnaireBlueprintFieldTypes.dropdown ||
    fieldType === QuestionnaireBlueprintFieldTypes.checkboxGroup ||
    fieldType === QuestionnaireBlueprintFieldTypes.radio ||
    fieldType === QuestionnaireBlueprintFieldTypes.checkbox
  );
}

export function isAgreeFieldType(fieldType: unknown): boolean {
  return fieldType === QuestionnaireBlueprintFieldTypes.agree;
}

export function isAddressAutocompleteFieldType(fieldType: unknown): boolean {
  return fieldType === QuestionnaireBlueprintFieldTypes.autocomplete;
}

// Select options in the frontend and changes applied in the backend should both use the same mapping between field type and validation.
export const fieldTypeToValidation = {
  [QuestionnaireBlueprintFieldTypes.agree]: BooleanFieldValidation,
  [QuestionnaireBlueprintFieldTypes.checkbox]: BooleanFieldValidation,
  [QuestionnaireBlueprintFieldTypes.button]: BooleanFieldValidation,
  [QuestionnaireBlueprintFieldTypes.input]: InputFieldValidation,
  [QuestionnaireBlueprintFieldTypes.number]: NumberFieldValidation,
  [QuestionnaireBlueprintFieldTypes.money]: MoneyFieldValidation,
  [QuestionnaireBlueprintFieldTypes.date]: DateFieldValidation,
  [QuestionnaireBlueprintFieldTypes.phone]: PhoneFieldValidation,
  [QuestionnaireBlueprintFieldTypes.currencyCard]: NumberFieldValidation,
  [QuestionnaireBlueprintFieldTypes.information]: StringFieldValidation,
  [QuestionnaireBlueprintFieldTypes.autocomplete]: StringFieldValidation,
  [QuestionnaireBlueprintFieldTypes.textarea]: StringFieldValidation,
  [QuestionnaireBlueprintFieldTypes.radio]: StringFieldValidation,
  [QuestionnaireBlueprintFieldTypes.dropdown]: StringFieldValidation,
  [QuestionnaireBlueprintFieldTypes.checkboxGroup]: StringFieldValidation,
  [QuestionnaireBlueprintFieldTypes.signature]: AlwaysValid,
};

import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../../../nodeIds';

export const sleepApnea: AnswerPathTreeNode = {
  path: 'sleepApnea',
  children: [
    { path: 'symptomsSeverity', nodeId: NodeIds.healthInsuredSleepApneaSymptomsSeverity },
    {
      path: 'cpapTreatment',
      children: [
        { path: 'usageHours', nodeId: NodeIds.healthInsuredCpapTreatmentUsageHours },
        { path: 'usageWeek', nodeId: NodeIds.healthInsuredCpapTreatmentUsageWeek },
        { path: 'isControlled', nodeId: NodeIds.healthInsuredCpapTreatmentIsControlled },
        { path: 'treatment', nodeId: NodeIds.healthInsuredCpapTreatment },
      ],
    },
    {
      path: 'obstrusiveSleepApnea',
      children: [{ path: 'diagnosisDate', nodeId: NodeIds.healthInsuredObstrusiveSleepApneaDiagnosisDate }],
    },
  ],
};

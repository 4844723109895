import { emptyTitle, Section } from '@breathelife/questionnaire-engine';
import {
  PdfDocumentType,
  IconName,
  InsuranceModule,
  PlatformType,
  RenderingType,
  SubsectionVariant,
} from '@breathelife/types';

import { InsuranceApplicationStepId } from '../../models/steps';

export const submissionSection: Section = {
  id: 'submission',
  blueprintId: '9a9a5ae8-96d0-414d-b613-6ae6c13058e2',
  index: '',
  title: emptyTitle,
  documentTypes: [PdfDocumentType.application, PdfDocumentType.advisorsReport],
  platformTypeFilter: [PlatformType.consumer],
  insuranceModuleFilter: [InsuranceModule.insuranceApplication],
  renderingTypeFilter: [RenderingType.web],
  subsections: [
    {
      id: InsuranceApplicationStepId.submission,
      blueprintId: 'e9c987bd-3c1a-41fe-a860-7b142d50d965',
      index: '',
      title: emptyTitle,
      iconName: IconName.confirmation,
      variant: SubsectionVariant.submission,
      questions: [],
    },
  ],
};

import { maximumAgeCondition, minimumAgeCondition } from '@breathelife/condition-engine';
import { DataLabel } from '@breathelife/meta-cruncher';
import {
  Field,
  FieldTypes,
  Section,
  Subsection,
  Validations,
  valueAboveMinimum,
  valueBelowMaximum,
} from '@breathelife/questionnaire-engine';
import {
  AgeRoundingType,
  BooleanOperator,
  ComparisonOperator,
  DateUnit,
  PdfDocumentType,
  FieldSizes,
  IconName,
  InsuranceModule,
  InsuranceScopes,
  PlatformType,
  QueryOperator,
  RenderingType,
} from '@breathelife/types';

import { yesNoOptions } from '../../helpers/options';
import { EducationCost, NeedsAnalysisStepId } from '../../models/steps';
import { NodeIds } from '../../nodeIds/nodeIds';

const PRIVATE_EDUCATION_YEARLY_PRICE = 17000;
const PRIVATE_EDUCATION_YEARS = 5;

const PUBLIC_EDUCATION_YEARLY_PRICE = 12000;
const PUBLIC_EDUCATION_YEARS = 2;

export const childrenBirthDateNodeIds = [
  NodeIds.child1BirthDate,
  NodeIds.child2BirthDate,
  NodeIds.child3BirthDate,
  NodeIds.child4BirthDate,
  NodeIds.child5BirthDate,
  NodeIds.child6BirthDate,
  NodeIds.child7BirthDate,
  NodeIds.child8BirthDate,
  NodeIds.child9BirthDate,
  NodeIds.child10BirthDate,
  NodeIds.child11BirthDate,
  NodeIds.child12BirthDate,
  NodeIds.child13BirthDate,
  NodeIds.child14BirthDate,
  NodeIds.child15BirthDate,
  NodeIds.child16BirthDate,
  NodeIds.child17BirthDate,
  NodeIds.child18BirthDate,
  NodeIds.child19BirthDate,
  NodeIds.child20BirthDate,
];

const maxNumberOfChildrenCondition = {
  nodeId: NodeIds.numberOfChildren,
  operator: ComparisonOperator.lessThanOrEqual,
  value: childrenBirthDateNodeIds.length,
};

const familyInformationStep: Subsection = {
  id: NeedsAnalysisStepId.familyInformation,
  blueprintId: '20946607-cce5-4dc7-a288-76cda5a62e12',
  scopeFilter: [InsuranceScopes.life],
  index: '1.2.1',
  title: { en: 'Tell me about your loved ones', fr: "Qu'en est-il de vos proches ?" },
  text: {
    en: "Our goal is to make sure that if something were to happen to you, it would not affect your loved ones' financial situation.",
    fr: "Veillons à ce que votre décès n'affecte pas la situation financière de vos proches.",
  },
  iconName: IconName.family,
  questions: [
    {
      id: 'familyInformation.spouse',
      blueprintId: '28131a22-3012-4526-b0a8-b69655001072',
      fields: [
        {
          id: 'hasSpouse',
          blueprintId: '9938f1c5-1ab4-49a3-99fd-37e4d1305868',
          optionSize: 'half',
          nodeId: NodeIds.insuredSpouseExists,
          dataLabel: DataLabel.KnownSafe,
          type: FieldTypes.radio,
          validation: { type: Validations.string },
          title: {
            en: 'Do you have a spouse or partner?',
            fr: 'Avez-vous un conjoint / partenaire ?',
          },
          options: yesNoOptions,
        },
        {
          id: 'spouseDateOfBirth',
          blueprintId: '69d6341f-02a0-428e-9d34-6cfa0517658b',
          nodeId: NodeIds.insuredSpouseDateOfBirth,
          dataLabel: DataLabel.DateOfBirth,
          type: FieldTypes.date,
          title: { en: "What's their date of birth?", fr: 'Leur date de naissance' },
          placeholder: { en: 'YYYY-MM-DD', fr: 'AAAA-MM-JJ' },
          visibleIf: {
            conditions: [
              {
                nodeId: NodeIds.insuredSpouseExists,
                value: 'yes',
                operator: ComparisonOperator.equal,
              },
            ],
          },
          validation: { type: Validations.pastDate },
          validIf: [
            {
              conditions: {
                conditions: [
                  minimumAgeCondition(
                    NodeIds.insuredSpouseDateOfBirth,
                    16,
                    DateUnit.year,
                    AgeRoundingType.lastBirthday
                  ),
                ],
              },
              message: {
                en: 'The minimum legal age for a spouse is 16 years old',
                fr: "L'âge légal minimum pour un conjoint est de 16 ans",
              },
            },
            {
              conditions: {
                conditions: [
                  maximumAgeCondition(
                    NodeIds.insuredSpouseDateOfBirth,
                    130,
                    DateUnit.year,
                    AgeRoundingType.lastBirthday
                  ),
                ],
              },
              message: {
                en: 'You spouse needs to be 130 years old or younger in order to continue',
                fr: 'Votre conjoint doit être âgé de 130 ans ou moins pour continuer',
              },
            },
          ],
        },
      ],
    },
    {
      id: `${NeedsAnalysisStepId.familyInformation}.children`,
      blueprintId: '8b061c56-ecfc-43c6-9e9b-107972cb40c3',
      fields: [
        {
          id: 'numberOfChildren',
          blueprintId: '3f896ba9-f769-478b-8dc8-5a60fe6432dc',
          nodeId: NodeIds.numberOfChildren,
          dataLabel: DataLabel.KnownSafe,
          type: FieldTypes.number,
          title: {
            en: 'How many children do you have?',
            fr: "Combien d'enfants avez-vous ?",
          },
          validation: { type: Validations.integer },
          validIf: [
            {
              conditions: {
                conditions: [maxNumberOfChildrenCondition],
              },
              message: {
                en: `You may specify ${childrenBirthDateNodeIds.length} children at most`,
                fr: `Vous pouvez specifier un maximum de ${childrenBirthDateNodeIds.length} enfants`,
              },
            },
          ],
        },
        ...generateChildFields(childrenBirthDateNodeIds),
      ],
    },
  ],
};

const educationFundStep: Subsection = {
  id: NeedsAnalysisStepId.educationFund,
  blueprintId: '51ce37e1-e632-402b-8fe6-b58eebd4956d',
  scopeFilter: [InsuranceScopes.life],
  dataLabel: DataLabel.KnownSafe,
  iconName: IconName.education,
  index: '1.2.3',
  title: {
    en: 'How much would you like to set aside for their education?',
    fr: 'Quel montant souhaitez-vous mettre de côté pour leur éducation ?',
  },
  text: {
    en: "Let's give your children the future you dream of for them.",
    fr: "Donnons à vos enfants l'avenir dont vous rêvez pour eux.",
  },
  visibleIf: {
    conditions: [
      {
        nodeId: NodeIds.numberOfChildren,
        operator: ComparisonOperator.notEqual,
        value: 0,
      },
    ],
  },
  questions: [
    {
      id: NeedsAnalysisStepId.educationFund,
      blueprintId: 'fd9b600c-5a0d-4a4a-9666-9bb498e3519d',
      fields: [
        {
          id: 'educationCost',
          blueprintId: 'b780a304-f75e-4f14-b4e6-2bce0d9247c1',
          nodeId: NodeIds.childrenEducationCost,
          type: FieldTypes.radio,
          validation: { type: Validations.string },
          title: {
            en: 'What kind of education do you want for your children?',
            fr: "Quel type d'éducation souhaitez-vous pour vos enfants ?",
          },
          options: [
            { id: EducationCost.lessExpensive, text: { fr: 'Public', en: 'Public' } },
            { id: EducationCost.moreExpensive, text: { fr: 'Privé', en: 'Private' } },
          ],
          defaultValue: EducationCost.lessExpensive,
        },
        {
          id: 'recommendedCoverageLessExpensiveEducation',
          blueprintId: '8a7fe00f-1c94-4f82-a463-eedd36beaf2b',
          nodeId: NodeIds.lessExpensiveEducationFundRecommendedAmount,
          type: FieldTypes.currencyCard,
          validation: { type: Validations.integer },
          readOnly: true,
          visibleIf: {
            conditions: [
              {
                nodeId: NodeIds.childrenEducationCost,
                operator: ComparisonOperator.equal,
                value: EducationCost.lessExpensive,
              },
            ],
          },
          title: {
            en: 'Your recommended public educational fund coverage amount',
            fr: "Le montant recommandé pour la couverture de votre fond d'éducation publique",
          },
          info: {
            title: {
              en: 'Public Recommended Educational Amount',
              fr: "Montant recommandé pour l'enseignement publique",
            },
            text: {
              en: 'This recommended amount is based on 2 years of Canadian average college tuition fees, cost of living and school supplies for the number of children indicated previously. \n\n($12,000 X Number of Kids X 2 Years)',
              fr: "Ce montant recommandé est basé sur deux années de frais de scolarité moyens au Canada, sur le coût de la vie et sur les fournitures scolaires pour le nombre d'enfants indiqué précédemment. \n\n(12 000 $ X nombre d'enfants X 2 ans)",
            },
          },
          computedValue: {
            select: [NodeIds.numberOfChildren],
            operator: QueryOperator.multiply,
            operatorParams: {
              numeric: [PUBLIC_EDUCATION_YEARLY_PRICE, PUBLIC_EDUCATION_YEARS],
            },
          },
        },
        {
          id: 'recommendedCoverageMoreExpensiveEducation',
          blueprintId: '3d02d5fd-7ece-4b90-91fe-44abd62fb8b1',
          nodeId: NodeIds.moreExpensiveEducationFundRecommendedAmount,
          type: FieldTypes.currencyCard,
          validation: { type: Validations.integer },
          readOnly: true,
          visibleIf: {
            conditions: [
              {
                nodeId: NodeIds.childrenEducationCost,
                operator: ComparisonOperator.equal,
                value: EducationCost.moreExpensive,
              },
            ],
          },
          title: {
            en: 'Your recommended private educational fund coverage amount',
            fr: "Le montant recommandé pour la couverture de votre fond d'éducation privée ",
          },
          info: {
            title: {
              en: 'Private Recommended Educational Amount',
              fr: "Montant recommandé pour l'enseignement privé",
            },
            text: {
              en: 'This recommended amount is based on 5 years of Canadian average university (bachelor and master degrees) tuition fees, cost of living and school supplies for the number of children indicated previously. \n\n($17,000 X Number of Kids X 5 Years)',
              fr: "Ce montant recommandé est basé sur 5 années d'études universitaires moyennes au Canada (licence et master), les frais de scolarité, le coût de la vie et les fournitures scolaires pour le nombre d'enfants indiqué ci-dessus. \n\n(17 000 $ X nombre d'enfants X 5 ans)",
            },
          },
          computedValue: {
            select: [NodeIds.numberOfChildren],
            operator: QueryOperator.multiply,
            operatorParams: {
              numeric: [PRIVATE_EDUCATION_YEARLY_PRICE, PRIVATE_EDUCATION_YEARS],
            },
          },
        },
        {
          id: 'useCalculator',
          blueprintId: '94a3852f-94e9-46da-be26-39ac69bbcd7d',
          nodeId: NodeIds.useCalculator,
          type: FieldTypes.button,
          validation: { type: Validations.boolean },
          triggerStepNavigation: true,
          title: {
            en: 'Want to calculate your own?',
            fr: 'Vous voulez calculer le vôtre ?',
          },
          iconName: IconName.educationCoverageCalculator,
          buttonText: {
            en: 'Use our calculator.',
            fr: 'Utilisez notre calculatrice.',
          },
        },
      ],
    },
  ],
};

const educationCoverageCalculatorStep: Subsection = {
  id: NeedsAnalysisStepId.educationCoverageCalculator,
  blueprintId: '977d303d-c205-483a-9dc0-5ba4b14bbaca',
  scopeFilter: [InsuranceScopes.life],
  dataLabel: DataLabel.KnownSafe,
  index: '1.2.3',
  iconName: IconName.educationCoverageCalculator,
  title: {
    en: 'Let’s figure out the right educational coverage, together',
    fr: 'Trouvons ensemble la bonne couverture éducative',
  },
  text: {
    en: 'Easy as 1-2-3.',
    fr: 'Un, deux, trois... go!',
  },
  visibleIf: {
    conditions: [
      {
        nodeId: NodeIds.useCalculator,
        operator: ComparisonOperator.equal,
        value: true,
      },
    ],
  },
  questions: [
    {
      id: NeedsAnalysisStepId.educationCoverageCalculator,
      blueprintId: '73170e16-bae5-4031-b6d0-874b876133a5',
      fields: [
        {
          id: 'numberOfChildrenCovered',
          blueprintId: 'b1d237f8-d1ed-488a-889f-c0e5cb7211d4',
          nodeId: NodeIds.numberOfChildrenCovered,
          dataLabel: DataLabel.KnownSafe,
          type: FieldTypes.number,
          validation: { type: Validations.integer },
          title: {
            en: 'How many children would you like to cover?',
            fr: "Combien d'enfants voulez-vous assurer ?",
          },
        },
        {
          id: 'numberOfYearsPerChild',
          blueprintId: '639afca4-1861-47e4-adfe-adc63f2837dd',
          nodeId: NodeIds.numberOfYearsPerChild,
          dataLabel: DataLabel.KnownSafe,
          type: FieldTypes.number,
          validation: { type: Validations.integer },
          title: {
            en: 'How many years of school per child?',
            fr: "Combien d'années scolaires par enfant ?",
          },
        },
        {
          id: 'amountPerSchoolYear',
          blueprintId: '4e4acba8-0e58-4f33-81fd-6dcf6642747d',
          nodeId: NodeIds.amountPerSchoolYear,
          dataLabel: DataLabel.KnownSafe,
          type: FieldTypes.money,
          validation: { type: Validations.decimal },
          title: {
            en: 'How much per school year?',
            fr: 'Combien par année scolaire ?',
          },
        },
      ],
    },
  ],
};

const customEducationCoverageStep: Subsection = {
  id: NeedsAnalysisStepId.customEducationCoverage,
  blueprintId: '8bebfedc-2ca3-4de7-986e-a7b8173f13ac',
  scopeFilter: [InsuranceScopes.life],
  dataLabel: DataLabel.KnownSafe,
  index: '1.2.4',
  iconName: IconName.education,
  title: {
    en: 'Your custom educational coverage amount',
    fr: 'Le montant de votre couverture scolaire personnalisée',
  },
  text: {
    en: 'Here is your personalized educational coverage amount based on your previous answers',
    fr: 'Voici le montant de votre couverture éducative personnalisée en fonction de vos réponses précédentes',
  },
  visibleIf: {
    conditions: [
      {
        nodeId: NodeIds.useCalculator,
        operator: ComparisonOperator.equal,
        value: true,
      },
    ],
  },
  questions: [
    {
      id: NeedsAnalysisStepId.customEducationCoverage,
      blueprintId: 'dfb0f417-faf7-40f1-9ad4-5b3bf60e75e4',
      fields: [
        {
          id: 'customEducationalAmount',
          blueprintId: '2a873e6a-9c8a-4c6d-82fc-5a9d6db6c79b',
          nodeId: NodeIds.customEducationFundRecommendedAmount,
          dataLabel: DataLabel.KnownSafe,
          type: FieldTypes.currencyCard,
          validation: { type: Validations.integer },
          readOnly: true,
          title: {
            en: 'Your custom educational fund coverage amount',
            fr: "Le montant de la couverture de votre fonds d'éducation personnalisé",
          },
          info: {
            title: {
              en: 'Custom educational coverage',
              fr: 'Couverture éducative personnalisée',
            },
            text: {
              en: 'Insured children X Number of school years per child X Amount per school year',
              fr: "Enfants assurés X Nombre d'années scolaires par enfant X Montant par année scolaire",
            },
          },
          computedValue: {
            select: [NodeIds.numberOfChildrenCovered, NodeIds.numberOfYearsPerChild, NodeIds.amountPerSchoolYear],
            operator: QueryOperator.multiply,
          },
        },
      ],
    },
  ],
};

const grossFamilyIncomeStep: Subsection = {
  id: NeedsAnalysisStepId.familyIncome,
  blueprintId: '3c1ca0bd-322a-4cec-b103-2c75d5edc13b',
  scopeFilter: [InsuranceScopes.life],
  dataLabel: DataLabel.KnownSafe,
  index: '1.2.5',
  iconName: IconName.familyIncome,
  title: {
    en: 'What is your family’s gross annual income?',
    fr: 'Quel est le revenu annuel brut de votre famille ?',
  },
  text: {
    en: 'Let’s make sure that in case something happens to you, they’ll be able to sustain their current lifestyle.',
    fr: 'Assurons-nous que, dans le cas où vous partirez, ils pourront maintenir leur mode de vie actuel.',
  },
  questions: [
    {
      id: NeedsAnalysisStepId.familyIncome,
      blueprintId: '67d0541b-9568-4d60-b3d8-80dd054fa716',
      fields: [
        {
          id: 'grossAnnualIncome',
          blueprintId: 'a7c17226-4d2b-4b03-950e-c450db107a1f',
          nodeId: NodeIds.insuredAnnualIncome,
          dataLabel: DataLabel.KnownSafe,
          type: FieldTypes.money,
          validation: { type: Validations.decimal },
          numericalDataType: 'float',
          title: {
            en: 'Enter your family’s annual income below',
            fr: 'Indiquez ci-dessous le revenu annuel de votre famille',
          },
          info: {
            text: {
              en: 'Your family’s total income before taxes and deductions. (salary, investments, self-employment income, bonuses, etc.)',
              fr: "Le revenu total de votre famille avant impôts et déductions. (salaire, investissements, revenus d'activités indépendantes, primes, etc.)",
            },
          },
        },
      ],
    },
  ],
};

const existingCoverageStep: Subsection = {
  id: NeedsAnalysisStepId.existingCoverage,
  blueprintId: '5dcd4f13-6bec-4099-8228-54b5fd67f4af',
  scopeFilter: [InsuranceScopes.life],
  dataLabel: DataLabel.KnownSafe,
  index: '1.2.6',
  iconName: IconName.coverage,
  title: {
    en: 'Existing life insurance coverage',
    fr: 'Avez-vous déjà une assurance vie ?',
  },
  text: {
    en: "Even if it's not with us. We just want to make sure you're properly insured.",
    fr: "Même si ce n'est pas avec nous. Nous voulons simplement s’assurer que vous soyez bien assuré",
  },
  questions: [
    {
      id: NeedsAnalysisStepId.existingCoverage,
      blueprintId: '3cc40474-dcda-45a0-bcd3-827a36607a11',
      fields: [
        {
          id: 'hasCoverage',
          blueprintId: '6a6154d4-b451-4ead-bf05-55998b8992e8',
          optionSize: 'half',
          nodeId: NodeIds.otherLifeInsurancePoliciesExists,
          dataLabel: DataLabel.KnownSafe,
          type: FieldTypes.radio,
          validation: { type: Validations.string },
          title: {
            en: 'Do you currently have life insurance?',
            fr: 'Possédez-vous déjà une assurance vie ?',
          },
          options: yesNoOptions,
          info: {
            text: {
              en: 'Includes any individual insurance, loan insurance, credit card payment insurance, group insurance, mortgage life insurance, etc.',
              fr: 'Comprend toute assurance individuelle, assurance de prêt, assurance de paiement par carte de crédit, assurance de groupe, assurance-vie hypothécaire, etc.',
            },
          },
        },
        {
          id: 'coverageTotal',
          blueprintId: '7a272b82-0e2d-44b1-91a7-ffcf0cb4b273',
          nodeId: NodeIds.otherLifeInsurancePoliciesCoverageAmount,
          dataLabel: DataLabel.KnownSafe,
          type: FieldTypes.money,
          validation: { type: Validations.decimal },
          numericalDataType: 'float',
          title: {
            en: 'What is the total coverage amount?',
            fr: 'Quel est le montant total de la couverture ?',
          },
          visibleIf: {
            conditions: [
              {
                nodeId: NodeIds.otherLifeInsurancePoliciesExists,
                value: 'yes',
                operator: ComparisonOperator.equal,
              },
            ],
          },
          layout: { forceNewLine: false },
        },
        {
          id: 'isReplacingCurrent',
          blueprintId: '6da55e33-98a9-4974-8a5e-f3d98cf01a37',
          optionSize: 'half',
          nodeId: NodeIds.otherLifeInsurancePolicesWillBeReplaced,
          dataLabel: DataLabel.KnownSafe,
          type: FieldTypes.radio,
          validation: { type: Validations.string },
          title: {
            en: 'Do you want to replace your current life insurance with a new one?',
            fr: 'Vous souhaitez remplacer votre assurance vie actuelle par une nouvelle ?',
          },
          options: yesNoOptions,
          visibleIf: {
            conditions: [
              {
                nodeId: NodeIds.otherLifeInsurancePoliciesExists,
                value: 'yes',
                operator: ComparisonOperator.equal,
              },
            ],
          },
        },
      ],
    },
  ],
};

const savingsAndDebtsStep: Subsection = {
  id: NeedsAnalysisStepId.savingsAndDebts,
  blueprintId: '5ea26727-4904-45cb-aa2a-d642735af1cb',
  scopeFilter: [InsuranceScopes.life],
  dataLabel: DataLabel.KnownSafe,
  index: '1.2.7',
  iconName: IconName.debtsSavings,
  title: {
    en: 'Savings and debts',
    fr: 'Epargne et dettes',
  },
  text: {
    en: 'Please specify the details of your savings and debts below.',
    fr: "Veuillez préciser ci-dessous l'état de vos économies et de vos dettes.",
  },
  questions: [
    {
      id: NeedsAnalysisStepId.savingsAndDebts,
      blueprintId: 'fce79127-a884-44eb-82a2-f922aa7175a8',
      fields: [
        {
          id: 'mortgageBalance',
          blueprintId: '27f00075-fc49-48e0-9eb0-9bb23e41be2b',
          nodeId: NodeIds.liabilitiesMortgageBalanceAmount,
          type: FieldTypes.money,
          validation: { type: Validations.decimal },
          numericalDataType: 'float',
          layout: { size: FieldSizes.half },
          title: {
            en: 'What is your mortgage balance?',
            fr: 'Quel est le solde de votre prêt hypothécaire ?',
          },
        },
        {
          id: 'totalDebt',
          blueprintId: '60e0ae2c-d057-4de0-9313-c7feb3d838d9',
          nodeId: NodeIds.liabilitiesTotalDebtAmount,
          type: FieldTypes.money,
          validation: { type: Validations.decimal },
          numericalDataType: 'float',
          title: {
            en: 'How much debt do you have?',
            fr: 'Quel est le montant total de vos dettes ?',
          },
          info: {
            title: {
              en: 'Debts to consider in this amount:',
              fr: 'Dettes à considérer dans ce montant :',
            },
            text: {
              en: 'Includes car loans, credit cards, student loans, personal loans, bank overdraft charges and income taxes.',
              fr: 'Comprend les prêts automobiles, les cartes de crédit, les prêts étudiants, les prêts personnels, les frais de découvert bancaire et les impôts sur le revenu.',
            },
          },
        },
        {
          id: 'totalSavings',
          blueprintId: 'e1716301-16df-4dc2-9c17-4ec20badf9f3',
          nodeId: NodeIds.assetsSavings,
          type: FieldTypes.money,
          validation: { type: Validations.decimal },
          layout: { size: FieldSizes.full },
          numericalDataType: 'float',
          title: {
            en: 'How much do you have in savings and other assets?',
            fr: 'Quel est le montant total de votre épargne et de vos autres avoirs ?',
          },
          info: {
            title: {
              en: 'Savings and assets to consider in this amount:',
              fr: 'Épargne et actifs à considérer dans ce montant :',
            },
            text: {
              en: `- Cash and cash equivalents, certificates of deposit, checking, and savings accounts, money market accounts, physical cash, Treasury bills.
            \n- Property or land and any structure that is permanently attached to it.
            \n- Personal property – boats, collectibles, household furnishings, jewelry, vehicles.
            \n- Investments – annuities, bonds, the cash value of life insurance policies, mutual funds, pensions, retirement plans, (IRA, 401(k), 403(b), etc.) stocks.`,
              fr: `- Espèces et quasi-espèces, certificats de dépôt, comptes chèques et comptes d'épargne, comptes sur le marché monétaire, espèces physiques, bons du Trésor.
            \n- Les biens ou terrains et toute structure qui y est attachée de façon permanente.
            \n- Biens personnels - bateaux, objets de collection, articles d'ameublement, bijoux, véhicules.
            \n- Placements - rentes, obligations, valeur de rachat des polices d'assurance-vie, fonds communs de placement, pensions, plans de retraite, (IRA, 401(k), 403(b), etc.) actions.`,
            },
          },
        },
      ],
    },
  ],
};

const finalExpensesStep: Subsection = {
  id: NeedsAnalysisStepId.finalExpenses,
  blueprintId: 'bdaa25c4-1f29-47a2-9d1a-a798d44dbc2f',
  scopeFilter: [InsuranceScopes.life],
  dataLabel: DataLabel.KnownSafe,
  index: '1.2.8',
  iconName: IconName.death,
  title: {
    en: 'How much do you want to set aside for funeral expenses?',
    fr: 'Quel montant voulez-vous mettre de côté pour les dépenses funéraires?',
  },
  text: {
    en: 'This amount covers your funeral costs and is based on your wishes.',
    fr: "Ce montant couvre vos frais d'obsèques. Le montant sera défini en fonction de vos souhaits.",
  },
  questions: [
    {
      id: NeedsAnalysisStepId.finalExpenses,
      blueprintId: '8ecd3bc5-f0cf-4328-8c33-42acbb476974',
      fields: [
        {
          id: 'amount',
          blueprintId: 'da02fb14-10bd-4545-a046-07cf3118b700',
          nodeId: NodeIds.finalExpensesAmount,
          type: FieldTypes.money,
          validation: { type: Validations.decimal },
          numericalDataType: 'float',
          title: {
            en: 'Enter the desired amount below',
            fr: 'Précisez le montant souhaité ci-dessous',
          },
          info: {
            title: {
              en: 'How Much Does a Funeral Cost?',
              fr: 'Combien coûtent les funérailles ? ',
            },
            text: {
              en: `The average funeral costs between $7,000 and $12,000.
              \nThis includes viewing and burial, basic service fees, transporting remains to a funeral home, a casket, embalming, and other preparation.
              \nThe average cost of a funeral with cremation is $6,000 to $7,000.`,
              fr: `Les funérailles coûtent en moyenne entre 7 000 $ et 12 000 $.
              \nCela comprend l'exposition et l'enterrement, les frais des services de base, le transport de la dépouille mortelle vers un salon funéraire, un cercueil, l'embaumement et d'autres préparatifs.
              \nLe coût moyen d'un enterrement avec crémation se situe entre 6 000 $ et 7 000 $.`,
            },
          },
        },
      ],
    },
  ],
};

const afterDeathFamilySupportStep: Subsection = {
  id: NeedsAnalysisStepId.afterDeath,
  blueprintId: 'f94e2570-1d25-4ece-878c-ff4c3576f0d4',
  scopeFilter: [InsuranceScopes.life],
  dataLabel: DataLabel.KnownSafe,
  index: '1.2.9',
  iconName: IconName.afterDeath,
  title: {
    en: 'For how many years after your death do you wish to support your dependents?',
    fr: "Pendant combien d'années après votre décès souhaitez-vous subvenir aux besoins de vos personnes à charge ?",
  },
  text: {
    en: 'Let’s provide your loved ones some financial comfort.',
    fr: 'Offrez à vos proches un soutien financier pendant un certain temps après votre départ.',
  },
  questions: [
    {
      id: NeedsAnalysisStepId.afterDeath,
      blueprintId: '4ffb7cee-6090-4d40-ba3d-a3fa2c8e296c',
      fields: [
        {
          id: 'numberOfYearsToProvideForDependents',
          blueprintId: 'd3e6c8c5-3283-4019-b4ad-3ed90a155cb4',
          nodeId: NodeIds.numberOfYearsToProvideForDependents,
          type: FieldTypes.number,
          validation: { type: Validations.integer },
          title: {
            en: 'Enter the number of years below',
            fr: "Précisez le nombre d'années ci-dessous",
          },
          placeholder: { en: 'e.g. 5', fr: 'ex: 5' },
          info: {
            text: {
              en: 'Your dependents may include your children, your spouse, your parents or other individuals. Average is 5 years.',
              fr: "Vos personnes à charge peuvent être vos enfants, votre conjoint, vos parents ou d'autres personnes. La moyenne est 5 ans.",
            },
          },
          validIf: [
            valueAboveMinimum(NodeIds.numberOfYearsToProvideForDependents, 1, ComparisonOperator.greaterThanOrEqual),
            valueBelowMaximum(NodeIds.numberOfYearsToProvideForDependents, 100, ComparisonOperator.lessThanOrEqual),
          ],
        },
      ],
    },
  ],
};

const grossRetirementInsuredIncomeStep: Subsection = {
  id: NeedsAnalysisStepId.retirementInsuredIncome,
  blueprintId: '76497db3-62c6-4149-ac7d-5fa88c445c94',
  scopeFilter: [InsuranceScopes.retirement],
  dataLabel: DataLabel.KnownSafe,
  index: '1.2.10',
  iconName: IconName.familyIncome,
  title: {
    en: 'What is your annual gross income?',
    fr: 'Quel est votre revenu annuel brut?',
  },
  text: {
    en: 'We know money is personal, but we need your input to find your financial solution',
    fr: "Nous savons que l'argent est personnel, mais nous avons besoin de votre contribution pour trouver votre solution financière",
  },
  questions: [
    {
      id: NeedsAnalysisStepId.retirementInsuredIncome,
      blueprintId: '189d0a43-f79e-4aa4-89df-8cdfc0e23a80',
      fields: [
        {
          id: 'retirementGrossAnnualIncome',
          blueprintId: '558a81ee-a1a0-41ea-a7c4-4d9eba6bf4fd',
          nodeId: NodeIds.retirementInsuredAnnualIncome,
          dataLabel: DataLabel.KnownSafe,
          type: FieldTypes.money,
          validation: { type: Validations.decimal },
          numericalDataType: 'float',
          title: {
            en: 'Your total income before taxes and deductions',
            fr: 'Votre revenu total avant impôts et déductions',
          },
        },
      ],
    },
  ],
};

function generateChildFields(childrenBirthDateNodeIds: NodeIds[]): Field[] {
  return childrenBirthDateNodeIds.map((childrenBirthDateNodeId: NodeIds, idx: number) => {
    return {
      id: `child${idx}.dateOfBirth`,
      blueprintId: '2408092d-6f83-4a89-953d-7852fe0dbac0',
      nodeId: childrenBirthDateNodeId,
      dataLabel: DataLabel.DateOfBirth,
      type: FieldTypes.date,
      title: { en: `Child # ${idx + 1} date of birth`, fr: `Date de naissance de l'enfant #${idx + 1}` },
      placeholder: { en: 'YYYY-MM-DD', fr: 'AAAA-MM-JJ' },
      layout: { forceNewLine: true },
      visibleIf: {
        conditions: [
          maxNumberOfChildrenCondition,
          {
            nodeId: NodeIds.numberOfChildren,
            value: idx + 1,
            operator: ComparisonOperator.greaterThanOrEqual,
          },
        ],
        operator: BooleanOperator.and,
      },
      validIf: [
        {
          conditions: {
            conditions: [
              {
                query: {
                  select: [childrenBirthDateNodeId, NodeIds.insuredDateOfBirth],
                  operator: QueryOperator.subtractDates,
                },
                operator: ComparisonOperator.greaterThan,
                value: 0,
              },
            ],
          },
          message: {
            en: 'You need to be older than your children',
            fr: "L'âge de votre enfant doit être inférieur à votre âge",
          },
        },
      ],
      validation: { type: Validations.pastDate },
    };
  });
}

export const financialNeedsAnalysisSection: Section = {
  id: 'financialNeedsAnalysis',
  blueprintId: '17c899ba-5674-4516-b50a-82401fde342d',
  index: '1.2',
  title: {
    en: 'Financial needs analysis',
    fr: 'Analyse des besoins financiers',
  },
  documentTypes: [PdfDocumentType.application, PdfDocumentType.advisorsReport],
  platformTypeFilter: [PlatformType.consumer],
  insuranceModuleFilter: [InsuranceModule.needsAnalysis],
  renderingTypeFilter: [RenderingType.web, RenderingType.proSummary],
  subsections: [
    familyInformationStep,
    educationFundStep,
    educationCoverageCalculatorStep,
    customEducationCoverageStep,
    grossFamilyIncomeStep,
    grossRetirementInsuredIncomeStep,
    existingCoverageStep,
    savingsAndDebtsStep,
    finalExpensesStep,
    afterDeathFamilySupportStep,
  ],
};

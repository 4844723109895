import { AnswerPathTreeNode } from '@breathelife/questionnaire-engine';

import { NodeIds } from '../../nodeIds';

export const secondOwner: AnswerPathTreeNode = {
  path: 'secondOwner',
  children: [
    { path: 'addSecondOwner', nodeId: NodeIds.addSecondOwner },
    { path: 'secondOwnerIs', nodeId: NodeIds.secondOwnerIs },
    { path: 'secondOwnerIsTheInsured', nodeId: NodeIds.secondOwnerIsTheInsured },
    { path: 'secondOwnerIsTheLegalGuardian', nodeId: NodeIds.secondOwnerIsTheLegalGuardian },
    { path: 'secondOwnerMiddleNameInitial', nodeId: NodeIds.secondOwnerMiddleNameInitial },
    { path: 'secondOtherOwnerLastName', nodeId: NodeIds.secondOtherOwnerLastName },
    { path: 'secondOtherOwnerFirstName', nodeId: NodeIds.secondOtherOwnerFirstName },
    { path: 'secondOtherOwnerGender', nodeId: NodeIds.secondOtherOwnerGender },
    { path: 'secondOtherOwnerDateOfBirth', nodeId: NodeIds.secondOtherOwnerDateOfBirth },
    { path: 'secondOtherOwnerMaritalStatus', nodeId: NodeIds.secondOtherOwnerMaritalStatus },
    { path: 'secondOtherOwnerProfession', nodeId: NodeIds.secondOtherOwnerProfession },
    { path: 'secondOtherOwnerAddress', nodeId: NodeIds.secondOtherOwnerAddress },
    { path: 'secondOtherOwnerApartment', nodeId: NodeIds.secondOtherOwnerApartment },
    { path: 'secondOtherOwnerCity', nodeId: NodeIds.secondOtherOwnerCity },
    { path: 'secondOtherOwnerCountry', nodeId: NodeIds.secondOtherOwnerCountry },
    { path: 'secondOtherOwnerProvince', nodeId: NodeIds.secondOtherOwnerProvince },
    { path: 'secondOtherOwnerPostalCode', nodeId: NodeIds.secondOtherOwnerPostalCode },
    { path: 'secondOtherOwnerSin', nodeId: NodeIds.secondOtherOwnerSin },
    { path: 'secondOtherOwnerEmail', nodeId: NodeIds.secondOtherOwnerEmail },
    { path: 'secondOtherOwnerPhoneTypeOne', nodeId: 'second-other-owner-phone-type-1' },
    { path: 'secondOtherOwnerPhoneOne', nodeId: 'second-other-owner-phone-1' },
    { path: 'secondOtherOwnerPhoneExtensionOne', nodeId: NodeIds.secondOtherOwnerPhoneExtensionOne },
    { path: 'secondOtherOwnerPhoneTypeTwo', nodeId: 'second-other-owner-phone-type-2' },
    { path: 'secondOtherOwnerPhoneTwo', nodeId: 'second-other-owner-phone-2' },
    { path: 'secondOtherOwnerPhoneExtensionTwo', nodeId: NodeIds.secondOtherOwnerPhoneExtensionTwo },
    { path: 'secondOtherOwnerPhoneTypeThree', nodeId: 'second-other-owner-phone-type-3' },
    { path: 'secondOtherOwnerPhoneThree', nodeId: 'second-other-owner-phone-3' },
    { path: 'secondOtherOwnerPhoneExtensionThree', nodeId: NodeIds.secondOtherOwnerPhoneExtensionThree },
    {
      path: 'secondOtherOwnerRelationToProposedInsured',
      nodeId: NodeIds.secondOtherOwnerRelationToProposedInsured,
    },
    { path: 'secondOwnerIdVerificationStatus', nodeId: NodeIds.secondOwnerIdVerificationStatus },
    { path: 'secondOwnerIdentificationDocument', nodeId: NodeIds.secondOwnerIdentificationDocument },
    { path: 'secondOwnerOtherIdentificationDocument', nodeId: NodeIds.secondOwnerOtherIdentificationDocument },
    { path: 'secondOwnerIdentificationDocumentNumber', nodeId: NodeIds.secondOwnerIdentificationDocumentNumber },
    { path: 'secondOwnerIdentificationDocumentExpiry', nodeId: NodeIds.secondOwnerIdentificationDocumentExpiry },
    {
      path: 'secondOwnerIdentificationDocumentIssuingAuthority',
      nodeId: NodeIds.secondOwnerIdentificationDocumentIssuingAuthority,
    },
    {
      path: 'secondOwnerIdentificationDocumentCountryProvince',
      nodeId: NodeIds.secondOwnerIdentificationDocumentCountryProvince,
    },
    { path: 'secondOwnerThirdPartyDetermination', nodeId: NodeIds.secondOwnerThirdPartyDetermination },
    { path: 'secondOwnerTpLastName', nodeId: NodeIds.secondOwnerTpLastName },
    { path: 'secondOwnerTpFirstName', nodeId: NodeIds.secondOwnerTpFirstName },
    { path: 'secondOwnerTpDateOfBirth', nodeId: NodeIds.secondOwnerTpDateOfBirth },
    { path: 'secondOwnerTpOccupation', nodeId: NodeIds.secondOwnerTpOccupation },
    { path: 'secondOwnerTpRelationship', nodeId: NodeIds.secondOwnerTpRelationship },
    { path: 'secondOwnerTpRelationshipOther', nodeId: NodeIds.secondOwnerTpRelationshipOther },
    { path: 'secondOwnerTpAddress', nodeId: NodeIds.secondOwnerTpAddress },
    { path: 'secondOwnerTpApartment', nodeId: NodeIds.secondOwnerTpApartment },
    { path: 'secondOwnerTpCity', nodeId: NodeIds.secondOwnerTpCity },
    { path: 'secondOwnerTpCountry', nodeId: NodeIds.secondOwnerTpCountry },
    { path: 'secondOwnerTpProvince', nodeId: NodeIds.secondOwnerTpProvince },
    { path: 'secondOwnerThirdPartyPhoneType', nodeId: NodeIds.secondOwnerThirdPartyPhoneType },
    { path: 'secondOwnerThirdPartyPhoneNumber', nodeId: NodeIds.secondOwnerThirdPartyPhoneNumber },
    { path: 'secondOwnerThirdPartyPhoneExtension', nodeId: NodeIds.secondOwnerThirdPartyPhoneExtension },
    { path: 'secondOwnerTpPostalCode', nodeId: NodeIds.secondOwnerTpPostalCode },
    { path: 'secondOwnerSin', nodeId: NodeIds.secondOwnerSin },
    { path: 'secondOwnerUsCitizen', nodeId: NodeIds.secondOwnerUsCitizen },
    { path: 'secondOwnerTin', nodeId: NodeIds.secondOwnerTin },
    { path: 'secondOwnerResidentOtherCountry', nodeId: NodeIds.secondOwnerResidentOtherCountry },
    { path: 'secondOwnerOtherCountry', nodeId: NodeIds.secondOwnerOtherCountry },
    { path: 'secondOwnerOtherCountryTin', nodeId: NodeIds.secondOwnerOtherCountryTin },
    { path: 'addSecondSubrogatedOwner', nodeId: NodeIds.addSecondSubrogatedOwner },
    { path: 'secondSubrogatedOwnerType', nodeId: NodeIds.secondSubrogatedOwnerType },
    { path: 'secondSubrogatedOwnerFirstName', nodeId: NodeIds.secondSubrogatedOwnerFirstName },
    { path: 'secondSubrogatedOwnerLastName', nodeId: NodeIds.secondSubrogatedOwnerLastName },
    { path: 'secondSubrogatedOwnerSex', nodeId: NodeIds.secondSubrogatedOwnerSex },
    { path: 'secondSubrogatedOwnerDateOfBirth', nodeId: NodeIds.secondSubrogatedOwnerDateOfBirth },
    { path: 'secondSubrogatedOwnerRelationship', nodeId: NodeIds.secondSubrogatedOwnerRelationship },
    { path: 'secondSubrogatedOwnerOther', nodeId: NodeIds.secondSubrogatedOwnerOther },
    { path: 'secondOwnerWaiverOfPremium', nodeId: NodeIds.secondOwnerWaiverOfPremium },
    { path: 'secondOwnerWaiverOfPremiumTerm', nodeId: NodeIds.secondOwnerWaiverOfPremiumTerm },
    { path: 'secondOwnerBirthCountry', nodeId: NodeIds.secondOwnerBirthCountry },
    { path: 'secondOwnerBirthCountryOther', nodeId: NodeIds.secondOwnerBirthCountryOther },
    { path: 'secondOwnerBirthProvinceOrState', nodeId: NodeIds.secondOwnerBirthProvinceOrState },
    { path: 'secondOwnerBirthCity', nodeId: NodeIds.secondOwnerBirthCity },
    { path: 'secondOwnerSignatureCity', nodeId: NodeIds.secondOwnerSignatureCity },
    { path: 'secondOwnerSignatureState', nodeId: NodeIds.secondOwnerSignatureState },
    { path: 'secondOwnerSignatureDate', nodeId: NodeIds.secondOwnerSignatureDate },
  ],
};

import _ from 'lodash';

import { formatHeightToFeetAndCm, formatWeightToLbsAndKg, SelectOption } from '@breathelife/questionnaire-engine';

export const identificationTitles: SelectOption[] = [
  { id: 'miss', text: { en: 'Miss', fr: 'Mlle' } },
  { id: 'mr', text: { en: 'Mr.', fr: 'M.' } },
  { id: 'mrs', text: { en: 'Mrs.', fr: 'Mme' } },
  { id: 'reverend', text: { en: 'Reverend', fr: 'Révérend' } },
  { id: 'other', text: { en: 'Other', fr: 'Mme' } },
];

export const yesNoOptions: SelectOption[] = [
  { id: 'yes', text: { fr: 'Oui', en: 'Yes' } },
  { id: 'no', text: { fr: 'Non', en: 'No' } },
];

export const monthOptions: SelectOption[] = [
  { id: 'january', text: { en: 'January', fr: 'Janvier' } },
  { id: 'february', text: { en: 'February', fr: 'Février' } },
  { id: 'march', text: { en: 'March', fr: 'Mars' } },
  { id: 'april', text: { en: 'April', fr: 'Avril' } },
  { id: 'may', text: { en: 'May', fr: 'Mai' } },
  { id: 'june', text: { en: 'June', fr: 'Juin' } },
  { id: 'july', text: { en: 'July', fr: 'Juillet' } },
  { id: 'august', text: { en: 'August', fr: 'Août' } },
  { id: 'september', text: { en: 'September', fr: 'Septembre' } },
  { id: 'october', text: { en: 'October', fr: 'Octobre' } },
  { id: 'november', text: { en: 'November', fr: 'Novembre' } },
  { id: 'december', text: { en: 'December', fr: 'Décembre' } },
];

export function yearOptions(options?: { yearMax: number; yearMin: number }): SelectOption[] {
  const yearMax = options?.yearMax ?? new Date().getFullYear();
  const yearMin = options?.yearMin ?? yearMax - 100;

  return numberRangeOptions(yearMax, yearMin);
}

export function numberRangeOptions(min: number, max: number): SelectOption[] {
  return _.range(min, max).map((num: number) => {
    const numString = num.toString();
    return { id: numString, text: { en: numString, fr: numString } };
  });
}

const HEIGHT_MIN_INCHES = 48;
const HEIGHT_MAX_INCHES = 96;
const HEIGHT_STEP_INCHES = 1;

const WEIGHT_MIN_LBS = 50;
const WEIGHT_MAX_LBS = 1000;
const WEIGHT_STEP_LBS = 5;

export const heightOptions: SelectOption[] = _.range(HEIGHT_MIN_INCHES, HEIGHT_MAX_INCHES + 1, HEIGHT_STEP_INCHES).map(
  (heightInch: number) => {
    return {
      id: heightInch.toString(),
      text: formatHeightToFeetAndCm(heightInch),
    };
  }
);

export function weightOptions(
  minWeight: number = WEIGHT_MIN_LBS,
  maxWeight: number = WEIGHT_MAX_LBS,
  weightStep: number = WEIGHT_STEP_LBS
): SelectOption[] {
  return _.range(minWeight, maxWeight + 1, weightStep).map((weightLbs) => {
    return {
      id: weightLbs.toString(),
      text: formatWeightToLbsAndKg(weightLbs),
    };
  });
}
